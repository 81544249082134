const useFinBlockOrderView = () => {
  const fileArray = [];

  function onAcceptToWorkBtnClick() {}
  function onExitBtnClick() {}

  return {onAcceptToWorkBtnClick, onExitBtnClick, fileArray};
};

export default useFinBlockOrderView;
