import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import useToaster from "../../../../utilities/hooks/useToaster";
import makeNameFromPersona from "../../../../utilities/makeNameFromPersona";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {markAsDefectedRequestSku} from "../../../Requests/RequestsSlice";
import {fetchStockPoolBySkuRequestId, updateOrder} from "../../redux/operations";
import {selectFetchedStockPool, selectWarehouseRequests} from "../../redux/selectors";

import {CHECK_SKU_STORAGE_MODAL_COLUMNS} from "./constants";

const useCheckSkuStorageModal = ({selectedOrderId}) => {
  const dispatch = useDispatch();
  const Toaster = useToaster();
  const {
    i18n: {language},
  } = useTranslation();

  const requests = useSelector(selectWarehouseRequests);
  const currentRequest = requests?.find(request => request.order.orderId === selectedOrderId) ?? null;
  const currentSkuRequestId = currentRequest?.skuRequestId;
  const currentOrder = currentRequest?.order ?? null;
  const currentOrderNumber = currentOrder?.orderNumber ?? "";
  const currentSp = useSelector(selectFetchedStockPool);

  const [checkedSkuIds, setCheckedSkuIds] = useState([]);
  const [isModalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (currentSkuRequestId) {
      dispatch(fetchStockPoolBySkuRequestId(currentSkuRequestId));
    }
  }, [currentSkuRequestId]);

  const areBtnsDisabled = checkedSkuIds.length === 0;

  const initialValues = {
    orderNumber: currentOrder?.orderNumber ?? "",
    managerCo: makeNameFromPersona({persona: currentOrder?.co?.managerCo?.persona, language}) ?? "",
    managerFea: makeNameFromPersona({persona: currentRequest?.request?.managerFea?.persona, language}) ?? "",
  };

  const noteInputRef = useRef();

  const skuTableColumns = useColumns(CHECK_SKU_STORAGE_MODAL_COLUMNS);
  const skuTableData =
    currentSp?.sku?.map(
      (
        {
          brand,
          skuId,
          productGroup,
          productType,
          name,
          nameEng,
          article,
          material,
          amount,
          retailPrice,
          color,
          height,
          length,
          width,
          weight,
          weightUnit,
          area,
          areaUnit,
          volume,
          volumeUnit,
          overallSizeUnit,
        },
        index,
      ) => {
        return {
          id: skuId,
          number: ++index,
          article: article ?? " - ",
          brandName: brand?.name ?? " - ",
          group: productGroup?.name ?? " - ",
          type: productType?.name ?? " - ",
          skuName: (language === "en" ? nameEng : name) ?? " - ",
          finishing: material ?? " - ",
          color: color ?? " - ",
          price: retailPrice ?? " - ",
          amount: amount ?? " - ",
          totalSum: retailPrice * amount ?? " - ",
          length: overallSizeUnit?.multiplier * length || " - ",
          width: overallSizeUnit?.multiplier * width || " - ",
          height: overallSizeUnit?.multiplier * height || " - ",
          weight: weightUnit?.multiplier * weight || " - ",
          area: areaUnit?.multiplier * area || " - ",
          volume: volumeUnit?.multiplier * volume || " - ",
          onStock: " - ",
          available: " - ",
          inOrder: currentSp?.order?.orderNumber ?? " - ",
          inRequest: currentSp?.request?.requestNumber ?? " - ",
          proforma: (currentSp?.request?.proforms ?? [])[0]?.proformaNumber ?? " - ",
          tpNumber: currentSp?.stockPool?.cargo?.cargoNumber ?? " - ",
          reservation: " - ",
          coReservation: " - ",
        };
      },
    ) ?? [];

  function onSkuCheck(checkedSkuIds) {
    setCheckedSkuIds(checkedSkuIds);
  }

  function onSaveNoteBtnClick() {
    const note = noteInputRef.current.value;
    if (!note) {
      Toaster.toastError("Enter a note first!");
      return;
    }

    dispatch(updateOrder({orderId: currentOrder?.orderId, data: {note}})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        Toaster.toastSuccess("Saved");
      }
    });
  }

  function onSaveCheckedSkusBtnClick() {
    if (checkedSkuIds.length === 0) return;
    swalCustom.info({
      text: "SKUs marked as defective remain in the order warehouse",
      confirmFunc: () => {
        dispatch(markAsDefectedRequestSku({skuRequestIds: [currentSp.skuRequestId]})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("Checked");
          }
        });
      },
    });
  }

  function onCancelBtnClick() {
    setCheckedSkuIds([]);
    noteInputRef.current.value = "";
    Toaster.toastSuccess("Canceled");
  }

  function onSkuTableDoubleClick() {
    setModalActive(true);
  }

  return {
    skuTableColumns,
    skuTableData,
    onSkuCheck,
    onSaveNoteBtnClick,
    onSaveCheckedSkusBtnClick,
    onCancelBtnClick,
    initialValues,
    noteInputRef,
    areBtnsDisabled,
    onSkuTableDoubleClick,
    checkedSkuIds,
    isModalActive,
    setModalActive,
    currentOrderNumber,
    currentSp,
  };
};

export default useCheckSkuStorageModal;
