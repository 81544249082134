import {t} from "i18next";

import {MoneyInput} from "../../../../../Inputs";

const PayerCoBalance = () => {
  return (
    <div className="row w100 aibl">
      <span>{t("Payer")}</span>
      <MoneyInput name="payerCoBalanceComposed" label={t("CO Balance")} autolabel width="8em" readOnly />
    </div>
  );
};

export default PayerCoBalance;
