import {useEffect} from "react";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {MoneyInput} from "../../../../../Inputs";

const Sum = ({readOnly = false}) => {
  const {
    values: {sumComposed, areAllFieldsFilledIn},
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (!readOnly && !sumComposed) {
      setFieldValue("areAllFieldsFilledIn", false);
    }
    if (!readOnly && sumComposed && !areAllFieldsFilledIn) {
      setFieldValue("areAllFieldsFilledIn", true);
    }
  }, [sumComposed, areAllFieldsFilledIn]);

  return (
    <div className="row w100">
      <MoneyInput name="sumComposed" label={t("Sum")} autolabel width="10em" readOnly={readOnly} />
    </div>
  );
};

export default Sum;
