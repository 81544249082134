import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import IndeterminateCheckbox from "../../../conteiners/reactTable/IndeterminateCheckbox";
import {TextAreaInput, TextInput} from "../../../Inputs";
import CurrencySelect from "../../../Inputs/CurrencySelect/CurrencySelect";
import DirectiveFilters from "../../../Project/ProjectDirectiveList/DirectiveFilters";
import OrderTypeComposedPart from "../../../Project/ProjectDirectiveList/OrderTypeComposedPart/OrderTypeComposedPart";
import OneAccountTemplate from "../../AccountPaymentCommonComponent/AccountCommonComponent/OneAccountTemplate";

import useFinBlockOrderDirectiveDo from "./useFinBlockOrderDirectiveDo";

const FinBlockOrderDirectiveDo = () => {
  const {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    onExitBtnClick,
    onDeleteOrderBtnClick,
    onRunOrderBtnClick,
    onSaveBtnClick,
    onCreateOrderBtnClick,
    onOkBtnClick,
  } = useFinBlockOrderDirectiveDo();

  return (
    <div className="fcCol h100 w100 pb aist">
      <DirectiveFilters />
      <Divider />
      <div className="fc aistr jcsb gap2x w100 fGrow2 mb0_5">
        <OrderTypeComposedPart readOnly className="fGrow" noAsterisk>
          <div className="row w100 jcsb">
            <FunctionButton onClick={onRunOrderBtnClick} name="Run order" autoWidth />
            <FunctionButton onClick={onExitBtnClick} name="Exit" autoWidth />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2 gap2x" style={{maxWidth: "70%"}}>
          {/* <FinStatus /> */}
          <div className="row gap2x aibl">
            <CurrencySelect label="Receipt currency" />
            <TextInput name="receiptSum" label={t("Receipt sum")} autolabel />
            <FunctionButton onClick={onOkBtnClick} name="Ok" autoWidth />
          </div>
          <div className="w100">
            <div className="smaller_font_size w100 mb" style={{border: "1px solid #A3B9D9", padding: "1em"}}>
              <div className="fdr gap jcsb">
                <div className="fdr aic gap " style={{marginRight: "2em"}}>
                  <div>{t("Dt")}</div>
                  <OneAccountTemplate name="dt" />
                </div>

                <div className="fdr aic gap" style={{marginLeft: "2em"}}>
                  <div>{t("Kt")}</div>
                  <OneAccountTemplate name="kt" />
                </div>
              </div>
              <div className="fdr aic gap">
                <ToolTip title="Dt account name" className="fGrow">
                  <TextAreaInput name="ktAccountName" height="2.5em" placeholder={t("Dt account name")} disabled />
                </ToolTip>
                <ToolTip title="Kt account name" className="fGrow">
                  <TextAreaInput name="dtAccountName" height="2.5em" placeholder={t("Kt account name")} disabled />
                </ToolTip>
              </div>
              <Divider />
              <div className="fdr aic gap">
                <ToolTip title="Payment purpose" className="fGrow2">
                  <TextAreaInput
                    name="paymentRecognition"
                    height="4.5em"
                    placeholder={t("Enter Recognition of payment") + "*"}
                  />
                </ToolTip>
                <ToolTip title="Note (for the whole directive)" className="fGrow">
                  <TextAreaInput name="note" height="4.5em" placeholder={t("Add note")} />
                </ToolTip>
              </div>
            </div>
            <div className="row jcfe w100">
              <FunctionButton onClick={onSaveBtnClick} name="Save" autoWidth />
            </div>
          </div>

          <ReactTable
            columns={upperTableColumns}
            defaultData={upperTableData}
            // current={null}
            // onSelect={onTableSelect}
            className="fGrow mb"
            style={{height: "unset"}}
          />
          <div className="row aibl w100">
            <div className="row gap aic">
              <IndeterminateCheckbox />
              <CurrencySelect name="covertToCurrencyId" label="Convert to currency" />
              <TextInput name="rate" label={t("rate")} autolabel width="5em" />
            </div>
            <FunctionButton onClick={onCreateOrderBtnClick} name="Create order" autoWidth />
          </div>
          <ReactTable
            columns={lowerTableColumns}
            defaultData={lowerTableData}
            // current={null}
            // onSelect={onTableSelect}
            className="fGrow mb"
            style={{height: "unset"}}
          />
          <div className="row jcfe w100 gap3x">
            <FunctionButton onClick={onDeleteOrderBtnClick} name="Delete order" autoWidth />
            <FunctionButton onClick={onPrintOrderBtnClick} name="Print an order" autoWidth />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinBlockOrderDirectiveDo;
