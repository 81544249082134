import {createAsyncThunk} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../../../hook/axios.hook";

export const fetchCarrierFilters = createAsyncThunk("logistCarriers/fetchCarrierFilters", async () =>
  fetchList(`/carriers/getFilters`, "get"),
);

export const fetchAllCarriers = createAsyncThunk("logistCarriers/fetchAllCarriers", async params =>
  fetchList(`/carriers/getAll`, "get", {params}),
);

export const getCarrier = createAsyncThunk("logistCarriers/getCarrier", async carrierId =>
  fetchList(`/carriers/get/${carrierId}`, "get"),
);
export const addNewCarrier = createAsyncThunk("logistCarriers/addNewCarrier", async ({typeId, data}) =>
  fetchData(`/carriers/add/${typeId}`, "post", data),
);
export const updateCarrier = createAsyncThunk("logistCarriers/updateCarrier", async ({carrierId, data}) =>
  fetchData(`/carriers/update/${carrierId}`, "patch", data),
);
export const deleteCarrier = createAsyncThunk("logistCarriers/deleteCarrier", async carrierId =>
  fetchData(`/carriers/remove/${carrierId}`, "delete"),
);

// export const fetchAllCarrierDocs = createAsyncThunk("logist/fetchAllCarrierDocs", async cargoId =>
//   fetchData(`cargos/docs/getAll/${cargoId}`, "get"),
// );
// export const fetchAllCarrierDocs = createAsyncThunk("logist/fetchAllCarrierDocs", async cargoId =>
//   fetchData(`cargos/docs/getAll?cargoId=${cargoId}`, "get"),
// );
//?
export const fetchAllCarrierDocs = createAsyncThunk("logistCarriers/fetchAllCarrierDocs", async () =>
  fetchData(`cargos/docs/getAll`, "get"),
);

//?
export const fetchAllCarrierDocTypes = createAsyncThunk("logistCarriers/fetchAllCarrierDocTypes", async () =>
  fetchData(`cargos/docs/getAllTypes`, "get"),
);
//** Carrier Types **/
//get
export const fetchAllCarrierTypes = createAsyncThunk("logistCarriers/fetchAllCarrierTypes", async () =>
  fetchList(`/carriers/types/getAll`, "get"),
);
//post
export const addNewCarrierType = createAsyncThunk("logistCarriers/addNewCarrierType", async params =>
  fetchData(`/carriers/types/add`, "post", params),
);
//delete
export const deleteCarrierType = createAsyncThunk("logistCarriers/deleteCarrierType", async typeId =>
  fetchData(`/carriers/types/remove/${typeId}`, "delete"),
);
//** Carrier Contacts **/
//post
export const addNewCarrierContact = createAsyncThunk(
  "logistCarriers/addNewCarrierContact",
  async ({carrierId, params}) => fetchData(`/carriers/contacts/add/${carrierId}`, "post", params),
);
//delete
export const deleteCarrierContact = createAsyncThunk("logistCarriers/deleteCarrierContact", async contactId =>
  fetchData(`/carriers/contacts/remove/${contactId}`, "delete"),
);
///update
export const updateCarrierContact = createAsyncThunk(
  "logistCarriers/updateCarrierContact",
  async ({contactId, params}) => fetchData(`/carriers/contacts/update/${contactId}`, "patch", params),
);
//** Carrier Accounts **/
//post
export const addNewCarrierAccount = createAsyncThunk(
  "logistCarriers/addNewCarrierAccount",
  async ({carrierId, params}) => fetchData(`/carriers/accounts/add/${carrierId}`, "post", params),
);
//delete
export const deleteCarrierAccount = createAsyncThunk("logistCarriers/deleteCarrierAccount", async accountId =>
  fetchData(`/carriers/accounts/remove/${accountId}`, "delete"),
);
export const updateCarrierAccount = createAsyncThunk(
  "logistCarriers/updateCarrierAccount",
  async ({accountId, params}) => fetchData(`/carriers/accounts/update/${accountId}`, "patch", params),
);

//** Carrier Docs **/
export const addCarrierDoc = createAsyncThunk("logistCarriers/addCarrierDoc", async ({carrierId, params}) =>
  fetchData(`carriers/docs/add/${carrierId}`, "post", params),
);
export const updateCarrierDoc = createAsyncThunk("logistCarriers/updateCarrierDoc", async ({docId, params}) =>
  fetchData(`carriers/docs/update/${docId}`, "patch", params),
);
export const deleteCarrierDoc = createAsyncThunk("logistCarriers/deleteCarrierDoc", async docId => {
  fetchData(`carriers/docs/remove/${docId}`, "delete");
});
export const addCarrierDocFile = createAsyncThunk("logistCarriers/addCarrierDocFile", async ({docId, doc}) =>
  fetchData(`carriers/docs/files/add/${docId}`, "post", doc),
);
export const deleteCarrierDocFile = createAsyncThunk("logistCarriers/deleteCarrierDocFile", async fileId =>
  fetchData(`carriers/docs/files/remove/${fileId}`, "delete"),
);
