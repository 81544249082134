import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";

import AdminPage from "../../pages/AdminPage";
import FinBlock from "../../pages/Finance/FinBlock";
import IndexPage from "../../pages/IndexPage";
import KpPage from "../../pages/KpPage";
import LogistPage from "../../pages/LogistPage";
import ProjectPage from "../../pages/ProjectPage";
import SKUPage from "../../pages/SKUPage";
import SkuVedPage from "../../pages/SkuVedPage";
import Translation from "../../pages/Translation";
import LoginForm from "../authorization/LoginForm";
import PasswordForm from "../authorization/PasswordForm";
import Demo from "../conteiners/filters/Demo";
// import DemoTabs from "../conteiners/tabs/useTabs";
import DemoComponent from "../conteiners/tabs/Demo";
import ErrorBoundary from "../errorBoundary/ErrorBoundary";
import TemporarilyUnavailable from "../errorBoundary/TemporarilyUnavailable";
import MainPage from "../MainPage/MainPage";
import NavBar from "../NavBar/NavBar";

import "react-toastify/dist/ReactToastify.css";
import "../../Styles/Buttons.scss";
import "../../Styles/List.scss";
import "../../Styles/Main.scss";
import "../Modal/modal.scss";
import "./App.css";

function App() {
  const {t} = useTranslation();
  const {i18n} = useTranslation();

  useEffect(() => {
    localStorage.setItem("language", i18n.language);
  }, [i18n.language]);

  // const appHeight = useCallback(() =>{
  //   const doc = document.documentElement
  //   doc.style.setProperty('--app-height', `${window.innerHeight-30}px`)
  // },[])

  // useEffect(() => {
  //   appHeight()
  //   window.addEventListener('resize', appHeight)

  //   return () => window.removeEventListener("resize", appHeight);
  // }, [appHeight]);

  const {token, user} = useSelector(state => state.user);

  return (
    <Router>
      <NavBar />
      <div className="App">
        <Routes>
          <Route path="/ba3a" element={<IndexPage />}>
            <Route path="admin/*" element={<AdminPage />} />
            <Route path="main" element={<MainPage />} />
            <Route path="project/*" element={<ProjectPage />} />
            <Route path="co/*" element={<KpPage />} />
            <Route path="sku/*" element={<SKUPage />} />
            <Route path="fin-block/*" element={<FinBlock />} />
            <Route path="fin-accounting/*" element={<TemporarilyUnavailable />} />
            <Route path="fea/*" element={<SkuVedPage />} />
            <Route path="settings/translate/*" element={<Translation />} />
            <Route path="logistician/*" element={<LogistPage />} />
          </Route>
          {/* <Route path="/ba3a/d" element={<ModalWithTabExample2 />} /> */}
          {/* <Route path="demoC" element={<DemoComponent />} /> */}
          <Route path="demoC" element={<Demo />} />
          <Route
            path="/"
            element={
              <ErrorBoundary>
                <Navigate to="/login" />
              </ErrorBoundary>
            }
          />
          <Route
            path="/login"
            element={
              user && !token ? (
                <ErrorBoundary>
                  <Navigate to="/password" />
                </ErrorBoundary>
              ) : (
                <ErrorBoundary>
                  <LoginForm />
                </ErrorBoundary>
              )
            }
          />
          <Route path="/password" element={user && token ? <Navigate to="/ba3a/main" /> : <PasswordForm />} />

          <Route
            path="/*"
            element={
              <div className="flex_container jcc aic" style={{width: "100%", height: "100%"}}>
                <p> {t("Under development")}</p>
              </div>
            }
          />
        </Routes>
        <ToastContainer position="top-center" hideProgressBar />
      </div>
    </Router>
  );
}

export default App;
