import React from "react";
import {Route, Routes} from "react-router-dom";

import Cashdesk from "../../Components/FinBlock/Cashdesk/CashdeskMainEl/Cashdesk";
import CashdeskOrders from "../../Components/FinBlock/CashdeskOrders/CashdeskOrders";
import FinSettings from "../../Components/FinBlock/FinSettings/FinSettings";
import PaymentsToFactory from "../../Components/FinBlock/PaymentsForPf/PaymentsToFactory/PaymentsToFactory";
import FinBlockDirectiveList from "../../Components/FinBlock/FinBlockDirectiveList/FinBlockDirectiveList";

const FinBlock = () => {
  return (
    <Routes>
      <Route path="/paymentsregistr" element={<PaymentsToFactory />}></Route>
      <Route path="/fin_settings" element={<FinSettings />}></Route>
      <Route path="/cashdesk" element={<Cashdesk />}></Route>
      <Route path="/cashdeskOrders" element={<CashdeskOrders />}></Route>
      <Route path="/finBlockDirectiveList" element={<FinBlockDirectiveList />}></Route>
    </Routes>
  );
};

export default FinBlock;
