import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import NewPdfViewer from "../../Documents/DocumentView/NewPdfViewer";
import ContractDocsScreenShotsTables from "../ContractDocsScreenShotsTables/ContractDocsScreenShotsTables";
import DirectiveFilters from "../DirectiveFilters";
import FinStatus from "../FinStatus";
import OrderTypeComposedPart from "../OrderTypeComposedPart/OrderTypeComposedPart";

import useKpCreateMainDirectiveD from "./useKpCreateMainDirectiveD";

const KpCreateMainDirectiveD = ({viewOnly = false}) => {
  const {
    fileArray,
    setFileArray,
    onForImplementationBtnClick,
    onCancelBtnClick,
    onOkBtnClick,
    contractTableDataRow,
    annexTableDataRow,
    einfoTableDataRow,
    isDirectiveCreated,
    areAllFieldsFilledIn,
  } = useKpCreateMainDirectiveD(viewOnly);

  return (
    <div className="fcCol h100 w100 pb aist maxH100 scrollY">
      <DirectiveFilters readOnly />
      <Divider />
      <div className="fc aistr jcsb gap2x w100 fGrow mb0_5 nowrap">
        <OrderTypeComposedPart onOkBtnClick={onOkBtnClick} readOnly={viewOnly}>
          <div className="row">
            {viewOnly ? (
              <ToolTip title={!areAllFieldsFilledIn && isDirectiveCreated ? "Fill in all fields!" : ""}>
                <FunctionButton onClick={() => {}} name="Cancel directive" autoWidth disabled={true} />
              </ToolTip>
            ) : (
              <ToolTip title={!areAllFieldsFilledIn && isDirectiveCreated ? "Fill in all fields!" : ""}>
                <FunctionButton
                  onClick={onForImplementationBtnClick}
                  name="For implementation"
                  autoWidth
                  disabled={
                    !isDirectiveCreated
                    //  || !areAllFieldsFilledIn
                  }
                />
              </ToolTip>
            )}
            <FunctionButton
              onClick={onCancelBtnClick}
              name={viewOnly ? "Exit" : "Cancel"}
              autoWidth
              disabled={!isDirectiveCreated}
            />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2" style={{maxWidth: "70%"}}>
          <FinStatus readOnly />
          <div className="grid2ColFirstBigger fGrow">
            <ContractDocsScreenShotsTables
              setFileArray={setFileArray}
              contractTableDataRow={contractTableDataRow}
              annexTableDataRow={annexTableDataRow}
              einfoTableDataRow={einfoTableDataRow}
            />
            <div className="mb2x">
              <NewPdfViewer fileArray={fileArray || []} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KpCreateMainDirectiveD;
