import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {dateFormat} from "../../../../utilities/dateFormat";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import FinBlockOrderDirectiveDo from "../../../FinBlock/FinBlockDirectiveList/FinBlockOrderDirectiveDo/FinBlockOrderDirectiveDo";
import {
  addNewFinOrder,
  fetchFinOrder,
  selectDirective,
  selectFinOrder,
  setFinOrder,
} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import OrderDirectiveView from "../OrderDirectiveView/OrderDirectiveView";
import {generateDirectiveOrderPdf} from "../OrderDirectiveView/orderFileGenerate";

import {ORDER_REGISTER_TABLE_COLUMNS} from "./constants";

const useOrderRegister = () => {
  const {addNewTabCustom, closeCurrentTab} = useTabsContext();
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const {values, setValues, setFieldValue} = useFormikContext();
  const dispatch = useDispatch();

  const directive = useSelector(selectDirective);

  // const [currentOrderId, setCurrentOrderId] = useState(null);
  const [printModalActive, setPrintModalActive] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const currentOrder = useSelector(selectFinOrder);
  const currentOrderId = currentOrder?.orderId || null;
  // const currentOrder = directive?.orders?.find(order => order.orderId === currentOrderId);

  useEffect(() => {
    if (directive) {
      const {status, co, operationType, note, sumFact, sumPlan, payer, outsideParty, recipient, fulfilBy} = directive;
      const {project, account, coNumber, sum, prepayment} = co;
      const {butget} = project;

      setValues(prev => ({
        ...prev,
        fulfilBy: dateFormat(fulfilBy),
        directiveType: "",
        status: language === "en" ? status.nameEng : status.nameUkr,
        coNumber,
        coBalance: account?.balance,
        clientBalance: co?.account?.balance,
        clientProject: butget,
        coSum: sum,
        advance: (sum && (prepayment / sum) * 100) || "0",
        nSum: prepayment,
        fact: (sum && (account?.balance / sum) * 100) || "0",
        finOrderTypeId: "",
        operationTypeId: operationType.operationTypeId,
        executorNote: note ?? t("No executor note"),

        payerCoBalanceComposed: payer?.balance ?? "-",
        payerClientNumberComposed: payer?.clientNumber ?? "-",
        payerClientBalanceComposed: payer?.balance ?? "-",
        payerCreditForCoComposed: payer?.balance ?? "-",

        recipientClientBalanceComposed: recipient?.balance ?? "-",
        recipientCoBalanceComposed: recipient?.balance ?? "-",
        recipientClientNumberComposed: recipient?.clientNumber ?? "-",
        recipientCreditForCoComposed: recipient?.balance ?? "-",

        creditSumComposed: sumPlan ?? "-",

        payerNameComposed: outsideParty?.name ?? "-",
        edrpouoComposed: outsideParty?.edrpouCode ?? "-",
        payerAccountComposed: outsideParty?.currentAccount ?? "-",

        sumComposed: currentOrder?.sum ?? "-",
        currencyIdComposed: currentOrder?.currencyId ?? "-",

        coSumComposed: co?.sum ?? "-",
        fSumComposed: sumFact ?? "-",
        coSumAdditionalComposed: co?.prepayment ?? "-",

        apSumComposed: sumPlan ?? "-",
        advancePercentageComposed:
          (Number.parseFloat(sumFact) && (Number.parseFloat(sumPlan) / Number.parseFloat(sumFact)) * 100) || "0",
        afSumComposed: sumFact ?? "-",
      }));
    }
  }, [directive?.directiveId]);

  useEffect(() => {
    if (currentOrderId) {
      const {loanTerms} = currentOrder ?? {};
      setTimeout(() => {
        setValues(prev => ({
          ...prev,
          finOrderTypeId: currentOrder?.type?.typeId ?? "",

          creditTermComposed: loanTerms?.termUpTo ?? "-",
          perAnnumComposed: loanTerms?.annualInterestRate ?? "-",
          repaymentTermIdComposed: loanTerms?.repaymentTermsId ?? "-",
        }));
      });
    }
  }, [currentOrderId]);

  const tableColumns = useColumns(ORDER_REGISTER_TABLE_COLUMNS);
  const tableData =
    directive?.orders?.map(({orderId, orderNumber}, index) => ({
      id: orderId,
      number: ++index,
      dateTo: "-",
      factDate: "-",
      directiveNumber: directive?.directiveNumber,
      orderNumber,
      dt: "-",
      kt: "-",
      sumF: "-",
      paymentPurpose: "-",
      note: "-",
    })) ?? [];

  function onTableSelect(id) {
    // setCurrentOrderId(id);
    if (id) dispatch(fetchFinOrder(id));
    else {
      setFieldValue("finOrderTypeId", "");
      dispatch(setFinOrder(null));
    }
  }

  function onRowDoubleClick(order) {
    if (!order) return;

    dispatch(fetchFinOrder(order.id)).then(() => {
      addNewTabCustom({
        TabComponent: OrderDirectiveView,
        title: `${t("Order number ")} ${order.orderNumber}`,
        tabIdName: "orderNumberTab",
      });
    });
  }

  function onExitBtnClick() {
    swalCustom.confirm({
      confirmFunc: () => {
        closeCurrentTab();
      },
    });
  }
  function onPrintOrderBtnClick() {
    dispatch(fetchFinOrder(currentOrderId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        const order = res.payload;
        const data = {
          ...order,
          coNumber: order.directive.co?.coNumber,
          coManager: order.directive.co?.coManager?.name,
          operationType: order.directive?.operationType["nameUkr"],
        };
        const pdf = generateDirectiveOrderPdf(data);
        setPdfFile(pdf);
        setPrintModalActive(true);
      }
    });
  }

  function onCreateNewOrderBtnClick() {
    if (!directive || !values.finOrderTypeId) return;

    dispatch(addNewFinOrder({directiveId: directive?.directiveId, body: {typeId: values.finOrderTypeId}})).then(
      resp => {
        if (resp.meta.requestStatus === "fulfilled")
          dispatch(
            fetchFinOrder(resp.payload.directive.orders[resp.payload?.directive.orders.length - 1].orderId),
          ).then(resp => {
            addNewTabCustom({
              TabComponent: OrderDirectiveView,
              title: `${t("Order number ")} ${resp.payload.orderNumber}`,
              tabIdName: "orderNumberTab",
            });
          });
      },
    );
  }

  function onViewOrderBtnClick() {
    if (!currentOrderId) return;

    dispatch(fetchFinOrder(currentOrderId)).then(() => {
      addNewTabCustom({
        TabComponent: OrderDirectiveView,
        title: `${t("Order number ")} ${currentOrder.orderNumber}`,
        tabIdName: "orderNumberTab",
      });
    });
  }

  const printOrderBtnDisabled = !(currentOrderId && currentOrder?.status.statusId === 3);

  return {
    onExitBtnClick,
    tableColumns,
    tableData,
    onTableSelect,
    onPrintOrderBtnClick,
    onRowDoubleClick,
    currentOrderId,
    onCreateNewOrderBtnClick,
    onViewOrderBtnClick,
    printModalActive,
    setPrintModalActive,
    pdfFile,
    currentOrder,
    printOrderBtnDisabled,
  };
};

export default useOrderRegister;
