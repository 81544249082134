import {t} from "i18next";

import Divider from "../../../utilities/Divider/Divider";
import ReactTable from "../../conteiners/ReactTable";
import {MoneyInput, TextInput} from "../../Inputs";

import useProjectView from "./useProjectView";

export const ProjectView = () => {
  const {
    projectTableColumns,
    projectTableData,
    onProjectTableRowSelect,
    isLoading,
    orderTableColumns,
    orderTableData,
    // currentOrderId,
    onOrderTableRowSelect,
    projectId,
  } = useProjectView();

  return (
    <div className="fcCol gap0_5 w100 h100 pb">
      <div className="fcCol w100">
        <div className="row w100 gap2x jcfs">
          <TextInput label={t("Project №")} name="projectNumber" readOnly autolabel />
          <MoneyInput label={t("Client Balance")} name="clientBalance" readOnly autolabel width="10em" />
          <MoneyInput label={t("Project Balance")} name="projectBalance" readOnly autolabel width="10em" />
        </div>
        <ReactTable
          enableMultiSelection
          className="mt fGrow tableTac"
          columns={projectTableColumns}
          defaultData={projectTableData}
          current={projectId}
          onSelect={onProjectTableRowSelect}
          loading={isLoading}
          style={{minHeight: "18em", flexGrow: 1}}
        />
      </div>
      <Divider />
      <div className="fcCol fGrow w100">
        <div className="row w100 gap2x jcfs">
          <TextInput label={t("Order number")} name="orderNumber" readOnly autolabel />
          <TextInput label={t("Take To")} name="takeTo" readOnly autolabel />
          <MoneyInput label={t("Order sum")} name="orderSum" readOnly autolabel />
        </div>
        <ReactTable
          enableMultiSelection
          className="mt fGrow"
          columns={orderTableColumns}
          defaultData={orderTableData}
          // current={currentOrderId}
          onSelect={onOrderTableRowSelect}
          loading={isLoading}
          style={{minHeight: "15em", flexGrow: 1}}
        />
      </div>
    </div>
  );
};
