import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import DoubleCheckIcon from "../../../images/icons/doubleCheck3x.png";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import {checkEmptyFilterFields} from "../../../utilities/checkEmptyFilterFields";
import Divider from "../../../utilities/Divider/Divider";
import ToolTip from "../../../utilities/ToolTip";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";
import ReactTable from "../../conteiners/ReactTable";
import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {fetchAllDirectives, fetchAllFinStatuses} from "../../FinBlock/FinSettings/FinSettingsSlice";

import useProjectDirectiveList from "./useProjectDirectiveList";

const ProjectDirectiveList = ({readOnly}) => {
  const {
    getFilterData,
    onFilterDelete,
    projectDirectiveTableColumns,
    projectDirectiveTableData,
    onProjectDirectiveTableSelect,
    onOpenDirectiveBtnClick,
    onOpenProjectBtnClick,
    onCancelDirectiveBtnClick,
    currentDirectiveId,
    loading,
    isCurrentDirectiveCreatedByCurrentUser,
    filtersLayout,
    PROJECT_DIRECTIVE_LIST_WINDOW_NAME,
    formPayerDataParamName,
    setFilterFields,
    onDoubleCheckIconClick,
    areDirectivesSorted,
  } = useProjectDirectiveList({readOnly});

  const disabled = !currentDirectiveId;

  return (
    <div className="fcCol h100 w100 pb">
      <SessionFiltersContainer
        window={PROJECT_DIRECTIVE_LIST_WINDOW_NAME}
        onFilterChange={getFilterData}
        onFilterDelete={onFilterDelete}
      >
        <Filters>
          <ToolTip title="Sort new ahead" style={{position: "absolute", top: 30, left: 27, width: "50px"}} bottom>
            <ImageButton
              src={DoubleCheckIcon}
              width={2}
              height={1.5}
              onClick={onDoubleCheckIconClick}
              className={`fc jcc w100 mt br ${areDirectivesSorted ? "bgcActive" : "bgc"}`}
            />
          </ToolTip>

          {filtersLayout}
        </Filters>
        <FiltersLogik formPayerDataParamName={formPayerDataParamName} setFilterFields={setFilterFields} />
      </SessionFiltersContainer>

      <Divider />
      <ReactTable
        columns={projectDirectiveTableColumns}
        defaultData={projectDirectiveTableData}
        current={currentDirectiveId}
        onSelect={onProjectDirectiveTableSelect}
        onRowDoubleClick={onOpenDirectiveBtnClick}
        className="tableTac fGrow scrollX"
        tableClassName="fGrow"
        loading={loading}
      />
      <div className="row w100 mt0_5">
        <div className="row gap2x">
          <FunctionButton name="Open directive" onClick={onOpenDirectiveBtnClick} autoWidth disabled={disabled} />
          <FunctionButton name="Open project" onClick={onOpenProjectBtnClick} autoWidth disabled={disabled} />
        </div>
        <div className="row gap2x">
          <ToolTip
            title={!disabled && !isCurrentDirectiveCreatedByCurrentUser ? "You can cancel only your directive" : ""}
          >
            <FunctionButton
              name="Return directive"
              onClick={onCancelDirectiveBtnClick}
              autoWidth
              disabled={!isCurrentDirectiveCreatedByCurrentUser}
            />
          </ToolTip>
        </div>
      </div>
    </div>
  );
};
const FiltersLogik = ({formPayerDataParamName, setFilterFields}) => {
  const {filters, sessionF} = useFiltersContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllFinStatuses());
  }, []);

  useEffect(() => {
    if (isEmpty(sessionF)) {
      dispatch(fetchAllDirectives());
      return;
    }
    const {payerDataType, payerData, ...restFilters} = filters;
    const payerDataParamName = formPayerDataParamName(payerDataType);

    dispatch(
      fetchAllDirectives({
        ...restFilters,
        [payerDataParamName]: payerData,
      }),
    );
    setFilterFields(checkEmptyFilterFields(restFilters));
  }, [sessionF]);

  return <></>;
};
export default withTabs({
  isMainTabControlled: true,
  isClosableTabs: true,
  label: "Project directives list",
  formInitialValues: {},
})(ProjectDirectiveList);
