import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import {accessAllowed} from "../../../../gitIgnore/accessAllowedIds";
import {deleteTemplate, getCurrentTemplate, getTemplates} from "../../../../slices/KPSlice";
import {getCurrentUser} from "../../../../slices/UserSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import CoTemplates from "../../CoTemplates/CoTemplates";

const TemplateReviewPart = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {addNewTabCustom} = useTabsContext();

  const currentTemplate = useSelector(getCurrentTemplate);
  const user = useSelector(getCurrentUser);

  const onDeleteTemplateClick = () => {
    if (currentTemplate?.creatorId === user?.id) {
      Swal.fire({
        title: "",
        text: t("Are you sure?"),
        icon: "warning",
        confirmButtonText: t("Yes"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
      }).then(value => {
        if (value.isConfirmed) {
          dispatch(deleteTemplate(currentTemplate?.cofTemplateId));
        }
      });
    } else {
      Swal.fire({
        title: "",
        text: `${t("Insufficient")}`,
        icon: "info",
        confirmButtonText: t("Ok"),
      });
    }
  };

  const onViewTemplateClick = () => {
    addNewTabCustom({
      TabComponent: CoTemplates,
      title: t("CO templates"),
      controlled: true,
    });
  };

  return (
    <div className="right-side w100">
      <div className="kpPreview__box h100">
        <NewPdfViewer fileArray={currentTemplate?.docsArray ? currentTemplate?.docsArray : []} />
      </div>
      <div className="fdr jcsb mb">
        <FunctionButton
          name={t("View template CO(f)")}
          autoWidth
          onClick={onViewTemplateClick}
          disabled={!currentTemplate}
        />
        <FunctionButton
          name={t("Delete template")}
          autoWidth
          onClick={onDeleteTemplateClick}
          disabled={!currentTemplate}
        />
      </div>
    </div>
  );
};

export default TemplateReviewPart;
