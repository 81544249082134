import {useTranslation} from "react-i18next";
import {withFormik} from "formik";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../../utilities/Divider/Divider";
import ReactTable from "../../../../conteiners/ReactTable";
import {DateInput, TextAreaInput, TextInput} from "../../../../Inputs";

import useCashView from "./useCashView";

const CashViewBase = () => {
  const {t} = useTranslation();
  const {onSavePdfBtnClick, onPrintBtnClick, cashViewTableColumns, cashViewTableData, onCashViewTableSelect} =
    useCashView();

  return (
    <div className="fcCol  fGrow">
      <div className="grid3ColMiddleMax gap2x h100 fGrow mt">
        <div>
          <DateInput name="operationDate" readOnly className="borderedInput" generalStyle={{width: "4.5em"}} />
          <TextInput name="coNumber" placeholder="KO №" className="borderedInput mt0_5" style={{width: "5em"}} />
        </div>
        <div className="fcCol aistr" style={{minWidth: "850px"}}>
          <div className="grid2Col">
            <div>
              <TextInput label={t("ctAccount")} name="ctAccount" style={{width: "5em"}} />
              <TextAreaInput name="accountNameCT" placeholder={t("Account name CT: ")} height="2em" />
            </div>
            <div>
              <TextInput label={t("dtAccount")} name="dtAccount" style={{width: "5em"}} />
              <TextAreaInput name="accountNameDT" placeholder={t("Account name DT: ")} height="2em" />
            </div>
          </div>
          <div className="row gap mt">
            <TextAreaInput
              name="paymentAssignation"
              placeholder={t("Payment assignation: ")}
              height="4em"
              className="fGrow"
            />
            <TextAreaInput name="note" placeholder={t("Note") + ": "} height="4em" width="30%" />
          </div>
          <ReactTable
            columns={cashViewTableColumns}
            defaultData={cashViewTableData}
            current={null}
            onSelect={onCashViewTableSelect}
            className="tableTac mt border borderBottom fGrow"
            style={{height: "15em"}}
          />
          <div className="row mt">
            <TextInput label={t("Created")} name="creatorName" style={{width: "5em"}} />
            <DateInput label={t("From")} name="creationDate" />
            <TextInput label={t("Conducted")} name="conductorName" style={{width: "5em"}} />
            <DateInput label={t("From")} name="conductionDate" />
            <TextInput label={t("Control")} name="controlName" style={{width: "5em"}} />
          </div>
        </div>
        <div>
          <div className="row jcfe mb0_5">
            <TextInput name="currency" className="borderedInput" style={{width: "5em"}} />
            <TextInput name="sum" placeholder="Sum" className="borderedInput" style={{width: "5em"}} />
          </div>
          <TextAreaInput name="sumByPrescription" placeholder={t("Sum by prescription")} />
        </div>
      </div>
      <Divider />
      <div className="row jcfe gap2x w100 mt">
        <FunctionButton name="Save pdf" onClick={onSavePdfBtnClick} autoWidth />
        <FunctionButton name="Print" onClick={onPrintBtnClick} autoWidth />
      </div>
    </div>
  );
};

const CashView = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    operationDate: "",
    coNumber: "",
    ctAccount: "",
    accountNameCT: "",
    dtAccount: "",
    accountNameDT: "",
    paymentAssignation: "",
    note: "",
    creatorName: "",
    creationDate: "",
    conductorName: "",
    conductionDate: "",
    controlName: "",
    currency: "",
    sum: "",
    sumByPrescription: "",
  }),
})(CashViewBase);
export default CashView;
