import {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {createSku, deleteSku, getCurrentSku} from "../../../SkuSlice";
import SkuVedForm from "../SkuVedForm";
import {withSkuForm} from "../skuVedFormHOC";

const SkuCreateForm = ({setModalActive, active}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentSku = useSelector(getCurrentSku);

  const handleBeforeUnload = useCallback(() => {
    console.log(currentSku);
    dispatch(deleteSku(currentSku));
  }, [currentSku]);

  //   useEffect(() => {
  //     // Добавляем обработчик события beforeunload при монтировании компонента
  //     window.addEventListener('beforeunload', handleBeforeUnload);

  //     // Возвращаем функцию очистки, чтобы удалить обработчик события при размонтировании компонента
  //     return () => {
  //         window.removeEventListener('beforeunload', handleBeforeUnload);
  //     };
  // }, []);

  const onSubmit = values => {
    const {skuId, brand, brandPrefix, currencyId, ...rest} = values;
    dispatch(
      createSku({
        sku: rest,
        skuId: currentSku,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setModalActive(false);
      }
    });
  };

  const onCancel = () => {
    Swal.fire({
      title: t("Sure"),
      text: t("Cancel creating Sku"),
      showCancelButton: true,
      confirmButtonText: t("Yes"),
      cancelButtonText: t("Continue creating"),
    }).then(res => {
      if (res.isConfirmed) {
        dispatch(deleteSku(currentSku)).then(() => {
          setModalActive(false);
        });
      }
    });
  };

  // const SkuForm = withSkuForm(t("Create SKU"), onSubmit, onCancel, OnReload);
  return (
    <SkuVedForm onSubmit={onSubmit}>
      <FunctionButton name={t("Create SKU")} type="submit" />
      <FunctionButton name={t("Cancel")} onClick={onCancel} />
      <OnReload />
    </SkuVedForm>
  );
};

const OnReload = () => {
  const dispatch = useDispatch();
  const currentSku = useSelector(getCurrentSku);

  const onUnload = useCallback(() => {
    console.log(currentSku);
    dispatch(deleteSku(currentSku));
  }, [currentSku]);

  useEffect(() => {
    // Добавляем обработчик события beforeunload при монтировании компонента
    window.addEventListener("beforeunload", onUnload);

    // Возвращаем функцию очистки, чтобы удалить обработчик события при размонтировании компонента
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []);

  return <></>;
};

SkuCreateForm.propTypes = {setModalActive: PropTypes.func, active: PropTypes.bool};

export default SkuCreateForm;
