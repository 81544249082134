export const ORDER_REGISTER_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
    maxSize: 15,
  },
  {
    header: "Date To",
    accessorKey: "dateTo",
    size: 50,
    maxSize: 50,
    // isSortable: true,
  },
  {
    header: "Date (f)",
    accessorKey: "factDate",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Directive №",
    accessorKey: "directiveNumber",
    size: 90,
    maxSize: 90,
    // isSortable: true,
  },
  {
    header: "Order number ",
    accessorKey: "orderNumber",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Dt",
    accessorKey: "dt",
    size: 50,
    maxSize: 50,
    // isSortable: true,
  },
  {
    header: "Kt",
    accessorKey: "kt",
    size: 50,
    maxSize: 50,
    // isSortable: true,
  },
  {
    header: "Sum (f)",
    accessorKey: "sumF",
    size: 70,
    maxSize: 70,
    // isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 100,
    // isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "note",
    size: 100,
  },
]);
