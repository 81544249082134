import React from "react";

import Filters, {FIELD_TYPES} from "../../../../../Filters/Filters";

const PackageFilters = ({fetchPackages}) => {
  const filterFields = [
    {type: FIELD_TYPES.TEXT, placeholder: "Package Name", name: "fpackageName"},
    {type: FIELD_TYPES.TEXT, placeholder: "Package Code", name: "fpackageCode"},
    // {type: FIELD_TYPES.Date, label: "Date", name: "filterDate"},
    {type: FIELD_TYPES.TEXT, placeholder: "Dt", name: "fdebitBalanceId", style: {width: "6em"}},
    {type: FIELD_TYPES.TEXT, placeholder: "Ct", name: "fcreditBalanceId", style: {width: "6em"}},
    {type: FIELD_TYPES.TEXT, placeholder: "Payment purpose", name: "fpaymentPurpose"},
    {type: FIELD_TYPES.TEXT, placeholder: "Dt. bal. name", name: "fbalanceNameDebit"},
    {type: FIELD_TYPES.TEXT, placeholder: "Ct. bal. name", name: "fbalanceNameCredit"},
  ];

  const onFiltersDel = () => {
    fetchPackages(null);
  };

  return (
    <div>
      <Filters
        window={"ACC_POSTING_PACKAGE"}
        onFiltersDel={onFiltersDel}
        fields={filterFields}
        getFilterData={fetchPackages}
      />
    </div>
  );
};

export default PackageFilters;
