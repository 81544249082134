import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {dateFormat} from "../../../../utilities/dateFormat";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {selectDirective, selectFinOrder} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {ORDER_DIRECTIVE_VIEW_LOWER_TABLE_COLUMNS, ORDER_DIRECTIVE_VIEW_UPPER_TABLE_COLUMNS} from "./constants";

const useOrderDirectiveView = () => {
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const {setValues} = useFormikContext();
  const {closeCurrentTab} = useTabsContext();

  const directive = useSelector(selectDirective);
  const currentOrder = useSelector(selectFinOrder);
  const currentOrderId = currentOrder?.orderId;

  useEffect(() => {
    if (directive?.directiveId && currentOrderId) {
      const {status, co, operationType, note, recipient, sumPlan, sumFact} = directive;
      const {project, account, coNumber, sum, prepayment} = co;
      const {butget} = project;
      const {payer, outsideParty, loanTerms} = currentOrder;

      setValues(prev => ({
        ...prev,
        fulfilBy: new Date().toISOString(),
        directiveType: "",
        status: language === "en" ? status.nameEng : status.nameUkr,
        coNumber,
        coBalance: account?.balance,
        clientBalance: account?.balance,
        clientProject: butget,
        coSum: sum,
        advance: (sum && (prepayment / sum) * 100) || "0",
        nSum: prepayment,
        fact: (sum && (account?.balance / sum) * 100) || "0",
        operationTypeId: operationType.operationTypeId,
        executorNote: note ?? t("No executor note"),
        finOrderTypeId: currentOrder.type.typeId ?? "",

        payerCoBalanceComposed: payer?.balance ?? "-",
        payerClientNumberComposed: payer?.clientNumber ?? "-",
        payerClientBalanceComposed: payer?.balance ?? "-",
        payerCreditForCoComposed: payer?.balance ?? "-",

        recipientClientBalanceComposed: recipient?.balance ?? "-",
        recipientCoBalanceComposed: recipient?.balance ?? "-",
        recipientClientNumberComposed: recipient?.clientNumber ?? "-",
        recipientCreditForCoComposed: recipient?.balance ?? "-",

        creditTermComposed: loanTerms?.termUpTo ?? "-",
        perAnnumComposed: loanTerms?.annualInterestRate ?? "-",
        repaymentTermIdComposed: loanTerms?.repaymentTermsId ?? "-",
        creditSumComposed: sumPlan ?? "-",

        payerNameComposed: outsideParty?.name ?? "-",
        edrpouoComposed: outsideParty?.edrpouCode ?? "-",
        payerAccountComposed: outsideParty?.currentAccount ?? "-",

        sumComposed: currentOrder?.sum ?? "-",
        currencyIdComposed: currentOrder?.currencyId ?? "-",

        coSumComposed: sum ?? "-",
        fSumComposed: sumFact ?? "-",
        coSumAdditionalComposed: prepayment ?? "-",

        apSumComposed: sumPlan ?? "-",
        advancePercentageComposed:
          (Number.parseFloat(sumFact) && (Number.parseFloat(sumPlan) / Number.parseFloat(sumFact)) * 100) || "0",
        afSumComposed: sumFact ?? "-",
      }));
    }
  }, [directive?.directiveId, currentOrderId]);

  const [currentTransactionId, setCurrentTransactionId] = useState(null);
  const currentTransaction = currentOrder?.transactions?.find(
    transaction => transaction.transactionId === currentTransactionId,
  );

  const upperTableColumns = useColumns(ORDER_DIRECTIVE_VIEW_UPPER_TABLE_COLUMNS);
  const upperTableData =
    currentOrder?.transactions?.map(
      ({transactionId, creditAccount, debitAccount, paymentPurpose, sum, createdAt}, index) => ({
        id: transactionId,
        number: ++index,
        date: dateFormat(createdAt),
        sum,
        dt: debitAccount.accountNumber,
        dtType: debitAccount.ownerType,
        kt: creditAccount.accountNumber,
        ktType: creditAccount.ownerType,
        paymentPurpose,
        dtAccountName: debitAccount.accountName,
        ktAccountName: creditAccount.accountName,
        note: "",
      }),
    ) ?? [];

  function onUpperTableSelect(id) {
    setCurrentTransactionId(id);
    if (id) {
      setCurrentCompletedTransactionId(null);
      setValues(prev => ({
        ...prev,
        dt: "",
        kt: "",
        dtAccountName: "",
        ktAccountName: "",
        paymentRecognition: "",
        paymentNote: "",
      }));
    }
  }

  const [currentCompletedTransactionId, setCurrentCompletedTransactionId] = useState(null);
  const completedTransactions = currentOrder?.transactions?.filter(transaction => transaction.isCompleted);
  const currentCompletedTransaction = completedTransactions?.find(
    transaction => transaction.transactionId === currentCompletedTransactionId,
  );

  const lowerTableColumns = useColumns(ORDER_DIRECTIVE_VIEW_LOWER_TABLE_COLUMNS);
  const lowerTableData =
    completedTransactions?.map(({transactionId, creditAccount, debitAccount, paymentPurpose, sum}, index) => ({
      id: transactionId,
      number: ++index,
      dateTo: "-",
      factDate: "-",
      directiveNumber: currentOrder?.directive?.directiveNumber,
      dt: debitAccount.accountNumber,
      kt: creditAccount.accountNumber,
      sumF: sum,
      paymentPurpose,
      note: "",
    })) ?? [];

  function onLowerTableSelect(id) {
    setCurrentCompletedTransactionId(id);
    if (id) {
      setCurrentTransactionId(null);
      setValues(prev => ({
        ...prev,
        dt: "",
        kt: "",
        dtAccountName: "",
        ktAccountName: "",
        paymentRecognition: "",
        paymentNote: "",
      }));
    }
  }

  useEffect(() => {
    if (currentTransactionId || currentCompletedTransactionId) {
      const {paymentPurpose, creditAccount, debitAccount} = currentTransaction ?? currentCompletedTransaction;

      setValues(prev => ({
        ...prev,
        dt: debitAccount.accountNumber,
        kt: creditAccount.accountNumber,
        dtAccountName: debitAccount.accountName,
        ktAccountName: creditAccount.accountName,
        paymentRecognition: paymentPurpose,
        paymentNote: "",
      }));
    }
  }, [currentTransactionId, currentCompletedTransactionId]);

  function onPrintOrderBtnClick() {}

  function onExitBtnClick() {
    swalCustom.confirm({
      confirmFunc: () => {
        closeCurrentTab();
      },
    });
  }

  return {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    onExitBtnClick,
    onUpperTableSelect,
    currentTransactionId,
    currentCompletedTransactionId,
    onLowerTableSelect,
  };
};

export default useOrderDirectiveView;
