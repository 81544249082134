import {useMemo} from "react";
import {useTranslation} from "react-i18next";

const useColumns = (columns = []) => {
  const {t} = useTranslation();
  const translatedColumns = useMemo(
    () =>
      columns?.map(column => ({
        ...column,
        header: typeof column.header === "string" ? t(column.header) : column.header,
      })) || [],
    [columns],
  );

  return translatedColumns;
};

export default useColumns;
