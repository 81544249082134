import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import Divider from "../../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../../conteiners/filters/Filter.hoc";
import {DateInput, TextInput} from "../../../Inputs";
import NewPdfViewer from "../../Documents/DocumentView/NewPdfViewer";
import {getProjectDocument} from "../../ProjectSlice";

import CoDocForm from "./forms/CoDocForm";
import ProjectDocForm from "./forms/ProjectDocForm";
import ScreenShots from "./forms/ScreenShots";

const ProjectDocuments = () => {
  const {t} = useTranslation();
  const currentDocuments = useSelector(getProjectDocument);

  return (
    <div className="fc col nowrap h100">
      <SessionFiltersContainer window={"PROJECT_DOCUMENTS"}>
        <Filters>
          <div className="fdr" style={{marginTop: "1em", marginRight: "10em", gap: "1em"}}>
            <TextInput label={t("Document №")} style={{width: "50em"}} name="docNumber" autolabel />
            <TextInput name={"note"} label={t('Text "Brief about"')} generalClassName={"w100"} />
            <div className="fc nowrap gap">
              {t("Period")}
              <DateInput label={t("From")} name="dateStart" />
              <DateInput label={t("To")} name="dateEnd" />
            </div>
          </div>
        </Filters>
        <Divider />
        <div style={{flexGrow: "1", paddingBottom: "3em", gap: "1em"}} className="fc nowrap w100">
          <div style={{width: "65%"}} className="fc col h100">
            <ProjectDocForm />
            <CoDocForm />
            <ScreenShots />
          </div>
          <div className="" style={{width: "35%", height: "100%"}}>
            <NewPdfViewer fileArray={currentDocuments?.files || []} hiding={false} />
          </div>
        </div>
      </SessionFiltersContainer>
    </div>
  );
};

export default ProjectDocuments;
