export const profExpanded = proforms => {
  return proforms?.length > 1
    ? {
        text: proforms.map(proform => proform?.proformaNumber).join("\n"),
        expand: true,
      }
    : proforms?.[0]?.proformaNumber || "";
};

export const getExpandedDocs = (arrayOfCommonDocs, id) => {
  const filteredDocs = arrayOfCommonDocs?.filter(item => item.docTypeId === id);
  return filteredDocs?.length > 1
    ? {
        text: filteredDocs.map(item => item?.docNumber).join("\n"),
        expand: true,
      }
    : filteredDocs?.[0]?.docNumber || "";
};

export const expanded = (values, value) => {
  if (!values || !value || values.length === 0) return " - ";
  let textArr = [];
  values.forEach(proform => (proform[value] !== "" ? textArr.push(proform[value]) : null));
  return values?.length > 1
    ? {
        text: textArr.join("\n"),
        expand: true,
      }
    : values?.[0][value] || " - ";
};
