import {useContext} from "react";
import {t} from "i18next";

import {toaster} from "../../../utilities/hooks/toaster";

import {TabsContext} from "./tabs.context";

export const useTabsContext = () => {
  const tabsConfig = useContext(TabsContext);

  if (!tabsConfig) {
    throw new Error("TabsContext must be used within a Tabs container (withTabs hok)");
  }

  const {addNewTab, tabs, setTabs, currentTabIndex, closeTab, setCurrentTabIndex} = tabsConfig;

  const tabsCount = tabs.length;

  function addNewTabCustom({TabComponent, controlled = true, title, tabIdName, ...props}) {
    const tabName = tabIdName || title;
    if (tabs.find(tab => tab.tabIdName === tabName)) {
      toaster.error("The tab already exists!");
      return;
    }

    addNewTab({
      TabComponent,
      controlled,
      title: t(title),
      tabIdName: tabName,
      ...props,
    });
    setTimeout(() => {
      setCurrentTabIndex(tabsCount);
    }, 200);
  }

  function closeCurrentTab() {
    closeTab(currentTabIndex);
  }

  function changeCurrentTabName(name, tabIdName) {
    if (!name) return;

    setTabs(prev => {
      prev[currentTabIndex].title = name;
      if (prev[currentTabIndex].tabIdName)
        prev[currentTabIndex].tabIdName = tabIdName || prev[currentTabIndex].tabIdName;
      return [...prev];
    });
  }

  return {
    addNewTab,
    tabsCount,
    currentTabIndex,
    closeTab,
    tabs,
    setCurrentTabIndex,
    addNewTabCustom,
    closeCurrentTab,
    changeCurrentTabName,
  };
};
