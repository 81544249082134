import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../../utilities/dateFormat";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../utilities/hooks/toaster";
import ReactTable from "../../../conteiners/ReactTable";
import ImgFilePreview from "../../../Inputs/fileSelect/preview/ImgFilePreview";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  clearDeskOperations,
  clearDirective,
  clearDirectiveDoc,
  completeDirective,
  createOrder,
  deleteDirective,
  deleteDirectiveDoc,
  fetchAllTransactions,
  fetchDirective,
  saveDirective,
  selectAllTransactions,
  selectDirective,
  selectDirectiveDoc,
  uploadDirectiveDoc,
} from "../../FinSettings/FinSettingsSlice";
import AccountCommonComponent from "../AccountCommonComponent/AccountCommonComponent";

import {CASHDESK_LOWER_PAYMENT_COLUMNS, CASHDESK_UPPER_PAYMENT_COLUMNS} from "./constants";

const PaymentCommonBase = ({
  accountOrderBtnName,
  setCurrentOperationId,
  currentSavedTransactionId,
  setCurrentSavedTransactionId,
  setCurrentAccountOwner,
  setAccountOwnerOptions,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();

  const directive = useSelector(selectDirective);
  const directiveId = directive?.directiveId;
  const savedTransactions = useSelector(selectAllTransactions);
  const directiveDocs = useSelector(selectDirectiveDoc);
  const directiveDocsUrls = directiveDocs?.map(doc => doc.fileUrl);

  const fileArray = currentSavedTransactionId
    ? // savedTransactions.map(transaction => transaction.directive.transactionDoc.files[0].fileUrl)
      []
    : directiveDocsUrls;

  const [currentTransactionId, setCurrentTransactionId] = useState(null);
  const [isFileUploaded, setFileUploaded] = useState(false);
  const [isOrderCreated, setOrderCreated] = useState(false);

  const [isDirectiveSaved, setDirectiveSaved] = useState(false);

  const currentTransaction = directive?.transactions?.find(
    transaction => transaction.transactionId === currentTransactionId,
  );
  const currentSavedTransaction = savedTransactions?.find(
    transaction => transaction.transactionId === currentSavedTransactionId,
  );

  useEffect(() => {
    dispatch(fetchAllTransactions({table: "Saved"}));
  }, []);

  function setValuesFromTransaction({transaction, isSavedTransaction = false}) {
    if (transaction) {
      const {sum, paymentPurpose, creditAccount, debitAccount} = transaction;
      console.log("sum :", sum);
      const dtAccountNumber = debitAccount.accountNumber;
      const ktAccountNumber = creditAccount.accountNumber;

      setValues(prev => ({
        ...prev,
        currencyIdInAccountCommon: debitAccount.currencyId ?? creditAccount.currencyId,
        sum,
        ktAccountName: creditAccount.accountName,
        dtAccountName: debitAccount.accountName,
        paymentRecognition: paymentPurpose,
        note: isSavedTransaction ? transaction.directive.note : prev.note,
        dtAccCountry: dtAccountNumber?.slice(0, 3) ?? "",
        dtAccCountryCode: dtAccountNumber?.slice(3, 5) ?? "",
        dtPersonaId: dtAccountNumber?.slice(5, 11) ?? "",
        dtBalanceSheetAccId: dtAccountNumber?.slice(11, 15) ?? "",
        dtBalanceSheetAccIdRel: dtAccountNumber?.slice(-13, -7) ?? "",
        dtCurrentYear: dtAccountNumber?.slice(-7, -5) ?? "",
        dtReserveCode: dtAccountNumber?.slice(-5, -3) ?? "",
        dtCurrencyId: dtAccountNumber?.slice(-3) ?? "",
        ktAccCountry: ktAccountNumber?.slice(0, 3) ?? "",
        ktAccCountryCode: ktAccountNumber?.slice(3, 5) ?? "",
        ktPersonaId: ktAccountNumber?.slice(5, 11) ?? "",
        ktBalanceSheetAccId: ktAccountNumber?.slice(11, 15) ?? "",
        ktBalanceSheetAccIdRel: ktAccountNumber?.slice(-13, -7) ?? "",
        ktCurrentYear: ktAccountNumber?.slice(-7, -5) ?? "",
        ktReserveCode: ktAccountNumber?.slice(-5, -3) ?? "",
        ktCurrencyId: ktAccountNumber?.slice(-3) ?? "",
      }));
    } else {
      setValues(prev => ({
        ...prev,
        currencyIdInAccountCommon: "",
        sum: "",
        ktAccountName: "",
        dtAccountName: "",
        paymentRecognition: "",
        note: "",
        dtAccCountry: "",
        dtAccCountryCode: "",
        dtPersonaId: "",
        dtBalanceSheetAccId: "",
        dtBalanceSheetAccIdRel: "",
        dtCurrentYear: "",
        dtReserveCode: "",
        dtCurrencyId: "",
        ktAccCountry: "",
        ktAccCountryCode: "",
        ktPersonaId: "",
        ktBalanceSheetAccId: "",
        ktBalanceSheetAccIdRel: "",
        ktCurrentYear: "",
        ktReserveCode: "",
        ktCurrencyId: "",
      }));
    }
  }

  useEffect(() => {
    setValuesFromTransaction({transaction: currentTransaction});
  }, [currentTransaction?.transactionId, currentSavedTransaction?.transactionId]);

  useEffect(() => {
    setValuesFromTransaction({transaction: currentSavedTransaction, isSavedTransaction: true});
  }, [currentSavedTransaction?.transactionId]);

  const paymentAccountUpperTableColumns = useColumns(CASHDESK_UPPER_PAYMENT_COLUMNS);
  const paymentAccountUpperTableData =
    directive?.transactions?.map(
      ({transactionId, createdAt, sum, paymentPurpose, debitAccount, creditAccount}, index) => ({
        number: ++index,
        id: transactionId,
        date: dateFormat(createdAt),
        sum,
        debitBalance: debitAccount?.balance ?? "",
        debitBalanceType: debitAccount?.balanceSheet?.type?.balanceTypeName ?? "",
        creditBalance: creditAccount?.balance ?? "",
        creditBalanceType: creditAccount?.balanceSheet?.type?.balanceTypeName ?? "",
        paymentPurpose,
        debitBalanceName: debitAccount?.balanceSheet?.balanceName ?? "",
        creditBalanceName: creditAccount?.balanceSheet?.balanceName ?? "",
        note: "",
      }),
    ) ?? [];
  const paymentAccountLowerTableColumns = useColumns(CASHDESK_LOWER_PAYMENT_COLUMNS);
  const paymentAccountLowerTableDate =
    savedTransactions?.map(
      (
        {transactionId, createdAt, paymentPurpose, debitAccount, creditAccount, serialNumber, sum, directive: {note}},
        index,
      ) => ({
        number: ++index,
        id: transactionId,
        cashTransactionNumber: serialNumber,
        date: dateFormat(createdAt),
        paymentPurpose,
        debitBalance: debitAccount?.accountNumber ?? "",
        debitBalanceType: debitAccount?.balanceSheet?.type?.balanceTypeName ?? "",
        creditBalance: creditAccount?.accountNumber ?? "",
        creditBalanceType: creditAccount?.balanceSheet?.type?.balanceTypeName ?? "",
        debitBalanceName: debitAccount?.balanceSheet?.balanceName ?? "",
        creditBalanceName: creditAccount?.balanceSheet?.balanceName ?? "",
        note: note ?? "-",
        sum,
      }),
    ) ?? [];

  function onDeleteDirectiveBthClick() {
    const directiveId = currentSavedTransaction?.directiveId;
    if (!directiveId) return;

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteDirective({directiveId})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            toaster.success("Directive deleted successfully");
            dispatch(fetchAllTransactions({table: "Saved"}));
            setCurrentSavedTransactionId(null);
          }
        });
      },
    });
  }

  function onConductDirectiveBthClick() {
    const directiveId = currentSavedTransaction?.directiveId;
    if (!directiveId) return;

    dispatch(completeDirective({directiveId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Directive conducted successfully");
        dispatch(fetchAllTransactions({table: "Saved"}));
      }
    });
  }

  function addFile(arrFiles) {
    if (!directiveId) return;

    const formdata = new FormData();
    for (const file of arrFiles) {
      const filename = file.name;
      formdata.append("doc", file, filename);
    }

    if (formdata.has("doc")) {
      dispatch(uploadDirectiveDoc({directiveId, formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Directive doc uploaded successfully");
          setFileUploaded(true);
        }
      });
    }
  }

  function delFunc(url) {
    const docId = directiveDocs.find(doc => doc.fileUrl === url)?.docId;
    if (!docId) return;

    dispatch(deleteDirectiveDoc({docId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("File deleted");
      }
    });
  }

  function onCreateOrderBtnClick() {
    if (!directiveId) return;

    dispatch(createOrder({directiveId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Order created successfully");
        dispatch(fetchAllTransactions({table: "Saved"}));
        setOrderCreated(true);
      }
    });
  }

  function onSaveDirectiveBtnClick() {
    if (!directiveId) return;

    dispatch(saveDirective({directiveId, body: {note: values.note, transactions: directive?.transactions}})).then(
      resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Directive saved successfully");
          dispatch(fetchAllTransactions({table: "Saved"}));
          setFileUploaded(false);
          setOrderCreated(false);
          setCurrentSavedTransactionId(resp.payload.transactionId);
          setCurrentOperationId(null);
          setAccountOwnerOptions([]);
          setCurrentAccountOwner(null);
          dispatch(clearDirectiveDoc());
          dispatch(clearDirective());
          dispatch(clearDeskOperations());
          setValues(prev => Object.keys(prev).reduce((acc, key) => ({...acc, [key]: ""}), {}));
        }
      },
    );
  }

  function onTransactionSelect(transactionId) {
    setCurrentSavedTransactionId(null);
    setTimeout(() => setCurrentTransactionId(transactionId), 0);

    if (transactionId) {
      dispatch(fetchDirective());
    } else {
      setFileUploaded(false);
      setOrderCreated(false);
      setDirectiveSaved(false);
    }
  }

  function onCurrentSavedTransactionSelect(savedTransactionId) {
    setCurrentTransactionId(null);
    setTimeout(() => setCurrentSavedTransactionId(savedTransactionId), 0);
  }

  return (
    <div className="fdr jcsb mb">
      <div className="fGrow" style={{width: "80%"}}>
        <div className="row">
          <AccountCommonComponent disabled={!currentTransaction} currentTransactionId={currentTransactionId} />
          <div className="fcCol gap jcc aic fGrow pb">
            <ImgFilePreview
              disable={!directive || isFileUploaded}
              addFunc={addFile}
              delFunc={delFunc}
              accept={".pdf"}
              imgs={directiveDocsUrls}
            />
            <FunctionButton
              name={accountOrderBtnName}
              width="7em"
              style={{height: "3em"}}
              onClick={onCreateOrderBtnClick}
              disabled={!directiveId || isOrderCreated}
            />
            <FunctionButton
              name={t("Save")}
              width="7em"
              style={{height: "3em"}}
              onClick={onSaveDirectiveBtnClick}
              disabled={!directiveId || isDirectiveSaved}
            />
          </div>
        </div>
        <div className="pr2x">
          <ReactTable
            defaultData={paymentAccountUpperTableData}
            columns={paymentAccountUpperTableColumns}
            current={currentTransactionId}
            className={"project_table mb"}
            style={{height: "8em"}}
            selectable={true}
            onSelect={onTransactionSelect}
          />
          <ReactTable
            defaultData={paymentAccountLowerTableDate}
            columns={paymentAccountLowerTableColumns}
            current={currentSavedTransactionId}
            className={"project_table mb scrollX"}
            style={{height: "9em"}}
            selectable={true}
            // enableMultiSelection
            onSelect={onCurrentSavedTransactionSelect}
          />
          <div className="fdr gap4x jcfe">
            <FunctionButton
              name={t("Delete")}
              onClick={onDeleteDirectiveBthClick}
              disabled={!currentSavedTransactionId}
              autoWidth
            />
            <FunctionButton
              name={t("Conduct")}
              onClick={onConductDirectiveBthClick}
              disabled={!currentSavedTransactionId}
              autoWidth
            />
          </div>
        </div>
      </div>
      <div className="kpPreview__box" style={{height: "100%", width: "20%"}}>
        <NewPdfViewer fileArray={fileArray ?? []} hiding={false} />
      </div>
    </div>
  );
};

export default PaymentCommonBase;
