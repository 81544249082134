import {useFormikContext} from "formik";
import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import DirectiveFilters from "../DirectiveFilters";
import FinStatus from "../FinStatus";
import PdfPreviewModal from "../OrderDirectiveView/pdfPreviewModal";
import OrderTypeComposedPart, {ORDER_COMPOSED_PART_TYPES} from "../OrderTypeComposedPart/OrderTypeComposedPart";

import useOrderRegister from "./useOrderRegister";

const OrderRegister = ({readOnly}) => {
  const {values} = useFormikContext();
  const {
    onExitBtnClick,
    tableColumns,
    tableData,
    onTableSelect,
    onPrintOrderBtnClick,
    onRowDoubleClick,
    currentOrderId,
    onCreateNewOrderBtnClick,
    onViewOrderBtnClick,
    printModalActive,
    setPrintModalActive,
    pdfFile,
    currentOrder,
    printOrderBtnDisabled,
  } = useOrderRegister();

  return (
    <div className="fcCol h100 w100 pb aist">
      <DirectiveFilters readOnly />
      <Divider />
      <div className="grid2ColSecondMax gap w100 fGrow mb0_5" style={{gridTemplateColumns: "30% 1fr"}}>
        <OrderTypeComposedPart
          // readOnly={currentOrderId || readOnly}
          // noAsterisk
          // noPreCreate
          type={
            readOnly || currentOrderId ? ORDER_COMPOSED_PART_TYPES.VIEW_FUll : ORDER_COMPOSED_PART_TYPES.FINBLOCK_DO
          }
        >
          <div className="row w100 jcsb">
            {readOnly ? (
              <FunctionButton onClick={onViewOrderBtnClick} name="View an order" autoWidth disabled={!currentOrderId} />
            ) : (
              <FunctionButton
                onClick={onCreateNewOrderBtnClick}
                name="New order"
                autoWidth
                disabled={!!currentOrderId || !values.finOrderTypeId || values.finOrderTypeId === "default"}
              />
            )}
            <FunctionButton onClick={onExitBtnClick} name="Exit" autoWidth />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2">
          <FinStatus readOnly />
          <ReactTable
            columns={tableColumns}
            defaultData={tableData}
            current={currentOrderId}
            onSelect={onTableSelect}
            className="fGrow mb scrollX"
            style={{height: "unset"}}
            onRowDoubleClick={onRowDoubleClick}
          />
          <div className="row jcfe w100">
            <FunctionButton
              onClick={onPrintOrderBtnClick}
              name="Print an order"
              autoWidth
              disabled={printOrderBtnDisabled}
            />
          </div>
        </div>
      </div>
      <ModalForm
        closable={true}
        Component={PdfPreviewModal}
        label={t("Printing")}
        active={printModalActive}
        setModalActive={setPrintModalActive}
        pdfFile={pdfFile}
        fileName={currentOrder?.orderNumber}
      />
    </div>
  );
};

export default OrderRegister;
