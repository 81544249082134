import {createAsyncThunk} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../../../hook/axios.hook";

export const fetchAllWarehouses = createAsyncThunk("logistWarehouses/fetchAllWarehouses", async params =>
  fetchList(`/warehouses/getAll`, "get", {params}),
);

export const addWarehouse = createAsyncThunk("logistWarehouses/addWarehouse", async ({typeId, data}) =>
  fetchList(`/warehouses/add/${typeId}`, "post", data),
);

export const updateWarehouse = createAsyncThunk("logistWarehouses/updateWarehouse", async ({warehouseId, data}) =>
  fetchList(`/warehouses/update/${warehouseId}`, "patch", data),
);

export const fetchWarehouseInfo = createAsyncThunk("logistWarehouses/getWarehouseInfo", async warehouseId =>
  fetchList(`/warehouses/get/${warehouseId}`, "get"),
);

export const deleteWarehouse = createAsyncThunk("logistWarehouses/deleteWarehouse", async warehouseId =>
  fetchList(`/warehouses/remove/${warehouseId}`, "delete"),
);

//* new
export const fetchWarehousesFilters = createAsyncThunk("logSettings/getWarehousesFilters", async params =>
  fetchList(`/warehouses/getFilters`, "get", params),
);

//** Warehouse Types **/
//get
export const fetchAllWarehouseTypes = createAsyncThunk("logistWarehouses/fetchAllWarehouseTypes", async () =>
  fetchList(`/warehouses/types/getAll`, "get"),
);
//post
export const addNewWarehouseType = createAsyncThunk("logistWarehouses/addNewWarehouseType", async params =>
  fetchData(`/warehouses/types/add`, "post", params),
);
//delete
export const deleteWarehouseType = createAsyncThunk("logistWarehouses/deleteWarehouseType", async typeId =>
  fetchData(`/warehouses/types/remove/${typeId}`, "delete"),
);

//** Warehouse Contacts **/
//post
export const addNewWarehouseContact = createAsyncThunk(
  "logistWarehouses/addNewWarehouseContact",
  async ({warehouseId, params}) => fetchData(`/warehouses/contacts/add/${warehouseId}`, "post", params),
);
//delete
export const deleteWarehouseContact = createAsyncThunk("logistWarehouses/deleteWarehouseContact", async contactId =>
  fetchData(`/warehouses/contacts/remove/${contactId}`, "delete"),
);
///update
export const updateWarehouseContact = createAsyncThunk(
  "logistWarehouses/updateWarehouseContact",
  async ({contactId, params}) => fetchData(`/warehouses/contacts/update/${contactId}`, "patch", params),
);

//** Warehouse Accounts **/
//post
export const addNewWarehouseAccount = createAsyncThunk(
  "logistWarehouses/addNewWarehouseAccount",
  async ({warehouseId, params}) => fetchData(`/warehouses/accounts/add/${warehouseId}`, "post", params),
);
//delete
export const deleteWarehouseAccount = createAsyncThunk("logistWarehouses/deleteWarehouseAccount", async accountId =>
  fetchData(`/warehouses/accounts/remove/${accountId}`, "delete"),
);
export const updateWarehouseAccount = createAsyncThunk(
  "logistWarehouses/updateWarehouseAccount",
  async ({accountId, params}) => fetchData(`/warehouses/accounts/update/${accountId}`, "patch", params),
);
//** Warehouse Docs **/
//post
export const addNewWarehouseDoc = createAsyncThunk(
  "logistWarehouses/addNewWarehouseDoc",
  async ({warehouseId, params}) => fetchData(`/warehouses/docs/add/${warehouseId}`, "post", params),
);
//patch
export const updateWarehouseDoc = createAsyncThunk("logistWarehouses/updateWarehouseDoc", async ({docId, params}) =>
  fetchData(`/warehouses/docs/update/${docId}`, "patch", params),
);
//delete
export const deleteWarehouseDoc = createAsyncThunk("logistWarehouses/deleteWarehouseDoc", async docId =>
  fetchData(`/warehouses/docs/remove/${docId}`, "delete"),
);

//* Warehouse Docs Files */
//post
export const addNewWarehouseDocFile = createAsyncThunk(
  "logistWarehouses/addNewWb arehouseDocFile",
  async ({docId, doc}) => fetchData(`/warehouses/docs/files/add/${docId}`, "post", doc),
);
//delete
export const deleteWarehouseDocFile = createAsyncThunk("logistWarehouses/deleteWarehouseDocFile", async fileId =>
  fetchData(`/warehouses/docs/files/remove/${fileId}`, "delete"),
);

//** Warehouse services **/
//get
export const fetchAllWarehouseService = createAsyncThunk("logistWarehouses/fetchAllWarehouseService", async params =>
  fetchList(`warehouses/services/getAll`, "get", {params}),
);

export const fetchWarehouseService = createAsyncThunk("logistWarehouses/fetchWarehouseService", async serviceId =>
  fetchData(`/warehouses/services/get/${serviceId}`, "get"),
);
export const updateWarehouseService = createAsyncThunk(
  "logistWarehouses/updateWarehouseService",
  async ({serviceId, data}) => fetchData(`/warehouses/services/update/${serviceId}`, "patch", data),
);

export const fetchAllWarehouseServiceTypes = createAsyncThunk(
  "logistWarehouses/fetchAllWarehouseServiceTypes",
  async () => fetchList(`/warehouses/services/types/getAll`, "get"),
);

//post
export const addWarehouseService = createAsyncThunk(
  "logistWarehouses/addWarehouseService",
  async ({warehouseId, typeId, data}) => fetchList(`/warehouses/services/add/${warehouseId}/${typeId}`, "post", data),
);
export const addWarehouseServiceType = createAsyncThunk("logistWarehouses/addWarehouseServiceType", async data =>
  fetchList(`/warehouses/services/types/add`, "post", data),
);
//
export const deleteWarehouseService = createAsyncThunk("logistWarehouses/deleteWarehouseService", async serviceId =>
  fetchData(`/warehouses/services/remove/${serviceId}`, "delete"),
);
export const deleteWarehouseServiceType = createAsyncThunk(
  "logistWarehouses/deleteWarehouseServiceType",
  async typeId => fetchData(`/warehouses/services/types/remove/${typeId}`, "delete"),
);

//** Warehouse services Transactions **/
export const addTransaction = createAsyncThunk("logistWarehouses/addTransaction", async ({serviceId, data}) =>
  fetchList(`/warehouses/services/transactions/add/${serviceId}`, "post", data),
);

export const deleteTransactionPackage = createAsyncThunk("logistWarehouses/deleteTransactionPackage", async serviceId =>
  fetchList(`/warehouses/services/transactions/removePackage/${serviceId}`, "delete"),
);
