import {memo} from "react";

import ReactTable from "../../../conteiners/ReactTable";

import useContractDocsScreenShotsTables from "./useContractDocsScreenShotsTables";

const ContractDocsScreenShotsTables = ({contractTableDataRow, annexTableDataRow, einfoTableDataRow, setFileArray}) => {
  const {
    contractTableColumns,
    contractTableData,
    onContractTableSelect,
    annexTableColumns,
    annexTableData,
    onAnnexTableSelect,
    einfoTableColumns,
    einfoTableData,
    onEinfoTableSelect,
    currentEinfoForTable,
    currentContractForTable,
    currentAnnexForTable,
  } = useContractDocsScreenShotsTables({contractTableDataRow, annexTableDataRow, einfoTableDataRow, setFileArray});

  return (
    <div className="fcCol">
      <ReactTable
        columns={contractTableColumns}
        defaultData={contractTableData}
        current={currentContractForTable || null}
        onSelect={onContractTableSelect}
        className="tableTac"
        style={{height: "max(150px, 18%)"}}
      />
      <ReactTable
        columns={annexTableColumns}
        defaultData={annexTableData}
        current={currentAnnexForTable || null}
        onSelect={onAnnexTableSelect}
        className="tableTac mt"
        style={{flexGrow: "1", height: "max(200px, 40%)"}}
      />
      <ReactTable
        columns={einfoTableColumns}
        defaultData={einfoTableData}
        current={currentEinfoForTable || null}
        onSelect={onEinfoTableSelect}
        className="tableTac mt mb"
        style={{flexGrow: "1", height: "max(200px, 40%)"}}
      />
    </div>
  );
};

export default memo(ContractDocsScreenShotsTables);
