import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {dateFormat} from "../../../../../utilities/dateFormat";
import {swalCustom} from "../../../../../utilities/hooks/swalCustom";
import useToaster from "../../../../../utilities/hooks/useToaster";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {setCurrentCargosDocFile} from "../../../redux/Cargos/CargosSlice";
import {
  addNewCargoPaymentDoc,
  addNewCargoPaymentDocFile,
  deleteCargoPaymentDoc,
  deleteCargoPaymentDocFile,
  fetchAllPaymentDocs,
  fetchPaymentDocTypes,
} from "../../../redux/Cargos/operations";
import {
  selectAllCargosPaymentDocs,
  selectCargosLoading,
  selectCargosPaymentDocTypes,
} from "../../../redux/Cargos/selectors";
import {TRANSPORTER_PAYMENT_TABLE_COLUMNS} from "../../utils/constants";

const useTransporterPayment = ({currentSkuRequest, setPdfFileArray}) => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();
  const {values, setValues} = useFormikContext();
  const Toaster = useToaster();

  const paymentDocTypes = useSelector(selectCargosPaymentDocTypes);
  const allPaymentDocs = useSelector(selectAllCargosPaymentDocs);
  const areCargosLoading = useSelector(selectCargosLoading);

  const currentCargoId = currentSkuRequest?.stockPool?.cargo?.cargoId;

  const [currentDocId, setCurrentDocId] = useState(null);
  const currentDoc = allPaymentDocs?.find(doc => doc.docId === currentDocId) ?? null;

  useEffect(() => {
    if (currentCargoId) {
      dispatch(fetchAllPaymentDocs({cargoId: currentCargoId}));
    }
    if (!currentCargoId) {
      clearDocFormikData();
      setCurrentDocId(null);
    }
    setCurrentDocId(null);
  }, [currentCargoId]);

  useEffect(() => {
    dispatch(fetchPaymentDocTypes());
  }, []);

  const docTypePaymentOptions =
    paymentDocTypes?.map(({docTypeId, nameEng, nameUkr}) => ({
      title: language === "en" ? nameEng : nameUkr,
      value: docTypeId,
    })) ?? [];

  const transporterPaymentTableColumns = useColumns(TRANSPORTER_PAYMENT_TABLE_COLUMNS);
  const transporterPaymentTableData =
    currentSkuRequest && allPaymentDocs
      ? [...allPaymentDocs].reverse().map(({docId, date, number, updatedAt, creator, docTypeId}, index) => ({
          id: docId,
          number: ++index,
          status: " - ",
          docDate: dateFormat(date) ?? " - ",
          documentType:
            (paymentDocTypes?.find(docType => docType.docTypeId === docTypeId) ?? {})[
              language === "en" ? "nameEng" : "nameUkr"
            ] ?? " - ",
          documentNumber: number ?? " - ",
          uploadDate: dateFormat(updatedAt) ?? " - ",
          addedBY: creator?.name ?? " - ",
        }))
      : [];

  const isSaveDocBtnDisabled = !values.docTypePayment || !values.onDocDatePayment || !values.docNumberPayment;

  function clearDocFormikData() {
    setValues(prev => ({...prev, docTypePayment: "", onDocDatePayment: "", docNumberPayment: ""}));
  }

  function onSaveDocBtnClick() {
    const {docTypePayment, onDocDatePayment, docNumberPayment} = values;

    if (!docTypePayment || !onDocDatePayment || !docNumberPayment || !currentCargoId) return;

    dispatch(
      addNewCargoPaymentDoc({
        cargoId: currentCargoId,
        params: {docTypeId: docTypePayment, number: docNumberPayment, date: onDocDatePayment},
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        Toaster.toastSuccess("Document added");
        dispatch(fetchAllPaymentDocs({cargoId: currentCargoId}));
        clearDocFormikData();
      }
    });
  }

  function onDocSelect(docId) {
    if (!docId) {
      clearDocFormikData();
      dispatch(setCurrentCargosDocFile(null));
      setCurrentDocId(null);
      setPdfFileArray([]);
      return;
    }

    const currentDoc = allPaymentDocs?.find(doc => doc.docId === docId);
    if (!currentDoc) return;
    setValues(prev => ({
      ...prev,
      docTypePayment: currentDoc?.docTypeId,
      onDocDatePayment: currentDoc?.date,
      docNumberPayment: currentDoc?.number,
    }));
    dispatch(setCurrentCargosDocFile(currentDoc.files));
    setPdfFileArray(currentDoc.files.map(file => file.fileUrl));

    setCurrentDocId(docId);
  }

  function onDocFileAdd(arrFiles) {
    if (!currentDocId) return;

    const formdata = new FormData();
    for (const file of arrFiles) {
      const filename = file.name;
      formdata.append("doc", file, filename);
    }

    if (formdata.has("doc")) {
      dispatch(addNewCargoPaymentDocFile({formdata, docId: currentDocId})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          Toaster.toastSuccess("doc Success");
          dispatch(fetchAllPaymentDocs({cargoId: currentCargoId}));
          setPdfFileArray(prev => [resp.payload.fileUrl, ...prev]);
        }
      });
    }
  }

  function onDocDelete() {
    if (!currentDocId) return;

    const currentDoc = allPaymentDocs?.find(doc => doc.docId === currentDocId);
    if (!currentDoc) return;
    if (currentDoc.files.length > 0) {
      Toaster.toastError("Delete doc files first");
      return;
    }

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteCargoPaymentDoc(currentDocId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("Document deleted");
            dispatch(fetchAllPaymentDocs({cargoId: currentCargoId}));
            clearDocFormikData();
            setCurrentDocId(null);
            setPdfFileArray([]);
          }
        });
      },
    });
  }

  function onFileDel(url) {
    if (!currentDoc) return;

    const fileId = currentDoc.files.find(file => file.fileUrl === url)?.fileId;
    if (!fileId) return;
    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteCargoPaymentDocFile(fileId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("File deleted");
            dispatch(fetchAllPaymentDocs({cargoId: currentCargoId}));

            setPdfFileArray(prev => prev.filter(item => url !== item));
          }
        });
      },
    });
  }

  return {
    docTypePaymentOptions,
    transporterPaymentTableColumns,
    transporterPaymentTableData,
    onSaveDocBtnClick,
    isSaveDocBtnDisabled,
    currentDocId,
    onDocSelect,
    onDocFileAdd,
    onDocDelete,
    onFileDel,
    currentDoc,
    areCargosLoading,
  };
};

export default useTransporterPayment;
