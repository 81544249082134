export const PROJECT_DOCUMENTS_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 50,
  },

  {
    header: "Contract witn num",
    accessorKey: "contractNumber",
    // size: 200,
  },

  {
    header: "Project To",
    accessorKey: "dateTo",
    maxSize: 70,
  },
  {
    header: "Briefly about",
    accessorKey: "brieflyAbout",

    size: 300,
  },

  {
    header: "Project Sum",
    accessorKey: "projectSum",
    isSortable: true,
    // maxSize: 100,
  },
]);

export const CO_DOCUMENTS_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 50,
  },

  {
    header: "CO №",
    accessorKey: "coNumber",
    // size: 200,
  },
  {
    header: "CO Annex №",
    accessorKey: "annexNumber",
    // size: 200,
  },
  {
    header: "Take To",
    accessorKey: "takeTo",
    maxSize: 70,
  },
  {
    header: "Briefly about",
    accessorKey: "brieflyAbout",
    size: 300,
  },

  {
    header: "CO Sum",
    accessorKey: "coSum",
    isSortable: true,
    // maxSize: 100,
  },
]);

export const SCREENSHPTS_DOCUMENTS_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 50,
  },

  {
    header: "ScreenShot №",
    accessorKey: "screenShotNumber",
    // size: 200,
  },
  {
    header: "Date S.",
    accessorKey: "creationDate",
    // size: 200,
  },
  {
    header: "Briefly about",
    accessorKey: "brieflyAbout",
    minSize: 300,
  },
]);

export const PROJECT_DOC_TYPES = {
  contract: "CONTRACT",
  annex: "ANNEX",
  screenshot: "SCREENSHOT",
};
