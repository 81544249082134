import {t} from "i18next";

import {MoneyInput} from "../../../../../Inputs";

const PayerCreditForCo = () => {
  return (
    <div className="row w100 aibl">
      <span>{t("Payer")}</span>
      <MoneyInput name="payerCreditForCoComposed" label={t("Credit for CO")} autolabel readOnly width="8em" />
    </div>
  );
};

export default PayerCreditForCo;
