import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {ORDER_REGISTER_TABLE_COLUMNS} from "../../../Project/ProjectDirectiveList/OrderRegister/constants";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import FinBlockOrderDirectiveDo from "../FinBlockOrderDirectiveDo/FinBlockOrderDirectiveDo";

const useFinBlockOrderRegister = () => {
  const {addNewTabCustom} = useTabsContext();

  const tableColumns = useColumns(ORDER_REGISTER_TABLE_COLUMNS);
  const tableData = [];

  function onTableSelect() {
    addNewTabCustom({TabComponent: FinBlockOrderDirectiveDo, title: "Order number ", tabIdName: "orderNumberTab"});
  }

  function onExitBtnClick() {
    //!remove
    addNewTabCustom({TabComponent: FinBlockOrderDirectiveDo, title: "Order number ", tabIdName: "orderNumberTab"});
  }
  function onPrintOrderBtnClick() {}
  function onNewOrderBtnClick() {}

  return {onExitBtnClick, tableColumns, tableData, onTableSelect, onPrintOrderBtnClick, onNewOrderBtnClick};
};

export default useFinBlockOrderRegister;
