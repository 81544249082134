import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import closeEye from "../../../../../images/icons/closeEye.png";
import openEye from "../../../../../images/icons/openEye.png";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {getProjectDocument, setCurrentProjectDocument} from "../../../ProjectSlice";

const ShowDocBtn = ({type, files, disabled}) => {
  const currentDocuments = useSelector(getProjectDocument);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentDocuments && currentDocuments?.type === type) {
      dispatch(setCurrentProjectDocument(files && files.length > 0 ? {type, files} : null));
    }
  }, [files, currentDocuments?.type]);

  return (
    <ImageButton
      onClick={() => {
        dispatch(setCurrentProjectDocument(currentDocuments?.type === type ? null : {type, files}));
      }}
      src={currentDocuments?.type === type ? openEye : closeEye}
      alt="show document"
      width={2}
      height={2}
      disabled={disabled}
    />
  );
};

export default ShowDocBtn;
