import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import {MoneyInput, TextAreaInput} from "../../../Inputs";
import CurrencySelect from "../../../Inputs/CurrencySelect/CurrencySelect";
import {changeDirectivePurpose} from "../../FinSettings/FinSettingsSlice";

import OneAccountTemplate from "./OneAccountTemplate";

const AccountCommonComponent = ({disabled, currentTransactionId}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  function onPaymentRecognitionBlur(e) {
    const paymentPurpose = e.target.value;
    dispatch(changeDirectivePurpose({transactionId: currentTransactionId, paymentPurpose}));
  }

  return (
    <div className="fdr gap mb fGrow2">
      <div className="smaller_font_size fGrow" style={{border: "1px solid #A3B9D9", padding: "1em"}}>
        <div className="fdr gap jcsb">
          <div className="fdr aic gap " style={{marginRight: "2em"}}>
            <div>{t("Dt")}</div>
            <OneAccountTemplate name="dt" />
          </div>

          <div className="fdr gap">
            <ToolTip title="Currency type">
              <CurrencySelect
                name="currencyIdInAccountCommon"
                // onlyUSDandEUR
                width="4.1em"
                readOnly
                noLabel
                className="outlined blue_readonly"
              />
            </ToolTip>
            <ToolTip title="Sum">
              <MoneyInput
                name="sum"
                inputClassName="borderedInput"
                width="8em"
                height="1.95em"
                placeholder={t("Sum")}
                currency=""
              />
            </ToolTip>
          </div>

          <div className="fdr aic gap" style={{marginLeft: "2em"}}>
            <div>{t("Kt")}</div>
            <OneAccountTemplate name="kt" />
          </div>
        </div>
        <div className="fdr aic gap">
          <ToolTip title="Dt account name" className="fGrow">
            <TextAreaInput name="ktAccountName" height="2.5em" placeholder={t("Dt account name")} disabled />
          </ToolTip>
          <ToolTip title="Kt account name" className="fGrow">
            <TextAreaInput name="dtAccountName" height="2.5em" placeholder={t("Kt account name")} disabled />
          </ToolTip>
        </div>
        <Divider />
        <div className="fdr aic gap">
          <ToolTip title="Payment purpose" className="fGrow2">
            <TextAreaInput
              name="paymentRecognition"
              height="4.5em"
              placeholder={t("Enter Recognition of payment") + "*"}
              onBlur={onPaymentRecognitionBlur}
              disabled={disabled}
            />
          </ToolTip>
          <ToolTip title="Note (for the whole directive)" className="fGrow">
            <TextAreaInput name="note" height="4.5em" placeholder={t("Add note")} disabled={disabled} />
          </ToolTip>
        </div>
      </div>
    </div>
  );
};

export default AccountCommonComponent;
