import {useCallback, useEffect} from "react";
import {useDrag, useDrop} from "react-dnd";
import {flexRender} from "@tanstack/react-table";

import {apiUrl} from "../../../../api/baseURL";
import handMove from "../../../../images/icons/hand_move_icon.png";
import {numberFormat} from "../../../../utilities/moneyFormat";
import IndeterminateCheckbox from "../../../conteiners/reactTable/IndeterminateCheckbox";

const KpFinTableRow = ({
  row,
  reorderRow,
  selectedRow,
  columns,
  index,
  shouldScrollToBottom,
  moveRows,
  rowCheck,
  ...props
}) => {
  const [, dropRef] = useDrop({
    accept: "row",
    drop: draggedRow => reorderRow(draggedRow.index, row.index),
  });

  const [{isDragging}, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: "row",
  });

  const stringRender = useCallback(
    (data, className) => {
      if (data) {
        return (
          <tr key={index} className={className}>
            <td colSpan={columns.length - 1}>
              <div style={{fontSize: "18px", fontWeight: "500"}}>{data}</div>
            </td>
          </tr>
        );
      } else {
        return null;
      }
    },
    [row],
  );

  const imagesRender = useCallback(
    (type, text = null, className, dimension = "100px") => {
      if (row.original[type] && row.original[type].length !== 0) {
        if (type === "overallView") {
          return (
            <tr className={className}>
              <td colSpan={columns.length - 1}>
                {row.original[type]?.map((img, index) => (
                  <img
                    key={index}
                    src={apiUrl + "/" + img}
                    style={{width: dimension, height: dimension, marginLeft: "5px"}}
                  />
                ))}
              </td>
            </tr>
          );
        }

        return (
          <tr className={className}>
            <td
              colSpan={3}
              style={{
                border: "0",
                borderBottom: type !== "overallView" ? "0" : "0.02px solid rgba(88, 89, 93, 0.2941176471)",
              }}
            ></td>

            <td
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                borderLeft: "0.02px solid rgba(88, 89, 93, 0.2941176471)",
                backgroundColor: row === selectedRow ? "#bfcde2aa" : "transparent",
              }}
            >
              {text}
            </td>

            <td colSpan={columns.length - 1} style={{textAlign: "left"}}>
              {row.original[type]?.map((img, index) => (
                <img
                  key={index}
                  src={apiUrl + "/" + img}
                  style={{width: dimension, height: dimension, marginLeft: "5px"}}
                />
              ))}
            </td>
          </tr>
        );
      } else if (text) {
        return (
          <tr className={className}>
            <td
              colSpan={3}
              style={{
                border: "0",
                borderBottom: type !== "overallView" ? "0" : "0.02px solid rgba(88, 89, 93, 0.2941176471)",
              }}
            ></td>

            <td
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                borderLeft: "0.02px solid rgba(88, 89, 93, 0.2941176471)",
                borderRight: "0.02px solid rgba(88, 89, 93, 0.2941176471)",

                backgroundColor: row === selectedRow ? "#bfcde2aa" : "transparent",
              }}
            >
              {text}
            </td>
          </tr>
        );
      }
      // }
    },
    [row],
  );

  const headerRender = stringRender(row.original.headerData, "tr_no_border td_kpf_header");
  const overallViewRender = imagesRender("overallView", null, "tr_border-bottom");

  const colorImgRender = imagesRender("colorImages", row.original.colorText, "tr_no_border");
  const decorImgRender = imagesRender(
    "decorImages",
    row.original.decorText,
    colorImgRender !== null ? "tr_border-bottom" : "tr_no_border",
  );

  useEffect(() => {
    if (shouldScrollToBottom) {
      const tableBody = document.querySelector(".project_list_kp.table_dnd_container");
      tableBody.scrollTop = tableBody.scrollHeight;
    }
  }, [shouldScrollToBottom]);

  return (
    <>
      <tr
        ref={previewRef}
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
        //
        {...props}
        className="tr_border-bottom kpf_table_tr"
      >
        {moveRows && (
          <td
            ref={dropRef}
            style={{
              backgroundColor: row === selectedRow ? "#bfcde2aa" : index % 2 === 0 ? "rgb(233 241 251 / 40%)" : "white",
            }}
          >
            <span ref={dragRef}>
              <img src={handMove} alt="" style={{width: "1em", marginLeft: "5px"}} draggable="false" />
            </span>
          </td>
        )}
        {rowCheck && (
          <td
            key={"j0"}
            style={{
              position: "relative",
              width: 20,
              backgroundColor: row === selectedRow ? "#bfcde2aa" : index % 2 === 0 ? "rgb(233 241 251 / 40%)" : "white",
              textAlign: "center",
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: row.original.isSelectFroze ?? !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </td>
        )}
        <td
          style={{
            backgroundColor: row === selectedRow ? "#bfcde2aa" : index % 2 === 0 ? "rgb(233 241 251 / 40%)" : "white",
          }}
        >
          {row.original.number}
        </td>
        <td
          colSpan={columns.length - 1}
          style={{padding: 0, margin: 0}}
          // className={index % 2 === 0 ? "row-even" : "row-odd"}
        >
          <table
            style={{
              width: "100%",
              padding: "0",
              margin: "0",
              borderCollapse: "collapse",
              backgroundColor: row === selectedRow ? "#bfcde2aa" : "transparent",
              tableLayout: "fixed",
            }}
          >
            <thead style={{borderBottom: "0"}}>
              <tr>
                {row.getVisibleCells().map((cell, i) => {
                  if (i === 0) return null;
                  else
                    return (
                      <th
                        key={cell.id}
                        style={{
                          width: cell.column.getSize(),
                          padding: "0",
                          margin: "0",
                          border: "0",
                        }}
                      ></th>
                    );
                })}
              </tr>
            </thead>

            <tbody>
              {headerRender}
              {overallViewRender}
              <tr>
                {row.getVisibleCells().map((cell, i) => {
                  if (i === 0) return null;
                  else {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          // width: cell.column.getSize(),
                          width: "200px",
                          fontSize: "11px",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                          minWidth: cell.column.columnDef.minWidth,
                        }}
                        className={`td ${cell.column.columnDef.isDisabled ? "disable" : ""}`}
                      >
                        {cell.column.columnDef.numberFormat
                          ? numberFormat(cell.getValue())
                          : flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  }
                })}
              </tr>
              {decorImgRender}
              {colorImgRender}
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};
export default KpFinTableRow;
