import {useTranslation} from "react-i18next";

import {TextInput} from "../../../../Inputs";
import FieldWithSample from "../../../../Inputs/FieldWithSample";

const SeriesBatchBlock = ({readOnly}) => {
  const {t} = useTranslation();

  return (
    <div className="part">
      <FieldWithSample name="productSeriesCheck" label={t("Series")} style={{gap: "10px"}} readOnly={readOnly}>
        <TextInput name="productSeries" width="10em" readOnly={readOnly} />
      </FieldWithSample>

      <FieldWithSample name="batchCheck" label={t("Batch")} style={{gap: "10px"}} readOnly={readOnly}>
        <TextInput name="batch" width="10em" readOnly={readOnly} />
      </FieldWithSample>
    </div>
  );
};
export default SeriesBatchBlock;
