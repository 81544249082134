import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEqual} from "lodash";

import {accessAllowed} from "../../../../gitIgnore/accessAllowedIds";
import {getClients} from "../../../../slices/PersonSlice";
import {getCurrentUser} from "../../../../slices/UserSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {STATUS_COLORS} from "../../../../utilities/colorConstants";
import {dateFormat} from "../../../../utilities/dateFormat";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {useFiltersContext} from "../../../conteiners/filters/useSessionFiltersContext";
import ReactTable from "../../../conteiners/ReactTable";
import {MoneyInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import ModalForm from "../../../Modal/FormModal";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import useCountryIdOptionsService from "../../../VED/settings/conditions&contactsFactory/utils/hooks/getCountryIdOptionsService";
import {Status} from "../../ProjectAddForm/ProjectAddForm";
import RecipientDetails from "../../ProjectModals/RecipientDetails";
import {
  addProject,
  deleteProject,
  deselectProject,
  fetchProjectById,
  fetchProjectFilters,
  fetchProjectsWithFilter,
  getCurrentProject,
  getProjectLoading,
  getProjects,
  updateProject,
} from "../../ProjectSlice";
import {PROJECT_TABLE_COLUMNS} from "../constants";

const ProjectSection = () => {
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const {setFieldValue, values, setValues} = useFormikContext();
  const {filters} = useFiltersContext();

  const dispatch = useDispatch();

  const user = useSelector(getCurrentUser);

  const projectTableColumns = useColumns(PROJECT_TABLE_COLUMNS);
  const countryIdOptions = useCountryIdOptionsService();

  const currentProject = useSelector(getCurrentProject);
  const projects = useSelector(getProjects);
  const clients = useSelector(getClients);
  const loading = useSelector(getProjectLoading);

  const [recipientDetailsModalActive, setRecipientDetailsModalActive] = useState(false);

  const clientsCatalog = useMemo(
    () =>
      clients.map(client => ({
        title: client.clientNumber,
        value: client.clientId,
        clientBalance: client?.accounts?.reduce((prev, current) => {
          // console.log(Number(current.balance));
          if (Number(current.balance)) return prev + Number(current.balance);
          else return prev;
        }, 0),
      })) || [],
    [clients],
  );

  function transformProjectForTable(project, i) {
    return {
      statusCircle: STATUS_COLORS.GREY,
      number: i + 1,
      dateFrom: dateFormat(project.createdAt),
      dateTo: dateFormat(project.deadline),
      projectNumber: project?.projectNumber,
      address: project?.delivery?.address || "",
      planSum: "",
      factSum: "",
      projectDesign: project.designNumber,
      projectManager: project?.projectManager?.name || "?",

      id: project.projectId,
    };
  }

  const tableData = projects?.map(transformProjectForTable) || [];

  // useEffect(() => {
  //   return () => {
  //     dispatch(deselectProject());
  //   };
  // }, []);

  useEffect(() => {
    fetchProjects(filters);
    dispatch(deselectProject());
  }, [filters]);

  const fetchProjects = params => {
    dispatch(fetchProjectsWithFilter(params));
  };

  useEffect(() => {
    setTimeout(() => {
      setValues(prev => ({
        ...prev,
        projectBudget: currentProject?.butget ?? "",
        project: currentProject?.projectNumber || "",
        projectOffice: currentProject?.orgStructure[language === "eng" ? "fullNameEng" : "fullNameUkr"] || "",
        projectManager: currentProject?.projectManager.name || "",
        statusId: currentProject?.statusId || "",
        countryId: currentProject?.delivery?.countryId || "",
        deliveryPoint: currentProject?.delivery?.city || "",
        deliveryPointIndex: currentProject?.delivery?.index || "",
        deliveryPointAddress: currentProject?.delivery?.address || "",
        projectDesign: currentProject?.designNumber || "",
        clientNumber: currentProject?.client?.clientId || "",
      }));
    }, 0);
  }, [currentProject]);

  const onCreateProject = () => {
    dispatch(addProject(values.clientNumber)).then(() => fetchProjects(filters));
    dispatch(fetchProjectFilters());
  };

  const onUpdateProject = () => {
    dispatch(
      updateProject({
        projectId: currentProject.projectId,
        data: {
          designNumber: values.projectDesign,
          delivery: {
            countryId: values.countryId || null,
            city: values.deliveryPoint,
            index: values.deliveryPointIndex,
            address: values.deliveryPointAddress,
          },
        },
      }),
    );
    dispatch(fetchProjectFilters());
  };

  const onDeleteProject = () => {
    if (accessAllowed.includes(user.id)) {
      swalCustom
        .fire({
          title: "",
          text: t("Are you sure?"),
          icon: "warning",
          confirmButtonText: t("Yes"),
          showCancelButton: true,
          cancelButtonText: t("Cancel"),
        })
        .then(value => {
          if (value.isConfirmed) {
            dispatch(deleteProject(currentProject.projectId));
          }
        });
    } else {
      swalCustom.fire({
        title: "",
        text: `${t("Insufficient")}`,
        icon: "info",
        confirmButtonText: t("Ok"),
      });
    }
  };

  const onProjectSelect = value => {
    if (!value) {
      dispatch(deselectProject());
      return;
    }
    dispatch(fetchProjectById(value));
  };

  const fieldsDisabled = false;

  const saveProjectDisabled = useMemo(() => {
    if (!currentProject) return true;
    let projectValues = {
      designNumber: values.projectDesign,
      countryId: values.countryId,
      city: values.deliveryPoint,
      index: values.deliveryPointIndex,
      address: values.deliveryPointAddress,
      statusId: values.statusId,
    };
    let project = {
      designNumber: currentProject.designNumber,
      countryId: currentProject.delivery.countryId,
      city: currentProject.delivery.city,
      index: currentProject.delivery.index,
      address: currentProject.delivery.address,
      statusId: currentProject.statusId,
    };
    return isEqual(projectValues, project);
  }, [values, currentProject]);

  function onModalClose({areValuesChanged}) {
    if (areValuesChanged) {
      swalCustom.unsavedData({
        confirmFunc: () => {
          setRecipientDetailsModalActive(false);
        },
      });
      return;
    }

    setRecipientDetailsModalActive(false);
  }

  return (
    <div className="left-side">
      <div>
        <div className="fdr gap mb0_5 jcsb">
          <Select
            label={t("Client №") + ":"}
            name="clientNumber"
            options={clientsCatalog}
            autolabel
            makeEffect={option => setFieldValue("clientBalance", option?.clientBalance)}
          />
          <MoneyInput label={t("Balance")} name="clientBalance" width="10em" readOnly />
          <MoneyInput label={t("Project budget")} name="projectBudget" autolabel style={{width: "20em"}} readOnly />
        </div>
        <div className="fdr mb0_5 jcfe" style={{gap: "4em"}}>
          <FunctionButton
            name={t("Create p")}
            onClick={onCreateProject}
            disabled={!!currentProject || !values?.clientNumber || values.clientNumber === ""}
          />
          <TextInput label={t("Project")} name="project" autolabel style={{width: "20em"}} readOnly />
        </div>
        <div className="fc mb0_5 jcsb">
          <TextInput label={t("Project office")} name="projectOffice" style={{width: "20em"}} readOnly />
          <TextInput label={t("Project Design")} name="projectDesign" style={{width: "20em"}} />
        </div>
        <div className="fc mb0_5 jcsb">
          <TextInput label={t("Manager")} name="projectManager" style={{width: "20em"}} readOnly />
          <Status readOnly={fieldsDisabled} style={{width: "20em"}} />
        </div>
      </div>
      <div className="mb0_5" style={{border: "1px solid #DAE1EC", padding: "0.5em"}}>
        <div className="fdr gap mb0_5">
          <Select
            label={t("Delivery country:")}
            name="countryId"
            options={countryIdOptions}
            width="10em"
            autolabel
            generalClassName={"left-drop"}
            // readOnly={isDisabled}
          />
          <TextInput label={t("Delivery point index") + ":"} name="deliveryPointIndex" autolabel />
          <TextInput label={t("Delivery point") + ":"} name="deliveryPoint" autolabel />
        </div>
        <TextInput label={t("Delivery point address") + " :"} name="deliveryPointAddress" autolabel />
      </div>

      <div className="fdr mb0_5 jcsb gap">
        <FunctionButton
          name="Recipient*"
          disabled={!currentProject}
          onClick={() => {
            setRecipientDetailsModalActive(true);
          }}
        />
        <FunctionButton name={t("Save Project")} onClick={onUpdateProject} disabled={saveProjectDisabled} />
        <FunctionButton name={t("Delete p")} onClick={onDeleteProject} disabled={!currentProject} />
      </div>
      <ReactTable
        defaultData={tableData}
        columns={projectTableColumns}
        current={currentProject?.projectId || null}
        onSelect={onProjectSelect}
        className="scrollX  mb0_5"
        loading={loading}
      />
      <ModalForm
        closable={true}
        canBeClosed={false}
        Component={RecipientDetails}
        label={t("Details of the recipient")}
        active={recipientDetailsModalActive}
        setModalActive={setRecipientDetailsModalActive}
        onModalClose={onModalClose}
        className="smallModal"
      />
    </div>
  );
};

export default ProjectSection;
