import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";
import {isEqual} from "lodash";

import {fetchAllCountries, selectAllCountries} from "../../../slices/CatalogsSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {swalCustom} from "../../../utilities/hooks/swalCustom";
import {toaster} from "../../../utilities/hooks/toaster";
import {TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import {fetchAllDeliveryTerms, getCurrentProject, selectDeliveryTerms, updateProject} from "../ProjectSlice";

const RecipientDetails = ({setModalActive, setOnCloseProps}) => {
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();

  const countries = useSelector(selectAllCountries);
  const deliveryTerms = useSelector(selectDeliveryTerms);
  const currentProject = useSelector(getCurrentProject);
  const projectNumber = currentProject?.projectNumber;
  const {payer, payerAddress, recipient, recipientAddress, customsClearenceOn, deliveryTermId, countryId} =
    currentProject?.recipient ?? {};

  const startValues = {
    projectNumber,
    payer,
    payerAddress,
    recipient,
    recipientAddress,
    customsClearenceOn,
    deliveryTermId,
    productCountryId: countryId,
  };

  const areValuesChanged = !isEqual({...values, projectNumber: ""}, {...startValues, projectNumber: ""});

  useEffect(() => {
    setOnCloseProps({areValuesChanged});
  }, [areValuesChanged]);

  useEffect(() => {
    setValues({...startValues, projectNumber});
  }, [projectNumber]);

  useEffect(() => {
    if (!countries?.length) {
      dispatch(fetchAllCountries());
    }
  }, [countries?.length]);

  useEffect(() => {
    dispatch(fetchAllDeliveryTerms());
  }, []);

  const deliveryConditionOptions =
    deliveryTerms?.map(({deliveryTermId, code}) => ({title: code, value: deliveryTermId})) ?? [];

  const productCountryOptions =
    countries?.map(({countryId, shortName}) => ({title: shortName, value: countryId})) ?? [];

  function onSaveBtnClick() {
    const projectId = currentProject?.projectId;
    if (!projectId) return;

    const {payer, payerAddress, recipient, recipientAddress, customsClearenceOn, deliveryTermId, productCountryId} =
      values;

    dispatch(
      updateProject({
        projectId,
        data: {
          recipient: {
            payer,
            payerAddress,
            recipient,
            recipientAddress,
            customsClearenceOn,
            deliveryTermId,
            countryId: productCountryId,
          },
        },
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Project updated");
        setModalActive(false);
      }
    });
  }

  function onCancelBtnClick() {
    swalCustom.confirm({
      confirmFunc: () => {
        setValues(startValues);
      },
    });
  }

  return (
    <div className="fcCol jcsb w100">
      <div className="fcCol w100 gap aistr mt2x" style={{paddingLeft: "50px", paddingRight: "50px"}}>
        <TextInput label={t("Project №")} name="projectNumber" autolabel readOnly />
        <TextInput label={t("Payer name")} name="payer" autolabel />
        <TextInput label={t("Payer Address")} name="payerAddress" autolabel />
        <TextInput label={t("Recipient")} name="recipient" autolabel />
        <TextInput label={t("Recipient Address")} name="recipientAddress" autolabel />
        <TextInput label={t("Customs clearance")} name="customsClearenceOn" autolabel />
        <Select
          name="deliveryTermId"
          label={t("Delivery condition:")}
          options={deliveryConditionOptions}
          width="5em"
          optionsAbove
        />
        <Select
          name="productCountryId"
          label={t("Product country")}
          options={productCountryOptions}
          width="25em"
          optionsAbove
        />
      </div>
      <div className="row w100 jcc gap2x">
        <FunctionButton name="Save" autoWidth onClick={onSaveBtnClick} disabled={!areValuesChanged} />
        <FunctionButton name="Cancel2" autoWidth onClick={onCancelBtnClick} disabled={!areValuesChanged} />
      </div>
    </div>
  );
};

export default withFormik({
  enableReinitialize: true,
})(RecipientDetails);
