import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {
  fetchFinOrderTypes,
  fetchOperationTypes,
  selectCreatedDirective,
  selectFinOrderTypes,
  selectOperationTypes,
} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import {ORDER_CHANGING_PART_TYPES} from "../OrderChangingPart/OrderChangingPart";

import {DIRECTIVE_PART_DEPENDENCY} from "./OrderTypeComposedPart";

const useOrderTypeComposedPart = ({readOnly}) => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();
  const {values, setFieldValue} = useFormikContext();
  const {finOrderTypeId, operationTypeId, fulfilBy} = values;
  const isOkBtnDisabled =
    !fulfilBy || !finOrderTypeId || !operationTypeId || finOrderTypeId === "default" || operationTypeId === "default";

  const operationTypes = useSelector(selectOperationTypes);
  const finOrderTypes = useSelector(selectFinOrderTypes);
  const createdDirective = useSelector(selectCreatedDirective);
  const isDirectiveCreated = createdDirective?.directiveId;

  useEffect(() => {
    const isCurrentFinOrderinFetched = finOrderTypes.find(({typeId}) => typeId === finOrderTypeId);
    if (!isCurrentFinOrderinFetched && finOrderTypeId !== "default") {
      setFieldValue("finOrderTypeId", "default");
    }
  }, [finOrderTypes?.length]);

  useEffect(() => {
    const isCurrentOperationinFetched = operationTypes.find(
      operationType => operationType.operationTypeId === operationTypeId,
    );
    if (!isCurrentOperationinFetched && operationTypeId !== "default") {
      setFieldValue("operationTypeId", "default");
    }
  }, [operationTypes?.length]);

  useEffect(() => {
    dispatch(fetchFinOrderTypes({}));
    dispatch(fetchOperationTypes({}));
  }, []);

  useEffect(() => {
    if (finOrderTypeId && finOrderTypeId !== "default") {
      dispatch(fetchOperationTypes({typeId: finOrderTypeId}));
    }
  }, [finOrderTypeId]);

  useEffect(() => {
    if (operationTypeId && operationTypeId !== "default") {
      dispatch(fetchFinOrderTypes({operationTypeId}));
    }
  }, [operationTypeId]);

  const finOrderTypeOptions =
    finOrderTypes?.map(({typeId, nameEng, nameUkr}) => ({
      title: language === "en" ? nameEng : nameUkr,
      value: typeId,
    })) ?? [];
  const operationTypeOptions =
    operationTypes?.map(({operationTypeId, nameEng, nameUkr}) => ({
      title: language === "en" ? nameEng : nameUkr,
      value: operationTypeId,
    })) ?? [];

  const changingPartType = readOnly
    ? DIRECTIVE_PART_DEPENDENCY[`${operationTypeId}.${finOrderTypeId}`]
    : isDirectiveCreated && DIRECTIVE_PART_DEPENDENCY[`${operationTypeId}.${finOrderTypeId}`];

  useEffect(() => {
    if (
      changingPartType === ORDER_CHANGING_PART_TYPES.ON_CLIENT_BALANCE_GENERAL ||
      changingPartType === ORDER_CHANGING_PART_TYPES.CLIENT_REFUND_GENERAL ||
      changingPartType === ORDER_CHANGING_PART_TYPES.ON_CREDIT_REPAYMENT_GENERAL
    ) {
      setTimeout(() => {
        setFieldValue("areAllFieldsFilledIn", null);
      });
    }
  }, [changingPartType]);

  function onDefaultFinOrderTypeSelect() {
    dispatch(fetchFinOrderTypes({}));
  }

  function onDefaultOperationTypeSelect() {
    dispatch(fetchOperationTypes({}));
  }
  return {
    finOrderTypeOptions,
    onDefaultFinOrderTypeSelect,
    operationTypeOptions,
    onDefaultOperationTypeSelect,
    isDirectiveCreated,
    isOkBtnDisabled,
    changingPartType,
  };
};

export default useOrderTypeComposedPart;
