import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Formik, useFormikContext} from "formik";
import {isEmpty} from "lodash";

import FilterButton from "../../../utilities/Buttons/FilterBtn/FilterButton";
import {checkEmptyFields} from "../../../utilities/checkEmptyFields";
import useSessionFilters from "../../../utilities/hooks/useSessionFilters";

import ChosenFiltersLine from "./ChosenFiltersLine";
import {FiltersContext} from "./filters.context";
import {useFiltersContext} from "./useSessionFiltersContext";

export const Filters = ({children}) => {
  return children;
};

export const SessionFiltersContainer = ({window, onFilterChange, onFilterDelete, ...props}) => {
  const {
    filters: sessionF,
    setFilters: setSessionF,
    clearFilters: clearSessionFilters,
    initialFilters,
  } = useSessionFilters(window);

  const [filters, setFiltersValues] = useState(initialFilters);
  const [formikValues, setFormikValues] = useState(initialFilters);

  const setFilters = filters => {
    setFiltersValues(filters);
    isEmpty(filters) ? clearSessionFilters() : setSessionF(filters);
    if (onFilterChange) onFilterChange(filters);
  };

  const wrapFilters = children => {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        if (typeof child.type === "function") {
          if (child.type.name === "Filters") {
            return (
              <Formik initialValues={{...initialFilters}} validateOnChange enableReinitialize>
                <InnerContainer onFilterDelete={onFilterDelete} setfFormValues={setFormikValues}>
                  {child}
                </InnerContainer>
              </Formik>
            );
          }
          return child;
        }
        if (child.props.children.length > 1) {
          const nestedChildren2 = wrapFilters(child.props.children);
          return React.cloneElement(child, {...child.props}, nestedChildren2);
        }
        return child;
      }
      return child;
    });
  };

  return (
    <FiltersContext.Provider
      value={{filters, setFilters, sessionF, setSessionF, clearSessionFilters, formValues: formikValues}}
    >
      {wrapFilters(props.children)}
    </FiltersContext.Provider>
  );
};

const InnerContainer = ({children, onFilterDelete, setfFormValues}) => {
  const {t} = useTranslation();
  const {filters, setFilters} = useFiltersContext();
  const {setValues, values} = useFormikContext();

  const [selectedFiltersNames, setSelectedFiltersName] = useState({});
  const areFiltersDefined = !isEmpty(filters);

  useEffect(() => {
    setfFormValues(values);
  }, [values]);

  useEffect(() => {
    if (isEmpty(filters)) {
      return;
    }
    const savedStr = generateSelectedFiltersNames(children, filters);
    let newNames = {};
    if (Object.keys(selectedFiltersNames)?.length > 0) {
      Object.keys(savedStr)?.forEach(filterOption => {
        if (
          selectedFiltersNames[filterOption]?.value === savedStr[filterOption]?.value &&
          selectedFiltersNames[filterOption]?.option !== savedStr[filterOption]?.option &&
          selectedFiltersNames[filterOption]?.option
        ) {
          newNames[filterOption] = selectedFiltersNames[filterOption];
        } else if (savedStr[filterOption]?.value && !savedStr[filterOption]?.option) {
          return;
        } else newNames[filterOption] = savedStr[filterOption];
      });
    } else newNames = {...savedStr};
    setSelectedFiltersName(newNames);
  }, [filters, children]);

  function onFilter(newFilters) {
    // resetForm();
    const params = checkEmptyFields({...filters, ...newFilters, ...values});
    if (Object.keys(params).length > 0) {
      setFilters(params);
    }
    if (newFilters && Object.keys(params).length === 0) {
      setFilters(null);
    }
  }

  const delFilters = () => {
    const resetValues = Object.keys(values).reduce((acc, key) => {
      acc[key] = typeof values[key] === "number" ? 0 : "";
      return acc;
    }, {});

    setValues(resetValues);
    setFilters(null);
    onFilterDelete();
  };

  const generateSelectedFiltersNames = (fields, filters_) => {
    let names = {};
    React.Children.forEach(fields, child => {
      if (React.isValidElement(child)) {
        if (typeof child.type === "function" && child.props.name && Object.keys(filters_).includes(child.props.name)) {
          let field = child.props;
          names[field?.name] = {
            label: field.label,
            option: field?.options?.find(option => option.value === filters_[child.props.name])?.title,
            value: filters_[child.props.name],
          };
          if (child.type.name === "DateInput") {
            names[field?.name].type = "date";
          }
        } else {
          names = {...names, ...generateSelectedFiltersNames(child.props.children, filters_)};
        }
      }
    });
    return names;
  };

  return (
    <>
      <div className="fc jcsb aist w100 g1 autolabel nowrap ">
        <div style={{paddingRight: "2.5em"}}>
          <FilterButton
            onFilter={onFilter}
            delFilter={delFilters}
            questionTitle={t("Filter")}
            areFiltersDefined={areFiltersDefined}
          />
        </div>

        {children}
      </div>
      <div className="w100">
        <ChosenFiltersLine filterNames={selectedFiltersNames} onFilterDelete={onFilterDelete} />
      </div>
    </>
  );
};
