import {t} from "i18next";

import {MoneyInput, TextInput} from "../../Inputs";

const DirectiveFilters = ({readOnly = false}) => {
  return (
    <div className="row jcsb gap3x w100">
      <TextInput
        name="directiveType"
        label={t("D type")}
        autolabel
        disabled={readOnly}
        inputClassName="unfinishedLayout"
      />
      <TextInput name="status" label={t("Status")} autolabel readOnly={readOnly} width="16em" />
      <TextInput name="coNumber" label={t("CO №")} autolabel readOnly={readOnly} width="7em" />
      {/* <TextInput name="coBalance" label={t("CO Balance")} autolabel readOnly={readOnly} width="8em" /> */}
      <MoneyInput name="clientBalance" label={t("Client Balance")} autolabel readOnly={readOnly} width="8em" />
      <MoneyInput name="clientProject" label={t("Project Balance")} autolabel readOnly={readOnly} width="8em" />
    </div>
  );
};

export default DirectiveFilters;
