import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {fetchFinOrder, selectDirective} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import OrderDirectiveView from "../OrderDirectiveView/OrderDirectiveView";

import {ORDER_REGISTER_TABLE_COLUMNS} from "./constants";

const useOrderRegister = () => {
  const {addNewTabCustom, closeCurrentTab} = useTabsContext();
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const {setValues} = useFormikContext();
  const dispatch = useDispatch();

  const directive = useSelector(selectDirective);

  const [currentOrderId, setCurrentOrderId] = useState(null);
  const currentOrder = directive?.orders?.find(order => order.orderId === currentOrderId);

  useEffect(() => {
    if (directive) {
      const {status, co, operationType, note} = directive;
      const {project, account, coNumber, sum, prepayment} = co;
      const {butget} = project;

      setValues(prev => ({
        ...prev,
        fulfilBy: new Date().toISOString(),
        directiveType: "",
        status: language === "en" ? status.nameEng : status.nameUkr,
        coNumber,
        coBalance: account?.balance,
        clientBalance: co?.account?.balance,
        clientProject: butget,
        coSum: sum,
        advance: (sum && (prepayment / sum) * 100) || "0",
        nSum: prepayment,
        fact: (sum && (account?.balance / sum) * 100) || "0",
        finOrderTypeId: "",
        operationTypeId: operationType.operationTypeId,
        executorNote: note ?? t("No executor note"),
      }));
    }
  }, [directive?.directiveId]);

  useEffect(() => {
    if (currentOrderId) {
      const {recipient, co, sumFact, sumPlan} = directive;
      const {payer, outsideParty, loanTerms} = currentOrder;

      setValues(prev => ({
        ...prev,
        finOrderTypeId: currentOrder.type.typeId ?? "",

        payerCoBalanceComposed: payer?.balance ?? "-",
        payerClientNumberComposed: payer?.clientNumber ?? "-",
        payerClientBalanceComposed: payer?.balance ?? "-",
        payerCreditForCoComposed: payer?.balance ?? "-",

        recipientClientBalanceComposed: recipient?.balance ?? "-",
        recipientCoBalanceComposed: recipient?.balance ?? "-",
        recipientClientNumberComposed: recipient?.clientNumber ?? "-",
        recipientCreditForCoComposed: recipient?.balance ?? "-",

        creditTermComposed: loanTerms?.termUpTo ?? "-",
        perAnnumComposed: loanTerms?.annualInterestRate ?? "-",
        repaymentTermIdComposed: loanTerms?.repaymentTermsId ?? "-",
        creditSumComposed: sumPlan ?? "-",

        payerNameComposed: outsideParty?.name ?? "-",
        edrpouoComposed: outsideParty?.edrpouCode ?? "-",
        payerAccountComposed: outsideParty?.currentAccount ?? "-",

        sumComposed: currentOrder?.sum ?? "-",
        currencyIdComposed: currentOrder?.currencyId ?? "-",

        coSumComposed: co?.sum ?? "-",
        fSumComposed: sumFact ?? "-",
        coSumAdditionalComposed: co?.prepayment ?? "-",

        apSumComposed: sumPlan ?? "-",
        advancePercentageComposed:
          (Number.parseFloat(sumFact) && (Number.parseFloat(sumPlan) / Number.parseFloat(sumFact)) * 100) || "0",
        afSumComposed: sumFact ?? "-",
      }));
    } else {
      setTimeout(() =>
        setValues(prev => ({
          ...prev,
          finOrderTypeId: "",
          payerCoBalanceComposed: "",
          payerClientNumberComposed: "",
          payerClientBalanceComposed: "",
          payerCreditForCoComposed: "",
          recipientClientBalanceComposed: "",
          recipientCoBalanceComposed: "",
          recipientClientNumberComposed: "",
          recipientCreditForCoComposed: "",
          creditTermComposed: "",
          perAnnumComposed: "",
          repaymentTermIdComposed: "",
          creditSumComposed: "",
          payerNameComposed: "",
          edrpouoComposed: "",
          payerAccountComposed: "",
          sumComposed: "",
          currencyIdComposed: "",
          coSumComposed: "",
          fSumComposed: "",
          coSumAdditionalComposed: "",
          apSumComposed: "",
          advancePercentageComposed: "",
          afSumComposed: "",
        })),
      );
    }
  }, [currentOrderId]);

  const tableColumns = useColumns(ORDER_REGISTER_TABLE_COLUMNS);
  const tableData =
    directive?.orders?.map(({orderId, orderNumber}, index) => ({
      id: orderId,
      number: ++index,
      dateTo: "-",
      factDate: "-",
      directiveNumber: directive?.directiveNumber,
      orderNumber,
      dt: "-",
      kt: "-",
      sumF: "-",
      paymentPurpose: "-",
      note: "-",
    })) ?? [];

  function onTableSelect(id) {
    setCurrentOrderId(id);
  }

  function onRowDoubleClick(order) {
    if (!order) return;

    dispatch(fetchFinOrder(order.id));
    addNewTabCustom({
      TabComponent: OrderDirectiveView,
      title: `${t("Order number ")} ${order.orderNumber}`,
      tabIdName: "orderNumberTab",
    });
  }

  function onExitBtnClick() {
    swalCustom.confirm({
      confirmFunc: () => {
        closeCurrentTab();
      },
    });
  }
  function onPrintOrderBtnClick() {}

  return {
    onExitBtnClick,
    tableColumns,
    tableData,
    onTableSelect,
    onPrintOrderBtnClick,
    onRowDoubleClick,
    currentOrderId,
  };
};

export default useOrderRegister;
