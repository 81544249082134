import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Form, useFormikContext} from "formik";

import {useDebouncedCallback} from "../../../hook/debounced";
import FilterButton from "../../../utilities/Buttons/FilterBtn/FilterButton";
import {checkEmptyFields} from "../../../utilities/checkEmptyFields";
import {dateFormat} from "../../../utilities/dateFormat";
import useSessionFilters from "../../../utilities/hooks/useSessionFilters";
import useCountryIdOptionsService from "../../VED/settings/conditions&contactsFactory/utils/hooks/getCountryIdOptionsService";
import {fetchMixedRequests} from "../RequestsSlice";

import ChosenFiltersLine from "./ChosenFiltersLine";
import useRequestFilters from "./filters.hook";

/* eslint-disable react/display-name */
const withRequestFilters = BaseComponent => {
  return props => {
    const {window} = props;

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {values, setValues} = useFormikContext();
    const {filters, setFilters, clearFilters, initialFilters} = useSessionFilters(window);

    const [areFiltersDefined, setAreFiltersDefined] = useState(!!filters);
    useEffect(() => {
      if (initialFilters) {
        setValues({...initialFilters});
        setAreFiltersDefined(true);
      }
    }, []);

    const [savedFilters, setSavedFilters] = useState({});
    useEffect(() => {
      const filters = JSON.parse(sessionStorage.getItem(window));
      if (filters && Object.keys(filters).length > 0) setSavedFilters(filters);
    }, [values]);

    const {brandsOptions, feaManagersOptions, coManagersOptions, requestStatusOptions} = useRequestFilters();
    const countryOptions = useCountryIdOptionsService();

    const filterNames = useMemo(
      () => ({
        statusId: {name: t("Request status"), options: requestStatusOptions},
        requestNumber: {name: t("Request №"), value: savedFilters?.requestNumber},
        managerCoId: {name: t("CO Manager"), options: coManagersOptions},
        brandId: {name: t("Factory"), options: brandsOptions},
        proformaNumber: {name: t("Prof №"), value: savedFilters?.proformaNumber},
        invoiceNumber: {name: t("Invoice №"), value: savedFilters?.invoiceNumber},
        managerFeaId: {name: t("FEA Manager"), options: feaManagersOptions},
        countryId: {name: t("Pr country"), options: countryOptions},
        dateStart: {name: t("From"), value: dateFormat(savedFilters?.dateStart)},
        dateEnd: {name: t("To"), value: dateFormat(savedFilters?.dateEnd)},
      }),
      [
        requestStatusOptions?.length,
        savedFilters?.requestNumber,
        coManagersOptions?.length,
        brandsOptions?.length,
        savedFilters?.proformaNumber,
        savedFilters?.invoiceNumber,
        feaManagersOptions?.length,
        countryOptions?.length,
        savedFilters?.dateStart,
        savedFilters?.dateStart,
      ],
    );

    const onFilter = newFilters => {
      const {
        statusId = "",
        requestNumber = "",
        managerCoId = "",
        managerFeaId = "",
        brandId = "",
        countryId = "",
        dateStart = "",
        dateEnd = "",
        proformaNumber = "",
        invoiceNumber = "",
      } = newFilters ?? values;

      const params = checkEmptyFields({
        requestNumber,
        statusId,
        managerFeaId,
        managerCoId,
        brandId,
        countryId,
        dateStart: new Date(dateStart).toJSON() || "",
        dateEnd: new Date(dateEnd).toJSON() || "",
        proformaNumber,
        invoiceNumber,
      });

      if (Object.keys(params).length > 0) {
        setAreFiltersDefined(true);
        delayedFilter(params);
        const result = {
          statusId,
          requestNumber,
          managerCoId,
          managerFeaId,
          brandId,
          countryId,
          dateStart,
          dateEnd,
          proformaNumber,
          invoiceNumber,
        };
        setFilters(result);
        setSavedFilters(params);
      }
      if (newFilters && Object.keys(params).length === 0) {
        setAreFiltersDefined(false);
        delayedFilter(params);
        clearFilters();
        setSavedFilters(params);
      }
    };

    const updateFilterQuery = params => {
      dispatch(fetchMixedRequests({...params, window}));
    };

    const delFilters = () => {
      updateFilterQuery({window});
      clearFilters();
      const nullValues = {
        statusId: "",
        requestNumber: "",
        managerFeaId: "",
        brandId: "",
        managerCoId: "",
        dateStart: "",
        dateEnd: "",
        countryId: "",
        proformaNumber: "",
        invoiceNumber: "",
      };
      setValues(nullValues);
      setSavedFilters({});
      setAreFiltersDefined(false);
    };

    const delayedFilter = useDebouncedCallback(updateFilterQuery, 500);

    return (
      <Form style={{width: "100%", marginBottom: "10px"}}>
        <div className="fc jcfs aist w100 g1 autolabel nowrap ">
          <div style={{paddingRight: "2.5em"}}>
            <FilterButton
              onFilter={onFilter}
              delFilter={delFilters}
              questionTitle={t("Filter")}
              areFiltersDefined={areFiltersDefined}
            />
          </div>
          <BaseComponent {...props} />
        </div>
        <ChosenFiltersLine
          window={window}
          savedFilters={savedFilters}
          setSavedFilters={setSavedFilters}
          onFilter={onFilter}
          filterNames={filterNames}
        />
      </Form>
    );
  };
};

export default withRequestFilters;
