import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {deleteReq, fetchData, fetchList} from "../../../hook/axios.hook";
import {checkEmptyFields} from "../../../utilities/checkEmptyFields";

const initialState = {
  planSkuList: null,
  // planSkuList: [],

  currentSkuPlan: null,
  uploadedOverallView: [],
  addedOverallView: [],
  addedImages: [],
  addedColorImages: [],
  addedDecorImages: [],

  allCOfs: [],
  currentCOf: null,

  allCOas: [],
  currentCOa: null,

  kpFLoadingStatus: false,
  imgLoading: false,
};

export const fetchSkuPlanListForKpf = createAsyncThunk("sku/fetchSkuPlanListForKpf", async cofId => {
  const responce = fetchList(`/skuPlan/getForCof/${cofId}`, "get");

  return responce;
});
// export const fetchSkuPlanInfo = createAsyncThunk(
//     'sku/fetchSkuPlanInfo',
//     async (skuPlanId) => {
//         return fetchData(`/sku/get/${skuPlanId}`,'get')
//     }
// );

export const addSkuToKpF = createAsyncThunk("sku/addSkuToKpF", async ({cofId, idealSkuId, skuPlan}) => {
  var plan = checkEmptyFields(skuPlan);

  return fetchData(`/skuPlan/add/${idealSkuId}/${cofId}`, "post", plan);
});

export const getSkuPlanInfo = createAsyncThunk("sku/getSkuPlanInfo", async skuPlanId => {
  return fetchData(`/skuPlan/get/${skuPlanId}`, "get");
});

export const updateSkuPlan = createAsyncThunk("sku/updateSkuPlan", async ({skuPlanId, skuPlan}) => {
  // var plan = checkEmptyFields(skuPlan);

  return fetchData(`/skuPlan/update/${skuPlanId}`, "patch", skuPlan);
});

export const delSkuPlan = createAsyncThunk("sku/delSkuPlan", async skuPlanId => {
  return deleteReq(`/skuPlan/delete/${skuPlanId}`, "delete");
});

export const addSkuPlanDoc = createAsyncThunk("sku/addSkuPlanDoc", async ({cofId, formData}) => {
  return fetchData(`/cos/f/docs/add/${cofId}`, "put", formData);
});

//
export const changeSequenceNumber = createAsyncThunk("sku/changeSequenceNumber", async ({sequenceObject}) => {
  return fetchData(`/skuPlan/reorder`, "patch", sequenceObject);
});

export const uploadOverallImage = createAsyncThunk("sku/uploadOverallImage", async ({skuPlanId, formdata}) => {
  const responce = fetchData(`/skuPlan/overallView/upload/${skuPlanId}`, "put", formdata);
  return responce;
});

export const delOverallImage = createAsyncThunk("sku/delOverallImages", async imageId => {
  const responce = deleteReq(`/skuPlan/overallView/delete/${imageId}`);
  return responce;
});

export const addOverallImage = createAsyncThunk("sku/addOverallImage", async imageId => {
  return fetchData(`/skuPlan/overallView/add/${imageId}`, "patch");
});

export const removeOverallImage = createAsyncThunk("sku/removeOverallImage", async imageId => {
  return fetchData(`/skuPlan/overallView/remove/${imageId}`, "patch");
});

export const addCommonImage = createAsyncThunk("sku/addImage", async ({imageId, skuPlanId, imageType}) => {
  return fetchData(`/skuPlan/image/add/${imageId}/${skuPlanId}?imageType=${imageType}`, "patch");
});

export const removeCommonImage = createAsyncThunk("sku/removeImage", async ({imageId, skuPlanId, imageType}) => {
  return fetchData(`/skuPlan/image/remove/${imageId}/${skuPlanId}?imageType=${imageType}`, "patch");
});

export const addColorImage = createAsyncThunk("sku/addColorImage", async ({imageId, skuPlanId}) => {
  return fetchData(`/skuPlan/colorImage/add/${imageId}/${skuPlanId}`, "patch");
});

export const removeColorImage = createAsyncThunk("sku/removeColorImage", async ({imageId, skuPlanId}) => {
  return fetchData(`/skuPlan/colorImage/remove/${imageId}/${skuPlanId}`, "patch");
});

export const addDecorImage = createAsyncThunk("sku/addDecorImage", async ({imageId, skuPlanId}) => {
  return fetchData(`/skuPlan/decorImage/add/${imageId}/${skuPlanId}`, "patch");
});

export const removeDecorImage = createAsyncThunk("sku/removeDecorImage", async ({imageId, skuPlanId}) => {
  return fetchData(`/skuPlan/decorImage/remove/${imageId}/${skuPlanId}`, "patch");
});

//*** CO(f) new*/
export const fetchAllCOfs = createAsyncThunk("cof/fetchAllCOfs", async ({coId}) =>
  fetchList("/cos/f/getAll", "get", {params: {coId}}),
);
export const fetchCOf = createAsyncThunk("cof/fetchCOf", async ({cofId}) => fetchData(`/cos/f/get/${cofId}`, "get"));
export const addCOf = createAsyncThunk("cof/addCOf", async ({coId}) => fetchData(`/cos/f/add/${coId}`, "post"));
export const dublicateCOf = createAsyncThunk("cof/dublicateCOf", async ({cofId}) =>
  fetchData(`/kp/f/duplicate/${cofId}`, "post"),
);
export const updateCOf = createAsyncThunk("cof/updateCOf", async ({cofId, body}) =>
  fetchData(`/cos/f/update/${cofId}`, "patch", body),
);
export const deleteCOf = createAsyncThunk("cof/delCOf", async ({cofId}) =>
  fetchData(`/cos/f/remove/${cofId}`, "delete"),
);

//*** CO(a) new*/
export const fetchAllCOas = createAsyncThunk("coa/fetchAllCOas", async ({coId}) =>
  fetchList(`/kp/a/getForCo/${coId}`, "get"),
);
export const fetchCOa = createAsyncThunk("coa/fetchCOa", async ({coaId}) => fetchData(`/kp/a/get/${coaId}`, "get"));
export const addCOa = createAsyncThunk("coa/addCOa", async ({coId, body}) =>
  fetchData(`/kp/a/add/${coId}`, "post", body),
);
export const updateCOa = createAsyncThunk("coa/updateCOa", async ({coaId, body}) =>
  fetchData(`/kp/a/update/${coaId}`, "patch", body),
);
export const deleteCOa = createAsyncThunk("coa/deleteCOa", async ({coaId}) =>
  fetchData(`/kp/a/delete/${coaId}`, "delete"),
);

const kpfSlice = createSlice({
  name: "kpf",
  initialState,
  reducers: {
    deselect: state => {
      state.currentSkuPlan = null;
      state.uploadedOverallView = [];
    },
    clearCurrentCof: state => {
      state.currentCOf = null;
    },
    clearAllCofs: state => {
      state.allCOfs = [];
    },
    clearCurrentCoa: state => {
      state.currentCOa = null;
    },
    clearAllCoas: state => {
      state.allCOas = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSkuPlanListForKpf.pending, state => {
        state.kpFLoadingStatus = true;
      })
      .addCase(fetchSkuPlanListForKpf.fulfilled, (state, {payload}) => {
        state.kpFLoadingStatus = false;

        // state.planSkuList = payload.skuPlanList;
        state.planSkuList = payload;

        state.currentSkuPlan = null;
        state.uploadedOverallView = [];
      })

      .addCase(addSkuToKpF.fulfilled, (state, {payload}) => {
        state.planSkuList.push(payload.skuPlan);
        const {uploadedOverallView, ...skuPlan} = payload.skuPlan;
        state.currentSkuPlan = skuPlan;
        state.uploadedOverallView = uploadedOverallView;
        state.addedOverallView = skuPlan.addedOverallView;
        state.addedImages = skuPlan.addedImages;
        state.addedColorImages = skuPlan.addedColorImages;
        state.addedDecorImages = skuPlan.addedDecorImages;
      })

      .addCase(getSkuPlanInfo.fulfilled, (state, {payload}) => {
        const {uploadedOverallView, ...rest} = payload;
        state.currentSkuPlan = {...rest};
        state.uploadedOverallView = uploadedOverallView;
        state.addedOverallView = rest.addedOverallView;
        state.addedImages = rest.addedImages;
        state.addedColorImages = rest.addedColorImages;
        state.addedDecorImages = rest.addedDecorImages;
      })
      .addCase(updateSkuPlan.fulfilled, (state, {payload}) => {
        const {uploadedOverallView, ...skuPlan} = payload.skuPlan;
        state.currentSkuPlan = skuPlan;
        state.uploadedOverallView = uploadedOverallView;
        state.addedOverallView = skuPlan.addedOverallView;
        state.addedImages = skuPlan.addedImages;
        state.addedColorImages = skuPlan.addedColorImages;
        state.addedDecorImages = skuPlan.addedDecorImages;

        state.planSkuList = state.planSkuList.map(plan => {
          if (plan.skuPlanId === payload.skuPlan.skuPlanId) {
            return payload.skuPlan;
          }
          return plan;
        });
      })
      .addCase(delSkuPlan.fulfilled, (state, {payload}) => {
        state.planSkuList = payload.skuPlanList;

        state.currentSkuPlan = null;
        state.uploadedOverallView = [];
      })
      .addCase(changeSequenceNumber.fulfilled, (state, {payload}) => {
        console.log(payload);
      })
      .addCase(uploadOverallImage.pending, (state, {payload}) => {
        state.imgLoading = true;
      })
      .addCase(uploadOverallImage.fulfilled, (state, {payload}) => {
        state.uploadedOverallView = payload.uploadedOverallView;
        state.imgLoading = false;
      })
      .addCase(delOverallImage.fulfilled, (state, {payload}) => {
        state.uploadedOverallView = payload.uploadedOverallView;
      })
      .addCase(addOverallImage.fulfilled, (state, {payload}) => {
        state.addedOverallView = payload.addedOverallView;
      })
      .addCase(removeOverallImage.fulfilled, (state, {payload}) => {
        state.addedOverallView = payload.addedOverallView;
      })
      .addCase(addCommonImage.fulfilled, (state, {payload}) => {
        state.addedImages = payload.addedImages;
      })
      .addCase(removeCommonImage.fulfilled, (state, {payload}) => {
        state.addedImages = payload.addedImages;
      })
      .addCase(addColorImage.fulfilled, (state, {payload}) => {
        state.addedColorImages = payload.addedColorImages;
      })
      .addCase(removeColorImage.fulfilled, (state, {payload}) => {
        state.addedColorImages = payload.addedColorImages;
      })
      .addCase(addDecorImage.fulfilled, (state, {payload}) => {
        state.addedDecorImages = payload.addedDecorImages;
      })
      .addCase(removeDecorImage.fulfilled, (state, {payload}) => {
        state.addedDecorImages = payload.addedDecorImages;
      })

      //*** CO(f) new*/
      .addCase(fetchAllCOfs.pending, (state, {payload}) => {
        state.kpFLoadingStatus = true;
        state.allCOfs = payload;
      })
      .addCase(fetchAllCOfs.fulfilled, (state, {payload}) => {
        state.kpFLoadingStatus = false;
        state.allCOfs = payload;
      })
      .addCase(fetchCOf.fulfilled, (state, {payload}) => {
        state.currentCOf = payload;
      })
      .addCase(addCOf.fulfilled, (state, {payload}) => {
        state.currentCOf = payload.cof;
      })
      .addCase(updateCOf.fulfilled, (state, {payload}) => {
        state.currentCOf = payload.cof;
      })
      .addCase(deleteCOf.fulfilled, state => {
        state.currentCOf = null;
      })

      //*** CO(a) new*/
      .addCase(fetchAllCOas.fulfilled, (state, {payload}) => {
        state.allCOas = payload;
      })
      .addCase(fetchCOa.fulfilled, (state, {payload}) => {
        state.currentCOa = payload;
      })
      .addCase(addCOa.fulfilled, (state, {payload}) => {
        state.currentCOa = payload.coa;
      })
      .addCase(updateCOa.fulfilled, (state, {payload}) => {
        state.currentCOa = payload.coa;
      })
      .addCase(deleteCOa.fulfilled, state => {
        state.currentCOa = null;
      })

      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = kpfSlice;

export default reducer;
export const {deselect, clearOverall, clearCurrentCof, clearAllCofs, clearAllCoas, clearCurrentCoa} = actions;

export const getPlanSkuList = state => state.kpf.planSkuList;
export const getCurrentSkuPlan = state => state.kpf.currentSkuPlan;
export const getCurrentUploadedOverallView = state => state.kpf.uploadedOverallView;
export const getAddedOverallView = state => state.kpf.addedOverallView;
export const getAddedImages = state => state.kpf.addedImages;
export const getAddedColorImages = state => state.kpf.addedColorImages;
export const getAddedDecorImages = state => state.kpf.addedDecorImages;

export const getKpFLoading = state => state.kpf.kpFLoadingStatus;
export const getImgLoading = state => state.kpf.imgLoading;

export const selectAllCofs = state => state.kpf.allCOfs;
export const selectCurrentCof = state => state.kpf.currentCOf;

export const selectAllCoas = state => state.kpf.allCOas;
export const selectCurrentCoa = state => state.kpf.currentCOa;
