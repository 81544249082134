import {useTranslation} from "react-i18next";

import {DateInput, TextInput} from "../../../Inputs";

import "../../../Requests/RequestRegister/requestRegister.scss";

const FactoryInfoFields = () => {
  const {t} = useTranslation();

  return (
    <div className="fcc mb jcsa gap">
      <div className="fcc jcfs gap2x">
        <DateInput label={t("C date")} name="createdAt" labelW="8em" generalClassName="filter_item" readOnly />
        <TextInput
          label={t("created by")}
          name="creator"
          type="text"
          generalClassName="filter_item"
          width="18em"
          labelStyles={{width: "6em"}}
        />
      </div>
      <div className="fcc jcfe gap2x">
        <DateInput label={t("e date")} name="updatedAt" labelW="9em" generalClassName="filter_item" readOnly />
        <TextInput
          label={t("edited by")}
          name="modifier"
          type="text"
          width="18em"
          labelStyles={{width: "7em"}}
          generalClassName="filter_item"
        />
      </div>
    </div>
  );
};

export default FactoryInfoFields;
