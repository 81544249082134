import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import {getCurrentUser} from "../../../slices/UserSlice";
import {checkEmptyFilterFields} from "../../../utilities/checkEmptyFilterFields";
import {dateFormat} from "../../../utilities/dateFormat";
import {useTabsContext} from "../../conteiners/tabs/useTabs";
import {
  fetchAllDirectives,
  fetchAllFinStatuses,
  fetchDirective,
  fetchDirectiveFilters,
  selectAllDirectives,
  selectAllStatuses,
  selectDirectiveFilters,
  selectFinSettingsLoading,
  setCreatedDirective,
} from "../../FinBlock/FinSettings/FinSettingsSlice";
import {DateInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import ProjectDirectiveListView from "../ProjectView/ProjectDirectiveListView/ProjectDirectiveListView";
import {ProjectView} from "../ProjectView/ProjectView";

import KpCreateMainDirectiveD from "./KpCreateMainDirectiveD/KpCreateMainDirectiveD";
import OrderRegister from "./OrderRegister/OrderRegister";
import {PROJECT_DIRECTIVE_TABLE_COLUMNS} from "./constants";

import "./projectDirectiveLIstStyles.scss";

const useProjectDirectiveList = () => {
  const PROJECT_DIRECTIVE_LIST_WINDOW_NAME = "ProjectDirectiveList";
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();

  const {addNewTabCustom} = useTabsContext();

  const loading = useSelector(selectFinSettingsLoading);
  const statuses = useSelector(selectAllStatuses);
  const allDirectives = useSelector(selectAllDirectives);
  const fetchedFilters = useSelector(selectDirectiveFilters);
  const currentUser = useSelector(getCurrentUser);
  const currentUserId = currentUser?.id;

  const [currentDirectiveId, setCurrentDirectiveId] = useState(null);

  const [filterFields, setFilterFields] = useState({});

  // useEffect(() => {
  //   if (!fetchedFilters || fetchedFilters.length === 0) {
  //     dispatch(fetchDirectiveFilters());
  //     dispatch(fetchAllFinStatuses());
  //     return;
  //   }
  //   dispatch(fetchAllDirectives());
  // }, [Object.keys(fetchedFilters ?? {}).length]);

  const isCurrentDirectiveCreatedByCurrentUser =
    allDirectives?.find(directive => directive.directiveId === currentDirectiveId)?.creatorId === currentUserId;

  const clientNumberOptions =
    fetchedFilters?.clients?.map(({clientNumber, clientId}) => ({title: clientNumber, value: clientId})) ?? [];
  const coNumberOptions = fetchedFilters?.cos?.map(({coNumber, coId}) => ({title: coNumber, value: coId})) ?? [];
  const operationTypeOptions =
    fetchedFilters?.operationTypes?.map(({operationTypeId, nameEng, nameUkr}) => ({
      value: operationTypeId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];
  const statusOptions =
    statuses?.map(({statusId, nameEng, nameUkr}) => ({
      value: statusId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];
  const sumOptions = fetchedFilters?.sumPlans?.map(sum => ({title: sum, value: sum})) ?? [];
  const PAYER_DATA = Object.freeze({
    TITLE: "pName",
    EDRPOUO: "edrpouCode",
    P_ACCOUNT: "currentAccount",
  });
  const payerDataTypeOptions = [
    {title: t("Title"), value: PAYER_DATA.TITLE},
    {title: t("EDRPOUO"), value: PAYER_DATA.EDRPOUO},
    {title: t("p/acc"), value: PAYER_DATA.P_ACCOUNT},
  ];
  const [payerDataType, setPayerDataType] = useState(null);

  const payerDataOptions =
    payerDataType === PAYER_DATA.TITLE
      ? fetchedFilters?.payerNames?.map(name => ({title: name, value: name}))
      : payerDataType === PAYER_DATA.EDRPOUO
      ? fetchedFilters?.payerEdrpouCodes?.map(code => ({title: code, value: code}))
      : payerDataType === PAYER_DATA.P_ACCOUNT
      ? fetchedFilters?.payerAccounts?.map(accountName => ({title: accountName, value: accountName}))
      : [];

  function onOpenCreateInnerDirectieveModalBtnClick() {
    addNewTabCustom({
      TabComponent: KpCreateMainDirectiveD,
      controlled: true,
      title: "Directive №",
      tabIdName: "createOrderTab",
    });
  }

  const onMakeEffect = ({value, name}) => {
    const field = {[name]: value};
    setFilterFields(prev => ({...prev, ...field}));
  };
  const onFilterDelete = value => {
    if (!value) setFilterFields({});
    else setFilterFields(prev => ({...prev, [value]: ""}));
  };

  useEffect(() => {
    dispatch(fetchDirectiveFilters(checkEmptyFilterFields(filterFields)));
  }, [filterFields]);

  const filtersLayout = (
    <div
      className="projectDireciveListFiltersContainer"
      // style={{
      //   gridTemplateAreas: '"area1 area2 area3 area4 area5" "area1 area2 area3 area3 area4"',
      // }}
    >
      <TextInput
        name="directiveNumber"
        label={t("Directive №")}
        style={{
          justifySelf: "stretch",
          gridArea: "directiveNumber",
        }}
        autolabel
        onBlur={(_, value) => {
          onMakeEffect({value, name: "directiveNumber"});
        }}
      />
      <Select
        name="clientId"
        label={t("Client №")}
        options={clientNumberOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "clientId",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "clientId"});
        }}
      />
      <Select
        name="coId"
        label={t("CO №")}
        options={coNumberOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "coId",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "coId"});
        }}
      />
      <Select
        name="operationTypeId"
        label={t("Transaction type")}
        options={operationTypeOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "operationTypeId",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "operationTypeId"});
        }}
      />
      <DateInput
        name="dateStart"
        style={{gridArea: "dateStart"}}
        label={t("Period From")}
        onDateChange={value => {
          onMakeEffect({value, name: "dateStart"});
        }}
      />
      <Select
        name="statusId"
        label={t("Status")}
        options={statusOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "statusId",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "statusId"});
        }}
      />
      <Select
        name="sumPlan"
        label={t("Sum (s/t)")}
        options={sumOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "sumPlan",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "sumPlan"});
        }}
      />
      <div className="row gap5 border" style={{gridArea: "payerData", justifySelf: "stretch", padding: "2px"}}>
        <Select
          name="payerDataType"
          label={t("Payer data")}
          options={payerDataTypeOptions}
          inputClassName="fGrow"
          className="fGrow"
          generalClassName="fGrow"
          makeEffect={option => {
            setPayerDataType(option?.value || null);
          }}
        />
        <Select
          name="payerData"
          label=" "
          options={payerDataOptions}
          style={{justifySelf: "stretch"}}
          makeEffect={option => {
            onMakeEffect({value: option?.value, name: formPayerDataParamName(payerDataType)});
          }}
        />
      </div>

      <DateInput
        name="dateEnd"
        label={t("To")}
        style={{justifyContent: "flex-end", gridArea: "dateEnd"}}
        makeEffect={value => {
          onMakeEffect({value, name: "dateEnd"});
        }}
      />
    </div>
  );

  function formPayerDataParamName(payerDataType) {
    switch (payerDataType) {
      case PAYER_DATA.TITLE:
        return "name";

      case PAYER_DATA.EDRPOUO:
        return "edrpouCode";

      case PAYER_DATA.P_ACCOUNT:
        return "currentAccount";
    }
  }

  function getFilterData(filters) {
    if (!filters) {
      // dispatch(fetchDirectiveFilters());
      // dispatch(fetchAllFinStatuses());
      dispatch(fetchAllDirectives());
      return;
    }

    const {
      directiveNumber,
      statusId,
      operationTypeId,
      sumPlan,
      clientId,
      dateStart,
      dateEnd,
      payerDataType,
      payerData,
      coId,
    } = filters;

    const payerDataParamName = formPayerDataParamName(payerDataType);

    dispatch(
      fetchAllDirectives({
        directiveNumber,
        coId,
        statusId,
        operationTypeId,
        sumPlan,
        clientId,
        dateStart,
        dateEnd,
        [payerDataParamName]: payerData,
      }),
    );
  }

  const projectDirectiveTableColumns = useColumns(PROJECT_DIRECTIVE_TABLE_COLUMNS);
  const projectDirectiveTableData =
    allDirectives?.map(
      (
        {
          directiveId,
          directiveNumber,
          createdAt,
          fulfilBy,
          sumFact,
          sumPlan,
          co: {coNumber, project},
          operationType,
          orders,
        },
        index,
      ) => ({
        id: directiveId,
        number: ++index,
        date: dateFormat(createdAt),
        dateTo: dateFormat(fulfilBy),
        sumPlan: sumPlan ?? "-",
        sumFact: sumFact ?? "0.00",
        directiveNumber,
        operationType: language === "en" ? operationType.nameEng : operationType.nameUkr,
        clientNumber: project?.client?.clientNumber ?? "-",
        coNumber,
        payerName: orders[0].outsideParty?.name ?? "-",
        payerEDRPOU: orders[0].outsideParty?.edrpouCode ?? "-",
        payerAccount: orders[0].outsideParty?.currentAccount ?? "-",
        pendingClarification:
          orders[0].outsideParty &&
          orders[0].outsideParty?.name &&
          orders[0].outsideParty?.edrpouCode &&
          orders[0].outsideParty?.currentAccount
            ? ""
            : "✅",
      }),
    ) ?? [];

  function onProjectDirectiveTableSelect(id) {
    setCurrentDirectiveId(id);
  }

  function onOpenDirectiveBtnClick() {
    if (!currentDirectiveId) return;

    dispatch(fetchDirective({directiveId: currentDirectiveId})).then(resp => {
      dispatch(setCreatedDirective(resp.payload));
      addNewTabCustom({
        TabComponent: KpCreateMainDirectiveD,
        controlled: true,
        title: `Directive №`,
        tabIdName: `Directive №${currentDirectiveId}`,
        viewOnly: true,
      });
      addNewTabCustom({
        TabComponent: OrderRegister,
        title: "Directive`s Order register",
        tabIdName: `Directive №${currentDirectiveId} orders`,
      });
    });
  }

  function onOpenProjectBtnClick() {
    dispatch(fetchDirective({directiveId: currentDirectiveId}));
    addNewTabCustom({
      TabComponent: ProjectView,
      title: "Project View",
    });
  }

  function onPrintReceiptBtnClick() {}

  function onCancelDirectiveBtnClick() {
    if (!isCurrentDirectiveCreatedByCurrentUser) return;

    addNewTabCustom({
      TabComponent: ProjectDirectiveListView,
      title: "Directive №",
      tabIdName: "viewOrderTabOnCancel",
    });
  }

  return {
    getFilterData,
    onFilterDelete,
    projectDirectiveTableColumns,
    projectDirectiveTableData,
    onProjectDirectiveTableSelect,
    onOpenCreateInnerDirectieveModalBtnClick,
    onOpenDirectiveBtnClick,
    onOpenProjectBtnClick,
    onPrintReceiptBtnClick,
    onCancelDirectiveBtnClick,
    currentDirectiveId,
    loading,
    isCurrentDirectiveCreatedByCurrentUser,
    filtersLayout,
    PROJECT_DIRECTIVE_LIST_WINDOW_NAME,
    formPayerDataParamName,
    setFilterFields,
  };
};

export default useProjectDirectiveList;
