import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import NewPdfViewer from "../../Documents/DocumentView/NewPdfViewer";
import ContractDocsScreenShotsTables from "../ContractDocsScreenShotsTables/ContractDocsScreenShotsTables";
import DirectiveFilters from "../DirectiveFilters";
import FinStatus from "../FinStatus";
import OrderTypeComposedPart from "../OrderTypeComposedPart/OrderTypeComposedPart";

import useOrderRegister from "./useOrderRegister";

const OrderRegister = () => {
  const {
    onExitBtnClick,
    tableColumns,
    tableData,
    onTableSelect,
    onPrintOrderBtnClick,
    onRowDoubleClick,
    currentOrderId,
  } = useOrderRegister();

  return (
    <div className="fcCol h100 w100 pb aist">
      <DirectiveFilters readOnly />
      <Divider />
      <div className="grid2ColSecondMax gap w100 fGrow mb0_5" style={{gridTemplateColumns: "30% 1fr"}}>
        <OrderTypeComposedPart readOnly>
          <div className="row w100 jcfe">
            <FunctionButton onClick={onExitBtnClick} name="Exit" autoWidth />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2">
          <FinStatus readOnly />
          <ReactTable
            columns={tableColumns}
            defaultData={tableData}
            current={currentOrderId}
            onSelect={onTableSelect}
            className="fGrow mb scrollX"
            style={{height: "unset"}}
            onRowDoubleClick={onRowDoubleClick}
          />
          <div className="row jcfe w100">
            <FunctionButton
              onClick={onPrintOrderBtnClick}
              name="Print an order"
              autoWidth
              disabled
              className="unfinishedLayout"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderRegister;
