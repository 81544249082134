import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";
import translit from "ua-en-translit";

import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import ImgBox from "../../../../Inputs/fileSelect/ImgBox/ImgBox";
import ImgFilePreview from "../../../../Inputs/fileSelect/preview/ImgFilePreview";
import {getSkuRequestAddedImages} from "../../../../Requests/RequestsSlice";
import {
  addColorImages,
  addDecorImages,
  addDrawingImages,
  addSkuImages,
  delColorImages,
  delDecorImages,
  delDrawingImages,
  delSkuImages,
  getCurrentSku,
  getImagesLoading,
} from "../../../../SKU/SkuSlice";
import {findImageId, processImages} from "../../../../SKU/VED/constants/functions";
import {getAddedImages, getCurrentSkuPlan} from "../../KpFinSlice";

import useAddingImageHandlers from "./hooks/useAddingImageHandlers.js";
import plus from "./plus.png";

const ImageBlock = ({label, selectorType, imageType}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {action, selector, currentSkuObjIdName, skuObjId, addedImagesKey} = useAddingImageHandlers(imageType);

  const currentSkuPlan = useSelector(getCurrentSkuPlan);

  const addedCommonImages = useSelector(currentSkuPlan ? getAddedImages : getSkuRequestAddedImages);

  const currentSku = useSelector(getCurrentSku);
  const imagesFromSku = useSelector(selectorType);
  const imgLoading = useSelector(getImagesLoading);

  const updatedImagesFromSku = processImages(imagesFromSku);

  const {setFieldValue} = useFormikContext();
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  const onActiveImgChange = index => {
    setCurrentImgIndex(index);
  };

  const updateImages = () => {
    if (updatedImagesFromSku !== null) {
      const imageFromIndex = updatedImagesFromSku[currentImgIndex];

      const matchId = findImageId(imageFromIndex, imagesFromSku);

      if (!matchId || selector.some(item => item.imageUrl === imageFromIndex)) {
        Swal.fire({
          title: "",
          text: `${t("Same image cannot be added twice")}`,
          icon: "warning",
          confirmButtonText: "Ок",
          customClas: {
            popup: "zindex",
            container: "zindex",
            htmlContainer: "zindex",
          },
        });
        return;
      }

      if (matchId) {
        dispatch(
          action({
            imageId: matchId,
            [currentSkuObjIdName]: skuObjId,
            ...(imageType !== "Color" &&
              imageType !== "Decor" && {imageType: imageType === "Common" ? "image" : "drawing"}),
          }),
        ).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            setFieldValue(addedImagesKey, processImages(res.payload[addedImagesKey]));
          }
        });
      }
    }
  };

  const addImg = (arrFiles, type) => {
    var formdata = new FormData();
    var i = 0,
      file,
      filename;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      filename = translit(file.name);
      formdata.append("image", file, filename);
    }

    const dataObj = {skuId: currentSku.skuId, formdata};

    switch (type) {
      case "Common":
        dispatch(addSkuImages(dataObj));
        break;
      case "Decor":
        dispatch(addDecorImages(dataObj));
        break;
      case "Color":
        dispatch(addColorImages(dataObj));
        break;
      case "Drawings":
        dispatch(addDrawingImages(dataObj));
        break;
      default:
        break;
    }
  };
  function onDell(imgUrl, type) {
    const necessaryImageId = findImageId(imgUrl, imagesFromSku);

    switch (type) {
      case "Common":
        dispatch(delSkuImages(necessaryImageId));
        break;
      case "Drawings":
        dispatch(delDrawingImages(necessaryImageId));
        break;
      case "Decor":
        dispatch(delDecorImages(necessaryImageId));
        break;
      case "Color":
        dispatch(delColorImages(necessaryImageId));
        break;
      default:
        break;
    }
  }

  const plusRender = type => {
    if (type === "Common" || type === "Drawings") {
      return addedCommonImages?.length >= 2 ? null : plus;
    } else if (type === "Color") {
      return plus;
    } else {
      return plus;
    }
  };

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <label className="label" style={{marginBottom: "12px"}}>
        {t(label)}
      </label>
      <div className="kpf_img_box" style={{position: "relative"}}>
        <ImageButton
          src={plusRender(imageType)}
          alt="plus"
          width={1.5}
          height={1.4}
          onClick={() => {
            updateImages(currentImgIndex);
          }}
          className="button-add"
        />
        <div style={{position: "absolute", height: "100%"}}>
          <ImgBox
            imgs={updatedImagesFromSku || []}
            width={"10em"}
            height={"10em"}
            onActiveImgChange={onActiveImgChange}
          ></ImgBox>
          <ImgFilePreview
            imgs={updatedImagesFromSku || []}
            delFunc={file => {
              onDell(file, imageType);
            }}
            addFunc={file => {
              addImg(file, imageType);
            }}
            disable={!currentSku}
            necessaryCrop={true}
            loading={imgLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageBlock;
