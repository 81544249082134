import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchProjectFilters, getProjectFilters} from "../../ProjectSlice";

const useProjectFilters = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const filtersOptions = useSelector(getProjectFilters);

  useEffect(() => {
    dispatch(fetchProjectFilters());
  }, []);

  const clientsOptions =
    filtersOptions?.clients?.map(client => ({
      title: client.clientNumber,
      value: client.clientId,
    })) || [];
  const coManagersOptions =
    filtersOptions?.coManagers?.map(coManager => ({
      title: coManager.name,
      value: coManager.managerCoId,
    })) || [];
  const designNumbersOptions = filtersOptions?.designNumbers.map(design => ({}));
  const designersOptions =
    filtersOptions?.designers?.map(designer => ({
      title: designer.name,
      value: designer.designerId,
    })) || [];
  const managersOptions =
    filtersOptions?.managers?.map(manager => ({
      title: manager.name,
      value: manager.managerId,
    })) || [];
  const coAgentOptions =
    filtersOptions?.agents?.map(agent => ({
      title: agent.name,
      value: agent.agentId,
    })) || [];
  const projectNumbersOptions = filtersOptions?.projectNumbers || [];

  return {clientsOptions, coManagersOptions, designersOptions, managersOptions, projectNumbersOptions, coAgentOptions};
};

export default useProjectFilters;
