import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {t} from "i18next";

import {ProjectDocButton} from "../../../utilities/Buttons/StatusFunctionButton/ProjectDocButton";
import Divider from "../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {useTabsContext} from "../../conteiners/tabs/useTabs";
import {DateInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import {getCurrentProject} from "../ProjectSlice";

import ProjectDocuments from "./Documents/ProjectDocuments";
import useProjectFilters from "./ProjectFiltersForm/ProjectFilters";
import CoSection from "./ProjectPageSections/CoSection/CoSection";
import ProjectSection from "./ProjectPageSections/ProjectSection";

import "./projectSecond.scss";

const ProjectSecondVersion = () => {
  const {t} = useTranslation();
  const {addNewTab, tabsCount, setCurrentTabIndex} = useTabsContext();
  const {clientsOptions, coManagersOptions, designersOptions, managersOptions, coAgentOptions} = useProjectFilters();
  const currentProject = useSelector(getCurrentProject);

  const additionalCloseCondition = () => {
    return new Promise((resolve, reject) => {
      const saveBtns = document.getElementsByClassName("documentSaveBtn");
      for (let i = 0; i < saveBtns.length; i++) {
        if (saveBtns[i].disabled === false) reject();
      }
      resolve();
    });
  };

  const onDocumentsOpen = () => {
    if (tabsCount === 1)
      addNewTab({
        TabComponent: ProjectDocuments,
        title: t("project docs label", {projectNumber: currentProject.projectNumber}),
        controlled: true,
        additionalCloseCondition: additionalCloseCondition,
        // dirtyFormCondition: true,
      });
    else {
      setCurrentTabIndex(1);
    }
  };

  return (
    <>
      <div className="form_wrapper">
        <SessionFiltersContainer window={"Project"}>
          <div className="relative">
            <Filters>
              <div className="fc jcsb g1 autolabel" style={{paddingTop: "0.7em"}}>
                <TextInput label={t("Project") + " №"} name="projectNumber" style={{width: "20em"}} />
                <Select label={t("Client №")} name="clientId" options={clientsOptions} style={{width: "20em"}} />
                <Select label={t("Designer")} name="designerId" options={designersOptions} style={{width: "20em"}} />
                <TextInput label={t("Project Design")} name="designNumber" style={{width: "20em"}} />

                <Select label={t("Manager")} name="managerId" options={managersOptions} style={{width: "20em"}} />
                <Select
                  label={t("CO Manager")}
                  name="managerCoId"
                  options={coManagersOptions}
                  style={{width: "20em"}}
                />

                <Select label={t("CO Agent")} name="agentId" options={coAgentOptions} style={{width: "20em"}} />
                <TextInput label={t("Address")} name="address" style={{width: "20em"}} />
              </div>
              <div className="period-wrapper" style={{paddingTop: "0.7em"}}>
                {t("Period")}
                <div className="request-date-wrapper">
                  <DateInput label={t("From")} name="dateStart" />
                  <DateInput label={t("To")} name="dateEnd" />
                </div>
              </div>
            </Filters>

            <div style={{position: "absolute", top: "3.4em", left: "0.3em"}}>
              <ProjectDocButton
                onClick={onDocumentsOpen}
                disabled={!currentProject}
                style={{cursor: currentProject ? "pointer" : "not-allowed"}}
                title={currentProject ? "Open documents" : "Select project to open documents"}
              />
            </div>
          </div>
          <Divider />

          <div className="project_form">
            <div className="two-column-page__container w100 pb0_5">
              <ProjectSection />
              <CoSection />
            </div>
          </div>
        </SessionFiltersContainer>
      </div>
    </>
  );
};
export default withTabs({
  isMainTabControlled: true,
  formInitialValues: {
    clientBalance: "0",
  },
  isClosableTabs: true,
  label: "Project",
})(ProjectSecondVersion);
