import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import {t} from "i18next";
import Swal from "sweetalert2";

import {fetchData, fetchList, sendRequest} from "../../../../../hook/axios.hook";
import Divider from "../../../../../utilities/Divider/Divider";
import {makeFilterManager} from "../../../../../utilities/makeFilterManager/makeFilterManager";

import {dateFormat} from "./../../../../../utilities/dateFormat";
import CreatePackage from "./utils/CreatePackage";
import PackageFilters from "./utils/filters";
import TransactionTamplatesList from "./utils/TransactionTamplatesList";

const CreatingBatchModal = ({setCouldModalClose}) => {
  const [packagesData, setPackagesData] = useState([]);
  const [packageTransactionsData, setPackageTransactionsData] = useState([]);

  const [transactionPackageId, setTransactionPackageId] = useState(null);
  const [transactionPackage, setTransactionPackage] = useState(null);

  const formatTransactionTemplatesData = (temp, index) => ({
    number: index + 1,
    id: temp.transactionTemplateId,
    date: dateFormat(temp.createdAt),
    debitBalance: temp.debitBalanceId,
    debitBalanceType: temp.debitBalanceSheet?.type.balanceTypeName,
    creditBalance: temp.creditBalanceId,
    creditBalanceType: temp.creditBalanceSheet?.type.balanceTypeName,
    paymentPurpose: temp.paymentPurpose,
    debitBalanceName: temp.debitBalanceSheet?.balanceName,
    creditBalanceName: temp.creditBalanceSheet?.balanceName,
    note: temp.note,
  });

  useEffect(() => {
    if (transactionPackageId) {
      fetchList(`/finance/transactionTemplates/packages/get/${transactionPackageId}`).then(res => {
        const data = res.transactionTemplates.map(formatTransactionTemplatesData);
        setPackageTransactionsData(data);
        setTransactionPackage(res);
      });
    } else {
      setPackageTransactionsData([]);
      setTransactionPackage(null);
    }
  }, [transactionPackageId]);

  const fetchTransactionTemplates = params => {
    fetchList(`/finance/transactionTemplates/getAll`, "get", {params}).then(response => {
      const data = response.map((res, index) => ({
        number: index + 1,
        id: res.transactionTemplateId,
        packageId: res.transactionPackageId,
        packageName: res.transactionPackage?.packageName,
        packageCode: res.transactionPackage?.packageCode,
        date: dateFormat(res.createdAt),
        debitBalance: res.debitBalanceId,
        debitBalanceType: res.debitBalanceSheet?.type.balanceTypeName,
        creditBalance: res.creditBalanceId,
        creditBalanceType: res.creditBalanceSheet?.type.balanceTypeName,
        paymentPurpose: res.paymentPurpose,
        debitBalanceName: res.debitBalanceSheet?.balanceName,
        creditBalanceName: res.creditBalanceSheet?.balanceName,
        note: res.note,
      }));
      setPackagesData(data);
    });
  };

  const onFilter = values => {
    let params = {};
    for (let fieldName in values) {
      if (fieldName === "window") return;
      params[fieldName.slice(1)] = values?.[fieldName];
    }
    fetchTransactionTemplates(params);
  };

  const createNewPackage = () => {
    sendRequest(`/finance/transactionTemplates/packages/add/`, "post")
      .then(res => {
        setTransactionPackageId(res.transactionPackageId);
      })
      .catch(() => {});
  };

  const addTemplate = template => {
    setPackageTransactionsData(prev => [...prev, formatTransactionTemplatesData(template, prev.length)]);
  };
  const deletePackage = () => {
    Swal.fire({
      title: t("Sure"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        fetchData(`/finance/transactionTemplates/packages/remove/${transactionPackageId}`, "delete")
          .then(() => {
            setPackagesData(prev => {
              return prev.filter(tpackage => tpackage.packageId !== transactionPackageId);
            });
            setTransactionPackageId(null);
          })
          .catch(() => {});
      }
    });
  };

  const savePackage = (transactionPackageId, packageGroupId, packageName) => {
    sendRequest(`/finance/transactionTemplates/packages/save/${transactionPackageId}/${packageGroupId}`, "patch", {
      packageName,
    })
      .then(res => {
        const chosenFilters = makeFilterManager("ACC_POSTING_PACKAGE").getFilters();
        onFilter(chosenFilters);
        setTransactionPackageId(null);
      })
      .catch(() => {});
  };

  const deleteLastTemplate = () => {
    Swal.fire({
      title: t("Sure"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        fetchData(`/finance/transactionTemplates/removeLast/${transactionPackageId}`, "delete")
          .then(res => {
            const data = res.transactionTemplates.map(formatTransactionTemplatesData);
            setPackageTransactionsData(data);
            setTransactionPackage(res);
          })
          .catch(() => {});
      }
    });
  };

  const transactionTamplatesListDisabled =
    transactionPackage && (!transactionPackage.packageCode || !transactionPackage.packageName);

  return (
    <>
      <Formik initialValues={{packageCode: "", paymentPurpose: "", note: ""}}>
        <Form className="fc col fGrow" style={{flex: "1 1", paddingTop: "10px"}}>
          <CreatePackage
            transactionPackage={transactionPackage}
            setTransactionPackageId={setTransactionPackageId}
            data={packageTransactionsData}
            createNewPackage={createNewPackage}
            addTemplate={addTemplate}
            deletePackage={deletePackage}
            // checkPackage={checkPackage}
            setCouldModalClose={setCouldModalClose}
            savePackage={savePackage}
            deleteLastTemplate={deleteLastTemplate}
          />
          <Divider />
          <div
            style={{
              width: "100%",
              flexGrow: "1",
              display: "flex",
              flexDirection: "column",
              opacity: transactionTamplatesListDisabled ? "0.7" : "1",
              pointerEvents: transactionTamplatesListDisabled ? "none" : "auto",
            }}
          >
            <PackageFilters fetchPackages={onFilter} />
            <TransactionTamplatesList setTransactionPackageId={setTransactionPackageId} data={packagesData} />
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default CreatingBatchModal;
