import {Route, Routes} from "react-router-dom";

import DevIT from "../Components/DevIT/DevIT";

const DevITPage = () => {
  return (
    <Routes>
      <Route path="/" element={<DevIT />} />
    </Routes>
  );
};

export default DevITPage;
