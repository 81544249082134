export const FINBLOCK_OPERATION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
    maxSize: 25,
  },
  {
    header: "Fav",
    accessorKey: "favourite",
    size: 25,
    maxSize: 25,
    cell: data => {
      const {isFavourite, onChange, disabled} = data.cell.getValue();

      return (
        <div className="row aic jcc">
          <input
            type="checkbox"
            onChange={e => {
              e.stopPropagation();
              onChange(e);
            }}
            checked={isFavourite}
            disabled={disabled}
          />
        </div>
      );
    },
  },
  {
    header: "Operation code",
    accessorKey: "operationCode",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },

  // {
  //   header: "Operation type",
  //   accessorKey: "operationType",
  //   // size: 100,
  // },
  {
    header: "Operation group",
    accessorKey: "operationGroup",
    size: 80,
    maxSize: 80,
    isSortable: true,
  },
  // {
  //   header: "Account №",
  //   accessorKey: "accountNumber",
  //   // size: 100,
  // },
  // {
  //   header: "Currency",
  //   accessorKey: "currency",
  //   // size: 60,
  // },
  {
    header: "Transaction type wide",
    accessorKey: "operationName",
    size: 200,
    isSortable: true,
  },
]);

export const ACCOUNT_OWNER_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
    maxSize: 25,
  },
  {
    header: "Account owner code",
    accessorKey: "accountownerCode",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Account owner wide",
    accessorKey: "accountOwner",
    size: 170,
    isSortable: true,
  },
]);
