import {t} from "i18next";

import {MoneyInput} from "../../../../../Inputs";

const RecipientCoBalance = () => {
  return (
    <div className="row w100 aibl">
      <span>{t("Recipient") + ":"}</span>
      <MoneyInput name="recipientCoBalanceComposed" label={t("CO Balance")} autolabel width="8em" readOnly />
    </div>
  );
};

export default RecipientCoBalance;
