import gridIcon from "../../../images/icons/gridIcon.png";
import ToolTip from "../../ToolTip";

export const FinblockGrigButton = ({disabled, title = "Open Register", ...props}) => {
  return (
    <ToolTip title={title}>
      <button type="button" className="status-button" disabled={disabled} {...props}>
        <img src={gridIcon} alt="grid" width="20em" height="20em" draggable="false" />
      </button>
    </ToolTip>
  );
};
