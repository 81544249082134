import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchAllCurrency, selectAllCurrency} from "../../FinBlock/FinSettings/FinSettingsSlice";
import {Select} from "../SelectField";
import {useFormikContext} from "formik";

const onlyUSDandEURCurrency = [
  {
    currencyId: 978,
    shortName: "EUR",
    fullName: "Євро",
  },
  {
    currencyId: 840,
    shortName: "USD",
    fullName: "Долар США",
  },
];

const CurrencySelect = ({
  label = "Currency*",
  noLabel = false,
  name = "currencyId",
  question,
  disabled = false,
  readOnly = false,
  makeEffect = () => {},
  fullName = false,
  onlyUSDandEUR = false,
  className,
  width = "7em",
  required = false,
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {values, handleChange} = useFormikContext();

  const allCurrency = useSelector(selectAllCurrency);
  const currency = onlyUSDandEUR ? onlyUSDandEURCurrency : allCurrency;
  const currencyOptions =
    currency?.map(currency => ({
      title: fullName ? currency.fullName : currency.shortName,
      value: currency.currencyId,
    })) ?? [];

  useEffect(() => {
    if (!allCurrency?.length && !onlyUSDandEUR) {
      dispatch(fetchAllCurrency());
    }
  }, [allCurrency?.length]);

  return (
    <Select
      label={noLabel ? "" : t(label)}
      name={name}
      autolabel
      options={currencyOptions}
      question={t(question) ?? null}
      disabled={disabled}
      makeEffect={makeEffect}
      readOnly={readOnly}
      generalClassName={className}
      width={width}
      required={required}
      value={values.name}
      onChange={handleChange}
    />
  );
};

export default CurrencySelect;
