import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import {currencyOptions} from "../../../../utilities/currencyOptions";
import {Select} from "../../../Inputs/SelectField";
import SkuPriceInput from "../../SkuPriceInput";

export const PRICES_TYPES = Object.freeze({SKU_VED_FORM: "skuVedForm"});

const Prices = ({mayEdit = false, type}) => {
  const {t} = useTranslation();

  const [procents, setProcents] = useState([]);

  const {values} = useFormikContext();
  const {listPrice, minimumPrice, settlementPrice, costPrice, retailPrice, discountPrice} = values;

  useEffect(() => {
    var proc = [100];
    proc.unshift((settlementPrice * 100) / minimumPrice);
    proc.unshift((listPrice * 100) / minimumPrice);
    setProcents(proc);
  }, [listPrice, minimumPrice, settlementPrice]);

  const getProcents = price => {
    return listPrice ? (price * 100) / listPrice : "";
  };

  return (
    <div>
      {type === PRICES_TYPES.SKU_VED_FORM ? (
        <div className="row gap jcfs aibl">
          <Select label={t("SKU price")} name="currencyId" options={currencyOptions} />
          <span>%%</span>
        </div>
      ) : (
        <label> {t("Price characteristics:")}</label>
      )}
      <table className="sku_price__table">
        <tbody>
          {/* <tr>
            <th style={{width: "40%", textAlign: "left", textDecoration: "underline"}}></th>
            <th style={{width: "30%"}}></th>
            <th style={{width: "30%", textAlign: "center"}}></th>
          </tr> */}
          <SkuPriceInput
            id="1"
            label={t("listPrice") + "*"}
            mayEdit={mayEdit}
            price={"listPrice"}
            priceProc={"%"}
            verify={false}
            control={"priceCheckUser"}
          />
          <SkuPriceInput
            id="2"
            label={t("discPrice")}
            price={"discountPrice"}
            priceProc={listPrice}
            verify={false}
            mayEdit={mayEdit}
            control={"settlementPriceCheckUser"}
          />
          <SkuPriceInput
            id="3"
            label={t("costPrice")}
            mayEdit={mayEdit}
            price={"costPrice"}
            priceProc={listPrice}
            verify={false}
            control={""}
          />
          <SkuPriceInput
            id="4"
            mayEdit={mayEdit}
            label={t("RR price") + "*"}
            price={"retailPrice"}
            priceProc={listPrice}
            verify={false}
            control={""}
          />
          <SkuPriceInput
            id="5"
            mayEdit={mayEdit}
            label={t("Min price")}
            price={"minimumPrice"}
            priceProc={listPrice}
            verify={false}
            control={""}
          />
        </tbody>
      </table>
    </div>
  );
};

Prices.propTypes = {mayEdit: PropTypes.bool};

export default Prices;
