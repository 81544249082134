export const BUTTON_COLORS = Object.freeze({
  BLUE: "blue",
  RED: "red",
  TRANSPARENT: "transparent",
  ORANGE: "orange",
});

export const BUTTON_TYPES = Object.freeze({
  SUBMIT: "submit",
  BUTTON: "button",
});
