import {FIELD_TYPES} from "../../../Filters/Filters";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {CASH_REGISTR_TABLE_COLUMNS} from "./constants";

const useCashRegistrModal = () => {
  const clientNumberOptions = [];
  const orderTypeOptions = [];
  const orderNumberOptions = [];
  const roleOptions = [];
  const fullNameOptions = [];

  const filterFields = [
    {type: FIELD_TYPES.TEXT, name: "requestNumber", label: "Request №"},
    {
      type: FIELD_TYPES.SELECT,
      name: "clientNumber",
      label: "Client №",
      options: clientNumberOptions,
      style: {width: "20em"},
    },
    {type: FIELD_TYPES.TEXT, name: "sum", label: "Sum", style: {width: "8em"}},
    {type: FIELD_TYPES.TEXT, name: "operationType", label: "Transaction type", style: {width: "20em"}},
    {type: FIELD_TYPES.DATE, name: "dateFrom", label: "Period From"},
    {type: FIELD_TYPES.NEW_LINE},
    {type: FIELD_TYPES.SELECT, name: "orderType", label: "Order type", options: orderTypeOptions},
    {
      type: FIELD_TYPES.SELECT,
      name: "orderNumber",
      label: "Order n",
      options: clientNumberOptions,
      style: {width: "20em"},
    },
    {type: FIELD_TYPES.SELECT, name: "Role", label: "Role", options: roleOptions, style: {width: "18em"}},
    {type: FIELD_TYPES.SELECT, name: "fullName", label: "Full Name", options: fullNameOptions},
    {type: FIELD_TYPES.DATE, name: "dateTo", label: "To"},
  ];

  const cashRegistrTableColumns = useColumns(CASH_REGISTR_TABLE_COLUMNS);
  const cashRegistrTableData = [];

  function onCashRegistrTableSelect() {}
  function getFilterData() {}

  return {filterFields, cashRegistrTableColumns, cashRegistrTableData, onCashRegistrTableSelect, getFilterData};
};

export default useCashRegistrModal;
