import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchTemplateFilters, getTemplateFilters} from "../../../../slices/KPSlice";

const useTemplateFilters = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const filtersOptions = useSelector(getTemplateFilters);

  useEffect(() => {
    dispatch(fetchTemplateFilters());
  }, []);

  const coManagersOptions =
    filtersOptions?.coManagers?.map(coManager => ({
      title: coManager.name,
      value: coManager.managerCoId,
    })) || [];

  const managersPOptions =
    filtersOptions?.projectManagers?.map(manager => ({
      title: manager.name,
      value: manager.managerId,
    })) || [];

  return {coManagersOptions, managersPOptions};
};

export default useTemplateFilters;
