import {useFormikContext} from "formik";

import DeleteIcon from "../../../images/icons/delete.png";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import {dateFormat} from "../../../utilities/dateFormat";

import {useFiltersContext} from "./useSessionFiltersContext";

const ChosenFiltersLine = ({filterNames, onFilterDelete}) => {
  const {filters, setFilters} = useFiltersContext();
  const {setValues} = useFormikContext();

  const makeFilterTitle = filter => {
    const optionsValue = filterNames?.[filter]?.option
      ? filterNames[filter]?.option
      : filterNames?.[filter]?.type === "date"
      ? dateFormat(filters[filter])
      : filters[filter];

    return filterNames[filter] && filters[filter] ? `${filterNames[filter].label}: ${optionsValue}` : "";
  };

  function onDeleteSavedFilterBtnClick(filter) {
    const filteredKeys = Object.keys(filters).filter(key => key !== filter);
    const newFilters = filteredKeys.reduce((result, key) => ({...result, [key]: filters[key]}), {});
    setFilters(newFilters);
    setValues(newFilters);
    onFilterDelete(filter);
  }

  const row = filters
    ? Object?.keys(filters)?.map(filter => {
        return (
          <div key={filter} className="row borderMiddle gap5 paddingMiddle">
            <div style={{fontSize: "0.9rem"}}>{makeFilterTitle(filter)}</div>
            <ImageButton src={DeleteIcon} onClick={() => onDeleteSavedFilterBtnClick(filter)} />
          </div>
        );
      })
    : null;

  if (!filters) return null;
  return (
    <div
      className="row mt0_5 jcfs"
      style={{
        // border: "1px solid #426ca681",
        padding: "7px",
      }}
    >
      {row}
    </div>
  );
};

export default ChosenFiltersLine;
