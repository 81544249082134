import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import {withKpForm} from "../../KP/kp-f/kpFinForm/KpFinFormHOC";
import {deselectCurrentRequestSku, fetchRequestSKUs, getRequestSkuInfo, updateRequestSku} from "../RequestsSlice";

const ZedUpdateForm = ({setModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequestSku = useSelector(getRequestSkuInfo);

  const onSubmit = (values, {resetForm}) => {
    dispatch(
      updateRequestSku({
        skuRequestId: values.skuRequestId,
        data: values,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        resetForm();
        setModalActive(false);
        dispatch(deselectCurrentRequestSku());
      }
    });
  };
  const onCancel = () => {
    // dispatch(deselectCurrentRequestSku());
    dispatch(fetchRequestSKUs(currentRequestSku?.requestId));

    setModalActive(false);
  };

  const ZedForm = withKpForm(t("Save into request"), onSubmit, onCancel, false, "save and return req");

  return <ZedForm />;
};

ZedUpdateForm.propTypes = {setModalActive: PropTypes.func};

export default ZedUpdateForm;
