import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import * as Yup from "yup";

import {getUsers} from "../../../../slices/PersonSlice";
import {getError} from "../../../../slices/UserSlice";
import UpDownButtons from "../../../../utilities/Buttons/UpDownButtons";
import {getSuccess} from "../../../../utilities/toasts";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import {
  appointRequests,
  cancelAppointmentRequests,
  fetchRequests,
  getAllStatusRequest,
  getFirstStatusRequest,
  getRequestRegistrFilters,
} from "../../RequestsSlice";
import SimilarSkuBlock from "../../SimilarSkuBlock/SimilarSkuBlock";

const OrderingForm = ({selectedReq, selectedForCancel}) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const incomingRequests = useSelector(getFirstStatusRequest);

  const isUpBtnDisabled = selectedForCancel.length === 0;
  const isDownBtnDisabled = selectedReq.length === 0;

  const onAppoint = (values, {resetForm}) => {
    const {managerFea, note, date} = values;
    if (!managerFea || !date) {
      getError(t("Select manager FEA and date first!"));
      return;
    }
    dispatch(
      appointRequests({
        managerFeaId: managerFea,
        data: {
          requestIds: selectedReq,
          note,
          date: date,
        },
      }),
    ).then(() => {
      const selectedRequests = incomingRequests
        .filter(item => selectedReq.includes(item.requestId))
        .map(item => item.requestNumber);
      getSuccess(t("request appointed", {selectedReqs: selectedRequests}));
      resetForm();
    });
  };

  const onCancel = () => {
    dispatch(cancelAppointmentRequests(selectedForCancel)).then(() => {
      dispatch(fetchRequests({statusId: 1, window: "Incoming requests"}));
    });
  };

  const feaManagersOptions = useMemo(() => {
    return (
      users.map(item => {
        if (item?.position?.positionId === 1 || item?.position?.positionId === 3) {
          const name =
            i18n.language === "en"
              ? item.persona.engFirstName + " " + item.persona.engLastName
              : item.persona.firstName + " " + item.persona.lastName;

          return {
            title: name,
            value: item?.userId,
          };
        }
      }) || []
    );
  }, [users, i18n.language]);

  const initialValues = useMemo(() => {
    return {
      note: "",
      date: "",
      managerFea: "",
    };
  }, []);

  return (
    <div style={{margin: "1em 0 "}}>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={Yup.object().shape({
          managerFea: Yup.string("mng fea")
            .nullable()
            .required([t("mng fea")]),
          date: Yup.date().required([t("Enter date")]),
        })}
        onSubmit={onAppoint}
      >
        {({submitForm}) => (
          <Form className="fc jcsb nowrap">
            <div className="fc jcfs autolabel g15">
              <Select
                name="managerFea"
                label={t("FEA Manager")}
                question="FeaM assign"
                options={feaManagersOptions}
                width="15em"
              />

              <DateInput
                name="date"
                question="dd to fact"
                label={t("DD per Factory(p):")}
                flatpikrConfig={{
                  minDate: moment().add(1, "days").format("DD-MM-YYYY"),
                }}
              />

              <TextInput name="note" placeholder={t("Add a Note to the Application ")} width="25em" />
            </div>

            <UpDownButtons
              onUpClick={onCancel}
              onDownClick={() => {
                submitForm();
              }}
              isUpBtnDisabled={isUpBtnDisabled}
              isDownBtnDisabled={isDownBtnDisabled}
            />

            <div style={{height: "57px"}}>
              <SimilarSkuBlock />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

OrderingForm.propTypes = {
  selectedReq: PropTypes.array,
};

export default OrderingForm;
