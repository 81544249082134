import {useContext} from "react";

import {FiltersContext} from "./filters.context";

export const useFiltersContext = () => {
  const filtersConfig = useContext(FiltersContext);

  if (!filtersConfig) {
    throw new Error("TabsContext must be used within a Tabs container (withTabs hok)");
  }

  return {...filtersConfig};
};
