import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";

import {FinblockGrigButton} from "../../../../utilities/Buttons/StatusFunctionButton/FinblockGridButton";
import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import withTabs from "../../../conteiners/tabs/Tabs.hoc";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import PaymentCommonBase from "../../AccountPaymentCommonComponent/PaymentCommonBase/PaymentCommonBase";
import {selectAllDeskOperations} from "../../FinSettings/FinSettingsSlice";
import CashRegistrModal from "../CashRegistrModal/CashRegistrModal";

import FinblockFilterForm from "./CashdeskFilters";
import {ACCOUNT_OWNER_TABLE_COLUMNS, FINBLOCK_OPERATION_TABLE_COLUMNS} from "./constants";

const CashdeskBase = () => {
  const {t} = useTranslation();
  const {addNewTabCustom} = useTabsContext();

  const {setValues} = useFormikContext();

  const allDeskOperations = useSelector(selectAllDeskOperations);

  const [accountOwnerOptions, setAccountOwnerOptions] = useState([]);
  const [currentAccountOwner, setCurrentAccountOwner] = useState(null);
  const [currentOperationId, setCurrentOperationId] = useState(null);
  const [currentSavedTransactionId, setCurrentSavedTransactionId] = useState(null);

  const operationTableColumns = useColumns(FINBLOCK_OPERATION_TABLE_COLUMNS);
  const operationTableData =
    allDeskOperations?.map(({operationId, operationCode, group: {groupName}, operationName, isFavourite}, index) => ({
      number: ++index,
      id: operationId,
      favourite: {
        isFavourite: !!isFavourite,
        onChange: e => {
          console.log("CHECK!!!!!!", e);
          //! no endpoint currently
        },
        disabled: true,
      },
      operationCode,
      operationGroup: groupName,
      operationName,
    })) ?? [];

  const accountOwnerTableColumns = useColumns(ACCOUNT_OWNER_TABLE_COLUMNS);
  const [accountOwnerTableData, setAccountOwnerTableData] = useState([]);

  useEffect(() => {
    setAccountOwnerTableData(
      accountOwnerOptions?.map(({title, value}, index) => ({
        number: ++index,
        id: value,
        accountOwner: title,
        accountownerCode: "-",
      })) ?? [],
    );
  }, [accountOwnerOptions?.length]);

  function onAccountOwnerTableRowSelect(ownerId) {
    setCurrentAccountOwner(ownerId);
    setValues(prev => ({...prev, accountOwner: ownerId}));
  }

  function onOperationTableRowSelect(operationId) {
    const operationGroup = allDeskOperations.find(operation => operation.operationId === operationId)?.packageGroupId;
    setCurrentOperationId(operationId);
    setValues(prev => ({...prev, operation: operationId, operationGroup}));
  }

  function onAoSelectEffect(option) {
    const aoId = option.value;
    setCurrentAccountOwner(aoId);
    setAccountOwnerTableData(prev =>
      [...prev]
        .sort((a, b) => (a.id === aoId ? -1 : b.id === aoId ? 1 : 0))
        .map((item, index) => {
          item.number = ++index;
          return item;
        }),
    );
  }
  function onOperationSelectEffect(option) {
    setCurrentOperationId(option.value);
  }

  return (
    <div className="fcCol h100">
      <div className="form_wrapper fGrow">
        <div
          style={{
            paddingBottom: "1em",
            position: "relative",
          }}
        >
          <FinblockFilterForm
            checkBoxField
            accountOwnerOptions={accountOwnerOptions}
            setAccountOwnerOptions={setAccountOwnerOptions}
            currentOperationId={currentOperationId}
            onAoSelectEffect={onAoSelectEffect}
            onOperationSelectEffect={onOperationSelectEffect}
            setCurrentOperationId={setCurrentOperationId}
            setCurrentSavedTransactionId={setCurrentSavedTransactionId}
            currentAccountOwner={currentAccountOwner}
            setCurrentAccountOwner={setCurrentAccountOwner}
          />
          <div style={{position: "absolute", top: "3.4em", left: "0.3em"}}>
            <FinblockGrigButton
              onClick={() => {
                addNewTabCustom({
                  TabComponent: CashRegistrModal,
                  controlled: true,
                  title: "Cash transactions register",
                  tabIdName: "CashTransactionsRegister",
                });
              }}
            />
          </div>
        </div>

        <div className="fdr fGrow mt" style={{gap: "5em", height: "8em"}}>
          <ReactTable
            defaultData={accountOwnerTableData}
            columns={accountOwnerTableColumns}
            current={currentAccountOwner}
            className={"project_table fGrow"}
            style={{width: "43%"}}
            onSelect={onAccountOwnerTableRowSelect}
          />
          <ReactTable
            defaultData={operationTableData}
            columns={operationTableColumns}
            current={currentOperationId}
            onSelect={onOperationTableRowSelect}
            className={"project_table fGrow"}
            loading={false}
          />
        </div>
        <Divider style={{marginTop: "1.5em", marginBottom: "1.5em"}} />
        <PaymentCommonBase
          accountOrderBtnName={t("Create cash order")}
          setCurrentOperationId={setCurrentOperationId}
          currentSavedTransactionId={currentSavedTransactionId}
          setCurrentSavedTransactionId={setCurrentSavedTransactionId}
          setCurrentAccountOwner={setCurrentAccountOwner}
          setAccountOwnerOptions={setAccountOwnerOptions}
        />
      </div>
    </div>
  );
};

const Cashdesk = withFormik({
  mapPropsToValues: () => ({sum: "", ammount: ""}),
  enableReinitialize: true,
})(CashdeskBase);

export default withTabs({isClosableTabs: true, label: "Cashdesk"})(Cashdesk);
