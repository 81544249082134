import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import LogRequisites, {LOG_REQUISITES_BLOCK_TYPES} from "../common/LogRequisites/LogRequisites";
import LogSettingsFilters, {LOG_SETTINGS_FILTERS_TYPES} from "../common/LogSettingsFilters/LogSettingsFilters";
import SettingsContacts, {SETTINGS_CONTACTS_BLOCK_TYPES} from "../common/SettingsContacts/SettingsContacts";
import SettingsDocs, {SETTINGS_DOCS_BLOCK_TYPES} from "../common/SettingsDocs/SettingsDocs";
import TypeNameTables, {TYPE_NAME_TABLES_BLOCK_TYPES} from "../common/TypeNameTables/TypeNameTables";

import useLogModSettingsTransporter from "./useLogModSettingsTransporter";

export const LOG_MOD_SETTINGS_TRANSPORTER_WINDOW_NAME = "ModSettingsTransporter";

const LogModSettingsTransporter = () => {
  const {t} = useTranslation();

  const {
    wrSettingsMainTableColumns,
    wrSettingsMainTableData,
    currentCarrierId,
    onWrSettingsMainTableSelect,
    requisiteOptions,
    initialValues,
    settingsDocsTableData,
    contactNumberOptions,
    carrierTypesTableData,
    carrierNamesTableData,
    currentTypeId,
    setCurrentTypeId,
    currentTableCarrierId,
    setCurrentTableCarrierId,
    onTypeSelect,
    onTypeSave,
    onTypeDelete,
    onNameSave,
    onNameDelete,
    onNameSelect,
    onAddMoreRequisitesBtnClick,
    onSaveRequisiteBtnClick,
    onDeleteRequisiteBtnClick,
    onRequisiteNumberChange,
    onAddMoreContactsBtnClick,
    onSaveContactsBtnClick,
    onDeleteContactsBtnClick,
    onContactNumberChange,
    isNoCurrentCarrier,
    onSaveDocBtnClick,
    onDeleteDocBtnClick,
    pdfFileArray,
    onDocFileAdd,
    onDocFileDelete,
    currentDocId,
    onDocSelect,
    isCurrentDocFile,
    onDocNoteAdd,
    onDocNoteDelete,
    isTransporterLoading,
  } = useLogModSettingsTransporter();

  const validationShema = Yup.object().shape({
    email: Yup.string().email(t("Invalid email")).nullable(),
    // phone: Yup.string().nullable(),
    // .matches(/^\+[0-9]{12}$/, t("Invalid phone number")),
  });

  return (
    <div className="page_flex_container noGapDiv">
      <LogSettingsFilters
        window={LOG_MOD_SETTINGS_TRANSPORTER_WINDOW_NAME}
        type={LOG_SETTINGS_FILTERS_TYPES.TRANSPORTER}
      />
      <Divider />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationShema}
        validateOnBlur
        validateOnChange
        validateOnMount
      >
        {({values, setValues}) => (
          <Form>
            <div className="grid3ColFirstMajor gap fGrow">
              <div>
                <TypeNameTables
                  firstTableData={carrierTypesTableData}
                  secondTableData={carrierNamesTableData}
                  type={TYPE_NAME_TABLES_BLOCK_TYPES.TRANSPORTER}
                  firstTableCurrent={currentTypeId}
                  setFirstTableCurrent={setCurrentTypeId}
                  secondTableCurrent={currentTableCarrierId}
                  setSecondTableCurrent={setCurrentTableCarrierId}
                  onFirstTableSelect={typeId => onTypeSelect(typeId, setValues)}
                  onSecondTableSelect={carrierId => onNameSelect(carrierId, setValues)}
                  onTypeSave={() => onTypeSave(setValues)}
                  onTypeDelete={() => onTypeDelete(setValues)}
                  onNameSave={() => onNameSave(setValues)}
                  onNameDelete={() => onNameDelete(setValues)}
                />
                <LogRequisites
                  requisiteOptions={requisiteOptions ?? []}
                  type={LOG_REQUISITES_BLOCK_TYPES.TRANSPORTER}
                  onAddMoreRequisitesBtnClick={() => onAddMoreRequisitesBtnClick(setValues)}
                  onSaveRequisiteBtnClick={() => onSaveRequisiteBtnClick({values, setValues})}
                  onDeleteRequisiteBtnClick={() => onDeleteRequisiteBtnClick(setValues)}
                  onRequisiteNumberChange={onRequisiteNumberChange}
                  disabled={isNoCurrentCarrier}
                />
              </div>
              <div>
                <SettingsDocs
                  settingsDocsTableData={settingsDocsTableData}
                  type={SETTINGS_DOCS_BLOCK_TYPES.TRANSPORTER}
                  onSaveDocBtnClick={() => onSaveDocBtnClick(setValues)}
                  onDeleteDocBtnClick={() => onDeleteDocBtnClick(setValues)}
                  disabled={isNoCurrentCarrier}
                  onDocFileAdd={onDocFileAdd}
                  onDocFileDelete={onDocFileDelete}
                  currentDocId={currentDocId}
                  onDocSelect={docId => onDocSelect({docId, setValues})}
                  isCurrentDocFile={isCurrentDocFile}
                  onDocNoteAdd={() => onDocNoteAdd(setValues)}
                  onDocNoteDelete={() => onDocNoteDelete(setValues)}
                  loading={isTransporterLoading}
                />
                <SettingsContacts
                  contactNumberOptions={contactNumberOptions}
                  type={SETTINGS_CONTACTS_BLOCK_TYPES.TRANSPORTER}
                  onAddMoreContactsBtnClick={() => onAddMoreContactsBtnClick(setValues)}
                  onSaveContactsBtnClick={() => onSaveContactsBtnClick({values, setValues})}
                  onDeleteContactsBtnClick={() => onDeleteContactsBtnClick(setValues)}
                  onContactNumberChange={onContactNumberChange}
                  disabled={isNoCurrentCarrier}
                />
              </div>
              <div className="fc jcfe aic">
                <div className="kpPreview__box" style={{width: "100%", height: "100%"}}>
                  <NewPdfViewer fileArray={pdfFileArray} hiding={true} />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ReactTable
        className="tableTac mt2x"
        columns={wrSettingsMainTableColumns}
        defaultData={wrSettingsMainTableData}
        current={currentCarrierId}
        onSelect={onWrSettingsMainTableSelect}
        style={{height: "14em"}}
      />
    </div>
  );
};

export default LogModSettingsTransporter;
