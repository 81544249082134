import useColumns from "../Requests/OrderProcessing/utils/hooks/useColumns";

import {APPEAL_LIST_TABLE_COLUMNS} from "./constants";

const useDevIT = () => {
  const fileArray = [];
  const appealTypes = [];

  const appealListTableColumns = useColumns(APPEAL_LIST_TABLE_COLUMNS);
  const appealListTableData = [];

  function onAddAppealTypeBtnClick() {}
  function onSaveBtnClick() {}
  function onDeleteBtnClick() {}

  return {
    fileArray,
    appealTypes,
    appealListTableColumns,
    appealListTableData,
    onSaveBtnClick,
    onDeleteBtnClick,
    onAddAppealTypeBtnClick,
  };
};

export default useDevIT;
