import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import DeleteIcon from "../../../images/icons/delete.png";
import ImageButton from "../../../utilities/Buttons/ImageBtn";

const ChosenFiltersLine = ({savedFilters, onFilter, filterNames}) => {
  const {setValues} = useFormikContext();

  function makeFilterTitle(filter) {
    const savedValue = savedFilters[filter];
    const optionsValue =
      filterNames[filter]?.value ||
      filterNames[filter]?.options?.find(option => option.value === savedValue)?.title ||
      "";

    return filterNames[filter] && savedFilters[filter] ? `${filterNames[filter].name}: ${optionsValue}` : "";
  }

  function onDeleteSavedFilterBtnClick(filter) {
    const filteredKeys = Object.keys(savedFilters).filter(key => key !== filter);
    const newFilters = filteredKeys.reduce((result, key) => ({...result, [key]: savedFilters[key]}), {});
    setValues(newFilters);
    onFilter(newFilters);
  }

  return (
    <div className="row mt jcfs">
      {Object.keys(savedFilters).map(filter => {
        return makeFilterTitle(filter) ? (
          <div key={filter} className="row borderMiddle gap5 paddingMiddle">
            <div style={{fontSize: "0.85em"}}>{makeFilterTitle(filter)}</div>
            <ImageButton src={DeleteIcon} onClick={() => onDeleteSavedFilterBtnClick(filter)} />
          </div>
        ) : (
          ""
        );
      }) ?? []}
    </div>
  );
};

ChosenFiltersLine.propTypes = {
  savedFilters: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
  filterNames: PropTypes.object,
};

export default ChosenFiltersLine;
