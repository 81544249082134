import {t} from "i18next";

import {TextInput} from "../../../../../Inputs";

const PayerClientNumber = () => {
  return (
    <div className="row w100 aibl">
      <span>{t("Payer")}</span>
      <TextInput name="payerClientNumberComposed" label={t("Client №")} autolabel readOnly width="8em" />
    </div>
  );
};

export default PayerClientNumber;
