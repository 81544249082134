export const ORDER_DIRECTIVE_VIEW_UPPER_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
    maxSize: 15,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 120,
    maxSize: 120,
    isSortable: true,
  },
  {
    header: "Sum",
    accessorKey: "sum",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Dt",
    accessorKey: "dt",
    size: 300,
    maxSize: 350,
    isSortable: true,
  },
  {
    header: "Type",
    accessorKey: "dtType",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Kt",
    accessorKey: "kt",
    size: 300,
    maxSize: 350,
    isSortable: true,
  },
  {
    header: "Type",
    accessorKey: "ktType",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    // size: 180,
    maxSize: 200,
    isSortable: true,
  },
  {
    header: "Dt account name",
    accessorKey: "dtAccountName",
    size: 180,
    maxSize: 180,
  },
  {
    header: "Kt account name",
    accessorKey: "ktAccountName",
    size: 180,
    maxSize: 180,
  },
  {
    header: "Note",
    accessorKey: "note",
    size: 80,
    maxSize: 80,
  },
]);

export const ORDER_DIRECTIVE_VIEW_LOWER_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
    maxSize: 15,
  },
  {
    header: "Date To",
    accessorKey: "dateTo",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Date (f)",
    accessorKey: "factDate",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Directive №",
    accessorKey: "directiveNumber",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Dt",
    accessorKey: "dt",
    size: 300,
    maxSize: 350,
    isSortable: true,
  },
  {
    header: "Kt",
    accessorKey: "kt",
    size: 300,
    maxSize: 350,
    isSortable: true,
  },
  {
    header: "Sum (f)",
    accessorKey: "sumF",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    maxSize: 200,
    isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "note",
    size: 100,
  },
]);
