import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../../utilities/dateFormat";
import ReactTable from "../../../conteiners/ReactTable";
import {getCurrentFourthStatusRequestId, setCurrentFourthStatusRequestId} from "../../RequestsSlice";
import useColumns from "../utils/hooks/useColumns";

import {TABLE_BLOCK_COLUMNS} from "./utils/constants";
import useStatus4Requests from "./utils/hooks/useStatus4Requests";

import styles from "./TableBlock.module.scss";

const TableBlock = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentFourthStatusRequestId(null));
  }, []);

  const currentRqstId = useSelector(getCurrentFourthStatusRequestId);

  const isCurrentRqstId = Boolean(currentRqstId);

  const tableBlockColumns = useColumns(TABLE_BLOCK_COLUMNS);
  const status4Requests = useStatus4Requests() ?? [];
  const tableBlockTableData = useMemo(
    () =>
      status4Requests?.map(
        ({requestId, managerCo, brand, requestSum, createdAt, note, status, requestNumber}, index) => ({
          id: requestId,
          number: index + 1,
          request: requestNumber,
          managerCo: managerCo?.name,
          factory: brand?.name,
          requestSum,
          createdAt: dateFormat(createdAt),
          note,
          status: i18n === "eng" ? status?.engName : status?.ukrName,
        }),
      ),
    [status4Requests],
  );

  const onTableBlockRowSelect = id => {
    if (!id || id === currentRqstId) {
      dispatch(setCurrentFourthStatusRequestId(null));
      return;
    }
    dispatch(setCurrentFourthStatusRequestId(id));
  };

  return (
    <div className={styles.tableBlockContainer}>
      <ReactTable
        defaultData={tableBlockTableData ?? []}
        columns={tableBlockColumns}
        onSelect={onTableBlockRowSelect}
        current={currentRqstId ?? null}
        className={styles.status4TableWrapper}
        style={{overflowX: "auto"}}
      />

      <FunctionButton
        name={t("Open factory")}
        autoWidth
        disabled={!isCurrentRqstId ?? true}
        tooltipMessage={"modal to factory"}
        onClick={() => {
          navigate("/ba3a/fea/reqCreate", {state: {currentRequestId: currentRqstId}});
        }}
      />
    </div>
  );
};

export default TableBlock;
