import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {makeFilterManager} from "../../utilities/makeFilterManager/makeFilterManager";
import {numberFormat} from "../../utilities/moneyFormat";
import ReactTable from "../conteiners/ReactTable";
import KpPreviewModal from "../KP/kp-f/kpFinForm/KpPreviewModal";
import {deselect} from "../KP/kp-f/KpFinSlice";
import ModalForm from "../Modal/FormModal";
import useColumns from "../Requests/OrderProcessing/utils/hooks/useColumns";

import {SKU_FILTERS_KEY} from "./VED/Filters/SkuFilters";
import {deselectSku, fetchSku, getCurrentPage, getCurrentSku, getSkuInfo, getSkuList, getTotalPages} from "./SkuSlice";

const SKU_COLLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    size: 40,
    enableResizing: false,
  },
  {
    header: "SKU",
    accessorKey: "article",
    size: 100,
    // maxSize: 100,
  },
  {
    header: "UnKit",
    accessorKey: "UnKit",
    size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    size: 100,
  },
  {
    header: "Group c",
    accessorKey: "group",
    size: 100,
  },
  {
    header: "Type c",
    accessorKey: "type",
    size: 100,
  },
  {
    header: "Batch",
    accessorKey: "batch",
    size: 100,
  },
  {
    header: "Nomenclature",
    accessorKey: "nomenclature",
    size: 100,
  },
  {
    header: "Finishing",
    accessorKey: "finishing",
    size: 100,
  },
  {
    header: "Color",
    accessorKey: "color",
    size: 100,
  },
  {
    header: "Price",
    accessorKey: "price",
    size: 70,
  },
  {
    header: "RR price",
    accessorKey: "retailPrice",
    size: 50,
  },
  {
    header: "Min price",
    accessorKey: "minPrice",
    size: 70,
  },
  {
    header: "Length mm",
    accessorKey: "length",
    size: 60,
  },
  {
    header: "Width mm",
    accessorKey: "width",
    size: 60,
  },
  {
    header: "Height mm",
    accessorKey: "height",
    size: 60,
  },
  {
    header: "Weight kg",
    accessorKey: "weight",
    size: 60,
  },
  {
    header: "Quantity short",
    accessorKey: "quantity",
    size: 50,
  },
  {
    header: "Area m2",
    accessorKey: "area",
    size: 60,
  },
  {
    header: "Volume m3",
    accessorKey: "volume",
    size: 60,
  },
]);

const SkuReactTableList = () => {
  const {t} = useTranslation();

  const tableColls = useColumns(SKU_COLLUMNS);

  const skuLoadingStatus = useSelector(state => state.sku.skuLoadingStatus);
  const skuList = useSelector(getSkuList);
  const currentSku = useSelector(getCurrentSku),
    totalPages = useSelector(getTotalPages),
    currPage = useSelector(getCurrentPage);

  const skuFilterManager = makeFilterManager(SKU_FILTERS_KEY);
  const filterParams = skuFilterManager.getFilters();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const dispatch = useDispatch();
  function transformForTable(item, i) {
    return {
      // avail: "",
      number: i + 1,
      article: item?.brand?.prefix + "-" + item.article,
      skuUnkit: item.unkit ?? "-",
      factory: item.brand?.name,
      group: item.productGroup?.name,
      type: item.productType?.name,
      batch: item.batch,
      nomenclature: item.name,
      finishing: item.material?.name,
      color: item.color?.name,
      price: numberFormat(item.listPrice),
      retailPrice: numberFormat(item.retailPrice),
      minPrice: numberFormat(item.minimumPrice),
      length: numberFormat(item["length"]),
      width: numberFormat(item.width),
      height: numberFormat(item.height),
      weight: numberFormat(item.weight),
      quantity: numberFormat(item.amount),
      area: numberFormat(item.area),
      volume: numberFormat(item.volume),
      id: item.skuId,
    };
  }
  const selectSku = id => {
    if (!id || (currentSku && currentSku.skuId === id)) {
      dispatch(deselectSku());
    } else {
      dispatch(getSkuInfo(id));
      dispatch(deselect());
    }
  };

  const list = useMemo(() => {
    return skuList.map(transformForTable);
  }, [skuList]);

  const paginateSkuList = () => {
    if (totalPages === currPage) return;
    dispatch(fetchSku({...filterParams, page: currPage + 1}));
  };

  const onIdealSkuDoubleClick = sku => {
    dispatch(getSkuInfo(sku?.id));
    setPreviewModalOpen(true);
  };

  return (
    <>
      <ReactTable
        defaultData={list}
        columns={tableColls}
        current={currentSku?.skuId || null}
        onSelect={selectSku}
        onRowDoubleClick={onIdealSkuDoubleClick}
        className="scrollX  mb0_5"
        loading={skuLoadingStatus === "loading"}
        enableColumnResizing={true}
        onPaginate={paginateSkuList}
        paginatable={true}
      />
      <ModalForm
        closable={true}
        Component={KpPreviewModal}
        label={t("View SKU") + currentSku?.article}
        active={previewModalOpen}
        setModalActive={setPreviewModalOpen}
        className="modal_sizes_preview"
      />
    </>
  );
};

export default SkuReactTableList;
