import {useFormikContext, withFormik} from "formik";

import {TextInput} from "../../Inputs";

import withTabs from "./Tabs.hoc";
import {useTabsContext} from "./useTabs";

const DemoComponent = () => {
  const {addNewTab, tabsCount} = useTabsContext();
  return (
    <div style={{marginTop: "200px"}}>
      <p>Hallo 1</p>
      <button
        onClick={() =>
          addNewTab({
            TabComponent: Demo2,
            text: "TAB 1",
            title: "D1",
            controlled: false,
          })
        }
      >
        ADDDDDDDD
      </button>

      <button
        onClick={() =>
          addNewTab({
            TabComponent: Demo2,
            controlled: true,
            text: "TAB 2",
            title: "D2 wgrrrrrrrr fgfg dffff3243",
          })
        }
      >
        AD1
      </button>

      <button
        onClick={() =>
          addNewTab({
            TabComponent: Demo3,
            title: "D3 gfbtrntn fdfdfd",
            controlled: true,
          })
        }
      >
        AD2
      </button>
      <p>{tabsCount}</p>
    </div>
  );
};

const Demo2 = ({text}) => {
  return <div style={{marginTop: "200px"}}> {text}</div>;
};

const Demo3 = () => {
  return (
    <div>
      <div style={{marginTop: "200px"}}> third TAB</div>
      <TextInput label={"TRUATTA"} name={"trutata"} />
      <button type={"submit"}>GeneralFormSubm</button>
      <FormikF />
    </div>
  );
};

const DemoFOrm = () => {
  const {values, submitForm} = useFormikContext();

  return (
    <div>
      {/* <div style={{marginTop: "200px"}}> third TAB</div> */}
      <TextInput label={"test1"} name={"test1"} />
      <button type={"submit"} onClick={e => e.stopPropagation()}>
        FormSubm
      </button>
      <button
        type={"button"}
        onClick={() => {
          console.log("button values :>> ", values);
          submitForm();
        }}
      >
        check
      </button>
    </div>
  );
};

const FormikF = withFormik({
  handleSubmit: values => {
    console.log("withFormik", values);
    return values;
  },
  mapPropsToValues: () => ({}),
})(DemoFOrm);

export default withTabs({isClosableTabs: true})(DemoComponent);
