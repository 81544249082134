import {useEffect} from "react";
import {Route, Routes} from "react-router-dom";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";
import Kp from "../Components/KP/kp/Kp";
import KpPfControl from "../Components/KP/kp/KpPfControl/KpPfControl";
import KpFin from "../Components/KP/kp-f/KpFin";
import KpSecondVersion from "../Components/KP/KpSecondVersion/KpSecondVersion";
import CoTemplates from "../Components/Templates/CoTemplates/CoTemplates";
import TemplateRegister from "../Components/Templates/TemplateRegistr/TemplateRegister";

const KpPage = () => {
  useEffect(() => {}, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <Kp />
          </ErrorBoundary>
        }
      />
      <Route
        path="/coSecondVersion"
        element={
          // <ErrorBoundary>
          <KpSecondVersion />
          // </ErrorBoundary>
        }
      />
      <Route
        path="/templateRegister"
        element={
          // <ErrorBoundary>
          <TemplateRegister />
          // </ErrorBoundary>
        }
      />
      {/* <Route
        path="/coTemplates"
        element={
          // <ErrorBoundary>
          <CoTemplates />
          // </ErrorBoundary>
        }
      /> */}
      <Route path="/kpfin" element={<KpFin />} />
      <Route
        path="control"
        element={
          <ErrorBoundary>
            <KpPfControl />
          </ErrorBoundary>
        }
      />
      {/* <Route path="archive" element={<ErrorBoundary><KpFin /></ErrorBoundary>} /> */}
    </Routes>
  );
};

export default KpPage;
