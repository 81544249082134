import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../../utilities/ToolTip";
import {DateInput, TextAreaInput} from "../../../Inputs";
import {SelectWithDefault} from "../../../Inputs/SelectFieldWithDefault";
import OrderChangingPart, {ORDER_CHANGING_PART_TYPES} from "../OrderChangingPart/OrderChangingPart";

import useOrderTypeComposedPart from "./useOrderTypeComposedPart";

export const DIRECTIVE_PART_DEPENDENCY = Object.freeze({
  //1row
  1.2: ORDER_CHANGING_PART_TYPES.ON_CLIENT_BALANCE_CASHDESK,
  2.2: ORDER_CHANGING_PART_TYPES.CO_ADVANCE_CASHDESK,
  3.2: ORDER_CHANGING_PART_TYPES.CO_ADDIT_PAYMENT_CASHDESK,
  4.2: ORDER_CHANGING_PART_TYPES.CLIENT_REFUND_CASHDESK,
  5.2: ORDER_CHANGING_PART_TYPES.CREDIT_REPAYMENT_CASHDESK,

  //2row
  1.1: ORDER_CHANGING_PART_TYPES.ON_CLIENT_BALANCE_GENERAL,
  1.3: ORDER_CHANGING_PART_TYPES.ON_CLIENT_BALANCE_GENERAL,
  2.1: ORDER_CHANGING_PART_TYPES.CO_ADVANCE_GENERAL,
  2.3: ORDER_CHANGING_PART_TYPES.CO_ADVANCE_GENERAL,
  3.1: ORDER_CHANGING_PART_TYPES.CO_AFTERPAYMENT_GENERAL,
  3.3: ORDER_CHANGING_PART_TYPES.CO_AFTERPAYMENT_GENERAL,
  4.1: ORDER_CHANGING_PART_TYPES.CLIENT_REFUND_GENERAL,
  4.3: ORDER_CHANGING_PART_TYPES.CLIENT_REFUND_GENERAL,
  5.1: ORDER_CHANGING_PART_TYPES.ON_CREDIT_REPAYMENT_GENERAL,
  5.3: ORDER_CHANGING_PART_TYPES.ON_CREDIT_REPAYMENT_GENERAL,

  //3row
  1.4: ORDER_CHANGING_PART_TYPES.FROM_CO_TO_BALANCE_INNER,
  2.4: ORDER_CHANGING_PART_TYPES.FROM_BALANCE_TO_CO_INNER,
  3.4: ORDER_CHANGING_PART_TYPES.CO_AFTERPAYMENT_INNER,
  5.4: ORDER_CHANGING_PART_TYPES.ON_CREDIT_REPAYMENT_INNER,

  //4row
  2.5: ORDER_CHANGING_PART_TYPES.CREDIT_TO_CO_ADVANCE_CREDIT,
  3.5: ORDER_CHANGING_PART_TYPES.CREDIT_TO_CO_ADVANCE_CREDIT,
});

export const DIRECTIVE_CREATE_PART_DEPENDENCY = Object.freeze({
  1: ORDER_CHANGING_PART_TYPES.ON_CLIENT_BALANCE_GENERAL,
  2: ORDER_CHANGING_PART_TYPES.CO_ADVANCE_GENERAL,
  3: ORDER_CHANGING_PART_TYPES.CO_AFTERPAYMENT_GENERAL,
  4: ORDER_CHANGING_PART_TYPES.CLIENT_REFUND_GENERAL,
  5: ORDER_CHANGING_PART_TYPES.ON_CREDIT_REPAYMENT_GENERAL,
});

export const ORDER_COMPOSED_PART_TYPES = Object.freeze({
  KP_CREATE: "kpCreate",
  FINBLOCK_DO: "finblockDo",
  VIEW_FUll: "kpViewFull",
  VIEW_OPERATION_TYPE: "viewOperationType",
});

const OrderTypeComposedPart = ({onOkBtnClick = () => {}, children, type, className = ""}) => {
  const readOnly =
    type === ORDER_COMPOSED_PART_TYPES.VIEW_FUll || type === ORDER_COMPOSED_PART_TYPES.VIEW_OPERATION_TYPE;
  const isForCreate = type === ORDER_COMPOSED_PART_TYPES.KP_CREATE;

  const {
    finOrderTypeOptions,
    onDefaultFinOrderTypeSelect,
    operationTypeOptions,
    onDefaultOperationTypeSelect,
    isDirectiveCreated,
    isOkBtnDisabled,
    changingPartType,
  } = useOrderTypeComposedPart({readOnly, isForCreate, type});

  let typeDependentPart;
  switch (type) {
    case ORDER_COMPOSED_PART_TYPES.KP_CREATE:
      typeDependentPart = (
        <>
          <SelectWithDefault
            name="operationTypeId"
            label={t("Type of operation")}
            autolabel
            readOnly={readOnly || isDirectiveCreated}
            options={operationTypeOptions}
            required
            defaultOptionShown
            defaultOptionTitle="Choose a type"
            onDefaultOptionSelect={onDefaultOperationTypeSelect}
          />
          <div className="row w100 aibl">
            <DateInput
              name="fulfilBy"
              label={t("Term Until")}
              autolabel
              readOnly={readOnly || isDirectiveCreated}
              required
              fromCurrentDate
            />
            <ToolTip title={isOkBtnDisabled ? "Fill in all fields!" : ""} className="fc aic jcc">
              <FunctionButton
                onClick={onOkBtnClick}
                name="Ok"
                width="3.5em"
                disabled={readOnly || isOkBtnDisabled || !!isDirectiveCreated}
              />
            </ToolTip>
          </div>
        </>
      );
      break;
    case ORDER_COMPOSED_PART_TYPES.FINBLOCK_DO:
      typeDependentPart = (
        <>
          <DateInput name="fulfilBy" label={t("Term Until")} autolabel readOnly fromCurrentDate />
          <SelectWithDefault
            name="finOrderTypeId"
            label={t("Order type")}
            autolabel
            // readOnly={readOnly || (isDirectiveCreated && !noPreCreate)}
            options={finOrderTypeOptions}
            required
            defaultOptionShown
            defaultOptionTitle="Choose a type"
            onDefaultOptionSelect={onDefaultFinOrderTypeSelect}
          />
          <SelectWithDefault
            name="operationTypeId"
            label={t("Type of operation")}
            autolabel
            readOnly
            options={operationTypeOptions}
            defaultOptionShown
            defaultOptionTitle="Choose a type"
            onDefaultOptionSelect={onDefaultOperationTypeSelect}
          />
        </>
      );
      break;
    case ORDER_COMPOSED_PART_TYPES.VIEW_FUll:
      typeDependentPart = (
        <>
          <DateInput name="fulfilBy" label={t("Term Until")} autolabel readOnly fromCurrentDate />
          <SelectWithDefault
            name="finOrderTypeId"
            label={t("Order type")}
            autolabel
            readOnly
            options={finOrderTypeOptions}
            defaultOptionShown
            defaultOptionTitle=""
            onDefaultOptionSelect={onDefaultFinOrderTypeSelect}
          />
          <SelectWithDefault
            name="operationTypeId"
            label={t("Type of operation")}
            autolabel
            readOnly
            options={operationTypeOptions}
            defaultOptionShown
            defaultOptionTitle=""
            onDefaultOptionSelect={onDefaultOperationTypeSelect}
          />
        </>
      );
      break;
    case ORDER_COMPOSED_PART_TYPES.VIEW_OPERATION_TYPE:
      typeDependentPart = (
        <>
          <DateInput name="fulfilBy" label={t("Term Until")} autolabel readOnly fromCurrentDate />
          <SelectWithDefault
            name="operationTypeId"
            label={t("Type of operation")}
            autolabel
            readOnly
            options={operationTypeOptions}
            defaultOptionShown
            defaultOptionTitle=""
            onDefaultOptionSelect={onDefaultOperationTypeSelect}
          />
        </>
      );
      break;
    default:
      <></>;
  }

  return (
    <div className={`fcCol aistr gap jcsb fGrow ${className}`}>
      {typeDependentPart}

      <div className="fc aistr pt pb fGrow">
        <OrderChangingPart type={changingPartType} readOnly={readOnly} />
      </div>

      <TextAreaInput
        name="executorNote"
        label=""
        placeholder={t("Executant note")}
        readOnly={
          readOnly ||
          (!isDirectiveCreated && type === ORDER_COMPOSED_PART_TYPES.KP_CREATE) ||
          type === ORDER_COMPOSED_PART_TYPES.FINBLOCK_DO
        }
      />
      {children}
    </div>
  );
};

export default OrderTypeComposedPart;
