import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {NavLink, useLocation, useNavigate} from "react-router-dom";

import {MODE} from "../../gitIgnore/appMode";
import {getCurrentUser, getToken} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import AccessBoundary from "../AccessBoundary/AccessBoundary";

import NavbarInfo from "./navbarUnits/NavbarInfo";
import MyLink from "./navbarUnits/NavbarLink";
import NavItem, {NAV_PATH_NAMES} from "./navbarUnits/NavItem";
import logo from "./src/Logo.png";

import "./src/NavBar.scss";

const NavBar = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const token = useSelector(getToken);
  const user = useSelector(getCurrentUser);

  let activeStyle = {
    fontWeight: 600,
  };

  useEffect(() => {
    document.title = location.pathname.split("/").pop() + " / " + MODE;
  }, []);

  return (
    <div className="navbar">
      <div className="navbar__container">
        <NavLink end to="/ba3a/main" style={({isActive}) => (isActive ? activeStyle : undefined)}>
          <div className="navbar__logo">
            <img alt="" src={logo} draggable="false" />
            <h1>ERP ’ba3a’ - {MODE}</h1>
          </div>
        </NavLink>

        {token && user && (
          <nav className="navbar__top_navigation">
            <ul>
              <NavItem name="ba3a" dataName={NAV_PATH_NAMES.ADMIN}>
                <MyLink to="/ba3a/admin/addPerson" name={t("Person")} />
                <AccessBoundary>
                  <MyLink to="/ba3a/admin/registr" name={t("User")} />
                </AccessBoundary>
                <MyLink to="/xx" name={t("Designer")} />
                <MyLink to="/ba3a/admin/addClient" name={t("Client")} />
              </NavItem>

              <NavItem name={t("PROJECT")} dataName={NAV_PATH_NAMES.PROJECT}>
                {/* <MyLink to="/ba3a/project" name={t("Project")} /> */}
                <MyLink to="/ba3a/project/projectSecondVersion" name={t("Project")} />

                <MyLink to="/ba3a/project/projectDirectiveList" name={t("Project directives list")} />
                {/* <MyLink to="/ba3a/project/projectView" name={t("Project View")} /> */}
              </NavItem>

              <NavItem name={t("CO")} dataName={NAV_PATH_NAMES.KP}>
                {/* <MyLink to="/ba3a/co" name={t("CO")} /> */}
                <MyLink to="/ba3a/co/coSecondVersion" name={t("CO")} />
                <MyLink to="/ba3a/co/templateRegister" name={t("TemplateRegister")} />
                {/* <MyLink to="/ba3a/co/coTemplates" name={t("CO templates")} /> */}

                {/* <MyLink to='/ba3a/kp/kpfin' name="КП финансы"/> */}
                <MyLink to="/vdvd" name={t("CO album")} />
                <MyLink to="/vdvd" name={t("CO register")} />
                <MyLink to="/ba3a/co/control" name={t("Control PF")} />
                {/* <MyLink to="/ba3a/kp/archive" name={t("Archive PF")} /> */}
                <MyLink to="/vdvd" name={t("Archive PF")} />
              </NavItem>
              <NavItem name="SKU" dataName={NAV_PATH_NAMES.SKU}>
                <MyLink to="/ba3a/sku" name="SKU" />
              </NavItem>
              <NavItem name={t("FEA")} dataName={NAV_PATH_NAMES.VED}>
                {/* <MyLink to="/ba3a/fea/sku" name="SKU" /> */}
                <MyLink to="/ba3a/fea/bos/request-registr" name={t("Request register")} />
                <MyLink to="/ba3a/fea/orderProcess" name={t("Order processing")} />
                <MyLink to="/ba3a/fea/reqInProgress" name={t("Requests in progress")} />
                <MyLink to="/ba3a/fea/readyReq" name={t("req stat read")} />
                <MyLink to="/ba3a/fea/factorySettings" name={t("factory cc")} />
                <MyLink to="/ba3a/fea/vedSettingsGeneral" name={t("General VED settings")} />
                {/* <MyLink to="/ba3a/ved/receiptAndVerific" name={t("rec and ver")} /> */}
              </NavItem>
              <NavItem name={t("LOGISTICIAN")} dataName={NAV_PATH_NAMES.LOGIST}>
                <MyLink to="/ba3a/logistician/sleeping-requests" name={t("Request Register for planning")} />
                <MyLink to="/ba3a/logistician/bos/tp-pool-preregister" name={t("Request Register (chief)")} />
                <MyLink to="/ba3a/logistician/tp-pool-register" name={t("Request Register in progress")} />
                <MyLink to="/ba3a/logistician/tp-pool-factory" name={t("Process block factory")} />
                <MyLink to="/ba3a/logistician/tp-transportation" name={t("Transportation TP")} />
                <MyLink to="/ba3a/logistician/warehouse" name={t("Process block 'Warehouse'")} />
                <MyLink to="/ba3a/logistician/settings" name={t("Settings (Logist)")} />
                <MyLink to="/ba3a/logistician/bos/final-destination" name={t("Final Destination (Boss)")} />
                <MyLink to="/ba3a/logistician/final-destination-Manager" name={t("Final Destination")} />
              </NavItem>
              <NavItem name={t("FIN UNIT")} dataName={NAV_PATH_NAMES.FINBLOCK}>
                <MyLink to="/ba3a/fin-block/paymentsregistr" name={t("Payments register for Pf")} />
                <MyLink to="/ba3a/fin-block/fin_settings" name={t("Settings (FinBlock)")} />
                <MyLink to="/ba3a/fin-block/cashdesk" name={t("Cashdesk")} />
                <MyLink to="/ba3a/fin-block/cashdeskOrders" name={t("Cashdesk orders")} />
                <MyLink to="/ba3a/fin-block/finBlockDirectiveList" name={t("Project directives list")} />
                <MyLink to="/ba3a/fin-block/exchangeRates" name={t("Exchange rates")} />
              </NavItem>
              <NavItem name={t("FIN ACCOUNTING")} dataName={NAV_PATH_NAMES.FIN_ACCOUNTING}>
                <MyLink to="/ba3a/fin-accounting" name={t("FIN ACCOUNTING")} />
                <MyLink to="/ba3a/fin-accounting/accountsdir" name={t("Account handbook")} />
              </NavItem>
              <NavItem name={t("SERVICE")} dataName={NAV_PATH_NAMES.SERVICE} multiUser></NavItem>
              <NavItem name={t("WAREHOUSE")} dataName={NAV_PATH_NAMES.WAREHOUSE} multiUser></NavItem>
              <NavItem name={t("HELP")} dataName={NAV_PATH_NAMES.SETTINGS} multiUser>
                <MyLink to="/ba3a/settings/translate" name={t("Translation")} />
                {/* <MyLink to="/demoC" name={t("DEMO")} /> */}
              </NavItem>
              <NavItem name={t("Reference")} dataName={NAV_PATH_NAMES.REFERENCE} multiUser uppercase>
                <MyLink to="/ba3a/references/tasksList" name={t("Tasks list")} />
              </NavItem>

              <NavLink
                end
                to="/ba3a/devIT"
                className="border br"
                style={{marginLeft: "4rem", padding: "0.3rem 0.7rem", backgroundColor: "aliceblue"}}
              >
                DevIT
              </NavLink>
            </ul>
          </nav>
        )}
        <NavbarInfo />
      </div>
    </div>
  );
};

export default NavBar;
