import {useEffect} from "react";
import {useFormikContext} from "formik";

import {MoneyInput} from "../../../../../Inputs";
import CurrencySelect from "../../../../../Inputs/CurrencySelect/CurrencySelect";

const SumCurrency = ({readOnly = false}) => {
  const {
    values: {sumComposed, currencyIdComposed, areAllFieldsFilledIn},
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (!sumComposed || !currencyIdComposed) {
      setTimeout(() => setFieldValue("areAllFieldsFilledIn", false));
    }
    if (sumComposed && currencyIdComposed && !areAllFieldsFilledIn) {
      setFieldValue("areAllFieldsFilledIn", true);
    }
  }, [sumComposed, currencyIdComposed]);

  return (
    <div className="row w100">
      <MoneyInput name="sumComposed" label="Sum" autolabel width="10em" required={!readOnly} readOnly={readOnly} />
      <CurrencySelect name="currencyIdComposed" label="Currency" required={!readOnly} readOnly={readOnly} />
    </div>
  );
};

export default SumCurrency;
