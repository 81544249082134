import {t} from "i18next";

import {TextInput} from "../../../../../Inputs";

const RecipientClientNumber = () => {
  return (
    <div className="row w100 aibl">
      <span>{t("Recipient") + ":"}</span>
      <TextInput name="recipientClientNumberComposed" label={t("Client №")} autolabel readOnly width="8em" />
    </div>
  );
};

export default RecipientClientNumber;
