import {t} from "i18next";

import IndeterminateCheckbox from "../../../conteiners/reactTable/IndeterminateCheckbox";
import {DateInput, TextInput} from "../../../Inputs";

const HistoryDateNameLine = ({checked = false}) => {
  return (
    <div className="row aic gap2x mt mb0_5 jcsb w100">
      <div className="row aic gap0_5">
        <TextInput name="creatorName" label={t("Created")} readOnly autolabel />
        <DateInput name="createDate" label={t("From")} readOnly autolabel />
      </div>
      <div className="row aic gap0_5">
        <TextInput name="conductorName" label={t("Conducted")} readOnly autolabel />
        <DateInput name="conductDate" label={t("From")} readOnly autolabel />
      </div>
      <div className="row aic gap0_5">
        <IndeterminateCheckbox checked={checked} readOnly />
        <div className="row aibl">
          <TextInput name="creatorName" label={t("Approved")} readOnly autolabel />
          <DateInput name="approveDate" label={t("From")} readOnly autolabel />
        </div>
      </div>
    </div>
  );
};

export default HistoryDateNameLine;
