import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

import {makeOrder, saveKpfAsMain} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {getError, getSuccess} from "../../../../utilities/toasts";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {clearCreatedDirective, preCreateDirective} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import {TextInput} from "../../../Inputs";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import KpCreateMainDirectiveD from "../../../Project/ProjectDirectiveList/KpCreateMainDirectiveD/KpCreateMainDirectiveD.js";
import {fetchCO, selectCurrentCO} from "../../../Project/ProjectSlice";
import {fetchAllCOfs, fetchCOf, selectCurrentCof} from "../../kp-f/KpFinSlice";

const KpPreviewAndMainButtonsSection = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {addNewTabCustom} = useTabsContext();

  const currentCo = useSelector(selectCurrentCO);
  const currentCoId = currentCo?.coId;
  const currentCof = useSelector(selectCurrentCof);
  const currentCofId = currentCof?.cofId;
  const disabled = !currentCofId;

  function onCreateDirectiveBtnClick() {
    dispatch(preCreateDirective({cofId: currentCofId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        addNewTabCustom({
          TabComponent: KpCreateMainDirectiveD,
          title: `${t("Directive №")}`,
          controlled: true,
        });
        dispatch(clearCreatedDirective());
      }
    });
  }

  function onSaveAsMainBtnClick() {
    if (!currentCof || !currentCoId) return;

    Swal.fire({
      title: "",
      text: `${t("CO will be saved as the main one", {cofNumber: currentCof.cofNumber})}`,
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(answ => {
      if (answ.isConfirmed) {
        if (!currentCof.docsArray) {
          getError(t("cof without data"));
        } else {
          const cofId = currentCof.cofId;
          if (!cofId) return;

          dispatch(saveKpfAsMain(cofId)).then(({meta}) => {
            if (meta.requestStatus === "fulfilled") {
              getSuccess(t("CO was successfully saved as the main one", {cofNumber: currentCof.cofNumber}));
              dispatch(fetchCO({coId: currentCoId}));
              dispatch(fetchCOf({cofId: currentCofId}));
              dispatch(fetchAllCOfs({coId: currentCo?.coId}));
            }
          });
          // .then(res => {
          //   if (res.meta.requestStatus === "fulfilled") {
          //     dispatch(selectSubKp({kp: cofId, type: "f"}));
          //   }
          // });
        }
      }
    });
  }

  function onCreateOrderForFeaBtnClick() {
    if (!currentCo?.bringTo) {
      Swal.fire({
        title: "",
        text: `${t("enter bringTo at first")}`,
        icon: "error",
        confirmButtonText: t("Go to project"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
      }).then(answ => {
        if (answ.isConfirmed) {
          navigate("/ba3a/project/projectSecondVersion", {
            state: {currentKpId: currentCo.coId, currentProjectId: currentCo.projectId},
          });
        }
      });
      return;
    }
    Swal.fire({
      title: "",
      text: `${t("Will be created an order for FEA")}`,
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(makeOrder(currentCof.cofId)).then(({meta}) => {
          if (meta.requestStatus === "fulfilled") {
            getSuccess(t("An order for FEA was successfully created"));
            // navigate("/ba3a/fea/bos/request-registr");
          }
        });
      }
    });
  }

  return (
    <>
      <div className="fdr aic mb0_5">
        <div>{t("CO preview 2")}</div>
        <TextInput autolabel label="" name="cofNumber" readOnly />
      </div>

      <div className="kpPreview__box w100">
        <NewPdfViewer fileArray={currentCof?.docsArray ?? []} />
      </div>
      <div className="fc jcsb gap mb">
        <FunctionButton
          name={t("Create directive")}
          autoWidth
          disabled={disabled}
          onClick={onCreateDirectiveBtnClick}
        />
        <FunctionButton
          name={t("Save as main")}
          autoWidth
          disabled={disabled || currentCof?.isMain}
          onClick={onSaveAsMainBtnClick}
        />
        <FunctionButton
          name={t("Create an Order for FEA")}
          autoWidth
          disabled={disabled || !currentCof?.isMain}
          onClick={onCreateOrderForFeaBtnClick}
        />
      </div>
      {/* <FunctionButton name={t("Cancel directive")} autoWidth disabled={disabled} /> */}
    </>
  );
};

export default KpPreviewAndMainButtonsSection;
