export const PROJECT_TASKS_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 10,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 40,
  },
  {
    header: "Execution date",
    accessorKey: "executionDate",
    size: 40,
  },
  {
    header: "Appeal type",
    accessorKey: "appealType",
    size: 60,
  },
  {
    header: "Briefly about",
    accessorKey: "about",
    size: 300,
  },
]);
