import {useDispatch} from "react-redux";

import {apiUrl} from "../../../../../api/baseURL";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {findImageId, processImages} from "../../../../SKU/VED/constants/functions";
import useRemoveImageHandlers from "../../kpFinForm/kpFinFormBlocks/hooks/useRemoveImageHandler";
import cancel from "../cancel.png";

const ImagesRow = ({setFieldValue, values, data, columns, chengeable, type}) => {
  const dispatch = useDispatch();

  const {action, selector, currentSkuObjIdName, skuObjId, addedImagesKey} = useRemoveImageHandlers(type);

  const handleDeleteImage = image => {
    const imageToDeleteId = findImageId(image, selector);

    dispatch(
      action({
        imageId: imageToDeleteId,
        [currentSkuObjIdName]: skuObjId,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setFieldValue(addedImagesKey, processImages(res.payload[addedImagesKey]));
      }
    });
  };

  return (
    <>
      {(data.text === "" || data.text === null) && data.images.length === 0 ? null : (
        <tr>
          <td
            style={{
              border: "0",
            }}
          ></td>
          <td
            style={{
              border: "0",
            }}
          ></td>
          <td
            style={{
              border: "0.02px solid rgba(88, 89, 93, 0.2941176471)",
            }}
          >
            {data.text ? (
              <div
                style={{
                  overflowWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  overflow: "hidden",
                }}
              >
                {data.text}
              </div>
            ) : null}
          </td>

          <td
            colSpan={columns.length}
            style={{
              border: "0.02px solid rgba(88, 89, 93, 0.2941176471)",
            }}
          >
            {data.images.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  marginLeft: "21px",
                }}
              >
                {data.images.map((image, index) => (
                  <div key={index} style={{position: "relative", marginLeft: "5px"}}>
                    <img src={apiUrl + "/" + image} style={{width: "100px", height: "100px"}} />
                    {chengeable && (
                      <ImageButton
                        src={cancel}
                        alt="cancel"
                        width={1}
                        height={1}
                        onClick={() => handleDeleteImage(image)}
                        style={{
                          position: "absolute",
                          border: "0",
                          background: "transparent",
                          cursor: "pointer",
                          right: "-5px",
                          top: "1px",
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </td>
        </tr>
      )}
    </>
  );
};

export default ImagesRow;
