import React from "react";
import {t} from "i18next";

import {MoneyInput, NumberInput, TextInput} from "../../Inputs";

const FinStatus = ({readOnly}) => {
  return (
    <div className="mb2x">
      <div className="row jcfs gap3x mb aibl">
        <span style={{width: "14em"}}>{t("Planned financial status")}</span>
        <div className="row gap aibl" style={{border: "1px solid #BFCDE2", padding: "3px"}}>
          <div className="row gap aibl">
            <MoneyInput name="coSum" label={t("CO Sum")} autolabel width="13.5em" readOnly={readOnly} />✖
            <NumberInput name="advance" label={t("%advance")} autolabel readOnly={readOnly} />
          </div>
          =
          <MoneyInput
            name="nSum"
            label={t("Sum (n)")}
            autolabel
            generalStyles={{width: "15em"}}
            width="10em"
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="row jcfs gap3x aibl">
        <span style={{width: "14em"}}>{t("Actual financial status")}</span>
        <div className="row gap aibl" style={{border: "1px solid #BFCDE2", padding: "3px"}}>
          <MoneyInput name="coBalance" label={t("CO Balance")} autolabel readOnly={readOnly} />
          <div>=</div>
          <TextInput name="fact" label={t("%fact")} autolabel readOnly={readOnly} />
        </div>
      </div>
    </div>
  );
};

export default FinStatus;
