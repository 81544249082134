import {useDispatch, useSelector} from "react-redux";

import {
  addSkuRequestColorImage,
  addSkuRequestCommonImage,
  addSkuRequestDecorImage,
  getRequestSkuInfo,
  getSkuRequestAddedColorImages,
  getSkuRequestAddedDecorImages,
  getSkuRequestAddedImages,
  removeSkuRequestColorImage,
  removeSkuRequestCommonImage,
  removeSkuRequestDecorImage,
} from "../../../../../Requests/RequestsSlice";
import {findImageId} from "../../../../../SKU/VED/constants/functions";
import {
  addColorImage,
  addCommonImage,
  addDecorImage,
  getAddedColorImages,
  getAddedDecorImages,
  getAddedImages,
  getCurrentSkuPlan,
  removeColorImage,
  removeCommonImage,
  removeDecorImage,
} from "../../../KpFinSlice";

const useRemoveImageHandlers = imageType => {
  const currentSkuPlan = useSelector(getCurrentSkuPlan);
  const currentRequestSku = useSelector(getRequestSkuInfo);

  const addedDecorImages = useSelector(currentSkuPlan ? getAddedDecorImages : getSkuRequestAddedDecorImages);
  const addedColorImages = useSelector(currentSkuPlan ? getAddedColorImages : getSkuRequestAddedColorImages);
  const addedCommonImages = useSelector(currentSkuPlan ? getAddedImages : getSkuRequestAddedImages);

  const actionsSkuPlan = {
    Common: removeCommonImage,
    Color: removeColorImage,
    Decor: removeDecorImage,
  };

  const actionsSkuRequest = {
    Common: removeSkuRequestCommonImage,
    Color: removeSkuRequestColorImage,
    Decor: removeSkuRequestDecorImage,
  };

  const imageArrays = {
    Common: addedCommonImages,
    Color: addedColorImages,
    Decor: addedDecorImages,
  };

  const actionSource = currentSkuPlan ? actionsSkuPlan : actionsSkuRequest;

  const actionKey = imageType in actionSource ? imageType : "Common";
  const selectorKey = imageType in imageArrays ? imageType : "Common";

  const action = actionSource[actionKey];
  const selector = imageArrays[selectorKey];

  const currentSkuObjIdName = currentSkuPlan ? "skuPlanId" : "skuRequestId";
  const skuObjId = currentSkuPlan ? currentSkuPlan?.skuPlanId : currentRequestSku?.skuRequestId;
  const addedImagesKey = imageType === "Common" || imageType === "Drawings" ? "addedImages" : `added${imageType}Images`;

  return {action, selector, currentSkuObjIdName, skuObjId, addedImagesKey};
};

export default useRemoveImageHandlers;
