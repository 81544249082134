import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../../utilities/dateFormat";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import useToaster from "../../../../utilities/hooks/useToaster";
import {makeFilterManager} from "../../../../utilities/makeFilterManager/makeFilterManager";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {expanded} from "../../../Requests/RequestsInProgress/utils/functions/expandDataForTable";
import {
  addNewWarehouseAccount,
  addNewWarehouseContact,
  addNewWarehouseDoc,
  addNewWarehouseDocFile,
  addNewWarehouseType,
  addWarehouse,
  deleteWarehouse,
  deleteWarehouseAccount,
  deleteWarehouseContact,
  deleteWarehouseDoc,
  deleteWarehouseDocFile,
  deleteWarehouseType,
  fetchAllWarehouses,
  fetchAllWarehouseTypes,
  fetchWarehouseInfo,
  fetchWarehousesFilters,
  updateWarehouse,
  updateWarehouseAccount,
  updateWarehouseContact,
  updateWarehouseDoc,
} from "../../redux/Warehouses/operations";
import {
  selectAreWarehousesLoading,
  selectCurrentWarehouse,
  selectCurrentWarehouseDocFile,
  selectWarehouses,
  selectWarehouseTypes,
} from "../../redux/Warehouses/selectors";
import {clearCurrentWarehouse, setCurrentWarehouseDocFile} from "../../redux/Warehouses/WarehousesSlice";

import {WR_SETTINGS_MAIN_TABLE_COLUMNS} from "./constants";
import {LOG_MOD_SETTINGS_STORAGE_WINDOW_NAME} from "./LogModSettingsStorage";

const useLogModSettingsStorage = () => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const Toaster = useToaster();

  const LogModSettingsFilterManager = makeFilterManager(LOG_MOD_SETTINGS_STORAGE_WINDOW_NAME);
  const logModSettingsStorageFilters = LogModSettingsFilterManager.getFilters();

  useEffect(() => {
    dispatch(fetchWarehousesFilters());
    dispatch(fetchAllWarehouseTypes());
    dispatch(fetchAllWarehouses(logModSettingsStorageFilters ?? {}));
    dispatch(clearCurrentWarehouse());
  }, []);

  const allWarehouses = useSelector(selectWarehouses);
  const allWarehouseTypes = useSelector(selectWarehouseTypes);
  const areWarehousesLoading = useSelector(selectAreWarehousesLoading);
  const currentWarehouse = useSelector(selectCurrentWarehouse);
  const isNoCurrentWarehouse = !currentWarehouse;

  const [currentTypeId, setCurrentTypeId] = useState(null);
  const [currentCurrentTableWarehouseId, setCurrentTableWarehouseId] = useState(null);

  const initialValues = {
    //TypeNameTables
    type: "",
    itemName: "",
    warehouseCountry: "",

    //LogRequisites
    requisitesNumber: (currentWarehouse?.accounts ?? [])[0]?.accountId ?? "",
    beneficiary: (currentWarehouse?.accounts ?? [])[0]?.beneficiary ?? "",
    benefBank: (currentWarehouse?.accounts ?? [])[0]?.bankOfBeneficiary ?? "",
    swiftCode: (currentWarehouse?.accounts ?? [])[0]?.swiftCode ?? "",
    iban: (currentWarehouse?.accounts ?? [])[0]?.iban ?? "",
    account: (currentWarehouse?.accounts ?? [])[0]?.number ?? "",

    //SettingsDocs
    docDate: "",
    docName: "",
    docNumber: "",
    settingsDocsNoteInput: "",
    settingsDocsNoteInputField: currentWarehouse?.note ?? "",

    //SettingsContacts
    contactNumber: (currentWarehouse?.contacts ?? [])[0]?.contactId ?? "",
    warehouseName: (currentWarehouse?.contacts ?? [])[0]?.title ?? "",
    warehouseAddress: (currentWarehouse?.contacts ?? [])[0]?.address ?? "",
    contactName: (currentWarehouse?.contacts ?? [])[0]?.name ?? "",
    email: (currentWarehouse?.contacts ?? [])[0]?.email ?? "",
    phone: (currentWarehouse?.contacts ?? [])[0]?.phone ?? "",
  };

  //*** TypeNameTables Block ***
  const [selectedTypeWarehouses, setSelectedTypeWarehouses] = useState(null);

  const warehouseTypesTableData =
    allWarehouseTypes?.map(({typeId, engName, ukrName}, index) => ({
      id: typeId,
      number: ++index,
      warehouseType: (language === "en" ? engName : ukrName) ?? " - ",
    })) ?? [];

  const warehouseNamesTableDataRow = selectedTypeWarehouses || allWarehouses;
  const warehouseNamesTableData =
    warehouseNamesTableDataRow?.map(({warehouseId, name}, index) => ({
      id: warehouseId,
      number: ++index,
      warehouseName: name ?? " - ",
    })) ?? [];

  function onTypeSelect(typeId, setValues) {
    setCurrentTypeId(typeId);
    if (!typeId) {
      setSelectedTypeWarehouses(null);
      setValues(prev => ({...prev, type: ""}));
      return;
    }
    const typeName = allWarehouseTypes?.find(type => type.typeId === typeId)[language === "en" ? "engName" : "ukrName"];
    setSelectedTypeWarehouses(allWarehouses?.filter(warehouse => warehouse?.type?.typeId === typeId));
    setValues(prev => ({...prev, type: typeName ?? " - "}));
  }

  function onNameSelect(warehouseId, setValues) {
    setCurrentTableWarehouseId(warehouseId);
    if (!warehouseId) {
      setValues(prev => ({...prev, itemName: "", warehouseCountry: ""}));
      return;
    }
    const selectedWarehouse = allWarehouses?.find(warehouse => warehouse.warehouseId === warehouseId);
    setValues(prev => ({...prev, itemName: selectedWarehouse?.name, warehouseCountry: selectedWarehouse?.countryId}));
  }

  function onTypeSave(setValues) {
    setValues(prev => {
      dispatch(addNewWarehouseType({[language === "en" ? "engName" : "ukrName"]: prev.type})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          Toaster.toastSuccess(`${t("Type saved")}: ${prev.type}`);
          dispatch(fetchWarehousesFilters());
          dispatch(fetchAllWarehouseTypes());
        } else {
          Toaster.toastError(resp.error.message);
        }
      });

      return {...prev, type: ""};
    });
  }

  function onTypeDelete(setValues) {
    swalCustom.confirm({
      confirmFunc: () => {
        setValues(prev => {
          if (selectedTypeWarehouses) {
            Toaster.toastError("The type cannot be deleted, because of existing warehouses");
            return prev;
          }
          dispatch(deleteWarehouseType(currentTypeId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.toastSuccess(`${t("Type deleted")}: ${prev.type}`);
              dispatch(fetchWarehousesFilters());
              dispatch(fetchAllWarehouseTypes());
            } else {
              Toaster.toastError(resp.error.message);
            }
          });

          return {...prev, type: ""};
        });
      },
    });
  }
  function onNameSave(setValues) {
    setValues(prev => {
      dispatch(
        addWarehouse({typeId: currentTypeId, data: {countryId: prev.warehouseCountry, name: prev.itemName}}),
      ).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          Toaster.toastSuccess(`${t("Warehouse name saved")}: ${prev.itemName}`);
          dispatch(fetchWarehousesFilters());
          dispatch(fetchAllWarehouses(logModSettingsStorageFilters ?? {})).then(resp => {
            setSelectedTypeWarehouses(resp?.payload?.filter(warehouse => warehouse.typeId === currentTypeId) ?? null);
          });
        } else {
          Toaster.toastError(resp.error.message);
        }
      });

      return {...prev, itemName: "", warehouseCountry: ""};
    });
  }
  function onNameDelete(setValues) {
    if (!currentCurrentTableWarehouseId) {
      Toaster.toastError("Choose a Warehouse first!");
      setValues(prev => ({...prev, itemName: "", warehouseCountry: ""}));
      return;
    }
    swalCustom.confirm({
      confirmFunc: () => {
        setValues(prev => {
          dispatch(deleteWarehouse(currentCurrentTableWarehouseId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.toastSuccess("Warehouse deleted");
              dispatch(fetchWarehousesFilters());
              dispatch(fetchAllWarehouses(logModSettingsStorageFilters ?? {})).then(resp => {
                if (currentTypeId) {
                  setSelectedTypeWarehouses(
                    resp?.payload?.filter(warehouse => warehouse.typeId === currentTypeId) ?? null,
                  );
                }
              });
              setCurrentTableWarehouseId(null);
            } else {
              Toaster.toastError(resp.error.message);
            }
          });
          return {...prev, itemName: "", warehouseCountry: ""};
        });
      },
    });
  }

  //*** LogRequisites Block***
  const nullRequiseteFields = {
    beneficiary: "",
    benefBank: "",
    swiftCode: "",
    iban: "",
    account: "",
    requisitesNumber: "",
  };
  const [requisiteOptions, setRequisiteOptions] = useState([]);

  useEffect(() => {
    if (currentWarehouse) {
      setRequisiteOptions(
        currentWarehouse?.accounts?.map((account, index) => ({
          title: `${++index}`,
          value: account.accountId,
          beneficiary: account.beneficiary,
          benefBank: account.bankOfBeneficiary,
          swiftCode: account.swiftCode,
          iban: account.iban,
          account: account.number,
        })) ?? [],
      );
    }
  }, [currentWarehouse?.accounts]);

  function onAddMoreRequisitesBtnClick(setValues) {
    const nextRequisitesNumber = requisiteOptions.length + 1;
    setRequisiteOptions(prev => [...prev, {title: `${nextRequisitesNumber}`, value: `temp#${nextRequisitesNumber}`}]);
    setValues(prev => {
      return {
        ...prev,
        ...nullRequiseteFields,
        requisitesNumber: `temp#${nextRequisitesNumber}`,
      };
    });
  }

  function onSaveRequisiteBtnClick({values, setValues}) {
    return new Promise((resolse, reject) => {
      if (!currentWarehouse) return reject();

      if (!values.beneficiary || !values.benefBank || !values.swiftCode || !values.iban || !values.account) {
        Toaster.toastError("Fill in all fields!");
        console.log("rej");
        return reject();
      }

      if (values?.requisitesNumber?.toString().includes("temp#")) {
        dispatch(
          addNewWarehouseAccount({
            warehouseId: currentWarehouse.warehouseId,
            params: {
              beneficiary: values.beneficiary,
              bankOfBeneficiary: values.benefBank,
              swiftCode: values.swiftCode,
              iban: values.iban,
              number: values.account,
            },
          }),
        ).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("New account saved");
            dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));

            const newRequisiteId = resp.payload.accountId;
            setRequisiteOptions(prev => [
              ...prev,
              {
                title: `${prev.length}`,
                value: newRequisiteId,
                beneficiary: resp.payload.beneficiary,
                benefBank: resp.payload.bankOfBeneficiary,
                swiftCode: resp.payload.swiftCode,
                iban: resp.payload.iban,
                account: resp.payload.number,
              },
            ]);
            setTimeout(() => {
              setValues(prev => ({
                ...prev,
                requisitesNumber: newRequisiteId,
              }));
              resolse();
            }, 500);
          } else {
            Toaster.toastError(resp.error.message);
            return reject();
          }
        });
      } else {
        dispatch(
          updateWarehouseAccount({
            accountId: values.requisitesNumber,
            params: {
              beneficiary: values.beneficiary,
              bankOfBeneficiary: values.benefBank,
              swiftCode: values.swiftCode,
              iban: values.iban,
              number: values.account,
            },
          }),
        ).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("Account saved");
            dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
            resolse();
          } else {
            return reject();
          }
        });
      }
    });
  }

  function onDeleteRequisiteBtnClick(setValues) {
    if (!currentWarehouse) return;

    swalCustom.confirm({
      confirmFunc: () => {
        setValues(prev => {
          if (
            !prev.requisitesNumber ||
            (typeof prev.requisitesNumber === "string" && prev.requisitesNumber.startsWith("temp#"))
          ) {
            Toaster.toastError("Save the requisite first");
            return prev;
          }

          dispatch(deleteWarehouseAccount(prev.requisitesNumber)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.toastSuccess("Account deleted");
              dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
            } else {
              Toaster.toastError(resp.error.message);
            }
          });

          const prevRequisite = (currentWarehouse?.accounts ?? [])[currentWarehouse?.accounts.length - 2];

          return {
            ...prev,
            requisitesNumber: prevRequisite?.accountId ?? "",
            beneficiary: prevRequisite?.beneficiary ?? "",
            benefBank: prevRequisite?.bankOfBeneficiary ?? "",
            swiftCode: prevRequisite?.swiftCode ?? "",
            iban: prevRequisite?.iban ?? "",
            account: prevRequisite?.number ?? "",
          };
        });
      },
    });
  }

  function onRequisiteNumberChange({option, setValues}) {
    if (!currentWarehouse) return;

    const currentAccount = currentWarehouse.accounts.find(account => account.accountId === option.value);
    if (!currentAccount) {
      setValues(prev => ({...prev, ...nullRequiseteFields, requisitesNumber: option.value}));
      return;
    }
    setValues(prev => ({
      ...prev,
      requisitesNumber: option.value,
      beneficiary: option.beneficiary,
      benefBank: option.benefBank,
      swiftCode: option.swiftCode,
      iban: option.iban,
      account: option.account,
    }));
  }

  //*** SettingsContacts Block ***

  const nullContactFields = {
    warehouseName: "",
    warehouseAddress: "",
    contactName: "",
    email: "",
    phone: "",
    contactNumber: "",
  };

  const [contactNumberOptions, setContactNumberOptions] = useState([]);

  useEffect(() => {
    if (currentWarehouse) {
      setContactNumberOptions(
        currentWarehouse?.contacts?.map((contact, index) => ({
          title: `${++index}`,
          value: contact.contactId,
          warehouseName: contact.title,
          warehouseAddress: contact.address,
          contactName: contact.name,
          email: contact.email,
          phone: contact.phone,
        })) ?? [],
      );
    }
  }, [currentWarehouse?.contacts]);

  function onAddMoreContactsBtnClick(setValues) {
    const nextContactsNumber = contactNumberOptions.length + 1;
    setContactNumberOptions(prev => [...prev, {title: `${nextContactsNumber}`, value: `temp#${nextContactsNumber}`}]);
    setValues(prev => {
      return {
        ...prev,
        ...nullContactFields,
        contactNumber: `temp#${nextContactsNumber}`,
      };
    });
  }

  function onSaveContactsBtnClick({values, setValues}) {
    return new Promise((resolse, reject) => {
      if (!currentWarehouse) return reject();

      if (!values.warehouseName || !values.contactName) {
        Toaster.toastError("Fill in all fields!");
        return reject();
      }

      // try {
      //   await Yup.string()
      //     .matches(/^\+[0-9]{12}$/, t("Invalid phone number"))
      //     .validate(values.phone);
      // } catch (error) {
      //   Toaster.toastError(error.message);
      //   return;
      // }

      if (values["contactNumber"].toString().includes("temp#")) {
        dispatch(
          addNewWarehouseContact({
            warehouseId: currentWarehouse.warehouseId,
            params: {
              title: values.warehouseName,
              address: values.warehouseAddress,
              name: values.contactName,
              email: values.email,
              phone: values.phone,
            },
          }),
        ).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("New contact saved");
            dispatch(fetchWarehousesFilters());
            dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
            dispatch(fetchAllWarehouses(logModSettingsStorageFilters ?? {}));

            const newContactId = resp.payload.contactId;
            setContactNumberOptions(prev => [
              ...prev,
              {
                title: `${prev.length}`,
                value: newContactId,
                warehouseName: resp.payload.title,
                warehouseAddress: resp.payload.address,
                contactName: resp.payload.name,
                email: resp.payload.email,
                phone: resp.payload.phone,
              },
            ]);
            setTimeout(() => {
              setValues(prev => ({
                ...prev,
                contactNumber: newContactId,
              }));
              resolse();
            }, 500);
          } else {
            Toaster.toastError(resp.error.message);
            return reject();
          }
        });
      } else {
        dispatch(
          updateWarehouseContact({
            contactId: values?.contactNumber,
            params: {
              title: values.warehouseName,
              address: values.warehouseAddress,
              name: values.contactName,
              email: values.email,
              phone: values.phone,
            },
          }),
        ).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("Contact saved");
            dispatch(fetchWarehousesFilters());
            dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
            dispatch(fetchAllWarehouses(logModSettingsStorageFilters ?? {}));
            resolse();
          } else {
            return reject();
          }
        });
      }
    });
  }

  function onDeleteContactsBtnClick(setValues) {
    if (!currentWarehouse) return;

    swalCustom.confirm({
      confirmFunc: () => {
        setValues(prev => {
          if (
            !prev.contactNumber ||
            (typeof prev.contactNumber === "string" && prev.contactNumber.startsWith("temp#"))
          ) {
            Toaster.toastError("Save the contact first");
            return prev;
          }

          dispatch(deleteWarehouseContact(prev.contactNumber)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.toastSuccess("Contact deleted");
              dispatch(fetchWarehousesFilters());
              dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
              dispatch(fetchAllWarehouses(logModSettingsStorageFilters ?? {}));
            } else {
              Toaster.toastError(resp.error.message);
            }
          });
          const prevContact = (currentWarehouse?.contacts ?? [])[currentWarehouse?.contacts.length - 2];

          return {
            ...prev,
            contactNumber: prevContact?.contactId ?? "",
            warehouseName: prevContact?.title ?? "",
            warehouseAddress: prevContact?.address ?? "",
            contactName: prevContact?.name ?? "",
            email: prevContact?.email ?? "",
            phone: prevContact?.phone ?? "",
          };
        });
      },
    });
  }
  function onContactNumberChange({option, setValues}) {
    if (!currentWarehouse) return;

    const currentContact = currentWarehouse.contacts.find(contact => contact.contactId === option.value);
    if (!currentContact) {
      setValues(prev => ({...prev, ...nullContactFields, contactNumber: option.value}));
      return;
    }
    setValues(prev => ({
      ...prev,
      contactNumber: option.value,
      warehouseName: currentContact.title,
      warehouseAddress: option.warehouseAddress,
      contactName: option.contactName,
      email: option.email,
      phone: option.phone,
    }));
  }

  //*** SettingsDocs Block ***
  const nullDocValues = {
    docName: "",
    docNumber: "",
    docDate: "",
  };
  const [currentDocId, setCurrentDocId] = useState(null);
  const settingsDocsTableData =
    currentWarehouse?.docs?.map(({docId, name, number, date, createdAt}, index) => ({
      id: docId,
      number: ++index,
      docName: name ?? " - ",
      docNumber: number ?? " - ",
      docDate: dateFormat(date) ?? " - ",
      uploadDate: dateFormat(createdAt) ?? " - ",
    })) ?? [];
  const currentDocFile = useSelector(selectCurrentWarehouseDocFile);
  const isCurrentDocFile = Boolean(currentDocFile);
  const pdfFileArray = currentDocFile ? [currentDocFile.url] : [];

  function onSaveDocBtnClick(setValues) {
    if (!currentWarehouse) return;

    setValues(prev => {
      if (!prev.docName || !prev.docNumber || !prev.docDate) {
        Toaster.toastError("Fill in all fields!");
        return prev;
      }

      (!currentDocId
        ? dispatch(
            addNewWarehouseDoc({
              warehouseId: currentWarehouse.warehouseId,
              params: {name: prev.docName, number: prev.docNumber, date: prev.docDate},
            }),
          )
        : dispatch(
            updateWarehouseDoc({
              docId: currentDocId,
              params: {name: prev.docName, number: prev.docNumber, date: prev.docDate},
            }),
          )
      ).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          if (currentDocId) {
            Toaster.toastSuccess("Doc updated");
          }
          setCurrentDocId(resp.payload.docId);
          dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
        } else {
          Toaster.toastError(resp.error.message);
        }
      });
      return {...prev, ...nullDocValues};
    });
  }

  function onDeleteDocBtnClick(setValues) {
    if (!currentDocId) return;
    if (currentDocFile) {
      Toaster.toastError("Delete the doc file first!");
      return;
    }

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteWarehouseDoc(currentDocId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("Doc deleted");
            dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
            dispatch(setCurrentWarehouseDocFile(null));
            setCurrentDocId(null);
          } else {
            Toaster.toastError(resp.error.message);
          }
        });
      },
    });

    setValues(prev => ({...prev, ...nullDocValues}));
  }

  function onDocSelect({docId, setValues}) {
    if (!currentWarehouse) return;

    setCurrentDocId(docId);
    if (!docId) {
      dispatch(setCurrentWarehouseDocFile(null));
      setValues(prev => ({...prev, ...nullDocValues}));
      return;
    }
    const currentDoc = currentWarehouse.docs.find(doc => doc.docId === docId) ?? {};
    if (currentDoc.files.length !== 0) {
      dispatch(setCurrentWarehouseDocFile({id: currentDoc.docId, url: (currentDoc.files ?? [])[0]?.fileUrl}));
    } else {
      dispatch(setCurrentWarehouseDocFile(null));
    }

    setValues(prev => {
      return {
        ...prev,
        docName: currentDoc.name,
        docNumber: currentDoc.number,
        docDate: currentDoc.date,
      };
    });
  }

  function onDocFileAdd(formdata) {
    if (!currentDocId) return;

    dispatch(addNewWarehouseDocFile({docId: currentDocId, doc: formdata})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        Toaster.toastSuccess("Doc file added");
        dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
        dispatch(setCurrentWarehouseDocFile({id: resp.payload.fileId, url: resp.payload.fileUrl}));
      } else {
        Toaster.toastError(resp.error.message);
      }
    });
  }

  function onDocFileDelete() {
    if (!currentDocId || !currentDocFile) return;
    const currentDoc = currentWarehouse.docs.find(doc => doc.docId === currentDocId);
    if (!currentDoc.files || currentDoc.files.length === 0) return;

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteWarehouseDocFile(currentDoc.files[0].fileId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("Doc file deleted");
            dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
            dispatch(setCurrentWarehouseDocFile(null));
          } else {
            Toaster.toastError(resp.error.message);
          }
        });
      },
    });
  }
  function onDocNoteAdd(setValues) {
    if (!currentWarehouse) return;

    setValues(prev => {
      if (!prev.settingsDocsNoteInput) {
        Toaster.toastWarning("Enter your note before saving");
        return prev;
      }
      dispatch(
        updateWarehouse({warehouseId: currentWarehouse.warehouseId, data: {note: prev.settingsDocsNoteInput}}),
      ).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          Toaster.toastSuccess("Doc note saved");
          dispatch(fetchWarehousesFilters());
          dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
        } else {
          Toaster.toastError(resp.error.message);
        }
      });
      return {...prev, settingsDocsNoteInput: "", settingsDocsNoteInputField: prev.settingsDocsNoteInput};
    });
  }
  function onDocNoteDelete(setValues) {
    if (!currentWarehouse) return;

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(updateWarehouse({warehouseId: currentWarehouse.warehouseId, data: {note: ""}})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("Doc note deleted");
            dispatch(fetchWarehousesFilters());
            dispatch(fetchWarehouseInfo(currentWarehouse.warehouseId));
            setValues(prev => {
              return {...prev, settingsDocsNoteInput: "", settingsDocsNoteInputField: ""};
            });
          } else {
            Toaster.toastError(resp.error.message);
          }
        });
      },
    });
  }

  //*** Lower General Table Block */
  const wrSettingsMainTableColumns = useColumns(WR_SETTINGS_MAIN_TABLE_COLUMNS);
  const wrSettingsMainTableData =
    allWarehouses?.map(({warehouseId, type, name, address, contacts, note}, index) => ({
      id: warehouseId,
      number: ++index,
      type: (language === "en" ? type?.engName : type?.ukrName) ?? " - ",
      warehouseName: name ?? " - ",
      address: contacts?.map(contact => contact?.address || "").join(", ") || " - ",
      contactFullName: expanded(contacts, "name"),
      email: expanded(contacts, "email"),
      phone: expanded(contacts, "phone"),
      note: note ?? " - ",
    })) ?? [];
  const currentWarehouseId = currentWarehouse?.warehouseId ?? null;

  function onWrSettingsMainTableSelect(warehouseId) {
    if (!warehouseId) {
      dispatch(clearCurrentWarehouse());
      setCurrentDocId(null);
      dispatch(setCurrentWarehouseDocFile(null));
      return;
    }
    dispatch(fetchWarehouseInfo(warehouseId));
  }

  return {
    wrSettingsMainTableColumns,
    wrSettingsMainTableData,
    currentWarehouseId,
    onWrSettingsMainTableSelect,
    requisiteOptions,
    initialValues,
    settingsDocsTableData,
    contactNumberOptions,
    warehouseTypesTableData,
    warehouseNamesTableData,
    currentTypeId,
    setCurrentTypeId,
    currentCurrentTableWarehouseId,
    setCurrentTableWarehouseId,
    onTypeSelect,
    onTypeSave,
    onTypeDelete,
    onNameSave,
    onNameDelete,
    onNameSelect,
    onAddMoreRequisitesBtnClick,
    onSaveRequisiteBtnClick,
    onDeleteRequisiteBtnClick,
    onRequisiteNumberChange,
    onAddMoreContactsBtnClick,
    onSaveContactsBtnClick,
    onDeleteContactsBtnClick,
    onContactNumberChange,
    isNoCurrentWarehouse,
    onSaveDocBtnClick,
    onDeleteDocBtnClick,
    currentDocId,
    onDocSelect,
    pdfFileArray,
    onDocFileAdd,
    onDocFileDelete,
    isCurrentDocFile,
    onDocNoteAdd,
    onDocNoteDelete,
    areWarehousesLoading,
  };
};

export default useLogModSettingsStorage;
