import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {checkEmptyFilterFields} from "../../../utilities/checkEmptyFilterFields";
import Divider from "../../../utilities/Divider/Divider";
import ToolTip from "../../../utilities/ToolTip";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";
import ReactTable from "../../conteiners/ReactTable";
import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {fetchAllDirectives, fetchAllFinStatuses} from "../../FinBlock/FinSettings/FinSettingsSlice";

import useProjectDirectiveList from "./useProjectDirectiveList";

const ProjectDirectiveList = () => {
  const {
    getFilterData,
    onFilterDelete,
    projectDirectiveTableColumns,
    projectDirectiveTableData,
    onProjectDirectiveTableSelect,
    // onOpenCreateInnerDirectieveModalBtnClick,
    onOpenDirectiveBtnClick,
    onOpenProjectBtnClick,
    onPrintReceiptBtnClick,
    onCancelDirectiveBtnClick,
    currentDirectiveId,
    loading,
    isCurrentDirectiveCreatedByCurrentUser,
    filtersLayout,
    PROJECT_DIRECTIVE_LIST_WINDOW_NAME,
    formPayerDataParamName,
    setFilterFields,
  } = useProjectDirectiveList();

  const disabled = !currentDirectiveId;

  return (
    <div className="fcCol h100 w100 pb">
      <SessionFiltersContainer
        window={PROJECT_DIRECTIVE_LIST_WINDOW_NAME}
        onFilterChange={getFilterData}
        onFilterDelete={onFilterDelete}
      >
        <Filters>{filtersLayout}</Filters>
        <FiltersLogik formPayerDataParamName={formPayerDataParamName} setFilterFields={setFilterFields} />
      </SessionFiltersContainer>

      <Divider />
      <ReactTable
        columns={projectDirectiveTableColumns}
        defaultData={projectDirectiveTableData}
        current={currentDirectiveId}
        onSelect={onProjectDirectiveTableSelect}
        className="tableTac fGrow scrollX"
        tableClassName="fGrow"
        loading={loading}
      />
      <div className="row w100 mt0_5">
        <div className="row gap2x">
          <FunctionButton name="Open directive" onClick={onOpenDirectiveBtnClick} autoWidth disabled={disabled} />
          <FunctionButton name="Open project" onClick={onOpenProjectBtnClick} autoWidth disabled={disabled} />
        </div>
        <div className="row gap2x">
          <FunctionButton
            name="Print receipt"
            onClick={onPrintReceiptBtnClick}
            autoWidth
            disabled={true || disabled}
            className="unfinishedLayout"
          />
          <ToolTip
            title={!disabled && !isCurrentDirectiveCreatedByCurrentUser ? "You can cancel only your directive" : ""}
          >
            <FunctionButton
              name="Cancel directive"
              onClick={onCancelDirectiveBtnClick}
              autoWidth
              disabled={true || disabled || !isCurrentDirectiveCreatedByCurrentUser}
            />
          </ToolTip>
        </div>
      </div>
    </div>
  );
};
const FiltersLogik = ({formPayerDataParamName, setFilterFields}) => {
  const {filters, sessionF} = useFiltersContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllFinStatuses());
  }, []);

  useEffect(() => {
    if (isEmpty(sessionF)) {
      dispatch(fetchAllDirectives());
      return;
    }
    const {payerDataType, payerData, ...restFilters} = filters;
    const payerDataParamName = formPayerDataParamName(payerDataType);

    dispatch(
      fetchAllDirectives({
        ...restFilters,
        [payerDataParamName]: payerData,
      }),
    );
    setFilterFields(checkEmptyFilterFields(restFilters));
  }, [sessionF]);

  return <></>;
};
export default withTabs({
  isMainTabControlled: true,
  isClosableTabs: true,
  label: "Project directives list",
  formInitialValues: {},
})(ProjectDirectiveList);
