import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../Inputs";
import DirectiveFilters from "../DirectiveFilters";
import OrderTypeComposedPart from "../OrderTypeComposedPart/OrderTypeComposedPart";

import useOrderDirectiveView from "./useOrderDirectiveView";

const OrderDirectiveView = () => {
  const {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    onExitBtnClick,
    onUpperTableSelect,
    currentTransactionId,
    currentCompletedTransactionId,
    onLowerTableSelect,
  } = useOrderDirectiveView();

  return (
    <div className="fcCol h100 w100 pb aist">
      <DirectiveFilters readOnly />
      <Divider />
      <div className="grid2ColSecondMax gap w100 fGrow2 mb0_5" style={{gridTemplateColumns: "30% 1fr"}}>
        <OrderTypeComposedPart readOnly className="fGrow">
          <div className="row w100 jcfe">
            <FunctionButton onClick={onExitBtnClick} name="Exit" autoWidth />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2 gap2x">
          <div className="smaller_font_size w100 mt2x mb2x" style={{border: "1px solid #A3B9D9", padding: "1em"}}>
            <div className="fdr gap jcsb">
              <div className="fdr aic gap " style={{marginRight: "2em"}}>
                <TextInput name="dt" label={t("Dt")} readOnly autolabel />
              </div>

              <div className="fdr aic gap" style={{marginLeft: "2em"}}>
                <TextInput name="kt" label={t("Kt")} readOnly autolabel />
              </div>
            </div>
            <div className="fdr aic gap">
              <ToolTip title="Dt account name" className="fGrow">
                <TextAreaInput name="dtAccountName" height="2.5em" placeholder={t("Dt account name")} disabled />
              </ToolTip>
              <ToolTip title="Kt account name" className="fGrow">
                <TextAreaInput name="ktAccountName" height="2.5em" placeholder={t("Kt account name")} disabled />
              </ToolTip>
            </div>
            <Divider />
            <div className="fdr aic gap">
              <ToolTip title="Payment purpose" className="fGrow2">
                <TextAreaInput
                  name="paymentRecognition"
                  height="4.5em"
                  placeholder={t("Enter Recognition of payment") + "*"}
                />
              </ToolTip>
              <ToolTip title="Note (for the whole directive)" className="fGrow">
                <TextAreaInput name="paymentNote" height="4.5em" placeholder={t("Add note")} />
              </ToolTip>
            </div>
          </div>
          <ReactTable
            columns={upperTableColumns}
            defaultData={upperTableData}
            current={currentTransactionId}
            onSelect={onUpperTableSelect}
            className="fGrow mb"
            style={{height: "unset"}}
          />
          <ReactTable
            columns={lowerTableColumns}
            defaultData={lowerTableData}
            current={currentCompletedTransactionId}
            onSelect={onLowerTableSelect}
            className="fGrow mb"
            style={{height: "unset"}}
          />
          <div className="row jcfe w100">
            <FunctionButton
              onClick={onPrintOrderBtnClick}
              name="Print an order"
              autoWidth
              disabled
              className="unfinishedLayout"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDirectiveView;
