import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import infoIcon from "../../../../images/icons/infoIcon.png";
import warnIcon from "../../../../images/icons/Triangle_Warning.png";
import {createTemplate, fetchTemplatesWithFilter, getTemplates, modifyTemplate} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import {TextAreaInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";

const TemplateCreateFromKpModal = ({currentCOf, setModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const templates = useSelector(getTemplates);

  const {setFieldValue, values} = useFormikContext();
  const templatesNumberOptions = useMemo(() => {
    return (
      templates?.map(temp => ({
        title: temp.templateNumber,
        value: temp.cofTemplateId,
      })) || []
    );
  }, [templates]);

  useEffect(() => {
    dispatch(fetchTemplatesWithFilter({cofId: currentCOf?.cofId})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setFieldValue("cofTemplateId", res.payload[0]?.cofTemplateId);
      }
    });
  }, []);

  const onCreateTemplateClick = () => {
    dispatch(createTemplate({cofId: currentCOf.cofId, note: values?.modalTCOnote})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        Swal.fire({
          title: "",
          text: t("Template created"),
          icon: "info",
          confirmButtonText: t("Ok"),
          showCancelButton: false,
        });
        setModalActive(false);
        setFieldValue("modalTCOnote", "");
      }
    });
  };

  const onUpdateTemplateClick = () => {
    dispatch(
      modifyTemplate({cofId: currentCOf.cofId, cofTemplateId: values?.cofTemplateId, note: values?.modalTCOnote}),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        Swal.fire({
          title: "",
          text: t("Template updated"),
          icon: "info",
          confirmButtonText: t("Ok"),
          showCancelButton: false,
        });
        setModalActive(false);
        setFieldValue("modalTCOnote", "");
      }
    });
  };

  return (
    <div style={{padding: "2em 3em"}} className="fdc">
      {templates?.length > 0 && (
        <div className="fdr aic mb2x gap2x" style={{fontSize: "20px", fontWeight: "lighter", fontStyle: "italic"}}>
          <ImageButton src={warnIcon} alt="warn" width={3} height={3} />
          <div className="fcCols aic jcc gap">
            <div className="fdr aibl">
              <div>{t("Creating temp warn")}</div>
              <Select label="" options={templatesNumberOptions} name="cofTemplateId" width="10em" />
            </div>
            <div>{t("To overwrite temp")}</div>
            <div>{t("To create temp")}</div>
          </div>
        </div>
      )}

      <div className="fdr aic mb3x mt gap2x" style={{fontSize: "20px", minWidth: "40em"}}>
        <ImageButton src={infoIcon} alt="info" width={3} height={3} />
        <div className="fcCols gap aic jcc w100">
          <div>{t("New temp create text")}</div>
          <div>
            {t("from CO(f) №")} {currentCOf?.cofNumber}
          </div>
        </div>
      </div>

      <div className="fdr aic w100">
        <div style={{width: "12em", marginRight: "5em"}}>{t("Notes on tCO")}</div>
        <TextAreaInput name="modalTCOnote" label="" width={"100%"} />
      </div>

      <div className="fdr gap2x jcsa aic" style={{marginTop: "auto"}}>
        <FunctionButton name={t("Template")} onClick={onCreateTemplateClick} autoWidth />
        {templates?.length > 0 && (
          <FunctionButton name={t("Update template")} onClick={onUpdateTemplateClick} autoWidth />
        )}

        <FunctionButton
          name={t("Cancel")}
          onClick={() => {
            setModalActive(false);
          }}
          autoWidth
        />
      </div>
    </div>
  );
};

export default TemplateCreateFromKpModal;
