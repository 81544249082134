import PropTypes from "prop-types";

const Divider = ({className = "", style = {}}) => {
  return (
    <div
      className={`w100 ${className}`}
      style={{borderTop: "1px solid #A3B9D9", height: 0, marginTop: "10px", marginBottom: "10px", ...style}}
    ></div>
  );
};

Divider.propTypes = {className: PropTypes.string, style: PropTypes.object};

export default Divider;
