import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";

import {fetchList} from "../../../../hook/axios.hook";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";
import {Select} from "../../../Inputs/SelectField";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {getCurrentProject} from "../../ProjectSlice";

import {CHOOSE_CO_TABLE_COLUMNS} from "./constants";

const ChooseCo = ({addCoAnnex, setModalActive}) => {
  const [cos, setCos] = useState([]);

  const tableColumns = useColumns(CHOOSE_CO_TABLE_COLUMNS);

  const {
    setFieldValue,
    values: {chosenCoNumber},
  } = useFormikContext();

  const isLoading = false;

  const currentProject = useSelector(getCurrentProject);

  function onTableRowSelect(id) {
    setFieldValue("chosenCoNumber", id);
  }

  function onCancelBtnClick() {
    setModalActive(false);
  }

  useEffect(() => {
    let params = {projectId: currentProject.projectId};
    fetchList(`/cos/getAll`, "get", {params}).then(res => {
      setCos(res);
    });
  }, []);

  const tableData = cos?.map((co, i) => ({
    id: co.coId,
    number: i + 1,
    coNumber: co.coNumber,
    about: co.note,
    coSum: co.sum,
  }));

  const coOptions = cos?.map((co, i) => ({
    value: co.coId,
    title: co.coNumber,
  }));

  return (
    <div className="fcCol jcsb w100">
      <Select
        label={t("Chosen CO №")}
        name="chosenCoNumber"
        readOnly
        autolabel
        options={coOptions}
        style={{marginLeft: "10px"}}
        generalClassName="mt mb2x asfs"
      />
      <ReactTable
        className="fGrow"
        columns={tableColumns}
        defaultData={tableData}
        current={chosenCoNumber}
        onSelect={onTableRowSelect}
        loading={isLoading}
        style={{minHeight: "15em", flexGrow: 1}}
      />
      <div className="row mt2x gap4x">
        <FunctionButton name="Add" autoWidth onClick={addCoAnnex} disabled={!chosenCoNumber || chosenCoNumber === ""} />
        <FunctionButton name="Cancel" autoWidth onClick={onCancelBtnClick} />
      </div>
    </div>
  );
};

// export default withFormik({enableReinitialize: true})(ChooseCo);
export default ChooseCo;
