import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEqual} from "lodash";
import moment from "moment";

import {
  fetchAgents,
  fetchAgentTypes,
  fetchDesigners,
  fetchDesignerTypes,
  selectAgents,
  selectAgentTypes,
  selectDesigners,
  selectDesignerTypes,
} from "../../../../../slices/CatalogsSlice";
import {fetchUsers, selectAllUsers} from "../../../../../slices/PersonSlice";
import {getCurrentUser} from "../../../../../slices/UserSlice";
import {STATUS_COLORS} from "../../../../../utilities/colorConstants";
import {dateFormat} from "../../../../../utilities/dateFormat";
import {swalCustom} from "../../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../../utilities/hooks/toaster";
import makeNameFromPersona from "../../../../../utilities/makeNameFromPersona";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addCO,
  clearCOs,
  clearCurrentCO,
  deleteCO,
  fetchAllCOs,
  fetchCO,
  fetchProjectFilters,
  getCOLoading,
  getCurrentProject,
  selectAllCOs,
  selectCurrentCO,
  updateCO,
} from "../../../ProjectSlice";
import {CO_TABLE_COLUMNS} from "../../constants";

const useCoSection = () => {
  const REMIND_COLORS = {RED: "red", BLUE: "blue", GREEN: "green", YELLOW: "yellow"};
  const {
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  const {values, setValues, setFieldValue} = useFormikContext();

  const currentUser = useSelector(getCurrentUser);
  const currentUserId = currentUser?.id;
  const users = useSelector(selectAllUsers);
  const designers = useSelector(selectDesigners);
  const designerTypes = useSelector(selectDesignerTypes);
  const agents = useSelector(selectAgents);
  const agentTypes = useSelector(selectAgentTypes);
  const allCOs = useSelector(selectAllCOs);
  const currentProject = useSelector(getCurrentProject);
  const projectId = currentProject?.projectId;
  const currentCO = useSelector(selectCurrentCO);

  const coLoading = useSelector(getCOLoading);

  const READY_CO_STATUS_ID = 2;

  const [currentCoId, setCurrentCoId] = useState(null);

  const hasCoChanged = useMemo(() => {
    if (!currentCO) return true;
    let coFromValues = {
      designer: values.designer || "",
      designerPersent: values.designerPersent || "",
      designerType: values.designerType || "",
      agent: values.agent || "",
      agentPersent: values.agentPersent || "",
      agentType: values.agentType || "",
      coDescription: values.coDescription || "",
    };
    let co = {
      designer: currentCO?.designers?.[0]?.designerId || "",
      designerPersent: currentCO?.designers?.[0]?.CoToDesigners?.find(item => item.coId === currentCoId)?.bonus || "",
      designerType:
        currentCO?.designers?.[0]?.CoToDesigners?.find(item => item.coId === currentCoId)?.designerTypeId || "",
      agent: currentCO?.agents?.[0]?.agentId || "",
      agentPersent: currentCO?.agents?.[0]?.CoToAgents?.find(item => item.coId === currentCoId)?.bonus || "",
      agentType: currentCO?.agents?.[0]?.CoToAgents?.find(item => item.coId === currentCoId)?.agentTypeId || "",
      coDescription: currentCO?.note || "",
    };

    if ((co.designer && !coFromValues.designer) || (co.agent && !coFromValues.agent)) return true;

    return isEqual(coFromValues, co);
  }, [values, currentCO]);

  useEffect(() => {
    if (!users?.length) {
      dispatch(fetchUsers());
    }
    if (!designers?.length) {
      dispatch(fetchDesigners());
    }
  }, [users?.length, designers?.length]);

  useEffect(() => {
    if (users?.length > 0 && currentUserId && projectId) {
      setValues(prev => ({...prev, managerCoId: currentUserId}));
    }
    if (!projectId) {
      setValues(prev => ({...prev, managerCoId: ""}));
    }
  }, [users?.length, currentUserId, projectId]);

  useEffect(() => {
    dispatch(fetchDesignerTypes());
    dispatch(fetchAgents());
    dispatch(fetchAgentTypes());
  }, []);

  useEffect(() => {
    setCurrentCoId(null);
    dispatch(clearCurrentCO());

    if (projectId) {
      dispatch(fetchAllCOs({projectId}));
    } else {
      dispatch(clearCOs());
    }
  }, [projectId]);

  const [remindBtnColor, setReminBtnColor] = useState(REMIND_COLORS.BLUE);

  // function calcStatusColor({startDate, finalDate}) {
  //   if (startDate && finalDate) {
  //     const dateGap = new Date(finalDate) - new Date(startDate);
  //     const dateGone = new Date(finalDate) - new Date();

  //     const persentGone = dateGap === 0 ? 0 : (dateGone / dateGap) * 100;

  //     console.log("dateGap", dateGap);
  //     console.log("dateGone", dateGone);
  //     console.log("persentGone", persentGone);

  //     if (persentGone >= 0 && persentGone <= 80) {
  //       return REMIND_COLORS.GREEN;
  //     } else if (persentGone > 80 && persentGone < 120) {
  //       return REMIND_COLORS.YELLOW;
  //     } else {
  //       return REMIND_COLORS.RED;
  //     }
  //   }
  //   return REMIND_COLORS.BLUE;

  function calcStatusColor({startDate, finalDate}) {
    if (startDate && finalDate) {
      const startDateMs = new Date(startDate).getTime();
      const finalDateMs = new Date(finalDate).getTime();
      const currentDateMs = new Date().getTime();

      const totalPeriod = finalDateMs - startDateMs;
      const timeToStart = startDateMs - currentDateMs;
      const timeAfterFinal = currentDateMs - finalDateMs;

      const percentToStart = (timeToStart / totalPeriod) * 100;
      const percentAfterFinal = (timeAfterFinal / totalPeriod) * 100;

      if (percentToStart >= 20) {
        return REMIND_COLORS.GREEN;
      } else if ((percentToStart < 20 && percentToStart >= 0) || percentAfterFinal <= 20) {
        return REMIND_COLORS.YELLOW;
      } else if (percentAfterFinal > 20) {
        return REMIND_COLORS.RED;
      }
    }
    return REMIND_COLORS.BLUE;
  }

  useEffect(() => {
    if (new Date(values.finalDate) - new Date(values.startDate) < 0) {
      toaster.error("Final date can`t be before start date!");
      setFieldValue("finalDate", "");
    }

    setReminBtnColor(calcStatusColor({startDate: values.startDate, finalDate: values.finalDate}));
  }, [values.startDate, values.finalDate]);

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      managerCoId: currentCO?.managerCoId ?? currentUserId ?? "",
      startDate: currentCO?.startDate ?? "",
      finalDate: currentCO?.finalDate ?? "",
      isReady: currentCO?.status?.statusId ? currentCO?.status?.statusId === READY_CO_STATUS_ID : "",
      coOffice: currentCO?.managerCo?.orgStructure?.shortName ?? "",
      coNumber: currentCO?.coNumber ?? "",
      coSum: currentCO?.sum ?? "",
      advance: currentCO?.prepayment ?? "",
      planSum: currentCO?.sum ?? "",
      factSum: currentCO?.sum ?? "",
      factSumPersent: currentCO?.sum ?? "",
      designer: (currentCO?.designers ?? [])[0]?.designerId,
      designerPersent:
        (currentCO?.designers ?? [])[0]?.CoToDesigners?.find(item => item.coId === currentCoId)?.bonus ?? "",
      designerType: (currentCO?.designers ?? [])[0]?.CoToDesigners?.find(item => item.coId === currentCoId)
        ?.designerTypeId,
      agent: (currentCO?.agents ?? [])[0]?.agentId,
      agentPersent: (currentCO?.agents ?? [])[0]?.CoToAgents?.find(item => item.coId === currentCoId)?.bonus ?? "",
      agentType: (currentCO?.agents ?? [])[0]?.CoToAgents?.find(item => item.coId === currentCoId)?.agentTypeId,
      coDescription: currentCO?.note ?? "",
    }));
  }, [currentCO?.coId]);

  const coManagerOptions =
    users?.map(({persona, userId}) => ({
      //   title: `${makeNameFromPersona({persona, language})} ${language === "en" ? nameEng : nameUkr}`,
      title: `${makeNameFromPersona({persona, language})}`,
      value: userId,
    })) ?? [];
  const designerOptions =
    designers?.map(({persona, designerId, bonus}) => ({
      title: makeNameFromPersona({persona, language}),
      value: designerId,
      bonus,
    })) ?? [];
  const designerTypeOptions =
    designerTypes?.map(({engName, ukrName, designerTypeId}) => ({
      title: language === "en" ? engName : ukrName,
      value: designerTypeId,
    })) ?? [];
  const coAgentOptions =
    agents?.map(({name, agentId, basicBonus}) => ({title: name, value: agentId, bonus: basicBonus})) ?? [];
  const agentTypeOptions =
    agentTypes?.map(({engName, ukrName, agentTypeId}) => ({
      title: language === "en" ? engName : ukrName,
      value: agentTypeId,
    })) ?? [];

  const coTableColumns = useColumns(CO_TABLE_COLUMNS);
  const coTableData =
    allCOs?.map(({coId, coNumber, note, sum, startDate, finalDate, bringTo, managerCoId, project}, index) => ({
      number: ++index,
      id: coId,
      statusCircle: STATUS_COLORS[calcStatusColor({startDate, finalDate}).toUpperCase()],
      startDate: dateFormat(startDate),
      finalDate: dateFormat(finalDate),
      coNumber,
      coDescription: note,
      sum,
      planSum: "-",
      factSum: "-",
      persent: "-",
      factDate: dateFormat(bringTo),
      managerP: makeNameFromPersona({persona: project.projectManager.persona, language}),
      managerCO: coManagerOptions?.find(item => item.value === managerCoId)?.title ?? "-",
    })) ?? [];

  function onCoTableRowSelect(coId) {
    setCurrentCoId(coId);
    if (coId) {
      dispatch(fetchCO({coId}));
    } else {
      dispatch(clearCurrentCO());
      setReminBtnColor(REMIND_COLORS.BLUE);
    }
  }

  function onCreateCoBtnClick() {
    if (!projectId) return;
    const {managerCoId, startDate, finalDate} = values;

    dispatch(addCO({projectId, body: {managerCoId, startDate, finalDate}})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("CO created");
        setValues(prev => ({
          ...prev,
          coNumber: resp.payload.co.coNumber,
          coOffice: resp.payload.co.managerCo.orgStructure.shortName,
        }));
        dispatch(fetchAllCOs({projectId}));
        setCurrentCoId(resp.payload.co.coId);
        dispatch(fetchProjectFilters());
      }
    });
  }

  function onSaveCoBtnClick() {
    if (!currentCoId) return;

    const {coDescription, designer, designerPersent, designerType, agent, agentPersent, agentType} = values;

    dispatch(
      updateCO({
        coId: currentCoId,
        body: {
          note: coDescription,
          ...(designer && {
            designer: {
              designerId: designer,
              designerTypeId: designerType || null,
              bonus: designerPersent || 0,
            },
          }),
          ...(agent && {
            agent: {
              agentId: agent,
              agentTypeId: agentType || null,
              bonus: agentPersent || 0,
            },
          }),
        },
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("CO saved/updated");
        dispatch(fetchAllCOs({projectId}));
        dispatch(fetchProjectFilters());
      }
    });
  }

  function onDeleteCoBtnClick() {
    if (!currentCoId) return;

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteCO({coId: currentCoId})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            toaster.success("CO deleted");
            dispatch(fetchAllCOs({projectId}));
            setCurrentCoId(null);
          }
        });
      },
    });
  }

  function onDesignerSelect(option) {
    if (!option) {
      setValues(prev => ({...prev, designer: "", designerPersent: ""}));
      return;
    }

    const {value, bonus} = option;
    setValues(prev => ({...prev, designer: value, designerPersent: bonus}));
  }
  function onAgentSelect(option) {
    if (!option) {
      setValues(prev => ({...prev, agent: "", agentPersent: ""}));
      return;
    }

    const {value, bonus} = option;
    setValues(prev => ({...prev, agent: value, agentPersent: bonus}));
  }

  return {
    coTableColumns,
    coTableData,
    onCoTableRowSelect,
    currentCoId,
    onCreateCoBtnClick,
    onSaveCoBtnClick,
    onDeleteCoBtnClick,
    coManagerOptions,
    designerOptions,
    designerTypeOptions,
    coAgentOptions,
    agentTypeOptions,
    projectId,
    onDesignerSelect,
    onAgentSelect,
    hasCoChanged,
    remindBtnColor,
    coLoading,
  };
};

export default useCoSection;
