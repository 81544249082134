import React from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import ReadOnlyFields from "../../../../hoc/ReadOnlyFields";
import FieldWithSample from "../../../../Inputs/FieldWithSample";
import useVWCharInputsService from "../../../../SKU/viewWidthCharInputs/vwCharInputsService";

const VolumeWeightCharacteristicsKpf = ({readOnly}) => {
  const {values} = useFormikContext();

  const {t} = useTranslation();

  const {area, dimension, volume, weight} = useVWCharInputsService("6em", "Dimension");

  return (
    <div className="" style={{display: "flex", flexDirection: "row", gap: "10px"}}>
      {values?.length !== null ||
      values?.width !== null ||
      (values?.height !== null && (+values?.length > 0 || +values?.width > 0 || +values?.height > 0)) ? (
        <FieldWithSample name="overallSizeCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "auto"}}>
          {dimension}
        </FieldWithSample>
      ) : null}
      {values?.area !== null && +values?.area > 0 && (
        <FieldWithSample name="areaCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "auto"}}>
          {area}
        </FieldWithSample>
      )}
      {values?.volume !== null && +values?.volume > 0 && (
        <FieldWithSample name="volumeCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "auto"}}>
          {volume}
        </FieldWithSample>
      )}
      {values?.weight !== null && +values?.weight > 0 && (
        <FieldWithSample name="weightCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "auto"}}>
          {weight}
        </FieldWithSample>
      )}
      {values?.pallet !== null && (
        <FieldWithSample name="palletCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "auto"}}>
          <div className=" nowrap autolabel">
            <label>{t("Pallet")}</label>

            <input name="" value={values.pallet?.name} readOnly={true} style={{width: "7em"}} />

            <label>{t("Length")}</label>
            <input name="" value={values.pallet?.length} readOnly={true} style={{width: "5em"}} />
            <label>{t("Width")}</label>
            <input name="" value={values.pallet?.width} readOnly={true} style={{width: "5em"}} />
          </div>
        </FieldWithSample>
      )}
    </div>
  );
};

export default VolumeWeightCharacteristicsKpf;
