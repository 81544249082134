export const CONTRACT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Contract witn num",
    accessorKey: "contractNumber",
    isSortable: true,
    // size: 100,
  },
  {
    header: "Project to",
    accessorKey: "projectTo",
    // size: 100,
  },
  {
    header: "Briefly about",
    accessorKey: "note",
    // size: 100,
  },
  {
    header: "Project Sum",
    accessorKey: "sum",
    // size: 100,
  },
]);

export const DATA_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "CO Annex №",
    accessorKey: "annexNumber",
    isSortable: true,
    // size: 100,
  },
  {
    header: "Take To",
    accessorKey: "takeTo",
    // size: 100,
  },
  {
    header: "Briefly about",
    accessorKey: "note",
    // size: 100,
  },
  {
    header: "CO Sum",
    accessorKey: "sum",
    // size: 100,
  },
]);

export const SCRSH_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "ScreenShot №",
    accessorKey: "einfoNumber",
    isSortable: true,
    // size: 100,
  },
  {
    header: "Date S.",
    accessorKey: "createdAt",
    // size: 100,
  },
  {
    header: "Briefly about",
    accessorKey: "note",
    // size: 100,
  },
]);
