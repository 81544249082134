import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import infoIcon from "../../../../images/icons/infoIcon.png";
import {clearSelectedTemplates, createCofFromTemplate} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import {dateFormat} from "../../../../utilities/dateFormat";
import makeNameFromPersona from "../../../../utilities/makeNameFromPersona";
import ReactTable from "../../../conteiners/ReactTable";
import {clearCurrentCO, fetchAllCOs, fetchCO, selectAllCOs, selectCurrentCO} from "../../../Project/ProjectSlice";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {CREATE_CO_TABLE_COLUMNS} from "../../constants";

const ChooseCoModal = ({setModalActive, templateIds, setDisabledTemplates}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();

  const createCoFTableColumns = useColumns(CREATE_CO_TABLE_COLUMNS);

  const allCOs = useSelector(selectAllCOs);
  const currentCo = useSelector(selectCurrentCO);

  useEffect(() => {
    dispatch(fetchAllCOs({isCurrent: true}));
  }, []);

  const cosTableData = useMemo(
    () =>
      allCOs?.map(({coId, coNumber, note, startDate, finalDate, managerCo, project}, index) => ({
        coNumber,
        id: coId,
        managerP: makeNameFromPersona({persona: project?.projectManager?.persona, language}),
        managerCO: makeNameFromPersona({persona: managerCo?.persona, language}),
        note,
        dateFrom: dateFormat(startDate),
        dateTo: dateFormat(finalDate),
      })) || [],
    [allCOs],
  );

  const onCoSelect = value => {
    if (!value) {
      dispatch(clearCurrentCO());
      return;
    }
    dispatch(fetchCO({coId: value}));
  };

  const onCreateCofClick = () => {
    dispatch(createCofFromTemplate({coId: currentCo.coId, cofTemplateIds: {cofTemplateIds: templateIds}})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setModalActive(false);
        // dispatch(deselectTemplate());
        Swal.fire({
          title: "",
          text: t("Co(f) created"),
          icon: "info",
          confirmButtonText: t("Ok"),
          showCancelButton: false,
        });
        if (setDisabledTemplates) {
          setDisabledTemplates([]);
        }
        dispatch(clearSelectedTemplates());
      }
    });
  };

  return (
    <div style={{padding: "1em 3em"}}>
      <div className="fdr aic mb" style={{fontSize: "30px", gap: "8em"}}>
        <ImageButton src={infoIcon} alt="info" width={3} height={3} />
        <div className="fcCols gap3x jcc">
          <div>{t("CO(f) will be created")}</div>
          <div>
            {t("from CO №")} {currentCo?.coNumber}
          </div>
        </div>
      </div>
      <ReactTable
        defaultData={cosTableData}
        columns={createCoFTableColumns}
        current={currentCo?.coId || null}
        onSelect={onCoSelect}
        className="project_table mb"
        style={{height: "20em"}}
      />
      <div className="fdr gap2x jcsa">
        <FunctionButton name={t("Create CO(f)")} onClick={onCreateCofClick} disabled={!currentCo} autoWidth />
        <FunctionButton name={t("Cancel")} onClick={() => setModalActive(false)} autoWidth />
      </div>
    </div>
  );
};

export default ChooseCoModal;
