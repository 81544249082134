import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {fetchRepaymentTerms, selectRepaymentTerms} from "../../../../../FinBlock/FinSettings/FinSettingsSlice";
import {DateInput, MoneyInput, TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";

const CreditData = ({readOnly}) => {
  const {
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  const {
    values: {creditTermComposed, perAnnumComposed, repaymentTermIdComposed, creditSumComposed, areAllFieldsFilledIn},
    setFieldValue,
  } = useFormikContext();

  const repaymentTerms = useSelector(selectRepaymentTerms);
  const repaymentTermsOptions =
    repaymentTerms?.map(({repaymentTermsId, nameEng, nameUkr}) => ({
      title: language === "en" ? nameEng : nameUkr,
      value: repaymentTermsId,
    })) ?? [];

  useEffect(() => {
    dispatch(fetchRepaymentTerms());
  }, []);

  useEffect(() => {
    if (!creditTermComposed || !perAnnumComposed || !repaymentTermIdComposed || !creditSumComposed) {
      setFieldValue("areAllFieldsFilledIn", false);
    }
    if (
      creditTermComposed &&
      perAnnumComposed &&
      repaymentTermIdComposed &&
      creditSumComposed &&
      !areAllFieldsFilledIn
    ) {
      setFieldValue("areAllFieldsFilledIn", true);
    }
  }, [creditTermComposed, perAnnumComposed, repaymentTermIdComposed, creditSumComposed, areAllFieldsFilledIn]);

  return (
    <div className="w100">
      <div className="fcCol aist gap mb3x">
        <DateInput
          name="creditTermComposed"
          label={t("Credit Term*")}
          autolabel
          readOnly={readOnly}
          width="10em"
          required
        />
        <TextInput
          name="perAnnumComposed"
          label={t("%%per annum per year")}
          autolabel
          readOnly={readOnly}
          width="10em"
          required
        />
        <Select
          name="repaymentTermIdComposed"
          label={t("repayment %%")}
          readOnly={readOnly}
          options={repaymentTermsOptions}
          generalClassName="w100"
          optionsClassName="w100"
          required
        />
      </div>
      <MoneyInput
        name="creditSumComposed"
        label={t("Credit Sum")}
        autolabel
        width="10em"
        readOnly={readOnly}
        required
      />
    </div>
  );
};

export default CreditData;
