import {STATUS_COLORS} from "./colorConstants";

export default function calcStatusColor({startDate, finalDate}) {
  if (startDate && finalDate) {
    const startDateMs = new Date(startDate).getTime();
    const finalDateMs = new Date(finalDate).getTime();
    const currentDateMs = new Date().getTime();

    const totalPeriod = finalDateMs - startDateMs;

    if (startDateMs > finalDateMs) return STATUS_COLORS.RED;
    if (currentDateMs < startDateMs) return STATUS_COLORS.GREEN;
    if (currentDateMs > finalDateMs) {
      return (currentDateMs - finalDateMs) / totalPeriod >= 0.2 ? STATUS_COLORS.RED : STATUS_COLORS.YELLOW;
    }
    if (currentDateMs <= finalDateMs) {
      return (currentDateMs - startDateMs) / totalPeriod >= 0.8 ? STATUS_COLORS.YELLOW : STATUS_COLORS.GREEN;
    }
  }
  return STATUS_COLORS.BLUE;
}
