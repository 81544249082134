const useProjectDirectiveListView = () => {
  const fileArray = [];

  function onCancelImplementationBtnClick() {}
  function onExitBtnClick() {}

  return {onCancelImplementationBtnClick, onExitBtnClick, fileArray};
};

export default useProjectDirectiveListView;
