import {useState} from "react";
import {useTranslation} from "react-i18next";

import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import Filters from "../../../Filters/Filters";
import ModalForm from "../../../Modal/FormModal";

import FunctionButton from "./../../../../utilities/Buttons/FunctionButton";
import CashView from "./CashView/CashView";
import useCashRegistrModal from "./useCashRegistrModal";

const CashRegistrModal = () => {
  const {t} = useTranslation();

  const [cashViewModalActive, setCashViewModalActive] = useState(false);

  const {filterFields, cashRegistrTableColumns, cashRegistrTableData, onCashRegistrTableSelect, getFilterData} =
    useCashRegistrModal();

  return (
    <div className="fcCol h100">
      <Filters window="CashRegistrModal" fields={filterFields} getFilterData={getFilterData} />
      <FunctionButton
        name={"view modal"}
        onClick={() => {
          setCashViewModalActive(true);
        }}
      />
      <Divider />
      <ReactTable
        columns={cashRegistrTableColumns}
        defaultData={cashRegistrTableData}
        current={null}
        onSelect={onCashRegistrTableSelect}
        className="tableTac fGrow"
        tableClassName="fGrow"
      />
      <ModalForm
        closable={true}
        Component={CashView}
        label={t("Cash transactions view")}
        active={cashViewModalActive}
        setModalActive={setCashViewModalActive}
      />
    </div>
  );
};

export default CashRegistrModal;
