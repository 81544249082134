import {withFormik} from "formik";
import {t} from "i18next";

import ReadyIcon from "../../../images/icons/ready.svg";
import SaveIcon from "../../../images/icons/SaveDisket.png";
import UploadIcon from "../../../images/icons/Upload.png";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import Divider from "../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import {DateInput, TextAreaInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import SingleFilePicker from "../../Inputs/SingleFilePicker";
import NewPdfViewer from "../../Project/Documents/DocumentView/NewPdfViewer";

import useProjectTaskList from "./useProjectTaskList";

const ProjectTasksList = () => {
  const {
    fileArray,
    appealTypes,
    appealListTableColumns,
    appealListTableData,
    onAddAppealNoteBtnClick,
    officeOptions,
    managerOptions,
    appealBlockOptions,
    onSaveBtnClick,
    onMoneyBtnClick,
  } = useProjectTaskList();

  return (
    <PageContainer title={t("Tasks list")}>
      <SessionFiltersContainer window="ProjectTasksList">
        <Filters>
          <div className="fcCol w100 gap h100 aistr">
            <div className="row gap">
              <Select label={t("Appeal type")} options={appealTypes} name="appealTypeId" />
              <Select label={t("Manager ")} options={managerOptions} name="managerId" />
              <Select label={t("Appeal block")} options={appealBlockOptions} name="appealBlockId" />

              <DateInput label={t("Period From")} name="dateStart" />
            </div>
            <div className="row gap">
              <Select label={t("Office ")} options={officeOptions} name="officeId" />
              <TextInput label={t("Appeal text")} name="appealTextInFilter" generalClassName="fGrow" />
              <DateInput label={t("To")} name="dateEnd" />
            </div>
          </div>
        </Filters>
      </SessionFiltersContainer>
      <Divider />
      <div className="grid2ColFirstBigger fGrow pb" style={{gap: "5rem"}}>
        <div className="fcCol gap relative">
          <ReactTable columns={appealListTableColumns} defaultData={appealListTableData} tableClassName="fGrow" />
          <div style={{position: "absolute", right: "-4.2rem", top: 0}}>
            <ImageButton src={ReadyIcon} size={2.2} onClick={onMoneyBtnClick} />
          </div>
          <TextAreaInput name="appealText" height="12rem" placeholder={t("appeal text")} />
          <Divider />
          <div className="row w100 jcfs">
            <FunctionButton name="Add an appeal note" onClick={onAddAppealNoteBtnClick} autoWidth />
          </div>
          <div className="relative w100">
            <TextAreaInput name="appealTextAreaEnter" height="12rem" placeholder={t("appeal response text")} />
            <div className="fcCol jcsb" style={{position: "absolute", right: "-4.2rem", top: 0, height: "12rem"}}>
              <SingleFilePicker src={UploadIcon} width={2.6} height={2} />
              <ImageButton src={SaveIcon} size={2.2} onClick={onSaveBtnClick} />
            </div>
          </div>
        </div>
        <div className="kpPreview__box w100 h100">
          <NewPdfViewer fileArray={fileArray ?? []} />
        </div>
      </div>
    </PageContainer>
  );
};

export default withFormik({enableReinitialize: true})(ProjectTasksList);
