import {useState} from "react";
import {useTranslation} from "react-i18next";

import ImgBox from "../../Inputs/fileSelect/ImgBox/ImgBox.js";
import {processImages} from "../../SKU/VED/constants/functions.js";

const TemplateImageBlock = ({label, images}) => {
  const {t} = useTranslation();

  const updatedImagesFromSku = processImages(images);

  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  const onActiveImgChange = index => {
    setCurrentImgIndex(index);
  };

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <label className="label" style={{marginBottom: "12px"}}>
        {t(label)}
      </label>
      <div className="kpf_img_box" style={{position: "relative"}}>
        <div style={{position: "absolute", height: "100%"}}>
          <ImgBox
            imgs={updatedImagesFromSku || []}
            width={"10em"}
            height={"10em"}
            onActiveImgChange={onActiveImgChange}
          ></ImgBox>
        </div>
      </div>
    </div>
  );
};

export default TemplateImageBlock;
