import {withFormik} from "formik";
import {t} from "i18next";

import DeleteIcon from "../../images/icons/deleteTrash.png";
import SaveIcon from "../../images/icons/SaveDisket.png";
import UploadIcon from "../../images/icons/Upload.png";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import ImageButton from "../../utilities/Buttons/ImageBtn";
import Divider from "../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../conteiners/filters/Filter.hoc";
import PageContainer from "../conteiners/PageContainer";
import ReactTable from "../conteiners/ReactTable";
import {DateInput, TextAreaInput, TextInput} from "../Inputs";
import {Select} from "../Inputs/SelectField";
import SingleFilePicker from "../Inputs/SingleFilePicker";
import NewPdfViewer from "../Project/Documents/DocumentView/NewPdfViewer";

import useDevIT from "./useDevIT";

const DevIT = () => {
  const {
    fileArray,
    appealTypes,
    appealListTableColumns,
    appealListTableData,
    onAddAppealTypeBtnClick,
    onSaveBtnClick,
    onDeleteBtnClick,
  } = useDevIT();

  return (
    <PageContainer title={t("Appeal to dev IT")}>
      <SessionFiltersContainer window="DevIT">
        <Filters>
          <div className="row w100 gap h100 aic">
            <Select label={t("Appeal type")} options={appealTypes} name="appealTypeId" />
            <TextInput label={t("Appeal text")} name="appealTextInFilter" generalClassName="fGrow" />
            <DateInput label={t("Date from")} name="dateStart" />
            <DateInput label={t("To")} name="dateEnd" />
          </div>
        </Filters>
      </SessionFiltersContainer>
      <Divider />
      <div className="grid2ColFirstBigger fGrow pb gap">
        <div className="fcCol gap">
          <div className="w100">
            <div className="row">
              <div className="row">
                <FunctionButton name={`${t("Add appeal type")} ▼`} onClick={onAddAppealTypeBtnClick} autoWidth />
                <TextInput name="addedAppealType" width="25rem" readOnly />
              </div>
              {/* <Select label={t("Add appeal type")} options={appealTypes} name="addedAppealTypeId" /> */}
              <div className="row gap aic">
                <SingleFilePicker src={UploadIcon} width={2.5} height={2} />
                <ImageButton src={SaveIcon} size={1.8} onClick={onSaveBtnClick} />
                <ImageButton src={DeleteIcon} size={2} onClick={onDeleteBtnClick} />
              </div>
            </div>
            <TextAreaInput
              name="appealTextAreaEnter"
              height="12rem"
              placeholder={t("Enter appeal text (5000 chars with spaces)")}
            />
          </div>
          <Divider />
          <div className="w100 fGrow">
            <div className="row jcfe gap"></div>
            <ReactTable columns={appealListTableColumns} defaultData={appealListTableData} tableClassName="fGrow" />
          </div>
          <TextAreaInput name="appealTextAreaView" placeholder={t("Appeal text")} height="12rem" />
        </div>
        <div className="kpPreview__box w100 h100">
          <NewPdfViewer fileArray={fileArray ?? []} />
        </div>
      </div>
    </PageContainer>
  );
};

export default withFormik({enableReinitialize: true})(DevIT);
