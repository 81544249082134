import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import withTabs from "../../conteiners/tabs/Tabs.hoc";
import ToDoSection from "../../Project/ProjectSecondVersion/ProjectPageSections/ToDoSection";
import {fetchAllCOs, selectCurrentCO} from "../../Project/ProjectSlice";
import {
  clearAllCoas,
  clearAllCofs,
  clearCurrentCoa,
  clearCurrentCof,
  fetchAllCOas,
  fetchAllCOfs,
} from "../kp-f/KpFinSlice";

import CreateKpPageSection from "./KpPageSections/CreateKpPageSection";
import KpMainListAndForm from "./KpPageSections/KpMainListAndForm";
import KpPreviewAndMainButtonsSection from "./KpPageSections/KpPreviewAndMainButtonsSection";

export const CO_STATUSES = Object.freeze({CREATED: 1, MAIN: 4});

const KpSecondVersion = () => {
  const dispatch = useDispatch();

  const currentCo = useSelector(selectCurrentCO);
  const currentCoId = currentCo?.coId;

  useEffect(() => {
    dispatch(fetchAllCOs({isCurrent: true}));
  }, []);

  useEffect(() => {
    if (currentCo?.coId) {
      dispatch(fetchAllCOfs({coId: currentCo?.coId}));
      dispatch(fetchAllCOas({coId: currentCo?.coId}));
    } else {
      dispatch(clearAllCofs());
      dispatch(clearAllCoas());
      dispatch(clearCurrentCof());
      dispatch(clearCurrentCoa());
    }
  }, [currentCo?.coId]);

  return (
    <div className="form_wrapper pt pb">
      <div className="two-column-page__container" style={{maxHeight: "100%"}}>
        <div className="left-side">
          <KpMainListAndForm />
          <CreateKpPageSection type={"f"} />
          <CreateKpPageSection type={"a"} />
        </div>
        <div className="right-side w100">
          <KpPreviewAndMainButtonsSection />
          <ToDoSection coId={currentCoId} noProject />
        </div>
      </div>
    </div>
  );
};

export default withTabs({isMainTabControlled: true, formInitialValues: {}, isClosableTabs: true, label: "CO"})(
  KpSecondVersion,
);
