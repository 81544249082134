import {
  ORDER_DIRECTIVE_VIEW_LOWER_TABLE_COLUMNS,
  ORDER_DIRECTIVE_VIEW_UPPER_TABLE_COLUMNS,
} from "../../../Project/ProjectDirectiveList/OrderDirectiveView/constants";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";

const useFinBlockOrderDirectiveDo = () => {
  const upperTableColumns = useColumns(ORDER_DIRECTIVE_VIEW_UPPER_TABLE_COLUMNS);
  const upperTableData = [];

  const lowerTableColumns = useColumns(ORDER_DIRECTIVE_VIEW_LOWER_TABLE_COLUMNS);
  const lowerTableData = [];

  function onPrintOrderBtnClick() {}
  function onExitBtnClick() {}
  function onDeleteOrderBtnClick() {}
  function onRunOrderBtnClick() {}
  function onSaveBtnClick() {}
  function onCreateOrderBtnClick() {}
  function onOkBtnClick() {}

  return {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    onExitBtnClick,
    onDeleteOrderBtnClick,
    onRunOrderBtnClick,
    onSaveBtnClick,
    onCreateOrderBtnClick,
    onOkBtnClick,
  };
};

export default useFinBlockOrderDirectiveDo;
