import AvailabilityStatusCIrcule from "../../../utilities/AvailabilityStatusCIrcule";

export const KP_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "Status.",
    accessorKey: "status",
    size: 40,
    maxSize: 40,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "CO №",
    accessorKey: "coNumber",
    size: 80,
    maxSize: 80,
    isSortable: true,
  },
  {
    header: "Manager",
    accessorKey: "projectManager",
    size: 150,
    maxSize: 150,
    isSortable: true,
  },
  {
    header: "Manager CO",
    accessorKey: "coManager",
    size: 150,
    maxSize: 150,
    isSortable: true,
  },
  {
    header: "Note/desc",
    accessorKey: "note",
    size: 200,
  },
  {
    header: "Date from",
    accessorKey: "startDate",
    size: 100,
    maxSize: 100,
    isSortable: true,
  },
  {
    header: "Date to",
    accessorKey: "finalDate",
    size: 100,
    maxSize: 100,
    isSortable: true,
  },
]);

export const KP_F_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "Status",
    accessorKey: "status",
    size: 40,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "№nn",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "CO(f) №",
    accessorKey: "cofNumber",
    // size: 100,
  },
  {
    header: "Manager",
    accessorKey: "projectManager",
    // size: 100,
  },
  {
    header: "Manager CO",
    accessorKey: "coManager",
    // size: 60,
  },
  {
    header: "Note/desc",
    accessorKey: "note",
    size: 400,
  },
  {
    header: "Date from",
    accessorKey: "startDate",
    // size: 100,
  },
  {
    header: "Date to",
    accessorKey: "finalDate",
    // size: 100,
  },
]);

export const KP_A_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "Status",
    accessorKey: "status",
    size: 40,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "№nn",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "CO(a) №",
    accessorKey: "coaNumber",
    // size: 100,
  },

  {
    header: "Manager",
    accessorKey: "projectManager",
    // size: 100,
  },
  {
    header: "Manager CO",
    accessorKey: "coManager",
    // size: 60,
  },
  {
    header: "Note/desc",
    accessorKey: "note",
    size: 400,
  },
  {
    header: "Date from",
    accessorKey: "startDate",
    // size: 100,
  },
  {
    header: "Date to",
    accessorKey: "finalDate",
    // size: 100,
  },
]);

export const KP_TO_DO_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 25,
    cell: value => <div className={value.row.original.status === 1 ? "strikethroughRow" : ""}>{value.getValue()}</div>,
  },
  {
    header: "CO(..)№",
    accessorKey: "coNumber",
    cell: value => <div className={value.row.original.status === 1 ? "strikethroughRow" : ""}>{value.getValue()}</div>,
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    size: 400,
    cell: value => <div className={value.row.original.status === 1 ? "strikethroughRow" : ""}>{value.getValue()}</div>,
  },
  {
    header: "D-day",
    accessorKey: "dueDate",
    cell: value => <div className={value.row.original.status === 1 ? "strikethroughRow" : ""}>{value.getValue()}</div>,
    // size: 60,
    isSortable: true,
  },
  {
    header: "Status",
    accessorKey: "status",
    // cell: status => (
    //   <div style={{fontStyle: "normal"}}>{status.getValue() === 1 ? "✓" : status.getValue() === 0 ? "⏰" : ""}</div>
    // ),
    cell: circleColor =>
      circleColor.getValue() === "done" ? (
        <div style={{fontStyle: "normal"}}>✓</div>
      ) : (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
          <AvailabilityStatusCIrcule color={circleColor.getValue()} />
        </div>
      ),
    // size: 60,
    isSortable: true,
  },
]);
