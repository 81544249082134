import {useTabsContext} from "../../conteiners/tabs/useTabs";
import {FIELD_TYPES} from "../../Filters/Filters";
import {PROJECT_DIRECTIVE_TABLE_COLUMNS} from "../../Project/ProjectDirectiveList/constants";
import {ProjectView} from "../../Project/ProjectView/ProjectView";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";

import FinBlockOrderRegister from "./FinBlockOrderRegister/FinBlockOrderRegister";
import FinBlockOrderView from "./FinBlockOrderView/FinBlockOrderView";

const useFinBlockDirectiveList = () => {
  const {addNewTabCustom} = useTabsContext();

  const clientNumberOptions = [];
  const coNumberOptions = [];
  const operationTypeOptions = [];
  const statusOptions = [];
  const sumOptions = [];
  const payerDataOptions = [];

  function onOpenCreateInnerDirectieveModalBtnClick() {
    addNewTabCustom({
      //   TabComponent: CreateInnerDirectiveModal,
      controlled: true,
      title: "Directive №",
      tabIdName: "createOrderTab",
    });
  }

  const filterFields = [
    {type: FIELD_TYPES.TEXT, name: "directiveNumber", label: "Directive №", style: {width: "20em"}},
    {
      type: FIELD_TYPES.SELECT,
      name: "clientNumber",
      label: "Client №",
      options: clientNumberOptions,
      style: {width: "20em"},
    },
    {type: FIELD_TYPES.SELECT, name: "coNumber", label: "CO №", options: coNumberOptions, style: {width: "20em"}},
    {
      type: FIELD_TYPES.SELECT,
      name: "operationType",
      label: "Transaction type",
      options: operationTypeOptions,
      style: {width: "20em"},
    },
    {type: FIELD_TYPES.DATE, name: "dateFrom", label: "Period From"},
    {type: FIELD_TYPES.NEW_LINE},

    {type: FIELD_TYPES.SELECT, name: "status", label: "Status", options: statusOptions, style: {width: "20em"}},
    {
      type: FIELD_TYPES.SELECT,
      name: "sum",
      label: "Sum (s/t)",
      options: sumOptions,
      style: {width: "20em"},
    },
    {
      type: FIELD_TYPES.SELECT,
      name: "payerData",
      label: "Payer data",
      options: payerDataOptions,
      style: {width: "20em"},
    },
    {type: FIELD_TYPES.TEXT, name: "payerNumber", label: " ", style: {width: "20em"}},

    {type: FIELD_TYPES.DATE, name: "dateTo", label: "To", style: {justifyContent: "flex-end"}},
  ];
  function getFilterData() {}

  const projectDirectiveTableColumns = useColumns(PROJECT_DIRECTIVE_TABLE_COLUMNS);
  const projectDirectiveTableData = [];
  function onProjectDirectiveTableSelect() {}

  function onOpenDirectiveBtnClick() {
    addNewTabCustom({
      TabComponent: FinBlockOrderView,
      title: "Directive №",
      tabIdName: "viewOrderTab",
    });
    addNewTabCustom({
      TabComponent: FinBlockOrderRegister,
      title: "Directive`s Order register",
    });
  }

  function onOpenProjectBtnClick() {
    addNewTabCustom({
      TabComponent: ProjectView,
      title: "Project View",
    });
  }

  return {
    filterFields,
    getFilterData,
    projectDirectiveTableColumns,
    projectDirectiveTableData,
    onProjectDirectiveTableSelect,
    onOpenCreateInnerDirectieveModalBtnClick,
    onOpenDirectiveBtnClick,
    onOpenProjectBtnClick,
  };
};

export default useFinBlockDirectiveList;
