import AvailabilityStatusCIrcule from "../../../utilities/AvailabilityStatusCIrcule";
import {STATUS_COLORS} from "../../../utilities/colorConstants";

export const PROJECT_TABLE_COLUMNS = Object.freeze([
  {
    header: (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={STATUS_COLORS.DARK_BLUE} />
      </div>
    ),
    accessorKey: "statusCircle",
    // size: 50,
    maxSize: 20,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 50,
  },

  {
    header: "Date from",
    accessorKey: "dateFrom",
    maxSize: 70,
  },

  {
    header: "Term P. To",
    accessorKey: "dateTo",
    maxSize: 70,
  },
  {
    header: "Project #",
    accessorKey: "projectNumber",
    isSortable: true,
    // size: 100,
  },
  {
    header: "Address",
    accessorKey: "address",
    isSortable: true,
    size: 270,
    maxSize: 270,
  },
  {
    header: "Sum (n)",
    accessorKey: "planSum",
    // size: ,
  },
  {
    header: "Sum (f)",
    accessorKey: "factSum",
    // size: 100,
  },
  {
    header: "Project Design",
    accessorKey: "projectDesign",
    // size: 100,
  },
  {
    header: "Manager",
    accessorKey: "projectManager",
  },
]);

export const CO_TABLE_COLUMNS = Object.freeze([
  {
    header: (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={STATUS_COLORS.DARK_BLUE} />
      </div>
    ),
    accessorKey: "statusCircle",
    size: 50,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "№пп",
    accessorKey: "number",
    size: 50,
    maxSize: 50,
  },
  {
    header: "Date from",
    accessorKey: "startDate",
    isSortable: true,
    size: 120,
    maxSize: 120,
  },

  {
    header: "Date to",
    accessorKey: "finalDate",
    isSortable: true,
    size: 120,
    maxSize: 120,
  },
  {
    header: "CO №",
    accessorKey: "coNumber",
    isSortable: true,
    size: 120,
    maxSize: 120,
  },
  {
    header: "CO description",
    accessorKey: "coDescription",
    // isSortable: true,
    size: 200,
    maxSize: 200,
  },
  {
    header: "CO Sum",
    accessorKey: "sum",
    size: 70,
    maxSize: 70,
  },
  {
    header: "Sum (n)",
    accessorKey: "planSum",
    size: 70,
    maxSize: 70,
  },
  {
    header: "Sum (f)",
    accessorKey: "factSum",
    size: 70,
    maxSize: 70,
  },
  {
    header: "%%",
    accessorKey: "persent",
    size: 70,
    maxSize: 70,
  },
  {
    header: "Date (f)",
    accessorKey: "factDate",
    size: 70,
    maxSize: 70,
  },
  {
    header: "Manager",
    accessorKey: "managerP",
    size: 150,
    maxSize: 150,
  },
  {
    header: "Manager CO",
    accessorKey: "managerCO",
    size: 150,
    maxSize: 150,
  },
]);

export const TODO_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },

  {
    header: "Date from",
    accessorKey: "dateFrom",
    // size: 200,
  },

  {
    header: "Date to",
    accessorKey: "dateTo",
    // size: 250,
  },
  {
    header: "CO №",
    accessorKey: "coNumber",
    // isSortable: true,
    // size: 100,
  },
  {
    header: "CO description",
    accessorKey: "coDescription",
    // isSortable: true,
    // size: 100,
  },
  {
    header: "Sum",
    accessorKey: "coSum",
    // size: 200,
  },

  {
    header: "Manager",
    accessorKey: "managerP",
    // size: 100,
  },
  {
    header: "Manager CO",
    accessorKey: "managerCO",
    // size: 100,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 50,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
]);
