import {useField, useFormikContext} from "formik";
import PropTypes from "prop-types";

import saveImg from "../../images/icons/save.png";
import delImg from "../../images/icons/trash.png";

import "./utils/Inputs.scss";

const TextAreaInput = ({
  label,
  width,
  areaWidth = "100%",
  height,
  areaHeight,
  id,
  action = false,
  className = "",
  style,
  labelWrap,
  placeholder = "",
  inputref,
  required,
  onBlur = () => {},
  readOnly = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();

  const delAll = () => {
    setFieldValue(field.name, "");
  };

  const styles = {
    width,
    height,
    ...style,
  };

  const labelText = label
    ? label?.[label?.length - 1] === ":"
      ? required
        ? label + "*"
        : label
      : (required ? label + "*" : label) + ":"
    : "";
  return (
    <div className={`${className} project_input textereaInput ${labelWrap ? "" : "labelWrap"}`} style={styles} id={id}>
      <div className="flex_container" style={{justifyContent: "space-between"}}>
        {label && (
          <label className="textArea_label" htmlFor={props.name}>
            {labelText}
          </label>
        )}
        {action ? (
          <div className="">
            <img src={saveImg} alt="" draggable="false" />
            <img src={delImg} alt="" onClick={delAll} draggable="false" />
          </div>
        ) : null}
      </div>
      <textarea
        {...props}
        {...field}
        value={field.value || ""}
        style={{width: areaWidth, height: areaHeight, cursor: readOnly ? "default" : "text"}}
        placeholder={placeholder}
        ref={inputref}
        onBlur={onBlur}
        readOnly={readOnly}
      />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

TextAreaInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  areaWidth: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
  action: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  props: PropTypes.array,
};

export default TextAreaInput;
