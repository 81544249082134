import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import {isEqual} from "lodash";
import Swal from "sweetalert2";

import {NumberInput, PriceInput, TextInput} from "../../../../Inputs";
import FieldWithSample from "../../../../Inputs/FieldWithSample";
import MoneyReadInput from "../../../../Inputs/MoneyReadInput";

const SummBlock = ({readOnly, initialLine}) => {
  const {t} = useTranslation();
  const {values, setValues, resetForm} = useFormikContext();

  const {
    values: {amount, listPrice, retailPrice, discount, topDiscount, topDiscountCheck, discountCheck},
  } = useFormikContext();

  const [summ, setSumm] = useState(null),
    [total, setTotal] = useState(null),
    [toPayment, setToPayment] = useState(null);

  const initialValues = {
    listPrice: null,
    retailPrice: null,
    sku: {minimumPrice: null},
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isEqual(initialValues, values)) {
        setValues(initialLine);
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [initialValues, values]);

  useEffect(() => {
    var initPrice = retailPrice || listPrice;
    var sum_ = +initPrice * (amount || 1);
    setSumm(sum_);
  }, [amount, retailPrice, listPrice]);

  useEffect(() => {
    setTotal(discountCheck && discount ? summ - (summ * discount) / 100 : summ);
  }, [summ, discount, discountCheck]);

  useEffect(() => {
    setToPayment(topDiscountCheck && topDiscount ? total - (total * topDiscount) / 100 : total);
  }, [summ, total, topDiscount, topDiscountCheck]);

  useEffect(() => {
    const minimumPrice = values?.sku?.minimumPrice ?? values?.listPrice;

    if (minimumPrice && (topDiscountCheck || discountCheck)) {
      if (toPayment && +minimumPrice > toPayment) {
        Swal.fire({
          text: t("kpf discount"),
          icon: "error",
          confirmButtonText: "Ok",
          showCancelButton: false,
        });
      }
    }
  }, [toPayment]);

  // useEffect(() => {
  //   if (values?.retailPrice === "" || values?.retailPrice === null) {
  //     Swal.fire({
  //       text: t("Enter RR price"),
  //       icon: "error",
  //       confirmButtonText: "Ok",
  //       showCancelButton: false,
  //     });
  //   }
  // }, [values?.retailPrice]);

  return (
    <div className="flex_container summ-block">
      <div className="summ-item">
        <PriceInput
          label={t("Price")}
          name="listPrice"
          width="6em"
          procent="100"
          readOnly={true}
          labelStyles={{width: "5em"}}
        />
      </div>
      <div className="summ-item">
        <PriceInput
          label={t("RR price")}
          name="retailPrice"
          procent={values?.retailPrice && values?.listPrice ? (values.retailPrice * 100) / values.listPrice : 0}
          labelStyles={{width: "5em"}}
        />
      </div>
      <div className="summ-item">
        <FieldWithSample
          name="discountCheck"
          label={t("Discount")}
          style={{display: "flex", alignItems: "end"}}
          readOnly={readOnly}
        >
          <NumberInput
            name="discount"
            width="6em"
            className="procent"
            min={0}
            max={80}
            generalClassName="summ-item"
            readOnly={readOnly}
          />
        </FieldWithSample>
      </div>
      <div className="summ-item">
        <FieldWithSample
          name="topDiscountCheck"
          label={t("Spec disc")}
          style={{display: "flex", alignItems: "end"}}
          readOnly={readOnly}
        >
          <NumberInput name="topDiscount" width="6em" className="procent" min={0} max="100" readOnly={readOnly} />
        </FieldWithSample>
      </div>
      <div className="summ-item">
        <PriceInput
          label={t("Min price")}
          name="sku.minimumPrice"
          procent="100%"
          readOnly={true}
          labelStyles={{width: "5em"}}
        />
      </div>
      <TextInput
        label={t("Quantity")}
        name="amount"
        type="number"
        width="4em"
        min={1}
        generalClassName="summ-item"
        labelStyles={{width: "5em"}}
        readOnly={readOnly}
      />

      <div className="summ-item">
        <MoneyReadInput
          label={t("Sum")}
          name="summ"
          rawValue={summ}
          readOnly={true}
          width="8em"
          labelStyles={{width: "5em"}}
        />
      </div>

      <div className="summ-item">
        <MoneyReadInput
          label={t("Total")}
          name="total"
          rawValue={total}
          readOnly={true}
          width="8em"
          labelStyles={{width: "5em"}}
        />
      </div>

      <div className="summ-item">
        <MoneyReadInput
          label={t("Payment")}
          name="toPayment"
          rawValue={toPayment}
          readOnly={true}
          max={100}
          width="8em"
          labelStyles={{width: "5em"}}
        />
      </div>
      <div className="summ-item"></div>
    </div>
  );
};

export default SummBlock;
