import AvailabilityStatusCIrcule from "../../utilities/AvailabilityStatusCIrcule";

export const T_KP_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },

  {
    header: "tCO(..)№",
    accessorKey: "templateNumber",
    // size: 200,
  },
  {
    header: "Manager",
    accessorKey: "managerP",
    // size: 100,
  },
  {
    header: "Manager CO",
    accessorKey: "managerCO",
    // size: 100,
  },
  {
    header: "Note/desc",
    accessorKey: "note",
    size: 400,
  },
  {
    header: "Template date",
    accessorKey: "templateDate",
    // size: 250,
  },
]);

export const BRAND_TEMPLATE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    size: 5,
  },

  {
    header: "Country",
    accessorKey: "country",
    size: 200,
  },
  {
    header: "Brand",
    accessorKey: "brand",
    size: 200,
  },
]);

export const CO_TEMPLATES_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 5,
  },

  {
    header: "Brand",
    accessorKey: "brand",
    // size: 200,
  },
  {
    header: "Country",
    accessorKey: "country",
    // size: 200,
  },
  {
    header: "Characteristics",
    accessorKey: "characteristics",
    // size: 200,
  },
  {
    header: "VWC",
    accessorKey: "vwc",
    // size: 200,
  },
  {
    header: "Image",
    accessorKey: "image1",
    // size: 200,
  },
  {
    header: "Image",
    accessorKey: "image2",
    // size: 200,
  },
  {
    header: "Set description",
    accessorKey: "setDesc",
    // size: 200,
  },
  {
    header: "Quantity",
    accessorKey: "quantity",
    // size: 200,
  },
  {
    header: "Price pcs",
    accessorKey: "price",
    // size: 200,
  },
  {
    header: "Sum €",
    accessorKey: "sum",
    // size: 200,
  },
  {
    header: "Discount %",
    accessorKey: "discount",
    // size: 200,
  },
  {
    header: "Total €",
    accessorKey: "total",
    // size: 200,
  },
  {
    header: "Total discount %",
    accessorKey: "totalDiscount",
    // size: 200,
  },
  {
    header: "Payment €",
    accessorKey: "payment",
    // size: 200,
  },
]);

export const CREATE_CO_TABLE_COLUMNS = Object.freeze([
  {
    header: "CO №",
    accessorKey: "coNumber",
    // size: 100,
  },

  {
    header: "Manager",
    accessorKey: "managerP",
    // size: 100,
  },
  {
    header: "Manager CO",
    accessorKey: "managerCO",
    // size: 60,
  },
  {
    header: "Note/desc",
    accessorKey: "note",
    size: 400,
  },
  {
    header: "Date from",
    accessorKey: "dateFrom",
    // size: 100,
  },
  {
    header: "Date to",
    accessorKey: "dateTo",
    // size: 100,
  },
]);
