import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useField, useFormikContext} from "formik";
import {debounce} from "lodash";
import PropTypes from "prop-types";

import {KEYBOARD_CODES} from "../../utilities/keyboardCodes";
import QuestionItem from "../../utilities/QuestionItem";

import useInputsTooltip from "./hook/InputsTooltipService";

export const Select = ({
  label,
  width = "100%",
  // styles = null,
  id,
  labelWrap,
  options, ///// должен быть вида [{value: ###, title: ### }, ....]
  makeEffect = null, /// функция (не обязательная) которая должна сработать когда происходит выбор из выпадающего списка
  question, ///// пояснение к полю
  // nomination = "",
  // multi = false, ///если передаём в массив выбора объекты с несколькими dependentField например {orgId, orgName, orgCode}
  minFilterLength = 3, //// минимальная длина строки для фильтрации поля
  errorField,
  labelwidth = "",
  disabled = false,
  selectOnly = false,
  inputClassName,
  generalClassName = "",
  labelClassName = "",
  optionsAbove = false,
  style,
  textMode = false,
  required,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);

  const [field, meta, helpers] = useField(props);
  const {value} = field;

  const optionsListRef = useRef();
  const optionsItemRef = useRef();
  const optionsListContainerRef = useRef();
  const optionsLength = filteredOptions.length;

  const {initialValues, errors, submitCount, values, setValues} = useFormikContext();

  const [textValue, setTextValue] = useState(initialValues?.[props?.name] ?? "");
  const [currentOption, setCurrentOption] = useState(null);
  const {t, i18n} = useTranslation();

  const {tooltipShown, onMouseEnter, onMouseLeave} = useInputsTooltip(field);

  const labelText = label
    ? label?.[label?.length - 1] === ":"
      ? required
        ? label + "*"
        : label
      : (required ? label + "*" : label) + ":"
    : "";
  useEffect(() => {
    if (!showOptions) {
      setFocusedOptionIndex(null);
    }
  }, [showOptions]);

  function onOptionsListKeyDown(e) {
    const currentOptionsListRef = optionsListRef.current;
    const optionHeight = optionsItemRef.current?.offsetHeight;
    const scrollBottomValue = optionHeight + 0.5692;
    const scrollTopValue = optionHeight - 0.1652;

    switch (e.code) {
      case KEYBOARD_CODES.ENTER:
        if (focusedOptionIndex !== null) {
          handleSelectOption(filteredOptions[focusedOptionIndex]);
        }
        break;

      case KEYBOARD_CODES.ARROW_UP:
        if (focusedOptionIndex !== null) {
          setFocusedOptionIndex(prev => (prev !== 0 ? --prev : optionsLength - 1));
          currentOptionsListRef.scrollTop =
            focusedOptionIndex !== 0
              ? currentOptionsListRef.scrollTop - scrollTopValue
              : currentOptionsListRef.scrollHeight;
        } else {
          setFocusedOptionIndex(optionsLength - 1);
          currentOptionsListRef.scrollTop = currentOptionsListRef.scrollHeight;
        }
        break;

      case KEYBOARD_CODES.ARROW_DOWN:
        if (focusedOptionIndex !== null) {
          setFocusedOptionIndex(prev => (prev !== optionsLength - 1 ? ++prev : 0));
          currentOptionsListRef.scrollTop =
            focusedOptionIndex !== optionsLength - 1 ? currentOptionsListRef.scrollTop + scrollBottomValue : 0;
        } else {
          setFocusedOptionIndex(0);
          currentOptionsListRef.scrollTop = 0;
        }
        break;
    }
  }

  // const fieldValue = value || initialValues[props.name] || "";

  //////////фильтрация

  const updateFilterQuery = filterStr => {
    if (filterStr && filterStr.length >= minFilterLength) {
      setFilter(filterStr);
    } else setFilter("");
  };

  const delayedFilter = debounce(updateFilterQuery, 500);
  // const namingType = useMemo(() => (i18n.language === "en" ? "engName" : "name"), [i18n.language]);

  useEffect(() => {
    var filtered = options.filter(option => {
      if (!option?.title || option.title == "") {
        return false;
      }
      let words = option.title.split(" ");
      // return words.some(word => word.toLowerCase().startsWith(filter.toLowerCase()));
      return words.some(word => word.toLowerCase().includes(filter.toLowerCase()));
    });
    setFilteredOptions(filtered);

    if (filter !== "" && !showOptions && filtered.length > 0 && filtered.length !== options.length) {
      openOptions();
    }
  }, [filter, options]);

  /////////////////////////
  const timeout = useRef();

  const handleSelectOption = option => {
    clearTimeout(timeout.current);
    setShowOptions(false);
    setFilter("");

    helpers.setValue(textMode ? option.title : option.value);
    if (makeEffect) makeEffect(option);
    // setTextValue(option.title)
  };

  const optionList = useMemo(() => {
    return filteredOptions.map((option, i) => {
      var {title} = option;
      const isSelected = focusedOptionIndex !== null && filteredOptions[focusedOptionIndex]?.title === title;

      return (
        <li
          className={`select_option ${isSelected ? "selected_option" : ""}`}
          key={i}
          onClick={() => {
            handleSelectOption(option);
          }}
          ref={optionsItemRef}
        >
          {title.length > 32 ? title.slice(0, 32) + "..." : title}
        </li>
      );
    });
  }, [filteredOptions.length, focusedOptionIndex, filteredOptions]);

  useEffect(() => {
    setShowOptions(false);
    setFilter("");
    // if (value && value !== "" && (options.length === 0 || options.find(opt => opt.value === value)?.length === 0)) {
    //   console.log("remove", value);
    //   setCurrentOption(null);
    //   setTextValue("");
    //   helpers.setValue(null);
    //   return;
    // }
    valueChange(value);
  }, [options]);

  const openOptions = () => {
    if (filteredOptions.length > 0 && !props.readOnly) setShowOptions(true);
  };

  useEffect(() => {
    showAllOptions();
  }, [initialValues]);

  const showAllOptions = useCallback(() => {
    setFilter("");
  }, []);

  //////// findId ////////

  // const updateIdQuery = value => {
  //   if (value && value !== "") {
  //     var item = options.find(option => option.title === value);
  //     if (item) helpers.setValue(item.value);
  //     else {
  //       helpers.setValue(value);
  //       if (makeEffect) makeEffect(null);
  //     }
  //   } else {
  //     {
  //       helpers.setValue(null);
  //       if (makeEffect) makeEffect(null);
  //     }
  //   }
  // };

  const valueChange = value => {
    if (value && value !== "") {
      if (value?.title) {
        setTextValue(value.title);
        if (textMode) helpers.setValue(options.find(option => option.value === value))?.title ?? "";
      } else if (currentOption?.value === value) {
        return;
      } else if (options.length > 0) {
        const option = options.find(option => option.value === value);
        if (option) {
          setTextValue(option.title);
          setCurrentOption(option);
        }
      } else if (options?.length < 1) {
        setTextValue("");
        return;
      }
    } else if ((value === "" || !value) && currentOption && currentOption?.value !== value) {
      setCurrentOption(null);
      setTextValue("");
      // if (textMode) setValues(prev => ({...prev, [props.name]: ""}));
    }
  };

  useEffect(() => {
    valueChange(value);
    if (textMode) setTextValue(value);
  }, [value]);

  useEffect(() => {
    if (textMode) {
      setValues(prev => ({...prev, [props.name]: textValue}));
    }
  }, [textValue]);

  // useEffect(() => {
  //   console.log("uef6");
  //   updateIdQuery(textValue);
  // }, [textValue]);

  const onInputChange = e => {
    const currentText = e.target.value;
    if (currentText && currentText !== "") {
      var option = options.find(option => option.title === currentText);
      if (option) {
        helpers.setValue(textMode ? currentText : option.value);
        setCurrentOption(option);
      } else if (value !== null || value !== "") {
        helpers.setValue(null);
        if (makeEffect) makeEffect(null);
        setCurrentOption(null);
      }
    } else if (value !== null || value !== "") {
      {
        helpers.setValue(null);
        if (makeEffect) makeEffect(null);
        setCurrentOption(null);
      }
    }
  };

  // const findId = debounce(updateIdQuery, 500);

  return (
    <div className={`project_input select ${generalClassName}  ${labelWrap ? "" : "labelWrap"}`} id={id} style={style}>
      {label && (
        <label
          htmlFor={props.name}
          style={{width: labelwidth, whiteSpace: "pre"}}
          className={`${props.autolabel ? "autolabel" : ""} ${labelClassName}`}
        >
          {/* {  typeof label === "string" ? t(label) : label  } */}
          {labelText}
        </label>
      )}
      <div
        style={{display: "flex", position: "relative", width: width, maxHeight: "100px"}}
        onMouseLeave={() => setTimeout(() => setShowOptions(false), 100)}
      >
        <input
          className={inputClassName}
          readOnly={props.readOnly || selectOnly}
          value={textValue}
          autoComplete="off"
          style={{width: "100%"}}
          onClick={openOptions}
          onChange={e => {
            setTextValue(e.target.value);
            onInputChange(e);
            // findId(e.target.value);
            delayedFilter(e.target.value);
            // makeEffect(e.target.value);
          }}
          disabled={disabled}
          onKeyDown={onOptionsListKeyDown}
          onMouseEnter={question ? onMouseEnter : null}
          onMouseLeave={question ? onMouseLeave : null}
        />
        {meta.error && (meta.touched || submitCount > 0) ? <div className="error">{meta.error}</div> : null}
        {errors[errorField] && meta.touched && submitCount > 0 ? (
          <div className="error">{errors[errorField]}</div>
        ) : null}
        {!props.readOnly && (
          <div
            className={`select__list_button ${disabled ? "disable" : ""}`}
            onClick={() => {
              if (disabled) return;
              if (!props.readOnly) {
                showAllOptions();
                setShowOptions(i => (i = !i));
              }
            }}
          >
            ▼
          </div>
        )}

        {showOptions && (
          <div
            className={`select__list disable ${optionsAbove ? "select__list--above" : ""}`}
            ref={optionsListContainerRef}
            onKeyDown={onOptionsListKeyDown}
            tabIndex="0"
          >
            <div className="optionListHeader">
              {optionList.length ? t("Choose an option:") : t("No such option found")}
            </div>
            <ul ref={optionsListRef}>{optionList}</ul>
          </div>
        )}
        {question ? <QuestionItem title={question} tooltipShown={tooltipShown} /> : null}
      </div>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  props: PropTypes.array,
  id: PropTypes.string,
  options: PropTypes.array,
  minFilterLength: PropTypes.number,
  errorField: PropTypes.string,
  disabled: PropTypes.bool,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  optionsAbove: PropTypes.bool,
  textMode: PropTypes.bool,
};
