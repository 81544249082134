import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import {TextAreaInput} from "../../../../Inputs";

const NameBlock = ({readOnly}) => {
  const {t} = useTranslation();
  const {values, setFieldValue} = useFormikContext();
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (option, setFieldValue) => {
    if (option === "name") {
      setFieldValue("nameCheck", true);
      setFieldValue("nameEngCheck", false);
    } else {
      setFieldValue("nameCheck", false);
      setFieldValue("nameEngCheck", true);
    }
    setSelectedOption(option);
  };

  return (
    <div className="name-block">
      <div className="name-check-block">
        <label className="name-label">
          <input
            type="radio"
            name="nameCheck"
            value="name"
            checked={values?.nameCheck || selectedOption === "name"}
            onChange={() => handleOptionChange("name", setFieldValue)}
            style={{marginRight: "9px"}}
            disabled={readOnly}
          />
          {t("Nomenclature")}
        </label>
        <label className="name-label">
          <input
            type="radio"
            name="nameEngCheck"
            value="nameEng"
            checked={values?.nameEngCheck || selectedOption === "nameEng"}
            onChange={() => handleOptionChange("nameEng", setFieldValue)}
            style={{marginRight: "9px"}}
            disabled={readOnly}
          />
          {t("Nomenclature (eng)")}
        </label>
      </div>

      <TextAreaInput
        name={values?.nameCheck ? "name" : "nameEng"}
        width="100%"
        height="4em"
        value={values?.nameCheck ? values?.name || "" : values?.nameEng || ""}
        readOnly={readOnly}
        onChange={e => setFieldValue(values?.nameCheck ? "name" : "nameEng", e.target.value)}
      />
    </div>
  );
};

export default NameBlock;
