import {useState} from "react";

import {dateFormat} from "../../../../utilities/dateFormat";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {CONTRACT_TABLE_COLUMNS, DATA_TABLE_COLUMNS, SCRSH_TABLE_COLUMNS} from "./constants";

const useContractDocsScreenShotsTables = ({
  contractTableDataRow,
  annexTableDataRow,
  einfoTableDataRow,
  setFileArray,
}) => {
  const [currentEinfoForTable, setCurrentEinfoForTable] = useState(null);
  const [currentContractForTable, setCurrentContractForTable] = useState(null);
  const [currentAnnexForTable, setCurrentAnnexForTable] = useState(null);

  const contractTableColumns = useColumns(CONTRACT_TABLE_COLUMNS);
  const contractTableData =
    contractTableDataRow?.map(({contractId, contractNumber, projectNumber, note, sum}, index) => ({
      id: contractId,
      number: ++index,
      contractNumber,
      projectNumber,
      note,
      sum,
    })) ?? [];
  function onContractTableSelect(id) {
    if (!id) {
      setCurrentContractForTable(null);
      setFileArray([]);
      return;
    }
    const currentContract = contractTableDataRow?.find(contract => contract.contractId === id);
    setCurrentContractForTable(currentContract?.contractId);
    setCurrentEinfoForTable(null);
    setCurrentAnnexForTable(null);
    setFileArray(currentContract?.files?.map(file => file?.fileUrl) ?? []);
  }

  const annexTableColumns = useColumns(DATA_TABLE_COLUMNS);
  const annexTableData =
    annexTableDataRow?.map(({annexId, note, sum, annexNumber, bringTo}, index) => ({
      id: annexId,
      number: ++index,
      annexNumber,
      takeTo: dateFormat(bringTo),
      note,
      sum,
    })) ?? [];

  function onAnnexTableSelect(id) {
    if (!id) {
      setCurrentAnnexForTable(null);
      setFileArray([]);
      return;
    }
    const currentAnnex = annexTableDataRow?.find(annex => annex.annexId === id);
    setCurrentAnnexForTable(currentAnnex?.annexId);
    setCurrentContractForTable(null);
    setCurrentEinfoForTable(null);
    setFileArray(currentAnnex?.files?.map(file => file?.fileUrl) ?? []);
  }

  const einfoTableColumns = useColumns(SCRSH_TABLE_COLUMNS);
  const einfoTableData =
    einfoTableDataRow?.map(({einfoId, note, einfoNumber, createdAt}, index) => ({
      id: einfoId,
      number: ++index,
      einfoNumber,
      note,
      createdAt: dateFormat(createdAt),
    })) ?? [];

  function onEinfoTableSelect(id) {
    if (!id) {
      setCurrentEinfoForTable(null);
      setFileArray([]);
      return;
    }
    const currentEinfo = einfoTableDataRow?.find(einfo => einfo.einfoId === id);
    setCurrentEinfoForTable(currentEinfo?.einfoId);
    setCurrentAnnexForTable(null);
    setCurrentContractForTable(null);
    setFileArray(currentEinfo?.files?.map(file => file?.fileUrl) ?? []);
  }

  return {
    contractTableColumns,
    contractTableData,
    onContractTableSelect,
    annexTableColumns,
    annexTableData,
    onAnnexTableSelect,
    einfoTableColumns,
    einfoTableData,
    onEinfoTableSelect,
    currentEinfoForTable,
    currentContractForTable,
    currentAnnexForTable,
  };
};

export default useContractDocsScreenShotsTables;
