import {useEffect} from "react";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {TextInput} from "../../../../../Inputs";

const PayerDataWithCheckbox = ({readOnly = false}) => {
  const {
    values: {payerNameComposed, edrpouoComposed, payerAccountComposed, areAllFieldsFilledIn},
    setFieldValue,
  } = useFormikContext();

  const checked = !payerNameComposed || !edrpouoComposed || !payerAccountComposed;

  useEffect(() => {
    if (!payerNameComposed || !edrpouoComposed || !payerAccountComposed) {
      areAllFieldsFilledIn && setFieldValue("areAllFieldsFilledIn", false);
    }
    if (
      payerNameComposed &&
      edrpouoComposed &&
      payerAccountComposed &&
      !areAllFieldsFilledIn &&
      areAllFieldsFilledIn !== null
    ) {
      setFieldValue("areAllFieldsFilledIn", true);
    }
  }, [payerNameComposed, edrpouoComposed, payerAccountComposed]);

  return (
    <div className="fcCol aistr gap0_5 mb w100">
      <div className="fcCol aistr gap" style={{border: "1px solid #A3B9D9", padding: "0.5em"}}>
        <TextInput name="payerNameComposed" label={t("Payer Name")} autolabel readOnly={readOnly} />
        <TextInput name="edrpouoComposed" label={t("Payer`s EDRPOUO")} autolabel readOnly={readOnly} />
        <TextInput name="payerAccountComposed" label={t("Payer`s account")} autolabel readOnly={readOnly} />
      </div>
      <div className="row gap jcfe w100">
        <input
          type="checkbox"
          name="pendingClarificationComposed"
          readOnly={true}
          checked={checked}
          onChange={() => {}}
        />
        {t("Pending clarification")}
      </div>
    </div>
  );
};

export default PayerDataWithCheckbox;
