import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import ControlledTab from "./utils/ControlledTab";
import {TabsContext} from "./tabs.context";

import "./utils/tabs.scss";

const withTabs =
  ({isClosableTabs, isMainTabControlled, label, formInitialValues}) =>
  WrappedComponent => {
    const TabsContainer = props => {
      const [tabs, setTabs] = useState([]);
      const [currentTabIndex, setCurrentTabIndex] = useState(0);
      const [scrollPosition, setScrollPosition] = useState(0);
      const [showScrollButtons, setShowScrollButtons] = useState(false);

      const closeBtnRefs = useRef([]);
      const tabsContainerRef = useRef(null);

      const {t} = useTranslation();

      useEffect(() => {
        setTabs([
          {
            TabComponent: WrappedComponent,
            controlled: !!isMainTabControlled,
            title: label ? t(label) : "nolabel",
            formInitialValues: formInitialValues,
            ...props,
          },
        ]);
      }, []);

      useEffect(() => {
        const handleResize = () => {
          if (tabsContainerRef.current) {
            const maxScroll = tabsContainerRef.current.scrollWidth - tabsContainerRef.current.offsetWidth;
            setShowScrollButtons(maxScroll > 0);
          }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [tabs.length]);

      const addNewTab = newTab => {
        setTabs(prev => [...prev, newTab]);
      };

      const closeTab = tabIndex => {
        const initialTabs = [...tabs];
        initialTabs.splice(tabIndex, 1);
        setTabs(initialTabs);
        if (tabIndex <= currentTabIndex) {
          setCurrentTabIndex(currentTabIndex - 1);
        }
      };

      const scrollTabs = direction => {
        const tabWidth = tabsContainerRef.current.querySelector(".tab").offsetWidth;
        const maxScroll = tabsContainerRef.current.scrollWidth - tabsContainerRef.current.offsetWidth;

        let newScrollPosition = scrollPosition + direction * tabWidth + 20;
        newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScroll));

        tabsContainerRef.current.scrollTo({left: newScrollPosition, behavior: "smooth"});
        setScrollPosition(newScrollPosition);
      };

      const titlesRender = () => {
        return tabs.map((tab, index) => {
          return (
            <div key={tab.tabIdName ?? tab.title} className={"tab " + (index === currentTabIndex ? " selected" : "")}>
              <div title={tab.title} className="container" onClick={() => setCurrentTabIndex(index)}>
                <span className="title">{typeof tab.title === "string" ? t(tab.title) : tab.title}</span>
                {!!isClosableTabs && index !== 0 && (
                  <span
                    style={{}}
                    className="tab-close-btn"
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (closeBtnRefs.current[index]) {
                        closeBtnRefs.current[index].click();
                      }
                    }}
                  >
                    &#215;
                  </span>
                )}
              </div>
            </div>
          );
        });
      };

      const tabsRender = () => {
        return tabs.map((tab, index) => {
          const {TabComponent, controlled, title, ...tabprops} = tab;
          if (controlled) {
            return (
              <ControlledTab
                key={index}
                closeBtnRefs={closeBtnRefs}
                tabIndex={index}
                Component={TabComponent}
                {...tabprops}
              />
            );
          } else if (index === currentTabIndex)
            return (
              <div className="page_container fcCol w100" key={index}>
                <div className="overflow_form_modal hmax100 fGrow w100" style={{maxHeight: "100%"}}>
                  <TabComponent key={index} {...tabprops} />
                </div>
              </div>
            );
        });
      };

      const titles = titlesRender();
      const tabsList = tabsRender();

      return (
        <TabsContext.Provider value={{addNewTab, tabs, setTabs, currentTabIndex, closeTab, setCurrentTabIndex}}>
          <div className="limitedSize">
            <div className="tab-container" style={{maxWidth: "100%"}}>
              <div className="tabs-wrapper" ref={tabsContainerRef}>
                <div className="tabs">{titles}</div>
              </div>
              {showScrollButtons && (
                <>
                  <button className="scroll-btn left" onClick={() => scrollTabs(-1)}>
                    &#9664;
                  </button>
                  <button className="scroll-btn right" onClick={() => scrollTabs(1)}>
                    &#9654;
                  </button>
                </>
              )}
            </div>
            {tabs.length > 0 && tabsList}
          </div>
        </TabsContext.Provider>
      );
    };
    return TabsContainer;
  };

export default withTabs;
