import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import IndeterminateCheckbox from "../../../conteiners/reactTable/IndeterminateCheckbox";
import {TextAreaInput, TextInput} from "../../../Inputs";
import CurrencySelect from "../../../Inputs/CurrencySelect/CurrencySelect";
import NewPdfViewer from "../../Documents/DocumentView/NewPdfViewer";
import ContractDocsScreenShotsTables from "../../ProjectDirectiveList/ContractDocsScreenShotsTables/ContractDocsScreenShotsTables";
import DirectiveFilters from "../../ProjectDirectiveList/DirectiveFilters";
import FinStatus from "../../ProjectDirectiveList/FinStatus";
import OrderTypeComposedPart from "../../ProjectDirectiveList/OrderTypeComposedPart/OrderTypeComposedPart";
import HistoryDateNameLine from "../HistoryDateNameLine/HistoryDateNameLine";

import useProjectDirectiveListView from "./useProjectDirectiveListView";

const ProjectDirectiveListView = () => {
  const {onCancelImplementationBtnClick, onExitBtnClick, fileArray} = useProjectDirectiveListView();

  return (
    <div className="fcCol h100 w100 pb aist">
      <DirectiveFilters />
      <Divider />
      <div className="fc aistr jcsb gap2x w100 fGrow mb0_5">
        <OrderTypeComposedPart readOnly>
          <div className="row">
            <FunctionButton onClick={onCancelImplementationBtnClick} name="For implementation" autoWidth />
            <FunctionButton onClick={onExitBtnClick} name="Exit" autoWidth />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2" style={{maxWidth: "70%"}}>
          <FinStatus />
          <div className="grid2ColFirstBigger fGrow">
            <ContractDocsScreenShotsTables />
            <div className="mb2x">
              <NewPdfViewer fileArray={fileArray} />
            </div>
          </div>
        </div>
      </div>
      {/* <ReactTable
        columns={tableColumns}
        defaultData={tableData}
        current={null}
        onSelect={onTableSelect}
        className="tableTac"
        tableClassName="fGrow"
        style={{height: "clamp(100px, 140px, 30%)"}}
      /> */}
    </div>
    // <div className="fcCol h100 w100">
    //   <div className="w100">
    //     <div className="row mb">
    //       <TextInput name="status" label={t("Status")} readOnly autolabel />
    //       <TextInput name="clientNumber" label={t("Client №")} readOnly autolabel />
    //       <TextInput name="coNumber" label={t("CO №")} readOnly autolabel />
    //       <CurrencySelect name="coCurrencyId" label={t("CO Currency")} readOnly />
    //       <TextInput name="operationType" label={t("Operation type ")} readOnly autolabel />
    //     </div>
    //     <div className="row">
    //       <TextInput name="payerName" label={t("Payer Name")} readOnly autolabel />
    //       <TextInput name="payerEDRPOUO" label={t("Payer`s EDRPOUO")} readOnly autolabel />
    //       <TextInput name="payerAccount" label={t("Payer`s account")} readOnly autolabel />
    //       <div className="row">
    //         <IndeterminateCheckbox checked={false} readOnly />
    //         {t("Pending clarification")}
    //       </div>
    //     </div>
    //   </div>
    //   <Divider />
    //   <div className="grid3ColMiddleBigger fGrow2 mb0_5">
    //     <div className="fcCol aist jcsa gap4x">
    //       <div className="fcCol gap0_5 mt w100">
    //         <TextInput name="directiveNumber" label={t("Directive №")} readOnly autolabel />
    //         <TextInput name="paymentTo" label={t("Payment To")} readOnly autolabel required />
    //         <TextInput name="directiveType" label={t("Directive type")} autolabel required />
    //       </div>
    //       <div className="fcCol aife gap0_5">
    //         <div className="row aibl gap0_5">
    //           <TextInput name="coSum" label={t("CO Sum")} readOnly autolabel />
    //           <TextInput name="advancePercentage" label={t("%advance")} readOnly autolabel />
    //         </div>
    //         <TextInput name="nSum" label={t("Sum (n)")} readOnly autolabel />
    //       </div>
    //       <TextAreaInput
    //         name="executantNote"
    //         placeholder={t("Executant note")}
    //         height={"100px"}
    //         readOnly
    //         className="mb"
    //       />
    //     </div>
    //     <ContractDocsScreenShotsTables />
    //     <div className="mb2x">
    //       <NewPdfViewer fileArray={fileArray} />
    //     </div>
    //   </div>
    //   <ReactTable
    //     columns={tableColumns}
    //     defaultData={tableData}
    //     current={null}
    //     onSelect={onTableSelect}
    //     tableClassName="fGrow"
    //     style={{height: "clamp(100px, 170px, 35%)"}}
    //   />
    //   <HistoryDateNameLine />
    // </div>
  );
};

export default ProjectDirectiveListView;
