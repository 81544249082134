import {useTranslation} from "react-i18next";

import {TextAreaInput} from "../../../../Inputs";
import FieldWithSample from "../../../../Inputs/FieldWithSample";

import CharactericticsField from "./CharactericticsField";

const CharDeskBlock = ({readOnly}) => {
  const {t} = useTranslation();

  return (
    <>
      <FieldWithSample
        name="charCheck"
        label={t("Characteristics")}
        style={{display: "flex", alignItems: "start"}}
        readOnly={readOnly}
      >
        <CharactericticsField readOnly={readOnly} />
      </FieldWithSample>

      <FieldWithSample
        name="complectDescCheck"
        label={t("Set description")}
        style={{display: "flex", alignItems: "start", gap: "10px"}}
        readOnly={readOnly}
      >
        <TextAreaInput name="complectDesc" width="101%" height={"41px"} readOnly={readOnly} />
      </FieldWithSample>
    </>
  );
};
export default CharDeskBlock;
