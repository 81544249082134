import {createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";

import {fetchData, fetchDataWithParams, fetchList} from "../../hook/axios.hook";
import i18n from "../../i18n";
import {dateFormat} from "../../utilities/dateFormat";
import {getInfo} from "../../utilities/toasts";

import {REQ_WINDOW_TYPES} from "./RequestsInProgress/utils/functions/requestsFiltersManager";
import {getForReadinessService} from "./RequestsInProgress/utils/getForReadinessService";

// import { fetchList } from "../hook/axios.hook";

const initialState = {
  requestsLoading: false,
  statuses: [],
  error: [],
  requests: [],
  firstStatusRequests: [],
  appointRequests: [],
  thirdStatusRequests: [],
  fourthStatusRequests: [],
  stockPoolRequests: [],

  filters: null,
  identicalSkus: [],
  current: {
    currentRequestId: null, /// 3 status
    currentRequestSKUs: [],
    currentNote: null,
    currentFourthStatusRequestId: null, /// 4
    checkedRequestIds: [],
    currentSkuRequestId: null,
  },
  splitedOrder: {first: null, second: null},
  currentRequestSku: null,
  currentRequestInfo: null,
  currentRequestFiles: [],
  requestFactoryData: [],

  //request dates
  requestDates: {
    datesWithPostponed: [],
    sheduledReadinessDate: [],
    confirmedDate: null,
  },
  transportDocs: [],
  transportDoc: null,
  transportDocLoading: false,
  packingSpaces: [],
  reqFilters: null,
  loadingForFiles: false,
  docsRef: [],

  stockPool: null,
  reqsInStockPool: [],
  sleppingReqsInStockPool: [],

  checkList: {
    curentOrder: null,
    curentCheckListDoc: null,
    currentStockPool: null,
  },

  addedSkuRequestImages: [],
  addedSkuRequestColorImages: [],
  addedSkuRequestDecorImages: [],
  defect: null,
};

export const fetchStatuses = createAsyncThunk("requests/fetchStatuses", async () => {
  const response = fetchList("/requests/getStatuses", "get");
  return response;
});

export const getRequest = createAsyncThunk("requests/getRequest", async requestId => {
  const response = fetchList(`/requests/get/${requestId}`, "get");
  return response;
});

//!
export const fetchMixedRequests = createAsyncThunk("requests/fetchMixedRequests", async params => {
  const response = fetchList(`/requests/getAll/`, "get", {params});

  return response;
});

export const fetchRequests = createAsyncThunk("requests/fetchRequests", async params => {
  const {showidenticalSku, ...restParams} = params;
  const response = fetchList(`/requests/getAll/`, "get", {params: {...restParams}});
  return response;
});

export const fetchFilters = createAsyncThunk("requests/fetchFilters", async params => {
  const response = fetchData(`requests/getFilters`, "get", {params});
  return response;
});

export const updateRequest = createAsyncThunk("requests/updateRequest", async ({requestId, note}) => {
  const response = fetchData(`/requests/update/${requestId}`, "patch", {note});
  return response;
});

export const appointRequests = createAsyncThunk("requests/appointRequests", async ({managerFeaId, data}) => {
  const response = fetchData(`/requests/appoint/${managerFeaId}`, "put", data);
  return response;
});

export const cancelAppointmentRequests = createAsyncThunk("requests/cancelAppointmentRequests", async requestIds => {
  const response = fetchData(`/requests/cancelAppointment`, "put", {requestIds});
  return response;
});

export const cancelAllAppointmentRequests = createAsyncThunk("requests/cancelAllAppointmentRequests", async () => {
  const response = fetchData(`/requests/cancelAllAppointment`, "put");
  return response;
});

export const distributeRequests = createAsyncThunk("requests/distributeRequests", async () => {
  const response = fetchData(`/requests/distribute`, "put");
  return response;
});

export const turnIntoRequest = createAsyncThunk("requests/turnIntoRequest", async ({requestIds, note}) => {
  const response = fetchData(`/requests/turnIntoRequest/`, "put", {requestIds, note});
  return response;
});

export const mergeRequests = createAsyncThunk("requests/mergeRequests", async requestIds => {
  const response = fetchData(`/requests/merge/`, "put", {requestIds});
  return response;
});

export const splitRequests = createAsyncThunk("requests/splitRequests", async requestId => {
  const response = fetchData(`/requests/split/${requestId}`, "patch");
  return response;
});

export const returnOrder = createAsyncThunk("requests/returnOrder", async requestIds => {
  const response = fetchData(`/requests/returnForDistribution`, "put", {requestIds});
  return response;
});

export const deleteRequest = createAsyncThunk("requests/deleteRequest", async requestId => {
  const response = fetchData(`/requests/delete/${requestId}`, "delete");
  return response;
});

export const uploadPdf = createAsyncThunk("requests/uploadPdf", async ({requestId, formData}) => {
  const response = fetchData(`/requests/uploadPdf/${requestId}`, "put", formData);
  return response;
});

export const sendToFactory = createAsyncThunk("requests/sendToFactory", async ({requestId, data}) => {
  const response = fetchData(`/requests/sendToFactory/${requestId}`, "put", data);
  return response;
});
// addReadiness;

//SKU requests
export const fetchRequestSKUs = createAsyncThunk("requests/getRequestSKUs", async requestId => {
  const response = fetchList(`/skuRequest/getForRequest/${requestId}`, "get");
  return response;
});

export const transferSkuToRequest = createAsyncThunk("requests/transferSkuToRequest", async ({requestId, data}) => {
  const response = fetchList(`/skuRequest/transferToRequest/${requestId}`, "put", data);
  return response;
});

export const getSkuRequestInfo = createAsyncThunk("requests/getSkuRequestInfo", async skuRequestId => {
  const response = fetchList(`/skuRequest/get/${skuRequestId}`, "get");
  return response;
});

export const updateRequestSku = createAsyncThunk("requests/updateRequestSku", async ({skuRequestId, data}) => {
  const response = fetchData(`/skuRequest/update/${skuRequestId}`, "patch", {...data});
  return response;
});

export const reorderRequestSku = createAsyncThunk("requests/reorderRequestSku", async ({requestId, data}) => {
  const response = fetchData(`/skuRequest/reorder/${requestId}`, "patch", data);
  return response;
});

export const markAsInspectedRequestSku = createAsyncThunk("requests/markAsInspectedRequestSku", async data => {
  const response = fetchData(`/skuRequest/markAsInspected`, "put", data);
  return response;
});
export const markAsAcceptedRequestSku = createAsyncThunk("requests/markAsAcceptedRequestSku", async params =>
  fetchData(`/skuRequest/markAsAccepted`, "put", params),
);
export const markAsDefectedRequestSku = createAsyncThunk("requests/markAsDefectedRequestSku", async params =>
  fetchData(`/skuRequest/markAsDefected`, "put", params),
);
export const cancelAcceptanceRequestSku = createAsyncThunk("requests/cancelAcceptanceRequestSku", async skuRequestId =>
  fetchData(`/skuRequest/cancelAcceptance/${skuRequestId}`, "put"),
);

//// skuRequestImages
export const addSkuRequestCommonImage = createAsyncThunk(
  "skuRequest/addSkuRequestCommonImage",
  async ({imageId, skuRequestId, imageType}) => {
    return fetchData(`/skuRequest/image/add/${imageId}/${skuRequestId}?imageType=${imageType}`, "patch");
  },
);

export const removeSkuRequestCommonImage = createAsyncThunk(
  "skuRequest/removeSkuRequestCommonImage",
  async ({imageId, skuRequestId, imageType}) => {
    return fetchData(`/skuRequest/image/remove/${imageId}/${skuRequestId}?imageType=${imageType}`, "patch");
  },
);

export const addSkuRequestColorImage = createAsyncThunk(
  "skuRequest/addSkuRequestColorImage",
  async ({imageId, skuRequestId}) => {
    return fetchData(`/skuRequest/colorImage/add/${imageId}/${skuRequestId}`, "patch");
  },
);

export const removeSkuRequestColorImage = createAsyncThunk(
  "skuRequest/removeSkuRequestColorImage",
  async ({imageId, skuRequestId}) => {
    return fetchData(`/skuRequest/colorImage/remove/${imageId}/${skuRequestId}`, "patch");
  },
);

export const addSkuRequestDecorImage = createAsyncThunk(
  "skuRequest/addSkuRequestDecorImage",
  async ({imageId, skuRequestId}) => {
    return fetchData(`/skuRequest/decorImage/add/${imageId}/${skuRequestId}`, "patch");
  },
);

export const removeSkuRequestDecorImage = createAsyncThunk(
  "skuRequest/removeSkuRequestDecorImage",
  async ({imageId, skuRequestId}) => {
    return fetchData(`/skuRequest/decorImage/remove/${imageId}/${skuRequestId}`, "patch");
  },
);

//// files
export const requestsAddFile = createAsyncThunk("requests/addFile", async ({requestId, formData}) => {
  const response = fetchData(`/requests/files/add/${requestId}`, "post", formData);
  return response;
});

export const requestsDeleteFile = createAsyncThunk("requests/deleteFile", async fileId => {
  const response = fetchData(`/requests/files/remove/${fileId}`, "delete");
  return response;
});

//** Defects **
//get
export const getDefect = createAsyncThunk("requests/defects/getDefect", async defectId =>
  fetchList(`/skuRequest/defects/get/${defectId}`, "get"),
);
//patch
export const updateDefect = createAsyncThunk("requests/defects/updateDefect", async ({defectId, description}) =>
  fetchData(`/skuRequest/defects/update/${defectId}`, "patch", {description}),
);
//post
export const addDefectPhoto = createAsyncThunk("requests/defects/addDefectPhoto", async ({defectId, formdata}) =>
  fetchData(`/skuRequest/defects/photos/add/${defectId}`, "post", formdata),
);
//delete
export const deleteDefectPhoto = createAsyncThunk("requests/defects/deleteDefectPhoto", async photoId =>
  fetchData(`/skuRequest/defects/photos/remove/${photoId}`, "delete"),
);

//*** Request dates ***
//get
export const getAllRequestDates = createAsyncThunk("requests/getAllRequestDates", async requestId =>
  fetchList(`/requests/dates/getForRequest/${requestId}`, "get"),
);
export const getForReadiness = createAsyncThunk("requests/getForReadiness", async requestId =>
  fetchList(`/requests/getForReadiness/${requestId}`, "get"),
);

//post
export const addReadinessDate = createAsyncThunk("requests/addReadinessDate", async ({requestId, data}) =>
  fetchData(`/requests/dates/addReadiness/${requestId}`, "post", data),
);
//patch
export const confirmReadiness = createAsyncThunk("requests/confirmReadiness", async requestId =>
  fetchData(`/requests/dates/confirmReadiness/${requestId}`, "patch"),
);

//***Orders ***
export const fetchOrderByRequest = createAsyncThunk("requests/fetchOrderByRequest", async ({requestId, coId}) =>
  fetchData(`orders/getFromRequest/${requestId}/${coId}`, "get"),
);

//***Transport Docs ***
//get
export const fetchAllTransportDocs = createAsyncThunk("requests/fetchAllTransportDocs", async requestId =>
  fetchData(`requests/transportDocs/getAll?requestId=${requestId}`, "get"),
);

export const getTransportDoc = createAsyncThunk("requests/getTransportDoc", async docId =>
  fetchData(`requests/transportDocs/get/${docId}`, "get"),
);
//post
export const addTransportDoc = createAsyncThunk("requests/addTransportDoc", async ({params, formData}) =>
  fetchDataWithParams(`requests/transportDocs/add`, "post", params, formData),
);
//delete
export const deleteTransportDoc = createAsyncThunk("requests/deleteTransportDoc", async docId => {
  const response = fetchData(`requests/transportDocs/remove/${docId}`, "delete");
  return response;
});

//*** Packing Spaces ***
//get
export const fetchPackingSpace = createAsyncThunk("requests/fetchPackingSpace", async spaceId =>
  fetchData(`requests/packingSpaces/get/${spaceId}`, "get"),
);

//post
export const addPackingSpace = createAsyncThunk("requests/addPackingSpace", async ({params, data}) => {
  return fetchDataWithParams(`requests/packingSpaces/add`, "post", params, data);
});

//patch
export const updatePackingSpace = createAsyncThunk("requests/updatePackingSpace", async ({spaceId, data}) =>
  fetchData(`requests/packingSpaces/update/${spaceId}`, "patch", data),
);

//delete
export const deletePackingSpace = createAsyncThunk("requests/deletePackingSpace", async spaceId => {
  const response = fetchData(`requests/packingSpaces/remove/${spaceId}`, "delete");
  return response;
});

//docs refs
export const fetchDocsRefs = createAsyncThunk("requests/fetchDocsRefs", async () => {
  const response = fetchList("/references/transportDocTypes", "get");
  return response;
});

// stock pool

//post
export const addToStockPool = createAsyncThunk("requests/addToStockPool", async requestIds =>
  fetchData("requests/addToStockPool", "post", {requestIds}),
);

//patch
export const removeFromStockPool = createAsyncThunk(
  "requests/removeFromStockPool",
  async ({stockPoolId, requestIds}) => {
    const response = fetchData(`/requests/removeFromStockPool/${stockPoolId}`, "patch", {requestIds});
    return response;
  },
);

//get
export const fetchStockPool = createAsyncThunk("requests/fetchStockPool", async stockPoolId => {
  const response = fetchData(`stockPools/get/${stockPoolId}`, "get");
  return response;
});

export const fetchRequestStockPoolUpdated = createAsyncThunk("requests/fetchStockPoolUpdated", async stockPoolId =>
  fetchList(`stockPools/getUpdate/${stockPoolId}`, "get"),
);

export const fetchComplexStockPool = createAsyncThunk("requests/fetchComplexStockPool", async stockPoolId => {
  const response = fetchData(`/stockPools/getUpdate/${stockPoolId}`, "get");
  return response;
});

//delete
export const disbandStockPool = createAsyncThunk("requests/disbandStockPool", async stockPoolId => {
  const response = fetchData(`stockPools/disband/${stockPoolId}`, "delete");
  return response;
});

//put
export const sendToLogistics = createAsyncThunk("requests/sendToLogistics", async ({stockPoolId, data}) => {
  const response = fetchData(`/stockPools/sendToLogistics/${stockPoolId}`, "put", data);
  return response;
});

//getAll
export const fetchAllStockPools = createAsyncThunk("requests/fetchAllStockPools", async params => {
  const response = fetchList(`/stockPools/getAll/`, "get", {params});
  return response;
});

const extraActions = [
  fetchStatuses,
  fetchRequests,
  fetchMixedRequests,
  fetchFilters,
  updateRequest,
  appointRequests,
  cancelAppointmentRequests,
  cancelAllAppointmentRequests,
  distributeRequests,
  turnIntoRequest,
  mergeRequests,
  splitRequests,
  deleteRequest,
  fetchRequestSKUs,
  transferSkuToRequest,
  getAllRequestDates,
  addReadinessDate,
  confirmReadiness,

  fetchPackingSpace,
  addPackingSpace,
  updatePackingSpace,
];
const getItems = type => extraActions.map(el => el[type]);

const namingLangSpecify = obj => {
  const lang = i18n.language;

  return (lang === "en" && obj ? obj?.persona?.engFirstName + " " + obj?.persona?.engLastName : obj?.name) || "";
};

const alterReq = requestRaw => {
  if (requestRaw) {
    const {managerFea} = requestRaw;
    const managerCo = requestRaw?.cos?.[0]?.managerCo || null;

    return {
      ...requestRaw,
      managerCo: managerCo ? {...managerCo, name: namingLangSpecify(managerCo)} : null,
      managerFea: requestRaw?.managerFea ? {...requestRaw?.managerFea, name: namingLangSpecify(managerFea)} : null,
    };
  }

  return requestRaw;
};

const RequestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
    setCurrentStockPool: (state, {payload}) => {
      state.checkList.currentStockPool = payload;
    },
    setFirstStatusRequests: (state, action) => {
      state.firstStatusRequests = action.payload;
    },
    setCurrent: (state, {payload}) => {
      state.current.currentRequestId = payload;
    },
    setCurrentNote: (state, {payload}) => {
      state.current.currentNote = payload;
    },
    clearNote: state => {
      state.current.currentNote = null;
    },
    setCurrentFourthStatusRequestId: (state, {payload}) => {
      state.current.currentFourthStatusRequestId = payload;
    },
    deselectCurrentOrder: state => {
      state.current.currentRequestId = null;
      state.current.currentRequestSKUs = [];
    },
    setCheckedRequestIds: (state, {payload}) => {
      state.current.checkedRequestIds = payload;
      if (payload?.length === 0) {
        state.current.currentNote = null;
      }
    },
    deselectCurrentRequestSku: (state, {payload}) => {
      state.currentRequestSku = null;
    },
    setCurrentRequestInfo: (state, {payload}) => {
      state.currentRequestInfo = payload;
    },
    deselectCurrentRequest: (state, {payload}) => {
      state.currentRequestInfo = null;
      state.currentRequestFiles = [];
      state.current.currentRequestSKUs = [];
    },
    setReqFilters: (state, {payload}) => {
      state.reqFilters = payload;
    },
    clearReqFilters: state => {
      state.reqFilters = null;
    },
    setCurrentSkuRequestId: (state, {payload}) => {
      state.current.currentSkuRequestId = payload;
    },

    deselectTransportDoc: (state, {payload}) => {
      state.transportDoc = null;
      // state.transportDocs = [];
    },

    setTransportDoc: (state, {payload}) => {
      state.transportDoc = payload;
      // state.transportDocs = [];
    },

    deselectPackingSpaces: (state, {payload}) => {
      state.packingSpaces = [];
    },
    setPackingSpaces: (state, {payload}) => {
      state.packingSpaces = payload;
    },
    deselectStockPool: (state, {payload}) => {
      state.stockPool = null;
      state.transportDocs = null;
      state.packingSpaces = [];
      state.transportDoc = null;
    },

    setCurentOrder: (state, {payload}) => {
      state.checkList.curentOrder = payload;
    },
    setCurenCheckLIstFile: (state, {payload}) => {
      state.checkList.curentCheckListDoc = payload;
    },
    clearDefect: state => {
      state.defect = null;
    },
    // setCurrentSPCheckList: (state, {payload}) => {
    //   state.requests.checkList.currentStockPool = payload;
    // },
    // decelectCurrentRequestSku: (state, {payload}) => {
    //   state.currentRequestSku = null;
    // }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStatuses.fulfilled, (state, action) => {
        state.orgStructures = action.payload;
      })

      .addCase(getRequest.fulfilled, (state, action) => {
        state.currentRequestInfo = alterReq(action.payload);
        state.currentRequestFiles = action.payload.requestFiles;
        state.current.currentRequestSKUs = action.payload.skuByRequest;
      })

      .addCase(fetchMixedRequests.fulfilled, (state, {payload}) => {
        const {requests: requestsRaw} = payload;
        const requests = requestsRaw?.map(alterReq) || [];
        state.requests = requests;
      })
      .addCase(fetchRequests.fulfilled, (state, action) => {
        state.requestsLoading = false;
        const {meta, payload} = action;
        const {identicalSku, requests: requestsRaw} = payload;
        const requests = requestsRaw?.map(alterReq) || [];

        switch (meta.arg?.window) {
          case REQ_WINDOW_TYPES.INCOMING:
            state.firstStatusRequests = requests;
            break;
          case REQ_WINDOW_TYPES.APPOINTED:
            state.appointRequests = requests;
            break;
          case REQ_WINDOW_TYPES.DISTRIBUTED:
            state.thirdStatusRequests = requests;
            break;
          case REQ_WINDOW_TYPES.PROCESSED:
            state.fourthStatusRequests = requests;
            break;
          case REQ_WINDOW_TYPES.SP:
            state.stockPoolRequests = requests;
            break;
          case REQ_WINDOW_TYPES.SLEPPING_IN_SP:
            state.sleppingReqsInStockPool = requests;
            break;
          default:
            state.requests = requests;
        }

        if (meta.arg.showidenticalSku) {
          state.identicalSkus = identicalSku;
        }
      })

      .addCase(turnIntoRequest.fulfilled, (state, {payload}) => {
        state.fourthStatusRequests = payload?.map(alterReq) || [];
        state.current.currentRequestId = null;
        state.current.currentRequestSKUs = [];
        state.current.currentNote = null;
        state.current.currentFourthStatusRequestId = null;
      })
      .addCase(mergeRequests.fulfilled, (state, {payload}) => {
        //TODO
        // console.log("mergeRequests payload", payload);
      })
      .addCase(splitRequests.fulfilled, (state, action) => {
        //TODO
        const {payload, meta} = action;
        // console.log("splitRequests payload", payload);
        state.splitedOrder.second = payload;
        const firstOrderNumber =
          state.thirdStatusRequests.length > 0
            ? state.thirdStatusRequests.find(req => req.requestId === meta.arg)?.requestNumber
            : state.currentRequestInfo.requestNumber;
        state.splitedOrder.first = firstOrderNumber;
      })
      .addCase(returnOrder.fulfilled, (state, {payload}) => {
        //TODO
        // console.log("returnRequests payload", payload);
      })

      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(appointRequests.fulfilled, (state, action) => {
        state.appointRequests = action.payload?.map(alterReq) || [];
        state.firstStatusRequests = state.firstStatusRequests.filter(
          req => !action.meta.arg.data.requestIds.includes(req.requestId),
        );
      })
      .addCase(cancelAppointmentRequests.fulfilled, (state, action) => {
        state.appointRequests = action.payload?.map(alterReq) || [];
      })
      .addCase(cancelAllAppointmentRequests.fulfilled, (state, action) => {
        state.appointRequests = [];
      })
      .addCase(distributeRequests.fulfilled, (state, action) => {
        state.appointRequests = [];
        getInfo("Замовлення успішно додано в обробку");
      })
      .addCase(fetchRequestSKUs.fulfilled, (state, {payload}) => {
        state.current.currentRequestSKUs = payload;
      })
      .addCase(deleteRequest.fulfilled, (state, action) => {
        state.thirdStatusRequests = state.thirdStatusRequests.filter(req => req.requestId !== action.meta.arg);
      })
      .addCase(transferSkuToRequest.fulfilled, (state, action) => {})
      .addCase(getSkuRequestInfo.fulfilled, (state, {payload}) => {
        state.currentRequestSku = payload;
        state.addedSkuRequestImages = payload.addedImages;
        state.addedSkuRequestDecorImages = payload.addedDecorImages;
        state.addedSkuRequestColorImages = payload.addedColorImages;
      })
      .addCase(updateRequestSku.fulfilled, (state, {payload}) => {
        state.currentRequestSku = payload.skuRequest;

        state.current.currentRequestSKUs = state.current.currentRequestSKUs.map(sku => {
          if (sku.skuRequestId === payload.skuRequest.skuRequestId) {
            return payload.skuRequest;
          }
          return sku;
        });
      })

      .addCase(uploadPdf.fulfilled, (state, {payload}) => {
        // state.currentRequestInfo = alterReq(payload.request);
        state.currentRequestInfo = {...state.currentRequestInfo, pdfFileUrl: payload.request.pdfFileUrl};
      })
      .addCase(requestsAddFile.pending, (state, {payload}) => {
        state.loadingForFiles = true;
      })
      .addCase(requestsAddFile.fulfilled, (state, {payload}) => {
        state.loadingForFiles = false;
        state.currentRequestFiles = payload.requestFiles;
        // state.currentRequestInfo = {...state.currentRequestInfo, requestFiles: payload.requestFiles};
      })
      .addCase(requestsDeleteFile.fulfilled, (state, {payload}) => {
        // state.currentRequestInfo = {...state.currentRequestInfo, requestFiles: payload.requestFiles};
        state.currentRequestFiles = payload.requestFiles;
      })

      //*** Request Dates ***
      .addCase(getAllRequestDates.fulfilled, (state, {payload}) => {
        state.requestDates.allDatesByRequest = payload;
      })
      .addCase(addReadinessDate.fulfilled, (state, {payload}) => {
        const {readinessDate, readinessControlDate} = payload;

        const newReadinessDate = {
          id: readinessDate.dateId + readinessDate.plannedDate,
          readinessDate: dateFormat(readinessDate.plannedDate),
          actualReadinessDate: dateFormat(readinessDate.actualDate),
          readinessControlDate: dateFormat(readinessControlDate.plannedDate),
          note: readinessDate.note,
        };
        state.requestDates.datesWithPostponed = [...state.requestDates.datesWithPostponed, newReadinessDate];
      })
      .addCase(confirmReadiness.fulfilled, (state, {payload}) => {
        state.requestDates.confirmedDate = payload;
      })
      .addCase(getForReadiness.fulfilled, (state, {payload}) => {
        state.requestDates.datesWithPostponed = getForReadinessService(payload);
        state.requestFactoryData = payload.brand.factoryContacts;

        state.packingSpaces = payload.packingSpaces;
      })

      //*** Transport Docs ***
      //get
      .addCase(fetchAllTransportDocs.fulfilled, (state, {payload}) => {
        state.transportDocs = payload;
      })
      //get
      .addCase(getTransportDoc.fulfilled, (state, {payload}) => {
        state.transportDoc = payload;
      })
      //post
      .addCase(addTransportDoc.pending, (state, {payload}) => {
        state.transportDocLoading = true;
      })
      .addCase(addTransportDoc.fulfilled, (state, {payload}) => {
        state.transportDocLoading = false;
        state.transportDoc = payload;
        state.transportDocs = state.transportDocs === null ? [payload] : [...state.transportDocs, payload];
      })
      //post

      .addCase(deleteTransportDoc.fulfilled, (state, action) => {
        state.transportDocs = state.transportDocs.filter(req => req.docId !== action.meta.arg);
      })
      //*** Packing Spaces ***
      //get
      .addCase(fetchPackingSpace.fulfilled, (state, {payload}) => {
        state.packingSpaces.current = payload;
      })
      //post
      .addCase(addPackingSpace.fulfilled, (state, {payload}) => {
        state.packingSpaces = [...state.packingSpaces, payload];
      })
      //patch
      .addCase(updatePackingSpace.fulfilled, (state, {payload}) => {
        state.packingSpaces = state.packingSpaces.map(space => {
          if (space.spaceId === payload.spaceId) {
            return payload;
          }
          return space;
        });
      })
      .addCase(deletePackingSpace.fulfilled, (state, action) => {
        state.packingSpaces = state.packingSpaces.filter(req => req.spaceId !== action.meta.arg);
      })

      .addCase(fetchDocsRefs.fulfilled, (state, action) => {
        state.docsRefs = action.payload;
      })
      //stockPool

      .addCase(fetchRequestStockPoolUpdated.fulfilled, (state, action) => {
        if (action.meta.arg?.type === "appointed") {
          state.checkList.currentStockPool = action.payload;
        }
        state.stockPool = action.payload;
        state.reqsInStockPool = action.payload.requests;
        state.transportDocs = action.payload.transportDocs;
        state.packingSpaces = action.payload.packingSpaces;
      })

      .addCase(fetchComplexStockPool.fulfilled, (state, {payload}) => {
        state.checkList.currentStockPool = payload;
      })
      .addCase(removeFromStockPool.fulfilled, (state, action) => {
        state.reqsInStockPool = state.reqsInStockPool.filter(req => req.stockPoolId !== action.meta.arg);
      })

      .addCase(fetchOrderByRequest.fulfilled, (state, {payload}) => {
        state.checkList.curentOrder = {...payload, requests: [payload.request]};
      })

      //** Defects */
      .addCase(getDefect.fulfilled, (state, {payload}) => {
        state.defect = payload;
      })
      .addCase(addSkuRequestCommonImage.fulfilled, (state, {payload}) => {
        state.addedSkuRequestImages = payload.addedImages;
      })
      .addCase(removeSkuRequestCommonImage.fulfilled, (state, {payload}) => {
        state.addedSkuRequestImages = payload.addedImages;
      })
      .addCase(addSkuRequestColorImage.fulfilled, (state, {payload}) => {
        state.addedSkuRequestColorImages = payload.addedColorImages;
      })
      .addCase(removeSkuRequestColorImage.fulfilled, (state, {payload}) => {
        state.addedSkuRequestColorImages = payload.addedColorImages;
      })
      .addCase(addSkuRequestDecorImage.fulfilled, (state, {payload}) => {
        state.addedSkuRequestDecorImages = payload.addedDecorImages;
      })
      .addCase(removeSkuRequestDecorImage.fulfilled, (state, {payload}) => {
        state.addedSkuRequestDecorImages = payload.addedDecorImages;
      })

      //pending matcher
      .addMatcher(isAnyOf(...getItems("fulfilled")), state => handleFulfilled(state))
      //pending matcher
      .addMatcher(isAnyOf(...getItems("pending")), state => handlePending(state))
      //rejected matcher
      .addMatcher(isAnyOf(...getItems("rejected")), (state, {error}) => handleRejected(state, error))
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.requestsLoading = false;
  state.error = [];
}

function handlePending(state) {
  state.requestsLoading = true;
  state.error = [];
}

function handleRejected(state, error) {
  state.requestsLoading = false;
  state.error = error;
}

const {actions, reducer} = RequestSlice;

export const {
  setRequests,
  setFirstStatusRequests,
  setCurrent,
  setCurrentFourthStatusRequestId,
  deselectCurrentOrder,
  deselectCurrentRequestSku,
  setCurrentNote,
  clearNote,
  setCheckedRequestIds,
  deselectCurrentRequest,
  setReqFilters,
  clearReqFilters,
  setCurrentSkuRequestId,
  deselectTransportDoc,
  setTransportDoc,
  deselectPackingSpaces,
  deselectStockPool,
  setCurentOrder,
  setCurenCheckLIstFile,
  setCurrentSPCheckList,
  setCurrentStockPool,
  setCurrentRequestInfo,
  setPackingSpaces,
  clearDefect,
} = actions;

export default reducer;

export const getRequestInfo = state => state.requests.currentRequestInfo;
export const getRequestFiles = state => state.requests.currentRequestFiles;
export const getRequestSkuInfo = state => state.requests.currentRequestSku;
export const getRequestRegistrFilters = state => state.requests.filters;
export const getFirstStatusRequest = state => state.requests.firstStatusRequests;
export const getIdenticalSkus = state => state.requests.identicalSkus;
export const getThirdStatusRequests = state => state.requests.thirdStatusRequests;
export const getFourthStatusRequests = state => state.requests.fourthStatusRequests;
export const getAppointRequests = state => state.requests.appointRequests;
export const getSPRequests = state => state.requests.stockPoolRequests;
export const getAllStatusRequest = state => state.requests.requests;
export const getLoadingRequest = state => state.requests.requestsLoading;
export const getCurrentRequestId = state => state.requests.current.currentRequestId;
export const getCurrentRequestSKUs = state => state.requests.current.currentRequestSKUs;
export const getCurrentFourthStatusRequestId = state => state.requests.current.currentFourthStatusRequestId;
export const getCurrentNote = state => state.requests.current.currentNote;
export const getCheckedRequestIds = state => state.requests.current.checkedRequestIds;
export const getCurrentSkuRequestId = state => state.requests.current.currentSkuRequestId;

export const getSplitedOrder = state => state.requests.splitedOrder;

export const getSkuRequestAddedImages = state => state.requests.addedSkuRequestImages;
export const getSkuRequestAddedColorImages = state => state.requests.addedSkuRequestColorImages;
export const getSkuRequestAddedDecorImages = state => state.requests.addedSkuRequestDecorImages;

//*** Request Dates ***
// export const selectAllRequestDates = state => state.requests.requestDates.allDatesByRequest;
export const selectReadinessDate = state => state.requests.requestDates.sheduledReadinessDate;
// export const selectConfirmedDate = state => state.requests.requestDates.confirmedDate;
export const selectdatesWithPostponed = state => state.requests.requestDates.datesWithPostponed;
export const selectFactoryData = state => state.requests.requestFactoryData;

//*** Transport Docs ***
export const selectAllTransportDocs = state => state.requests.transportDocs;
export const selectTransportDoc = state => state.requests.transportDoc;
export const selectTransportDocLoading = state => state.requests.transportDocLoading;

//*** Packing Spaces ***
export const selectPackingSpace = state => state.requests.packingSpaces;
//docs
export const selectDocsRefs = state => state.requests.docsRefs;

//*** Req Filters ***
export const selectReqFilters = state => state.requests.reqFilters;

export const selectLoadingForFiles = state => state.requests.loadingForFiles;

//stockPoll

export const getStockPoolInfo = state => state.requests.stockPool;
export const getRequestsInStockPool = state => state.requests.reqsInStockPool;
export const getSlepRequestsInStockPool = state => state.requests.sleppingReqsInStockPool;

export const getStockPoolCheckLIst = state => state.requests.checkList.currentStockPool;

/////orders

export const getCurentOrder = state => state.requests.checkList.curentOrder;

//** Defects */
export const selectDefect = state => state.requests.defect;
