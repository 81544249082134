import {t} from "i18next";

import {MoneyInput} from "../../../../../Inputs";

const PayerClientBalance = () => {
  return (
    <div className="row w100 aibl">
      <span>{t("Payer")}</span>
      <MoneyInput name="payerClientBalanceComposed" label={t("Client Balance")} autolabel width="8em" readOnly />
    </div>
  );
};

export default PayerClientBalance;
