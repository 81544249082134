import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEqual} from "lodash";
import * as Yup from "yup";

import {getCurrentUser} from "../../../../../slices/UserSlice";
import {swal, swalCustom} from "../../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../../utilities/hooks/toaster";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addNewBsAccount,
  clearCurrentBsAccount,
  deleteBsAccount,
  fetchAllBsAccounts,
  fetchAllBsaClasses,
  fetchAllBsaTypes,
  fetchBsAccount,
  selectAllBsAccounts,
  selectBsaClasses,
  selectBsaTypes,
  selectBsCurrentAccount,
  updateBsAccount,
} from "../../FinSettingsSlice";
import {BALANCE_TABLE_COLUMNS} from "../constants";

const useBalanceSheetAccountsModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllBsaTypes());
    dispatch(fetchAllBsaClasses());
    dispatch(fetchAllBsAccounts());
  }, []);

  const currentUser = useSelector(getCurrentUser);
  const types = useSelector(selectBsaTypes);
  const classes = useSelector(selectBsaClasses);
  const allAccounts = useSelector(selectAllBsAccounts);
  const currentAccount = useSelector(selectBsCurrentAccount);
  const currentAccountId = currentAccount?.balanceSheetAccId;

  const typeOptions =
    types?.map(({balanceTypeName, balanceTypeId}) => ({title: balanceTypeName, value: balanceTypeId})) ?? [];
  const accountClassOptions =
    classes?.map(({description, balanceClassId}) => ({
      title: String(balanceClassId),
      value: balanceClassId,
      description,
    })) ?? [];

  const balanceTableColumns = useColumns(BALANCE_TABLE_COLUMNS);
  const balanceTableData =
    allAccounts?.map(
      (
        {
          balanceSheetAccId,
          // balanceSerialNumber,
          balanceTypeId,
          balanceName,
          balancePurpose,
          creditDescription,
          debitDescription,
        },
        index,
      ) => ({
        number: ++index,
        id: balanceSheetAccId,
        balanceSerialNumber: balanceSheetAccId,
        type: typeOptions?.find(option => option.value === balanceTypeId)?.title ?? "",
        nameAndPurpose: `${balanceName} ${balancePurpose}`,
        operationDescription: `${creditDescription} ${debitDescription}`,
      }),
    ) ?? [];

  const initialValues = formValues(currentAccount);
  const validationSchema = Yup.object().shape({
    balanceSerialNumber: Yup.string()
      .matches(/^\d{4}$/, "Must be 4 digits")
      .required("Required"),
  });

  function findOutIfSaveBtnDisabled({values}) {
    return (
      !!currentAccountId ||
      !values.balanceClassId ||
      !values.balanceTypeId ||
      !values.balanceSerialNumber ||
      !/^\d{4}$/.test(values.balanceSerialNumber) ||
      !values.balanceSerialNumber?.startsWith(values.balanceClassId)
    );
  }

  function findOutIfEditBtnDisabled({values}) {
    return !currentAccountId || isEqual(values, formValues(currentAccount));
  }

  function formValues(currentAccount) {
    if (!currentAccount) {
      return {
        balanceClassId: "",
        balanceClassDescription: "",
        debitDescription: `${t("Amounts are debited to the account")}: `,
        balanceNumber: "",
        balanceTypeId: "",
        balanceName: "",
        balancePurpose: `${t("Account purpose")} `,
        creditDescription: `${t("Amounts are debited to the Credit account")}: `,
        balanceSerialNumber: "",
      };
    }

    const {
      balanceClassId,
      balanceName,
      balancePurpose,
      creditDescription,
      debitDescription,
      balanceTypeId,
      // balanceSerialNumber,
      balanceSheetAccId,
      class: balanceClass,
    } = currentAccount;
    return {
      balanceSerialNumber: String(balanceSheetAccId),
      balanceClassId,
      balanceClassDescription: balanceClass.description,
      debitDescription,
      balanceTypeId,
      balanceName,
      balancePurpose,
      creditDescription,
    };
  }

  function onBalanceClassSelect({data, setValues}) {
    setValues(prev => ({...prev, balanceClassId: data.value, balanceClassDescription: data.description}));
  }

  function onBalanceTableRowSelect(id) {
    if (!id) {
      dispatch(clearCurrentBsAccount());
      return;
    }
    dispatch(fetchBsAccount(id));
  }

  function onSaveBtnClick({
    values: {
      balanceClassId,
      balanceTypeId,
      balanceName,
      balancePurpose,
      debitDescription,
      creditDescription,
      balanceSerialNumber,
    },
  }) {
    if (!balanceClassId || !balanceTypeId) return;

    dispatch(
      addNewBsAccount({
        balanceClassId,
        balanceTypeId,
        params: {
          balanceSheetAccId: balanceSerialNumber,
          balanceSerialNumber: balanceSerialNumber,
          balanceName,
          balancePurpose,
          creditDescription,
          debitDescription,
        },
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("New balance sheet account created");
        dispatch(fetchAllBsAccounts());
      }
    });
  }

  function onEditBtnClick({
    values: {balanceName, balancePurpose, debitDescription, creditDescription, balanceSheetAccId},
  }) {
    if (!currentAccountId) return;

    dispatch(
      updateBsAccount({
        balanceSheetAccId: currentAccountId,
        params: {balanceSheetAccId, balanceName, balancePurpose, creditDescription, debitDescription},
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Balance sheet account updated");
        dispatch(fetchAllBsAccounts());
      }
    });
  }

  function onDeleteBtnClick() {
    if (!currentAccountId || !currentAccount || !currentUser) return;
    if (currentAccount.creatorId !== currentUser.id) {
      toaster.error("An account can be deleted only by its creator or by a superuser");
      return;
    }

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteBsAccount(currentAccountId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            toaster.success("Balance sheet account deleted");
            dispatch(clearCurrentBsAccount());
            dispatch(fetchAllBsAccounts());
          }
        });
      },
    });
  }

  return {
    typeOptions,
    accountClassOptions,
    balanceTableColumns,
    balanceTableData,
    initialValues,
    onSaveBtnClick,
    onEditBtnClick,
    onDeleteBtnClick,
    onBalanceTableRowSelect,
    currentAccountId,
    onBalanceClassSelect,
    validationSchema,
    findOutIfSaveBtnDisabled,
    findOutIfEditBtnDisabled,
  };
};

export default useBalanceSheetAccountsModal;
