export const CASH_REGISTR_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "KO №",
    accessorKey: "coNumber",
    // size: 100,
  },
  {
    header: "Date",
    accessorKey: "date",
    // size: 100,
  },
  {
    header: "Sum",
    accessorKey: "sum",
    // size: 100,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    // size: 100,
  },
  {
    header: "Client №",
    accessorKey: "clientNumber",
    // size: 100,
  },
  {
    header: "Type of operation",
    accessorKey: "operationType",
    // size: 100,
  },
  {
    header: "Order type",
    accessorKey: "orderType",
    // size: 100,
  },
  {
    header: "Order n",
    accessorKey: "orderNumber",
    // size: 100,
  },
  {
    header: "Role",
    accessorKey: "role",
    // size: 100,
  },
  {
    header: "Full name",
    accessorKey: "fullName",
    // size: 100,
  },
]);

export const CASH_VIEW_TABLE_COLUMNS = Object.freeze([
  {
    header: "Request",
    accessorKey: "request",
    // size: 100,
  },
  {
    header: "Order type",
    accessorKey: "orderType",
    // size: 100,
  },
  {
    header: "Order n",
    accessorKey: "orderNumber",
    // size: 100,
  },
  {
    header: "Role",
    accessorKey: "role",
    // size: 100,
  },
  {
    header: "Full name",
    accessorKey: "fullName",
    // size: 100,
  },
]);
