import {useTranslation} from "react-i18next";

import Divider from "../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {DateInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

import useTemplateFilters from "./Filters/useTemplateFilters";
import TemplateMainInfo from "./TemplateRegisterParts/TemplateMainInfo";
import TemplateReviewPart from "./TemplateRegisterParts/TemplateReviewPart";

const TemplateRegister = () => {
  const {t} = useTranslation();
  const {coManagersOptions, managersPOptions} = useTemplateFilters();

  return (
    <div className="form_wrapper">
      <SessionFiltersContainer window={"TemplateRegister"}>
        <Filters>
          <div className="f_block w100 nowrap" style={{paddingTop: "0.7em"}}>
            <TextInput label={t("tCO №")} name="templateNumber" width="100%" autolabel />
            <Select label={t("Manager")} name="managerId" options={managersPOptions} width="100%" autolabel />
            <Select label={t("Manager CO")} name="managerCoId" options={coManagersOptions} width="100%" autolabel />
            <TextInput label={t("About")} name="note" width="100%" autolabel />
            {t("Period")}
            <DateInput label={t("From")} name="dateStart" />
            <DateInput label={t("To")} name="dateEnd" />
          </div>
        </Filters>
        <Divider />

        <div className="two-column-page__container">
          <TemplateMainInfo />
          <TemplateReviewPart />
        </div>
      </SessionFiltersContainer>
    </div>
  );
};

export default withTabs({
  isMainTabControlled: true,
  formInitialValues: {},
  isClosableTabs: true,
  label: "TemplateRegister",
})(TemplateRegister);
