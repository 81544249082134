import {useFormikContext} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import NewPdfViewer from "../../Documents/DocumentView/NewPdfViewer";
import ContractDocsScreenShotsTables from "../ContractDocsScreenShotsTables/ContractDocsScreenShotsTables";
import DirectiveFilters from "../DirectiveFilters";
import FinStatus from "../FinStatus";
import OrderTypeComposedPart, {ORDER_COMPOSED_PART_TYPES} from "../OrderTypeComposedPart/OrderTypeComposedPart";

import useMainDirective from "./useMainDirective";

export const MAIN_DIRECTIVE_TAB_TYPES = Object.freeze({FINBLOCK: "finblock", KP_VIEW: "kpView", KP_CREATE: "kpCreate"});

const MainDirective = ({type}) => {
  const readOnly = type === MAIN_DIRECTIVE_TAB_TYPES.KP_VIEW;
  const {values} = useFormikContext();

  const {
    fileArray,
    setFileArray,
    onForImplementationBtnClick,
    onCancelBtnClick,
    onOkBtnClick,
    contractTableDataRow,
    annexTableDataRow,
    einfoTableDataRow,
    isDirectiveCreated,
    areAllFieldsFilledIn,
    onCancelDirectiveBtnClick,
    hasDirectivePayment,
    onTakeIntoWorkBtnClick,
  } = useMainDirective();

  let orderTypeComposedPartType;
  let typeDependentPart;
  switch (type) {
    case MAIN_DIRECTIVE_TAB_TYPES.FINBLOCK:
      orderTypeComposedPartType = ORDER_COMPOSED_PART_TYPES.FINBLOCK_DO;
      typeDependentPart = (
        <ToolTip title={!values.finOrderTypeId || values.finOrderTypeId === "default" ? "Choose fin order type" : ""}>
          <FunctionButton
            onClick={onTakeIntoWorkBtnClick}
            name="Take into work"
            autoWidth
            disabled={!values.finOrderTypeId || values.finOrderTypeId === "default"}
          />
        </ToolTip>
      );
      break;
    case MAIN_DIRECTIVE_TAB_TYPES.KP_CREATE:
      orderTypeComposedPartType = ORDER_COMPOSED_PART_TYPES.KP_CREATE;
      typeDependentPart = (
        <ToolTip title={!areAllFieldsFilledIn && isDirectiveCreated ? "Fill in all fields!" : ""}>
          <FunctionButton
            onClick={onForImplementationBtnClick}
            name="For implementation"
            autoWidth
            disabled={!isDirectiveCreated || !areAllFieldsFilledIn}
          />
        </ToolTip>
      );
      break;
    case MAIN_DIRECTIVE_TAB_TYPES.KP_VIEW:
      orderTypeComposedPartType = ORDER_COMPOSED_PART_TYPES.VIEW_OPERATION_TYPE;
      typeDependentPart = (
        <ToolTip title={hasDirectivePayment ? "Directive has payment" : ""}>
          <FunctionButton
            onClick={onCancelDirectiveBtnClick}
            name="Cancel directive"
            autoWidth
            disabled={hasDirectivePayment}
          />
        </ToolTip>
      );
      break;
  }

  return (
    <div className="fcCol h100 w100 pb aist maxH100 scrollY">
      <DirectiveFilters readOnly />
      <Divider />
      <div className="fc aistr jcsb gap2x w100 fGrow mb0_5 nowrap">
        <OrderTypeComposedPart onOkBtnClick={onOkBtnClick} type={orderTypeComposedPartType}>
          <div className="row">
            {typeDependentPart}
            <FunctionButton
              onClick={onCancelBtnClick}
              name={readOnly || type === MAIN_DIRECTIVE_TAB_TYPES.FINBLOCK ? "Exit" : "Cancel"}
              autoWidth
              disabled={!isDirectiveCreated}
            />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2" style={{maxWidth: "70%"}}>
          <FinStatus readOnly />
          <div className="grid2ColFirstBigger fGrow">
            <ContractDocsScreenShotsTables
              setFileArray={setFileArray}
              contractTableDataRow={contractTableDataRow}
              annexTableDataRow={annexTableDataRow}
              einfoTableDataRow={einfoTableDataRow}
            />
            <div className="mb2x">
              <NewPdfViewer fileArray={fileArray || []} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDirective;
