import React, {useEffect, useState} from "react";

import ReactTable from "../../../../../conteiners/ReactTable";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {TRANSACTION_TAMPLATES_COLUMNS} from "./constants";

const TransactionTamplatesList = ({data, setTransactionPackageId}) => {
  const columns = useColumns(TRANSACTION_TAMPLATES_COLUMNS);
  const [currentTemplate, setCurrentTempl] = useState(null);

  useEffect(() => {
    setCurrentTempl(null);
  }, [data]);

  return (
    <div style={{flexGrow: "1"}}>
      <ReactTable
        defaultData={data}
        columns={columns}
        onSelect={templateId => {
          setCurrentTempl(templateId);
          const packageId = data.find(temp => temp.id === templateId)?.packageId;
          setTransactionPackageId(packageId || null);
        }}
        current={currentTemplate}
        className={"scrollX"}
        loading={false}
        style={{minHeight: "150px"}}
      />
    </div>
  );
};

export default TransactionTamplatesList;
