import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import DirectiveFilters from "../../../Project/ProjectDirectiveList/DirectiveFilters";
import FinStatus from "../../../Project/ProjectDirectiveList/FinStatus";
import OrderTypeComposedPart from "../../../Project/ProjectDirectiveList/OrderTypeComposedPart/OrderTypeComposedPart";

import useFinBlockOrderRegister from "./useFinBlockOrderRegister";

const FinBlockOrderRegister = () => {
  const {onExitBtnClick, tableColumns, tableData, onTableSelect, onPrintOrderBtnClick, onNewOrderBtnClick} =
    useFinBlockOrderRegister();

  return (
    <div className="fcCol h100 w100 pb aist">
      <DirectiveFilters />
      <Divider />
      <div className="fc aistr jcsb gap2x w100 fGrow mb0_5">
        <OrderTypeComposedPart readOnly noAsterisk>
          <div className="row w100 jcsb">
            <FunctionButton onClick={onNewOrderBtnClick} name="New order" autoWidth />
            <FunctionButton onClick={onExitBtnClick} name="Exit" autoWidth />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2" style={{maxWidth: "70%"}}>
          <FinStatus />
          <ReactTable
            columns={tableColumns}
            defaultData={tableData}
            current={null}
            onSelect={onTableSelect}
            className="fGrow mb"
            style={{height: "unset"}}
          />
          <div className="row jcfe w100">
            <FunctionButton onClick={onPrintOrderBtnClick} name="Print an order" autoWidth />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinBlockOrderRegister;
