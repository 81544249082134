import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";
import translit from "ua-en-translit";

import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {CROP_IMG_CATEGORIES} from "../../../../Inputs/fileSelect/cropper/cropImgSizes";
import ImgBox from "../../../../Inputs/fileSelect/ImgBox/ImgBox";
import ImgFilePreview from "../../../../Inputs/fileSelect/preview/ImgFilePreview";
import {findImageId, processImages} from "../../../../SKU/VED/constants/functions";
import {
  addOverallImage,
  delOverallImage,
  getAddedOverallView,
  getCurrentSkuPlan,
  getCurrentUploadedOverallView,
  getImgLoading,
  uploadOverallImage,
} from "../../KpFinSlice";

import plus from "./plus.png";

const OverallView = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const currentSkuPlan = useSelector(getCurrentSkuPlan);
  const uploadedOverallView = useSelector(getCurrentUploadedOverallView);
  const addedOverallView = useSelector(getAddedOverallView);
  const imgLoading = useSelector(getImgLoading);

  const updatedUploadedOverallView = processImages(uploadedOverallView);

  const {values, setFieldValue} = useFormikContext();

  const [currentImgViewIndex, setCurrentImgViewIndex] = useState(0);

  const onActiveImgViewChange = index => {
    setCurrentImgViewIndex(index);
  };

  const updatePlanImage = () => {
    if (updatedUploadedOverallView !== null) {
      const imageFromIndex = updatedUploadedOverallView[currentImgViewIndex];

      const matchId = findImageId(imageFromIndex, uploadedOverallView);

      if (!matchId || addedOverallView.some(item => item.imageUrl === imageFromIndex)) {
        Swal.fire({
          title: "",
          text: `${t("Same image cannot be added twice")}`,
          icon: "warning",
          confirmButtonText: "Ок",
          customClas: {
            popup: "zindex",
            container: "zindex",
            htmlContainer: "zindex",
          },
        });
        return;
      }

      if (matchId) {
        dispatch(addOverallImage(matchId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            setFieldValue("addedOverallView", processImages(addedOverallView));
          }
        });
      }
    }
  };

  const addOvImg = arrFiles => {
    var formdata = new FormData();
    var i = 0,
      file,
      filename;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      filename = translit(file.name);
      formdata.append("image", file, filename);
    }

    dispatch(uploadOverallImage({skuPlanId: currentSkuPlan.skuPlanId, formdata}));
  };

  function onDell(imgUrl) {
    const imageToDeleteId = findImageId(imgUrl, uploadedOverallView);
    dispatch(delOverallImage(imageToDeleteId));
  }

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <label className="label" style={{marginBottom: "10px"}}>
        {t("Overall view")}
      </label>
      <div className="kpf_img_box" style={{position: "relative"}}>
        <ImageButton
          src={plus}
          alt="plus"
          width={1.5}
          height={1.4}
          onClick={() => {
            updatePlanImage();
          }}
          className="button-add"
        />
        <div style={{position: "absolute", height: "100%"}}>
          <ImgBox
            imgs={updatedUploadedOverallView || []}
            width={"10em"}
            height={"10em"}
            onActiveImgChange={onActiveImgViewChange}
          ></ImgBox>
          <ImgFilePreview
            imgs={updatedUploadedOverallView || []}
            delFunc={file => {
              onDell(file);
            }}
            accept={"image/*"}
            addFunc={file => {
              addOvImg(file);
            }}
            disable={!currentSkuPlan}
            necessaryCrop={true}
            category={CROP_IMG_CATEGORIES.DEFAULT}
            loading={imgLoading}
          />
        </div>
      </div>
    </div>
  );
};
export default OverallView;
