import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

import {getCurrentProject} from "../../../selectors/generalSelectors";

import NewPdfViewer from "./DocumentView/NewPdfViewer";
import AddKPDocumentForm from "./AddKPDocumentForm";
import AddProjectDocumentForm from "./AddProjectDocumentForm";
import AddScreenShotsForm from "./AddScreenShotsForm";

import "./utils/AddProjectDocForm.scss";

const MainDocComponent = () => {
  const [currentFile, setCurrentFile] = useState();
  const currentProject = useSelector(getCurrentProject);

  const docRefs = useRef([]);
  const focusOnDoc = (id = null) => {
    if (currentProject) {
      docRefs.current.forEach(item => item.classList.remove("selectedFormRef"));
      if (id) docRefs.current[id].classList.add("selectedFormRef");
    } else {
      docRefs.current.forEach(item => item.classList.remove("selectedFormRef"));
    }
  };

  useEffect(() => {
    setCurrentFile(null);
    focusOnDoc(1);
    if (currentProject) {
      setTimeout(() => {
        setCurrentFile(currentProject?.contracts?.[currentProject.contracts.length - 1]?.docsArray);
      }, 500);
    }
  }, [currentProject]);

  return (
    <div className="doc gap">
      <div className="doc_form_disabled">
        <AddProjectDocumentForm files={currentFile} setFile={setCurrentFile} docRef={docRefs} focusOnDoc={focusOnDoc} />

        <AddKPDocumentForm files={currentFile} setFile={setCurrentFile} docRef={docRefs} focusOnDoc={focusOnDoc} />

        <AddScreenShotsForm files={currentFile} setFile={setCurrentFile} docRef={docRefs} focusOnDoc={focusOnDoc} />
      </div>
      <div className="project_doc_view">
        <NewPdfViewer fileArray={currentFile} />
      </div>
    </div>
  );
};

export default MainDocComponent;
