import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import Divider from "../../../../utilities/Divider/Divider";
import {TextInput} from "../../../Inputs";
import {
  fetchSkuCatalogs,
  getColorImages,
  getCurrentSku,
  getDecorImages,
  getDrawings,
  getSkuImages,
} from "../../../SKU/SkuSlice";
import TemplateImageBlock from "../../../Templates/CoTemplates/TemplatesImagesBlock";

import CharDeskBlock from "./kpFinFormBlocks/CharDeskBlock";
import DecorColorBlock from "./kpFinFormBlocks/DecorColorBlock";
import NameBlock from "./kpFinFormBlocks/NameBlock";
import SeriesBatchBlock from "./kpFinFormBlocks/SeriesBatchBlock";
import SummBlock from "./kpFinFormBlocks/SummBlock";
import VolumeWeightCharacteristicsKpf from "./kpFinFormBlocks/VolumeWeightCharacteristicsKpf";

const KpPreviewModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {values, setValues} = useFormikContext();

  const currentSku = useSelector(getCurrentSku);
  const colorImages = useSelector(getColorImages);
  const decorImages = useSelector(getDecorImages);
  const images = useSelector(getSkuImages);
  const drawings = useSelector(getDrawings);

  useEffect(() => {
    setValues({
      ...currentSku,
    });
  }, [currentSku]);

  useEffect(() => {
    dispatch(fetchSkuCatalogs());
  }, []);

  const readOnly = true;

  return (
    <>
      {values && (
        <div className="form_wrapper" style={{padding: "1em", paddingTop: "0", paddingBottom: "0.5em"}}>
          <div>
            <div className="fields_check form-part">
              <div className="upper-block">
                <div className="part-with-img">
                  <div className="left-part-p">
                    <NameBlock />
                    <CharDeskBlock readOnly={readOnly} />
                  </div>

                  <div className="image-block-three">
                    <TemplateImageBlock label={"Product view"} images={images} />
                    <TemplateImageBlock label={"Drawings"} images={drawings} />
                  </div>

                  <div className="image-block-two">
                    <TemplateImageBlock label={"Finishing"} images={decorImages} />
                    <TemplateImageBlock label={"Color"} images={colorImages} />
                  </div>
                  <DecorColorBlock readOnly={readOnly} />
                </div>
                <div className="lower-part">
                  <SeriesBatchBlock readOnly={readOnly} />
                  <div className="vwchar">
                    <label className="label">{t("VWC")}</label>
                    <VolumeWeightCharacteristicsKpf idealSku={true} />
                  </div>
                </div>
              </div>
            </div>
            <TextInput
              label="SKU*"
              name="article"
              question="curr sku"
              labelStyles={{width: "3em"}}
              readOnly={true}
              width="10em"
              disabled={readOnly}
            />
            <SummBlock readOnly={readOnly} />
            <Divider />
          </div>
        </div>
      )}
    </>
  );
};

export default KpPreviewModal;
