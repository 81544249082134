import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import DeleteIcon from "../../../../images/icons/delete.png";
import {fetchClients, fetchOrgStructures, fetchUsers} from "../../../../slices/PersonSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import {toaster} from "../../../../utilities/hooks/toaster";
import makeNameFromPersona from "../../../../utilities/makeNameFromPersona";
import ToolTip from "../../../../utilities/ToolTip";
import IndeterminateCheckbox from "../../../conteiners/reactTable/IndeterminateCheckbox";
import {MoneyInput, NumberInput} from "../../../Inputs";
import CurrencySelect from "../../../Inputs/CurrencySelect/CurrencySelect";
import {Select} from "../../../Inputs/SelectField";
import {
  clearDeskOperations,
  deleteDirective,
  fetchAllDeskOperations,
  fetchOwnerTypes,
  fetchTransactionPackageGroups,
  runOperation,
  selectDirective,
  selectOwnerTypes,
  selectTransactionPackageGroups,
} from "../../FinSettings/FinSettingsSlice";

const BaseCashdeskFilters = ({
  checkBoxField,
  accountOwnerOptions,
  setAccountOwnerOptions,
  currentOperationId,
  onAoSelectEffect = () => {},
  onOperationSelectEffect = () => {},
  setCurrentOperationId = () => {},
  setCurrentSavedTransactionId,
  currentAccountOwner,
  setCurrentAccountOwner,
}) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const {values, setValues} = useFormikContext();

  const ownerTypes = useSelector(selectOwnerTypes);
  const ownerTypeOptions = ownerTypes?.map(type => ({title: t(type.ownerType), value: type.ownerTypeId})) ?? [];

  const transactionPackageGroups = useSelector(selectTransactionPackageGroups);
  const operationGroupOptions =
    transactionPackageGroups?.map(group => ({title: group.groupName, value: group.packageGroupId})) ?? [];

  const directive = useSelector(selectDirective);

  const [operationTypeOptions, setOperationTypeOptions] = useState([]);
  const [wasOkBtnPressed, setOkBtnWasPressed] = useState(false);
  const [isFavouritesCheckboxChecked, setFavouritesCheckboxChecked] = useState(false);

  useEffect(() => {
    dispatch(fetchOwnerTypes());
    dispatch(fetchTransactionPackageGroups());
  }, []);

  function onOwnerTypeSelect(selectedOption) {
    setValues(prev => ({...prev, accountOwner: "", accountOwnerType: selectedOption.value}));
    setCurrentAccountOwner(null);

    switch (selectedOption.value) {
      case 1:
        dispatch(fetchClients()).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            setAccountOwnerOptions(resp.payload?.map(item => ({title: item.clientNumber, value: item.clientId}))) ?? [];
          }
        });
        break;
      case 2:
        dispatch(fetchUsers()).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            setAccountOwnerOptions(
              resp.payload?.map(item => ({
                title: `${makeNameFromPersona({persona: item.persona, language})} ${item.position.shortName}`,
                value: item.userId,
              })),
            ) ?? [];
          }
        });
        break;
      case 3:
        dispatch(fetchOrgStructures()).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            setAccountOwnerOptions(
              resp.payload?.map(item => ({
                title: language === "en" ? item.fullNameEng : item.fullNameUkr,
                value: item.orgStructureId,
              })),
            ) ?? [];
          }
        });
        break;
    }
  }

  function onOperationGroupSelect(selectedOption) {
    dispatch(
      fetchAllDeskOperations({
        packageGroupId: selectedOption.value,
        operationCode: values.operationCode,
        isFavourite: values.isFavourite,
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        setOperationTypeOptions(
          resp.payload?.map(item => ({
            title: item.operationName,
            value: item.operationId,
          })),
        ) ?? [];
      }
    });
  }

  function onOkBtnClick() {
    const {currencyId, ammount, accountOwner, accountOwnerType} = values;
    if (!currencyId || !ammount) return;

    dispatch(
      runOperation({
        operationId: currentOperationId,
        params: {currencyId: currencyId, ammount: ammount, ownerId: accountOwner, ownerType: accountOwnerType},
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Directive created successfully");
        setOkBtnWasPressed(true);
        const directive = resp.payload;
        const {currencyId} = directive;
        setCurrentSavedTransactionId(null);

        setValues(prev => ({
          ...prev,
          currencyType: currencyId,
          sum: "",
          ktAccountName: "",
          dtAccountName: "",
          paymentRecognition: "",
          note: "",
          dtAccCountry: "",
          dtAccCountryCode: "",
          dtPersonaId: "",
          dtBalanceSheetAccId: "",
          dtBalanceSheetAccIdRel: "",
          dtCurrentYear: "",
          dtReserveCode: "",
          dtCurrencyId: "",
          ktAccCountry: "",
          ktAccCountryCode: "",
          ktPersonaId: "",
          ktBalanceSheetAccId: "",
          ktBalanceSheetAccIdRel: "",
          ktCurrentYear: "",
          ktReserveCode: "",
          ktCurrencyId: "",
        }));
      }
    });
  }

  function onDeleteBtnClick() {
    const directiveId = directive?.directiveId;
    if (!directiveId) return;

    dispatch(deleteDirective(directiveId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Directive deleted successfully");
        setOkBtnWasPressed(false);
        setCurrentOperationId(null);
        setValues(prev => Object.keys(prev).reduce((acc, key) => ({...acc, [key]: ""}), {}));
        dispatch(clearDeskOperations());
        setAccountOwnerOptions([]);
        setCurrentAccountOwner(null);
      }
    });
  }

  function onFavouritesCheckboxCheck(e) {
    const isChecked = e.target.checked;
    setFavouritesCheckboxChecked(isChecked);

    if (isChecked) {
      dispatch(
        fetchAllDeskOperations({
          isFavorite: isChecked,
        }),
      );
      setCurrentOperationId(null);
      setValues(prev => ({...prev, operationGroup: "", operationCode: "", operation: ""}));
    } else {
      dispatch(clearDeskOperations());
    }
  }

  function onOperationCodeChange(e) {
    const operationCode = e.target.value;
    if (operationCode.trim()) {
      dispatch(
        fetchAllDeskOperations({
          operationCode,
        }),
      ).then(resp => {
        if (resp.meta.requestStatus === "fulfilled" && resp.payload.length === 1) {
          const {packageGroupId, operationId, operationName} = resp.payload[0];
          setOperationTypeOptions([{title: operationName, value: operationId}]);
          setValues(prev => ({...prev, operationGroup: packageGroupId, operation: operationId}));
          setCurrentOperationId(operationId);
        }
        if (resp.payload.length !== 1) {
          setValues(prev => ({...prev, operationGroup: "", operation: ""}));
        }
      });
    } else {
      dispatch(clearDeskOperations());
      setValues(prev => ({...prev, operationGroup: "", operation: "", operationCode: ""}));
    }
  }

  return (
    <div className="fdr fGrow" style={{paddingTop: "0.7em", gap: "5em"}}>
      <div className="f_f_block w100" style={{marginLeft: "120px"}}>
        <div className="row jcsb gap3x w100">
          <div className="fcCol gap2x aist">
            <Select
              label={t("Account owner type") + ":"}
              name="accountOwnerType"
              options={ownerTypeOptions}
              labelwidth="8em"
              makeEffect={onOwnerTypeSelect}
            />
            <Select
              label={t("Account owner (AO)") + "*:"}
              name="accountOwner"
              autolabel
              options={accountOwnerOptions}
              question={t("Choose account owner type first")}
              disabled={!values.accountOwnerType}
              makeEffect={onAoSelectEffect}
            />
          </div>
          <div className="fcCol gap2x aist">
            <ToolTip title="Operation search via operation code">
              <NumberInput
                label={t("Operation code") + ":"}
                name="operationCode"
                width="7em"
                autolabel
                type="number"
                inputClassName="inputTypeNumberNoArrows"
                handleInputChange={onOperationCodeChange}
                disabled={!currentAccountOwner}
                noFormat
              />
            </ToolTip>

            {checkBoxField && (
              <ToolTip title="Show favourite operations">
                <IndeterminateCheckbox
                  type="checkbox"
                  name="isFavourite"
                  onChange={onFavouritesCheckboxCheck}
                  disabled={!currentAccountOwner}
                />
              </ToolTip>
            )}
          </div>
          <div className="fcCol gap2x aist">
            <div className="row gap2x">
              <CurrencySelect name="currencyId" onlyUSDandEUR disabled={!currentAccountOwner} />
              <MoneyInput
                name="ammount"
                inputClassName="borderedInput"
                width="7em"
                placeholder={t("Sum")}
                currency=""
                disabled={!currentAccountOwner}
              />
            </div>
            <div className="row gap2x aibl">
              <Select
                label={t("Operation group") + ":"}
                name="operationGroup"
                autolabel
                options={operationGroupOptions}
                makeEffect={onOperationGroupSelect}
                disabled={isFavouritesCheckboxChecked || !currentAccountOwner}
              />
              <Select
                label={t("Operation type ") + ":"}
                name="operation"
                autolabel
                options={operationTypeOptions}
                question={t("Choose an operation type first")}
                disabled={!values.operationGroup || !!values.operationCode || !currentAccountOwner}
                makeEffect={onOperationSelectEffect}
              />
              <ToolTip title="Choose currency, ammount and operation first">
                <FunctionButton
                  name="Ok"
                  disabled={!currentOperationId || wasOkBtnPressed || !values.currencyId || !values.ammount}
                  onClick={onOkBtnClick}
                  autoWidth
                />
              </ToolTip>

              <ImageButton src={DeleteIcon} onClick={onDeleteBtnClick} disabled={!wasOkBtnPressed} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseCashdeskFilters;
