import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import {getCurrentSku, getSkuOptionsList} from "../../../../selectors/skuSelectors";
import FilterButton from "../../../../utilities/Buttons/FilterBtn/FilterButton";
import {checkEmptyFilterFields} from "../../../../utilities/checkEmptyFilterFields";
import {makeFilterManager} from "../../../../utilities/makeFilterManager/makeFilterManager";
import {Select} from "../../../Inputs/SelectField";
import ChosenFiltersLine from "../../../Requests/Filters/ChosenFiltersLine";
import useSkuCatalogsService from "../../SkuCatalogsService";
import {changeSkuPage, fetchSku, fetchSkuCatalogs} from "../../SkuSlice";

import "./src/skuFilters.scss";

export const SKU_FILTERS_KEY = "sku_filters";

const SkuFilters = () => {
  const currentSku = useSelector(getCurrentSku);

  const dispatch = useDispatch();

  const skuFilterManager = makeFilterManager(SKU_FILTERS_KEY);

  const [skuFilters, setSkuFilters] = useState(null);

  useEffect(() => {
    setSkuFilters(skuFilterManager.getFilters() ?? null);
  }, []);

  const initialValues = useMemo(
    () => ({
      search: skuFilters?.search || "",
      // country:'',
      brand: skuFilters?.brandId || "",
      productGroup: skuFilters?.productGroupId || "",
      productType: skuFilters?.productTypeId || "",
      material: skuFilters?.materialId || "",
      colorId: skuFilters?.colorId || "",
    }),
    [skuFilterManager],
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({})}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        // onSubmit={values => {
        //   // console.log(values);
        //   // dispatch(createSku(values))
        // }}
      >
        <InputsRender
          dispatch={dispatch}
          currentSku={currentSku}
          skuFilters={skuFilters}
          setSkuFilters={setSkuFilters}
        />
      </Formik>
    </div>
  );
};

const InputsRender = ({skuFilters, setSkuFilters}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {brandList, colorsList, materialsList, productGroups, productTypes} = useSkuCatalogsService();

  const skuOptions = useSelector(getSkuOptionsList);
  const {values, setValues, initialValues} = useFormikContext();
  const {brand, productGroup, productType, search, material, colorId} = values;

  const skuFilterManager = makeFilterManager(SKU_FILTERS_KEY);

  const areFiltersDefined = Boolean(skuFilterManager.getFilters());

  // useEffect(() => {
  //   if (skuFilters) {
  //     updateFilterQuery(skuFilters);
  //   }
  // }, [skuFilters]);

  const [savedFilters, setSavedFilters] = useState({});

  useEffect(() => {
    const filters = JSON.parse(sessionStorage.getItem(SKU_FILTERS_KEY));
    if (filters && Object.keys(filters).length > 0) setSavedFilters(filters);
  }, [values]);

  const filterNames = useMemo(
    () => ({
      search: {name: t("SKU"), value: savedFilters.search},
      brandId: {name: t("Brand"), options: brandList},
      productGroupId: {name: t("Group"), options: productGroups},
      productTypeId: {name: t("Type"), options: productTypes},
      materialId: {name: t("Finishing"), options: materialsList},
      colorId: {name: t("Color"), options: colorsList},
    }),
    [
      savedFilters.search,
      brandList?.length,
      productGroups?.length,
      productTypes?.length,
      materialsList?.length,
      colorsList?.length,
    ],
  );

  const onFilter = newFilters => {
    const isArticle = search === "" ? undefined : skuOptions.find(item => item.value === search)?.title;
    var params = checkEmptyFilterFields(
      newFilters ?? {
        // countryId: country.value,
        brandId: brand,
        productGroupId: productGroup,
        productTypeId: productType,
        search: search,
        sku: isArticle,
        // search: search,
        // skuId: search,
        materialId: material,
        colorId,
        page: 1,
      },
    );

    // updateFilterQuery(params);
    setSkuFilters(params);
    setSavedFilters(params);
    skuFilterManager.setFilters(params);

    if (newFilters && Object.keys(params).filter(param => param !== "page" && param !== "sku").length === 0) {
      skuFilterManager.clearFilters();
      setSavedFilters(params);
    }
  };

  // function updateFilterQuery(params) {
  //   dispatch(fetchSku(params));
  // }

  useEffect(() => {
    dispatch(fetchSkuCatalogs());
    dispatch(fetchSku({...(skuFilters && {...skuFilters}), page: 1}));
  }, [skuFilters]);

  const delFilters = () => {
    // dispatch(fetchSku({page: 1}));
    setValues(initialValues);
    setSavedFilters({});
    dispatch(changeSkuPage(1));
    // dispatch(setFilters(null));
    skuFilterManager.clearFilters();
    setSkuFilters(null);
  };

  return (
    <div className="project_form mb0_5">
      <div className="fc nowrap jcfs autolabel sku-filters_container">
        <div className="flex_container nowrap" style={{justifyContent: "space-between"}}>
          <FilterButton
            onFilter={onFilter}
            delFilter={delFilters}
            questionTitle={[t("Filter info one")]}
            areFiltersDefined={areFiltersDefined}
          />

          <Select label="SKU:" name="search" options={skuOptions} multi={true} width="10em" textMode />
        </div>
        {/* <Select
            label="Країна*:"
            name="country" 
            options={countries}
            multi={true}
            errorField="countryId"
        /> */}
        <Select label={t("Factory")} name="brand" options={brandList} multi={true} width="100%" errorField="brandId" />
        <Select
          label={t("Group")}
          name="productGroup"
          options={productGroups}
          multi={true}
          width="100%"
          errorField="productGroupId"
        />
        <Select
          label={t("Type")}
          name="productType"
          options={productTypes}
          width="100%"
          multi={true}
          errorField="productTypeId"
        />
        <Select label={t("Finishing")} name="material" options={materialsList} width="100%" multi={true} />
        <Select label={t("Color")} name="colorId" options={colorsList} width="100%" multi={true} />
      </div>
      <ChosenFiltersLine
        window={window}
        savedFilters={savedFilters}
        setSavedFilters={setSavedFilters}
        onFilter={onFilter}
        filterNames={filterNames}
      />
    </div>
  );
};

InputsRender.propTypes = {
  skuFilters: PropTypes.shape({
    article: PropTypes.number,
    brand: PropTypes.number,
    productGroup: PropTypes.number,
    productType: PropTypes.number,
    material: PropTypes.number,
    colorId: PropTypes.number,
  }),
  setSkuFilters: PropTypes.func,
};

export default SkuFilters;
