import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";

import {PROJECT_TASKS_LIST_TABLE_COLUMNS} from "./constants";

const useProjectTaskList = () => {
  const appealTypes = [];
  const fileArray = [];

  const officeOptions = [];
  const managerOptions = [];
  const appealBlockOptions = [];

  const appealListTableColumns = useColumns(PROJECT_TASKS_LIST_TABLE_COLUMNS);
  const appealListTableData = [];

  function onAddAppealNoteBtnClick() {}
  function onSaveBtnClick() {}
  function onMoneyBtnClick() {}

  return {
    appealTypes,
    fileArray,
    appealListTableColumns,
    appealListTableData,
    onAddAppealNoteBtnClick,
    officeOptions,
    managerOptions,
    appealBlockOptions,
    onSaveBtnClick,
    onMoneyBtnClick,
  };
};

export default useProjectTaskList;
