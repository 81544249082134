// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectDireciveListFiltersContainer {
  display: grid;
  column-gap: 20px;
  row-gap: 10px;
  width: 100%;
  grid-template-areas: "directiveNumber clientId coId operationTypeId dateStart" "statusId sumPlan payerData payerData dateEnd";
  align-items: center;
  justify-items: end;
}`, "",{"version":3,"sources":["webpack://./src/Components/Project/ProjectDirectiveList/projectDirectiveLIstStyles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;EACA,6HACE;EAEF,mBAAA;EACA,kBAAA;AADF","sourcesContent":[".projectDireciveListFiltersContainer {\n  display: grid;\n  column-gap: 20px;\n  row-gap: 10px;\n  width: 100%;\n  grid-template-areas:\n    \"directiveNumber clientId coId operationTypeId dateStart\"\n    \"statusId sumPlan payerData payerData dateEnd\";\n  align-items: center;\n  justify-items: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
