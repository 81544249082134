import {t} from "i18next";

import {MoneyInput} from "../../../../../Inputs";

const Sums = ({readOnly = true}) => {
  return (
    <div className="fcCol gap aist w100">
      <MoneyInput name="coSumComposed" label={t("CO Sum")} autolabel width="10em" readOnly={true} />
      <MoneyInput name="fSumComposed" label={t("Sum (f)")} autolabel width="10em" readOnly={true} />
      <MoneyInput name="coSumAdditionalComposed" label={t("CO Sum (addit)")} autolabel width="10em" readOnly={true} />
    </div>
  );
};

export default Sums;
