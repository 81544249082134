import {useDispatch} from "react-redux";

import {apiUrl} from "../../../../../api/baseURL";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {
  getRequestSkuInfo,
  getSkuRequestAddedImages,
  removeSkuRequestCommonImage,
} from "../../../../Requests/RequestsSlice";
import {findImageId, processImages} from "../../../../SKU/VED/constants/functions";
import useRemoveImageHandlers from "../../kpFinForm/kpFinFormBlocks/hooks/useRemoveImageHandler";
import cancel from "../cancel.png";

const MainRow = ({setFieldValue, values, data, chengeable, ...props}) => {
  const dispatch = useDispatch();

  const {action, selector, currentSkuObjIdName, skuObjId, addedImagesKey} = useRemoveImageHandlers("Common");

  const onRemoveImageClick = value => {
    const imageToDeleteId = findImageId(value, selector);
    const imageTypeFromUrl = value.includes("/drawings/") ? "drawing" : "image";

    dispatch(
      action({
        imageId: imageToDeleteId,
        [currentSkuObjIdName]: skuObjId,
        imageType: imageTypeFromUrl,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setFieldValue(addedImagesKey, processImages(res.payload[addedImagesKey]));
      }
    });
  };

  return (
    <tr {...props}>
      {Object.entries(data).map(([key, value], index) => {
        if (key.startsWith("image") && value !== "") {
          return (
            <td key={index} style={{border: "0.02px solid rgba(88, 89, 93, 0.2941176471)"}}>
              <div style={{display: "flex", justifyContent: "center", position: "relative"}}>
                <img src={apiUrl + "/" + value} alt={`Image ${index}`} style={{width: "100px", height: "100px"}} />
                {chengeable && (
                  <ImageButton
                    src={cancel}
                    alt="cancel"
                    width={1}
                    height={1}
                    onClick={() => {
                      onRemoveImageClick(value);
                    }}
                    style={{
                      position: "absolute",
                      border: "0",
                      background: "transparent",
                      cursor: "pointer",
                      right: "3px",
                    }}
                  />
                )}
              </div>
            </td>
          );
        } else {
          return (
            <td
              key={index}
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                border: "0.02px solid rgba(88, 89, 93, 0.2941176471)",
              }}
            >
              {value}
            </td>
          );
        }
      })}
    </tr>
  );
};

export default MainRow;
