import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {getClientsList, getDesignersrsList, getManagersList} from "../api/FakeServices/catalogsService";
import {fetchList} from "../hook/axios.hook";

const initialState = {
  catalogsLoading: false,
  error: null,
  managersCatalog: [],
  clientsCatalog: [],
  designers: [],
  designerTypes: [],
  agents: [],
  agentTypes: [],
  orgStructures: [],
  countries: [],
};

export const fetchManagersCatalog = createAsyncThunk("catalogs/fetchManagersCatalog", async () => {
  const responce = getManagersList();
  return responce;
});
export const fetchClientsCatalog = createAsyncThunk("catalogs/fetchClientsCatalog ", async () => {
  const responce = getClientsList();
  return responce;
});
export const fetchDesignersCatalog = createAsyncThunk("catalogs/fetchDesignersCatalog", async () => {
  const responce = getDesignersrsList();
  return responce;
});

export const fetchOrgStructures = createAsyncThunk("catalogs/fetchOrgStructures", async () => {
  const responce = fetchList("/orgStructure/getAll", "get");
  return responce;
});

export const fetchDesigners = createAsyncThunk("catalogs/fetchDesigners", async () => {
  const responce = fetchList("/designer/getAll", "get");
  return responce;
});
export const fetchDesignerTypes = createAsyncThunk("person/fetchDesignerTypes", async () => {
  return fetchList("/designer/types/getAll", "get");
});
export const fetchAgents = createAsyncThunk("person/fetchAgents", async () => {
  return fetchList("/agent/getAll", "get");
});
export const fetchAgentTypes = createAsyncThunk("person/fetchAgentTypes", async () => {
  return fetchList("/agent/types/getAll", "get");
});

export const fetchAllCountries = createAsyncThunk("references/fetchAllCountries", async required => {
  return fetchList("/references/countries", "get", {params: {required}});
});

const projectSlice = createSlice({
  name: "catalogs",
  initialState,
  reducers: {
    orgStructuresFetched: (state, {payload}) => {
      state.orgStructures = payload;
    },
    designersFetched: (state, {payload}) => {
      state.designers = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOrgStructures.pending, state => {
        state.catalogsLoading = true;
      })
      .addCase(fetchOrgStructures.fulfilled, (state, action) => {
        state.catalogsLoading = false;
        state.orgStructures = action.payload;
      })
      .addCase(fetchOrgStructures.rejected, (state, {error}) => {
        state.catalogsLoading = false;
        state.error = error;
      })

      .addCase(fetchDesigners.pending, state => {
        state.catalogsLoading = true;
      })
      .addCase(fetchDesigners.fulfilled, (state, action) => {
        state.catalogsLoading = false;
        state.designers = action.payload;
      })
      .addCase(fetchDesigners.rejected, (state, {error}) => {
        state.catalogsLoading = false;
        state.error = error;
      })
      .addCase(fetchDesignerTypes.fulfilled, (state, {payload}) => {
        state.designerTypes = payload;
      })
      .addCase(fetchAgents.fulfilled, (state, {payload}) => {
        state.agents = payload;
      })
      .addCase(fetchAgentTypes.fulfilled, (state, {payload}) => {
        state.agentTypes = payload;
      })

      .addCase(fetchManagersCatalog.fulfilled, (state, action) => {
        state.managersCatalog = action.payload;
      })
      .addCase(fetchClientsCatalog.fulfilled, (state, action) => {
        state.clientsCatalog = action.payload;
      })
      .addCase(fetchDesignersCatalog.fulfilled, (state, action) => {
        state.designersCatalog = action.payload;
      })

      .addCase(fetchAllCountries.fulfilled, (state, {payload}) => {
        state.countries = payload;
      })
      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = projectSlice;

export default reducer;
export const {orgStructuresFetched, designersFetched} = actions;

export const getOrgStructuresList = state => {
  return state.catalogs.orgStructures.map(item => {
    return {
      value: item.fullName,
      orgShortName: item.shortName,
      orgStructureId: item.orgStructureId,
    };
  });
};
export const getOrgStructuresCodeList = state => {
  return state.catalogs.orgStructures.map(item => {
    return {
      value: item.shortName,
      orgFullName: item.fullName,
      orgStructureId: item.orgStructureId,
    };
  });
};

export const getOrgStructures = state => state.catalogs.orgStructures;
export const getCatalogsLoading = state => state.catalogs.catalogsLoading;
export const selectDesignerTypes = state => state.catalogs.designerTypes;
export const selectAgents = state => state.catalogs.agents;
export const selectAgentTypes = state => state.catalogs.agentTypes;
export const selectDesigners = state => state.catalogs.designers;
export const selectAllCountries = state => state.catalogs.countries;
