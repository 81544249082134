import {Route, Routes} from "react-router-dom";

import ProjectTasksList from "../Components/Reference/ProjectTasksList/ProjectTasksList";

const ReferencePage = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectTasksList />} />
    </Routes>
  );
};

export default ReferencePage;
