import {toast} from "react-toastify";
import {t} from "i18next";

export const toaster = {
  success: text => {
    toast.success(t(text), {autoClose: 1000});
  },
  error: text => {
    toast.error(t(text), {autoClose: 1000});
  },
  warning: text => {
    toast.warning(t(text), {autoClose: 1000});
  },
  info: text => {
    toast.info(t(text), {autoClose: 1000});
  },
};
