import {Route, Routes} from "react-router-dom";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";
import Project from "../Components/Project/Project";
import ProjectDirectiveList from "../Components/Project/ProjectDirectiveList/ProjectDirectiveList";
import ProjectSecondVersion from "../Components/Project/ProjectSecondVersion/ProjectSecondVersion";

const ProjectPage = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <Project />
          </ErrorBoundary>
        }
      />
      <Route
        path="/projectSecondVersion"
        element={
          // <ErrorBoundary>
          <ProjectSecondVersion />
          // </ErrorBoundary>
        }
      />
      <Route
        path="/projectDirectiveList"
        element={
          <ErrorBoundary>
            <ProjectDirectiveList />
          </ErrorBoundary>
        }
      />
      {/* <Route
        path="/projectView"
        element={
          <ErrorBoundary>
            <ProjectView />
          </ErrorBoundary>
        }
      /> */}
    </Routes>
  );
};

export default ProjectPage;
