import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import ToolTip from "../../../../utilities/ToolTip";
import {TextInput} from "../../../Inputs";

const OneAccountTemplate = ({name}) => {
  const {t} = useTranslation();
  const {values, errors, touched, handleBlur} = useFormikContext();

  const accInputStyle = {border: "1px solid #A3B9D9"};
  const accInputStyleAccent = {backgroundColor: "#fff", border: "1px solid #A3B9D9"};
  function formErrorInputStyle(fieldName) {
    return errors[fieldName] && touched[fieldName] ? {border: "1px solid red"} : {};
  }

  return (
    <div className="row gap2x mb0_5">
      <div className="row gap">
        <div className="row gap2">
          <ToolTip title="account country">
            <TextInput
              name={`${name}AccCountry`}
              width="3em"
              className="outlined blue_readonly"
              // style={accInputStyle}
              readOnly
            />
          </ToolTip>
          <ToolTip title="account country code">
            <TextInput name={`${name}AccCountryCode`} width="2.5em" className="outlined blue_readonly" readOnly />
          </ToolTip>
        </div>
        <ToolTip title="persona id">
          <TextInput name={`${name}PersonaId`} width="4.5em" className="outlined blue_readonly" readOnly />
        </ToolTip>

        <div className="row gap2">
          <ToolTip title="balance sheet account number">
            <TextInput name={`${name}BalanceSheetAccId`} width="4.5em" className="outlined blue_readonly" readOnly />
          </ToolTip>
          <ToolTip title="+i balance sheet account">
            <TextInput name={`${name}BalanceSheetAccIdRel`} width="4.5em" className="outlined blue_readonly" readOnly />
          </ToolTip>
          <ToolTip title="account creation current year">
            <TextInput name={`${name}CurrentYear`} width="2.5em" className="outlined blue_readonly" readOnly />
          </ToolTip>
          <ToolTip title="reserve code">
            <TextInput name={`${name}ReserveCode`} width="3.1em" className="outlined blue_readonly" readOnly />
          </ToolTip>
        </div>
        <ToolTip title="currency code">
          <TextInput name={`${name}CurrencyId`} width="4.1em" className="outlined blue_readonly" readOnly />
        </ToolTip>
      </div>
    </div>
  );
};

export default OneAccountTemplate;
