import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchList} from "../../../../hook/axios.hook";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import useToaster from "../../../../utilities/hooks/useToaster";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {addNewReminder, deleteReminder, fetchAllReminders, fetchSystemDates} from "../../redux/Reminders/operations";
import {selectAllReminders, selectAllSystemDates, selectAreRemindersLoading} from "../../redux/Reminders/selectors";
import {fetchAllWarehouses} from "../../redux/Warehouses/operations";
import {selectWarehouses} from "../../redux/Warehouses/selectors";

import {WR_TERMS_MAIN_TABLE_COLUMNS} from "./constants";

const useLogModSettingsDeadlines = () => {
  const dispatch = useDispatch();
  const Toaster = useToaster();
  const {
    i18n: {language},
  } = useTranslation();

  //selectors
  const allWarehouses = useSelector(selectWarehouses);
  const allReminders = useSelector(selectAllReminders);
  const systemDates = useSelector(selectAllSystemDates);
  const areRemindersLoading = useSelector(selectAreRemindersLoading);

  //state
  const [countries, setCountries] = useState([]);
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [termTypes, setTermTypes] = useState([]);
  const [currentDateId, setCurrentDateId] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [wrSettingsReminders, setWrSettingsReminders] = useState(null);
  const [currentReminder, setCurrentReminder] = useState(null);
  const [currentCountryAndWrh, setCurrentCountryAndWrh] = useState({countryId: null, warehouseId: null});
  const [currentWrInSettingsInfo, setCurrentWrInSettingsInfo] = useState(null);

  const currentReminderId = currentReminder?.reminderId ?? null;

  const currentWarehouseId = null;

  //effects
  useEffect(() => {
    dispatch(fetchSystemDates());
    dispatch(fetchAllWarehouses());
    dispatch(fetchAllReminders());
    fetchList("references/countries", "get", {params: {required: "Warehouses"}}).then(countries => {
      setCountries(countries);
    });
    fetchList("references/measurementQuantities", "get", {params: {field: "time"}}).then(term => {
      setTermTypes(term);
    });
  }, []);

  useEffect(() => {
    if (allWarehouses?.length > 0) {
      setWarehouseOptions(allWarehouses?.map(({warehouseId, name}) => ({title: name, value: warehouseId})));
    }
  }, [allWarehouses?.length]);

  const initialValues = {
    country: currentCountryAndWrh?.countryId ?? "",
    warehouse: currentCountryAndWrh?.warehouseId ?? "",
    startDate: currentReminder?.date?.dateId ?? "",
    firstTermType: currentReminder?.isFirstTerm ? currentReminder?.unitId : "",
    firstTerm:
      currentReminder?.isFirstTerm && currentReminder?.unit
        ? currentReminder?.period * currentReminder?.unit?.multiplier
        : "",
    firstTermAreaField: currentReminder?.isFirstTerm ? currentReminder?.notification : "",
    secondTermType: !currentReminder?.isFirstTerm ? currentReminder?.unitId : "",
    secondTerm:
      !currentReminder?.isFirstTerm && currentReminder?.unit
        ? currentReminder?.period * currentReminder?.unit?.multiplier
        : "",
    secondTermAreaField: !currentReminder?.isFirstTerm ? currentReminder?.notification : "",
    warehouseInSettingsInfo: currentWrInSettingsInfo ?? "",
  };

  //* Notification settings for reminders */
  let countryOptions = countries?.map(({countryId, shortName}) => ({title: shortName, value: countryId})) ?? [];
  if (countryOptions.length > 0) countryOptions.unshift({title: "All", value: null});

  const warehouseInSettingsOptions =
    allWarehouses?.map(({warehouseId, name}) => ({title: name, value: warehouseId})) ?? [];
  const startDateOptions = systemDates?.map(({dateId, name}) => ({title: name, value: dateId})) ?? [];
  const termTypeOptions = termTypes?.map(({unitId, name}) => ({title: name, value: unitId})) ?? [];

  const startDatesTableData =
    systemDates?.map(({dateId, name, description}, index) => ({
      id: dateId,
      number: ++index,
      startDateName: name,
      description,
    })) ?? [];

  function onCountrySelect({item: {value}, setValues}) {
    setValues(prev => ({
      ...prev,
      firstTermType: "",
      firstTerm: "",
      firstTermAreaField: "",
      secondTermType: "",
      secondTerm: "",
      secondTermAreaField: "",
      warehouse: "",
      country: value,
      startDate: "",
    }));
    fetchList(`/warehouses/getAll`, "get", {params: {countryId: value}}).then(data => {
      setWarehouseOptions(data.map(({warehouseId, name}) => ({title: name, value: warehouseId})));
    });
    dispatch(fetchAllReminders()).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        setWrSettingsReminders(resp.payload);
      }
    });
    setIsCountrySelected(Boolean(value));
    setCurrentDateId(null);
    setCurrentCountryAndWrh({warehouseId: null, countryId: value});
    setCurrentReminder(null);
    setCurrentWrInSettingsInfo(null);
  }

  function onWarehouseSelect({item: {value}}) {
    dispatch(fetchAllReminders({warehouseId: value}));
    setCurrentCountryAndWrh(prev => ({...prev, warehouseId: value}));
  }

  function onStartDatesTableSelect({dateId, setValues}) {
    if (!dateId) {
      setValues(values => ({...values, startDate: ""}));
      setCurrentDateId(null);
      return;
    }

    setValues(values => ({...values, startDate: dateId}));
    setCurrentDateId(dateId);
  }

  function onSaveFirstTermBtnClick({
    startDate,
    warehouse,
    firstTermType,
    firstTerm,
    firstTermAreaField,
    warehouseInSettingsInfo,
  }) {
    if (!startDate || !warehouse || !firstTermType || !firstTerm) {
      Toaster.toastError("You must fill in startDate, warehouse, term type and term before saving");
      return;
    }

    dispatch(
      addNewReminder({
        dateId: startDate,
        warehouseId: warehouse,
        unitId: firstTermType,
        body: {isFirstTerm: true, period: firstTerm, notification: firstTermAreaField},
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        Toaster.toastSuccess("Saved");
        dispatch(fetchAllReminders({warehouseId: warehouse})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            if (warehouseInSettingsInfo === warehouse) {
              setWrSettingsReminders(resp.payload);
            }
            if (!warehouseInSettingsInfo) {
              fetchList(`/reminders/logistics/getAll`, "get").then(data => setWrSettingsReminders(data));
            }
          }
        });
      }
    });
  }

  function onDeleteFirstTermBtnClick(setValues) {
    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteReminder(currentReminderId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            setCurrentDateId(null);
            setCurrentReminder(null);
            setValues(values => {
              dispatch(fetchAllReminders({warehouseId: values.warehouse})).then(resp => {
                if (resp.meta.requestStatus === "fulfilled") {
                  if (values.warehouseInSettingsInfo === values.warehouse) {
                    setWrSettingsReminders(resp.payload);
                  }
                  if (!values.warehouseInSettingsInfo) {
                    fetchList(`/reminders/logistics/getAll`, "get").then(data => setWrSettingsReminders(data));
                  }
                }
              });
              return {...values, firstTermType: "", firstTerm: "", firstTermAreaField: ""};
            });
            Toaster.toastSuccess("The term was deleted");
          }
        });
      },
    });
  }

  function onSaveSecondTermBtnClick({
    startDate,
    warehouse,
    secondTermType,
    secondTerm,
    secondTermAreaField,
    warehouseInSettingsInfo,
  }) {
    if (!startDate || !warehouse || !secondTermType || !secondTerm) {
      Toaster.toastError("You must fill in startDate, warehouse, term type and term before saving");
      return;
    }

    dispatch(
      addNewReminder({
        dateId: startDate,
        warehouseId: warehouse,
        unitId: secondTermType,
        body: {isFirstTerm: false, period: secondTerm, notification: secondTermAreaField},
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        dispatch(fetchAllReminders({warehouseId: warehouse})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            if (warehouseInSettingsInfo === warehouse) {
              setWrSettingsReminders(resp.payload);
            }
            if (!warehouseInSettingsInfo) {
              fetchList(`/reminders/logistics/getAll`, "get").then(data => setWrSettingsReminders(data));
            }
          }
        });
        Toaster.toastSuccess("Saved");
      }
    });
  }
  function onDeleteSecondTermBtnClick(setValues) {
    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteReminder(currentReminderId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            setValues(values => {
              dispatch(fetchAllReminders({warehouseId: values.warehouse})).then(resp => {
                if (resp.meta.requestStatus === "fulfilled") {
                  if (values.warehouseInSettingsInfo === values.warehouse) {
                    setWrSettingsReminders(resp.payload);
                  }
                  if (!values.warehouseInSettingsInfo) {
                    fetchList(`/reminders/logistics/getAll`, "get").then(data => setWrSettingsReminders(data));
                  }
                }
              });
              return {...values, secondTermType: "", secondTerm: "", secondTermAreaField: ""};
            });
            setCurrentDateId(null);
            Toaster.toastSuccess("The term was deleted");
          }
        });
      },
    });
  }

  //* Warehouse Settings Info block */
  const wrSettingsInfoTableData =
    (wrSettingsReminders ?? allReminders)?.map(({reminderId, period, notification, date, unit}, index) => {
      return {
        id: reminderId,
        number: ++index,
        startDate: date?.name ?? " - ",
        description: date?.description ?? " - ",
        type: (language === "en" ? unit?.engName : unit?.name) ?? " - ",
        term: period ?? " - ",
        notification: notification ?? " - ",
      };
    }) ?? [];

  function onWrSettingsWarehouseSelect({value}) {
    setCurrentWrInSettingsInfo(value);
    fetchList(`/reminders/logistics/getAll`, "get", {params: {warehouseId: value}}).then(data => {
      setWrSettingsReminders(data);
    });
  }

  //* Warehouse Terms lower main table */
  const wrTermsMainTableColumns = useColumns(WR_TERMS_MAIN_TABLE_COLUMNS);
  const wrTermsMainTableDataRow =
    allReminders?.map(({reminderId, warehouseId, period, notification, date, unit}, index) => {
      const reminderWarehouse = allWarehouses?.find(warehouse => warehouse.warehouseId === warehouseId) ?? null;
      const reminderCountry = findCountryByWarehouseId({warehouseId, allWarehouses, countries});

      return {
        id: reminderId,
        number: ++index,
        countryId: reminderCountry?.countryId,
        country: reminderCountry?.shortName ?? " - ",
        warehouse: reminderWarehouse?.name ?? " - ",
        startDate: date?.name ?? " - ",
        description: date?.description ?? " - ",
        type: (language === "en" ? unit?.engName : unit?.name) ?? " - ",
        term: period ?? " - ",
        notification: notification ?? " - ",
      };
    }) ?? [];
  const wrTermsMainTableData = currentCountryAndWrh.countryId
    ? wrTermsMainTableDataRow
        .filter(item => item.countryId === currentCountryAndWrh.countryId)
        .map((item, index) => ({...item, number: ++index}))
    : wrTermsMainTableDataRow;

  function onWrTermsMainTableSelect(reminderId) {
    if (!reminderId) {
      setCurrentReminder(null);
      setCurrentDateId(null);
      return;
    }
    const currentReminder = allReminders?.find(reminder => reminder.reminderId === reminderId) ?? null;
    setCurrentReminder(currentReminder);
    setCurrentDateId(currentReminder?.dateId ?? null);
  }

  return {
    initialValues,
    wrTermsMainTableColumns,
    wrTermsMainTableData,
    currentWarehouseId,
    onWrTermsMainTableSelect,
    countryOptions,
    warehouseOptions,
    startDateOptions,
    startDatesTableData,
    onSaveFirstTermBtnClick,
    onDeleteFirstTermBtnClick,
    onSaveSecondTermBtnClick,
    onDeleteSecondTermBtnClick,
    termTypeOptions,
    wrSettingsInfoTableData,
    onCountrySelect,
    isCountrySelected,
    onStartDatesTableSelect,
    currentDateId,
    onWarehouseSelect,
    warehouseInSettingsOptions,
    onWrSettingsWarehouseSelect,
    currentReminderId,
    areRemindersLoading,
  };
};

export default useLogModSettingsDeadlines;

function findCountryByWarehouseId({warehouseId, allWarehouses, countries}) {
  if (!warehouseId || !allWarehouses || !countries) return null;

  const reminderWarehouse = allWarehouses.find(warehouse => warehouse.warehouseId === warehouseId);
  const reminderCountryId = reminderWarehouse.countryId;
  const reminderCountry = countries.find(country => country.countryId === reminderCountryId);

  return reminderCountry;
}
