import Divider from "../../../../utilities/Divider/Divider";
import {ReadOnlyFieldsToggled} from "../../../hoc/ReadOnlyFields";
import {TextAreaInput} from "../../../Inputs";

import RecipientClientBalance from "./Parts/bottom/RecipientClientBalance";
import RecipientClientNumber from "./Parts/bottom/RecipientClientNumber";
import RecipientCoBalance from "./Parts/bottom/RecipientCoBalance";
import RecipientCreditForCo from "./Parts/bottom/RecipientCreditForCo";
import CreditData from "./Parts/middle/CreditData";
import PayerData from "./Parts/middle/PayerData";
import PayerDataWithCheckbox from "./Parts/middle/PayerDataWithCheckbox";
import Sum from "./Parts/middle/Sum";
import SumCurrency from "./Parts/middle/SumCurrency";
import Sums from "./Parts/middle/Sums";
import SumsWithAdvance from "./Parts/middle/SumsWithAdvance";
import PayerClientBalance from "./Parts/top/PayerClientBalance";
import PayerClientNumber from "./Parts/top/PayerClientNumber";
import PayerCoBalance from "./Parts/top/PayerCoBalance";
import PayerCreditForCo from "./Parts/top/PayerCreditForCo";

export const ORDER_CHANGING_PART_TYPES = Object.freeze({
  ON_CLIENT_BALANCE_CASHDESK: "ON_CLIENT_BALANCE_CASHDESK",
  CO_ADVANCE_CASHDESK: "CO_ADVANCE_CASHDESK",
  CO_ADDIT_PAYMENT_CASHDESK: "CO_ADDIT_PAYMENT_CASHDESK",
  CLIENT_REFUND_CASHDESK: "CLIENT_REFUND_CASHDESK",
  CREDIT_REPAYMENT_CASHDESK: "CREDIT_REPAYMENT_CASHDESK",

  ON_CLIENT_BALANCE_GENERAL: "ON_CLIENT_BALANCE_GENERAL",
  CO_ADVANCE_GENERAL: "CO_ADVANCE_GENERAL",
  CO_AFTERPAYMENT_GENERAL: "CO_AFTERPAYMENT_GENERAL",
  CLIENT_REFUND_GENERAL: "CLIENT_REFUND_GENERAL",
  ON_CREDIT_REPAYMENT_GENERAL: "ON_CREDIT_REPAYMENT_GENERAL",

  FROM_CO_TO_BALANCE_INNER: "FROM_CO_TO_BALANCE_INNER",
  FROM_BALANCE_TO_CO_INNER: "FROM_BALANCE_TO_CO_INNER",
  CO_AFTERPAYMENT_INNER: "CO_AFTERPAYMENT_INNER",
  ON_CREDIT_REPAYMENT_INNER: "ON_CREDIT_REPAYMENT_INNER",

  CREDIT_TO_CO_ADVANCE_CREDIT: "CREDIT_TO_CO_ADVANCE_CREDIT",
});

const OrderChangingPart = ({type, readOnly = false}) => {
  const fieldsRender = () => {
    switch (type) {
      //1row
      case ORDER_CHANGING_PART_TYPES.ON_CLIENT_BALANCE_CASHDESK:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientNumber />
            <SumCurrency readOnly={readOnly} />
            <div>
              <Divider />
              <RecipientClientBalance />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.CO_ADVANCE_CASHDESK:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientNumber />
            <SumsWithAdvance readOnly={readOnly} />
            <div>
              <Divider />
              <RecipientCoBalance />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.CO_ADDIT_PAYMENT_CASHDESK:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientNumber />
            <Sums readOnly={readOnly} />
            <div>
              <Divider />
              <RecipientCoBalance />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.CLIENT_REFUND_CASHDESK:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientBalance />
            <SumCurrency readOnly={readOnly} />
            <div>
              <Divider />
              <RecipientClientNumber />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.CREDIT_REPAYMENT_CASHDESK:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientNumber />
            <Sum readOnly={readOnly} />
            <div>
              <Divider />
              <RecipientCreditForCo />
            </div>
          </div>
        );

      //2row
      case ORDER_CHANGING_PART_TYPES.ON_CLIENT_BALANCE_GENERAL:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientNumber />
            <div className="fcCol aistr jcsa mt fGrow">
              <PayerDataWithCheckbox readOnly={readOnly} />
              <SumCurrency readOnly={readOnly} />
            </div>
            <div className="">
              <Divider />
              <RecipientClientBalance />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.CO_ADVANCE_GENERAL:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientNumber />
            <div>
              <PayerDataWithCheckbox readOnly={readOnly} />
              <SumsWithAdvance readOnly={readOnly} />
            </div>
            <div className="mta">
              <Divider />
              <RecipientCoBalance />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.CO_AFTERPAYMENT_GENERAL:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientNumber />
            <div>
              <PayerDataWithCheckbox readOnly={readOnly} />
              <Sums readOnly={readOnly} />
            </div>
            <div className="mta">
              <Divider />
              <RecipientCoBalance />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.CLIENT_REFUND_GENERAL:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientBalance />
            <SumCurrency readOnly={readOnly} />
            <div>
              <Divider />
              <PayerData readOnly={readOnly} />
              <RecipientClientNumber />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.ON_CREDIT_REPAYMENT_GENERAL:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientNumber />
            <div>
              <PayerDataWithCheckbox readOnly={readOnly} />
              <Sum readOnly />
            </div>
            <div>
              <Divider />
              <RecipientCreditForCo />
            </div>
          </div>
        );

      //3row
      case ORDER_CHANGING_PART_TYPES.FROM_CO_TO_BALANCE_INNER:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerCoBalance />
            <Sum readOnly={readOnly} />
            <div>
              <Divider />
              <RecipientClientBalance />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.FROM_BALANCE_TO_CO_INNER:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientBalance />
            <div>
              <SumsWithAdvance readOnly={readOnly} />
              <Sum readOnly={readOnly} />
            </div>
            <div>
              <Divider />
              <RecipientCoBalance />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.CO_AFTERPAYMENT_INNER:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientBalance />
            <Sums readOnly={readOnly} />
            <div>
              <Divider />
              <RecipientCoBalance />
            </div>
          </div>
        );

      case ORDER_CHANGING_PART_TYPES.ON_CREDIT_REPAYMENT_INNER:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerClientBalance />
            <Sum readOnly={readOnly} />
            <div>
              <Divider />
              <RecipientCreditForCo />
            </div>
          </div>
        );

      //4row
      case ORDER_CHANGING_PART_TYPES.CREDIT_TO_CO_ADVANCE_CREDIT:
        return (
          <div className="fcCol jcsb aistr mt fGrow">
            <PayerCreditForCo />
            <CreditData readOnly={readOnly} />
            <div>
              <Divider />
              <RecipientCoBalance />
            </div>
          </div>
        );

      default:
        return <TextAreaInput name="_" label="" className="fGrow" height={"auto"} disabled />;
    }
  };
  const fields = fieldsRender();
  return <ReadOnlyFieldsToggled readOnly={readOnly}>{fields}</ReadOnlyFieldsToggled>;
};

export default OrderChangingPart;
