import docIcon from "../../../images/icons/File_Document_icon.png";
import ToolTip from "../../ToolTip";

export const ProjectDocButton = ({disabled, title = "Open documents", ...props}) => {
  return (
    <ToolTip title={title}>
      <button type="button" className="status-button" disabled={disabled} {...props}>
        <img src={docIcon} alt="grid" width="18em" height="20em" draggable="false" />
      </button>
    </ToolTip>
  );
};
