import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../utilities/hooks/toaster";
import {getSuccess} from "../../../../utilities/toasts";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import FinBlockOrderDirectiveDo from "../../../FinBlock/FinBlockDirectiveList/FinBlockOrderDirectiveDo/FinBlockOrderDirectiveDo";
import {
  addNewDirective,
  addNewFinOrder,
  clearCreatedDirective,
  fetchAllFinStatuses,
  fetchFinOrder,
  selectAllStatuses,
  selectCreatedDirective,
  selectReadyToCreateDirectiveCO,
  updateDirective,
} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import OrderDirectiveView from "../OrderDirectiveView/OrderDirectiveView";
import OrderRegister from "../OrderRegister/OrderRegister";

const useMainDirective = () => {
  const {
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  const {closeCurrentTab, changeCurrentTabName, addNewTabCustom} = useTabsContext();
  const {values, setValues} = useFormikContext();

  const {fulfilBy, finOrderTypeId, operationTypeId, areAllFieldsFilledIn} = values;

  const createdDirective = useSelector(selectCreatedDirective);
  const isDirectiveCreated = createdDirective?.directiveId;
  const hasDirectivePayment = Number.parseFloat(createdDirective?.sumFact) > 0;

  const statuses = useSelector(selectAllStatuses);
  const co = useSelector(selectReadyToCreateDirectiveCO) || createdDirective?.co;
  const coId = co?.coId;

  const [fileArray, setFileArrayUnsaved] = useState([]);
  const setFileArray = useCallback(setFileArrayUnsaved, []);

  const contractTableDataRow = useMemo(() => co?.project.contracts, [coId]);
  const annexTableDataRow = useMemo(() => co?.annexes, [coId]);
  const einfoTableDataRow = useMemo(() => co?.project.einfo, [coId]);

  useEffect(() => {
    dispatch(fetchAllFinStatuses());
  }, []);

  useEffect(() => {
    if (co?.coId) {
      const {project, account, coNumber, sum, prepayment} = co;
      const {butget, client} = project;

      setValues(prev => ({
        ...prev,
        areAllFieldsFilledIn: true,
        fulfilBy: new Date().toISOString(),
        directiveType: "",
        // status: "",
        coNumber,
        coBalance: account?.balance,
        clientBalance: client?.account?.balance,
        clientProject: butget,
        coSum: sum,
        advance: (sum && (prepayment / sum) * 100) || "0",
        nSum: prepayment,
        fact: (sum && (account?.balance / sum) * 100) || "0",
      }));
    }
  }, [co?.coId]);

  useEffect(() => {
    if (createdDirective) {
      const directive = createdDirective;
      changeCurrentTabName(`${t("Directive №")} ${directive.directiveNumber}`);

      const {status, orders, recipient, co, sumFact, sumPlan, payer, outsideParty} = directive;
      const {project, account, coNumber, sum, prepayment} = directive.co;
      const {butget, client} = project;

      // const payer = orders ? orders[0]?.payer : {};
      // const outsideParty = orders ? orders[0]?.outsideParty : {};
      const loanTerms = orders ? orders[0]?.loanTerms : {};

      let newDirective = {
        status: language === "en" ? status?.nameEng : status?.nameUkr,

        payerCoBalanceComposed: payer?.balance,
        payerClientNumberComposed: payer?.clientNumber,
        payerClientBalanceComposed: payer?.balance,
        payerCreditForCoComposed: payer?.balance,

        recipientClientBalanceComposed: recipient?.balance,
        recipientCoBalanceComposed: recipient?.balance,
        recipientClientNumberComposed: recipient?.clientNumber,
        recipientCreditForCoComposed: recipient?.balance,

        creditTermComposed: loanTerms?.termUpTo,
        perAnnumComposed: loanTerms?.annualInterestRate,
        repaymentTermIdComposed: loanTerms?.repaymentTermsId,
        creditSumComposed: sumPlan ?? "",

        payerNameComposed: outsideParty?.name,
        edrpouoComposed: outsideParty?.edrpouCode,
        payerAccountComposed: outsideParty?.currentAccount,

        sumComposed: sumPlan || "",
        currencyIdComposed: orders[0]?.currencyId || "",

        coSumComposed: co?.sum,
        fSumComposed: sumFact ?? "0",
        coSumAdditionalComposed: co?.prepayment ?? "0",

        apSumComposed: sumPlan ?? "0",
        advancePercentageComposed: ((sumPlan / sum) * 100).toFixed(2) || 0,

        afSumComposed: sumFact ?? "0",

        fulfilBy: directive?.fulfilBy || new Date().toISOString(),
        directiveType: "",
        coNumber,
        coBalance: account?.balance,
        clientBalance: client?.account?.balance,
        clientProject: butget,
        coSum: sum,
        advance: (sum && (prepayment / sum) * 100) || "0",
        nSum: prepayment,
        fact: (sum && (account?.balance / sum) * 100) || "0",

        // finOrderTypeId: directive.orders[0].typeId,
        operationTypeId: directive.operationTypeId,
        executorNote: directive?.note,
      };

      setValues(prev => ({...prev, ...newDirective}));
    }
  }, [createdDirective]);

  function onOkBtnClick() {
    if (!fulfilBy || !finOrderTypeId || !operationTypeId || !coId) return;

    dispatch(addNewDirective({coId, operationTypeId, typeId: finOrderTypeId, fulfilBy}));
  }

  function onForImplementationBtnClick() {
    if (!isDirectiveCreated) {
      console.error("No created directive");
      return;
    }

    const {
      executorNote,
      edrpouoComposed,
      payerNameComposed,
      payerAccountComposed,
      sumComposed,
      currencyIdComposed,
      // repaymentTermIdComposed,
      // creditTermComposed,
      // perAnnumComposed,
      creditSumComposed,
    } = values;

    if (sumComposed < 0 || creditSumComposed < 0) {
      toaster.error("Sum can`t be a negative number");
      return;
    }

    dispatch(
      updateDirective({
        directiveId: createdDirective?.directiveId,
        body: {
          ...(sumComposed || creditSumComposed ? {sumPlan: sumComposed?.trim() ? sumComposed : creditSumComposed} : {}),
          statusId: statuses?.find(status => status.nameEng === "Sent for implementation")?.statusId,
          ...(executorNote ? {note: executorNote} : {}),
          ...(currencyIdComposed ? {currencyId: currencyIdComposed} : {}),
          ...(payerNameComposed || edrpouoComposed || payerAccountComposed
            ? {
                outsideParty: {
                  partyId: createdDirective?.outsideParty?.partyId,
                  ...(payerNameComposed ? {name: payerNameComposed} : {}),
                  ...(edrpouoComposed ? {edrpouCode: edrpouoComposed} : {}),
                  ...(payerAccountComposed ? {currentAccount: payerAccountComposed} : {}),
                },
              }
            : {}),

          // order: {
          //   orderId: createdDirective?.orders[0].orderId,
          //   ...(currencyIdComposed ? {currencyId: currencyIdComposed} : {}),
          //   ...(payerNameComposed || edrpouoComposed || payerAccountComposed
          //     ? {
          //         outsideParty: {
          //           partyId: createdDirective?.orders[0].outsideParty?.partyId,
          //           ...(payerNameComposed ? {name: payerNameComposed} : {}),
          //           ...(edrpouoComposed ? {edrpouCode: edrpouoComposed} : {}),
          //           ...(payerAccountComposed ? {currentAccount: payerAccountComposed} : {}),
          //         },
          //       }
          //     : {}),
          //   ...(repaymentTermIdComposed || creditTermComposed || perAnnumComposed
          //     ? {
          //         loanTerms: {
          //           termsId: createdDirective?.orders[0].loanTerms?.termsId,
          //           ...(repaymentTermIdComposed ? {repaymentTermsId: repaymentTermIdComposed} : {}),
          //           ...(creditTermComposed ? {termUpTo: creditTermComposed} : {}),
          //           ...(perAnnumComposed ? {annualInterestRate: perAnnumComposed} : {}),
          //         },
          //       }
          //     : {}),
          // },
        },
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        getSuccess("Created directive has been sent for execution");
        closeCurrentTab();
        dispatch(clearCreatedDirective());
      }
    });
  }

  function onCancelBtnClick() {
    swalCustom.confirm({
      confirmFunc: () => {
        closeCurrentTab();
        dispatch(clearCreatedDirective());
      },
    });
  }

  function onCancelDirectiveBtnClick() {
    if (!createdDirective || hasDirectivePayment) return;

    swalCustom.confirm({
      confirmFunc: () => {
        // const tabOrdersIndex = tabs.findIndex(
        //   tab => tab.tabIdName === `Directive №${createdDirective?.directiveId} orders`,
        // );
        // closeTab(tabOrdersIndex);
        closeCurrentTab();

        dispatch(clearCreatedDirective());
      },
    });
  }

  function onTakeIntoWorkBtnClick() {
    if (!createdDirective || !values.finOrderTypeId) return;

    dispatch(addNewFinOrder({directiveId: createdDirective?.directiveId, body: {typeId: values.finOrderTypeId}})).then(
      resp => {
        if (resp.meta.requestStatus === "fulfilled")
          dispatch(
            fetchFinOrder(resp.payload.directive.orders[resp.payload?.directive.orders.length - 1].orderId),
          ).then(resp => {
            addNewTabCustom({
              TabComponent: OrderDirectiveView,
              title: `${t("Order number ")} ${resp.payload.orderNumber}`,
              tabIdName: "orderNumberTab",
            });
            if (createdDirective.orders.length === 0) {
              addNewTabCustom({
                TabComponent: OrderRegister,
                title: "Directive`s Order register",
                tabIdName: `Directive №${createdDirective?.directiveId} orders`,
              });
            }
          });
      },
    );
  }

  return {
    fileArray,
    setFileArray,
    onForImplementationBtnClick,
    onCancelBtnClick,
    onOkBtnClick,
    contractTableDataRow,
    annexTableDataRow,
    einfoTableDataRow,
    isDirectiveCreated,
    areAllFieldsFilledIn,
    onCancelDirectiveBtnClick,
    hasDirectivePayment,
    onTakeIntoWorkBtnClick,
  };
};

export default useMainDirective;
