import {t} from "i18next";
import Swal from "sweetalert2";

export const swalCustom = {
  confirm: ({confirmFunc = () => {}}) => {
    Swal.fire({
      title: "",
      text: `${t("Are you sure?")}`,
      icon: "question",
      confirmButtonText: [t("Yes")],
      showCancelButton: true,
      cancelButtonText: [t("No")],
    }).then(answ => {
      if (answ.isConfirmed) {
        confirmFunc();
      }
    });
  },
  info: ({confirmFunc = () => {}, text = "", title = "", noTitle, showCancelButton = true}) => {
    Swal.fire({
      title: !noTitle ? t(title) || t("Information") : "",
      text: t(text),
      icon: "info",
      confirmButtonText: [t("Yes")],
      showCancelButton,
      cancelButtonText: [t("No")],
    }).then(answ => {
      if (answ.isConfirmed) {
        confirmFunc();
      }
    });
  },
  unsavedData: ({confirmFunc = () => {}, text = "", title = "", cancelFunc = () => {}}) => {
    Swal.fire({
      title: t(title) || t("You have unsaved issues. Close anyway?"),
      text: t(text),
      icon: "warning",
      confirmButtonText: [t("Yes")],
      showCancelButton: true,
      cancelButtonText: [t("No")],
    }).then(answ => {
      if (answ.isConfirmed) {
        confirmFunc();
      } else cancelFunc();
    });
  },
};
