import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {TextAreaInput} from "../../../Inputs";
import ImgBox from "../../../Inputs/fileSelect/ImgBox/ImgBox";
import {getColorImages, getDecorImages, getDrawings, getSkuImages} from "../../SkuSlice";
import {processImages} from "../constants/functions";

const Decoration = () => {
  const {t} = useTranslation();

  const currentSku = useSelector(state => state.sku.currentSku);

  const skuImages = useSelector(getSkuImages),
    colorImages = useSelector(getColorImages),
    decorImages = useSelector(getDecorImages),
    drawings = useSelector(getDrawings);

  return (
    <div className="fc nowrap " style={{alignItems: "start", width: "55%", height: "100%"}}>
      <div className="fc nowrap jcc aist" style={{gap: "10px", height: "100%"}}>
        <ImgBox label={t("General view")} classNames="sku_item_image" imgs={processImages(skuImages) || []}></ImgBox>
        <ImgBox label={t("Drawings")} classNames="sku_item_image" imgs={processImages(drawings) || []}></ImgBox>
      </div>
      <div
        className="fc wrap"
        style={{marginLeft: "1em", height: "100%", width: "60%", justifyContent: "space-between"}}
      >
        <div className="fc nowrap aist" style={{height: "49%", gap: "5px", flexGrow: "2"}}>
          <ImgBox label={t("Finishing") + ":"} classNames="smaller-image-box" imgs={processImages(decorImages) || []} />
          <TextAreaInput label={t("Finishing type")} name="materialType" width="100%" height="100%" readOnly={true} />
        </div>
        <div className="fc nowrap aist" style={{height: "49%", gap: "5px", flexGrow: "2"}}>
          <ImgBox label={t("Color") + ":"} classNames="smaller-image-box" imgs={processImages(colorImages) || []} />
          <TextAreaInput label={t("Color type")} name="colorType" width="100%" height="100%" readOnly={true} />
        </div>
      </div>
      {/* <div
        className="autolabel fc nowrap"
        style={{
          marginLeft: "15px",
          width: "100%",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div style={{height: "50%", width: "100%"}}>
          <TextAreaInput label={t("Finishing type")} name="materialType" width="95%" height="100%" readOnly={true} />
        </div>
        <div style={{height: "50%", width: "100%"}}>
          <TextAreaInput
            label={t("Color type")}
            id="colorType"
            name="colorType"
            width="95%"
            height="100%"
            readOnly={true}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Decoration;
