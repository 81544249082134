import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {CASH_VIEW_TABLE_COLUMNS} from "../constants";

const useCashView = () => {
  const cashViewTableColumns = useColumns(CASH_VIEW_TABLE_COLUMNS);
  const cashViewTableData = [];

  function onCashViewTableSelect() {}

  function onSavePdfBtnClick() {}
  function onPrintBtnClick() {}

  return {onSavePdfBtnClick, onPrintBtnClick, cashViewTableColumns, cashViewTableData, onCashViewTableSelect};
};

export default useCashView;
